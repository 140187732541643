import React, { useState, useLayoutEffect, useEffect } from "react";
import styled, { css } from "styled-components";
import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Button from "../../atoms/button";
import about_img5 from "../../assets/images/Logos of media coverage 2.png";
import heros from "../../assets/images/hero_1.png";
import anju from "../../assets/images/anju_kish.jpg";
import anjubook from "../../assets/images/anjubook.png";
import booklaunch from "../../assets/images/booklaunch.jpg";
import about_img7 from "../../assets/images/Anjukish.png";
import { ControlledCarousel } from "../../organisms/BannerCarousel";
import { TestimonialCarousel } from "../../organisms/Testimonial";
import { WorkshopCarousel } from "../../organisms/workshoop";
import video_bg from "../../assets/images/homepageThumbnail.jpg";
import CountUp from "react-countup";
import pfg_img from "../../assets/images/1.png";
import pfb_img from "../../assets/images/2.png";
import prog3 from "../../assets/images/3.png";
import prog4 from "../../assets/images/4.png";
import prog5 from "../../assets/images/5 (2).png";
import prog6 from "../../assets/images/6.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Faq } from "../../organisms/faq";
import cat from "../../assets/images/caticon.png";
import { BrandLogos } from "../../organisms/brandlogo";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";
import pfbg_img from "../../assets/images/pfbg_image.png";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import axios from "axios";
import {
  useGAEventPubertyAdventure9to12yrBoys,
  useGAEventPubertyAdventure9to12yrGirls,
  useGAEventSafety13to15yr,
  useGAEventSexualliteracy13to15yr,
  useGAEventUpcomingRegisterYourInterest,
  useGAEventSexsexualitySafety16to18yr,
  useGAEventSmokingAlcoholDrugs13to18yr,
  useGAEventPubertyForBoysHomeRedirect,
  useGAEventPubertyForGirlsHomeRedirect,
  useGAEventShowMeHow,
  useGAEventAlreadyAMemberLogin,
  useGAEventSignUp,
} from "../../hoc/hooks/useGAEventTracker";
import pubertyGirlNBoy from "../../assets/images/Puberty & Beyond.jpg";
import pubertyGirl from "../../assets/images/Positive Puberty for Girls.png";
import { MdOutlineWatchLater } from "react-icons/md";
import { FaRegUser } from "react-icons/fa";
import { app_api } from "../../atoms/api";
import Mvideo from "../../atoms/ModalVideo";

export const Home = (props) => {
  //   useLayoutEffect(() => {
  //     window.scrollTo(0, 0)
  // });
  const GAEventShowMeHow = useGAEventShowMeHow();
  const GAEventPubertyAdventure9to12yrBoys =
    useGAEventPubertyAdventure9to12yrBoys();
  const GAEventPubertyAdventure9to12yrGirls =
    useGAEventPubertyAdventure9to12yrGirls();
  const GAEventSafety13to15yr = useGAEventSafety13to15yr();
  const GAEventSexualliteracy13to15yr = useGAEventSexualliteracy13to15yr();
  const GAEventUpcomingRegisterYourInterest =
    useGAEventUpcomingRegisterYourInterest();
  const GAEventSexsexualitySafety16to18yr =
    useGAEventSexsexualitySafety16to18yr();
  // const GAEventSmokingAlcoholDrugs13to18yr = useGAEventSmokingAlcoholDrugs13to18yr();
  const GAEventPubertyForBoysHomeRedirect =
    useGAEventPubertyForBoysHomeRedirect();
  const GAEventPubertyForGirlsHomeRedirect =
    useGAEventPubertyForGirlsHomeRedirect();
  const GAEventAlreadyAMemberLogin = useGAEventAlreadyAMemberLogin();

  const navigate = useNavigate();
  const params = useParams();
  const [isOpen, setOpen] = useState(false);
  function filterPrograms(e) {
    const programs = document.querySelectorAll(".programsfilter .filtercard"); // select all animal divs
    const active = document.querySelectorAll(".filterbtn button");
    let filter = e.target.dataset.filter; // grab the value in the event target's data-filter attribute
    programs.forEach((program) => {
      program.classList.contains(filter) // does the animal have the filter in its class list?
        ? program.classList.remove("hidden") // if yes, make sure .hidden is not applied
        : program.classList.add("hidden"); // if no, apply .hidden
    });
    let activefilter = e.target.dataset.filter;
    active.forEach((values) => {
      // values.classList.contains(filter)
      values.classList.contains(activefilter)
        ? values.classList.add("processactive")
        : values.classList.remove("processactive");

      // values.classList.add("processactive");
    });
  }
  document.title = "UnTaboo";
  // useLayoutEffect(() => {
  //   window.scrollTo(0, 0);
  // });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [value, onChange] = useState(new Date());

  const subscribe = (e) => {
    e.preventDefault();
    setLoading(true);
    const formdata = new FormData(e.target);
    let values = {};
    formdata.forEach((value, key) => {
      values[key] = value;
    });
    app_api
      .post("/user/subscribe/newsletter", values)
      .then((res) => {
        setLoading(false);
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 3000);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Error");
      });
  };

  return (
    <div className="poppins  px-lg-0">
      {/* <ControlledCarousel></ControlledCarousel> */}
      <div className="bg-img-about">
        <Container>
          <div className="pt-lg-10 pt-5  px-4 px-lg-0 d-lg-flex ">
            <div className="col-lg-6 col-12 ">
              <h4 className="f-25">Navigating Teenagehood</h4>

              <h1
                className="f-55 f-sm-38"
                style={{
                  fontWeight: "700",
                  color: "#1e4363",
                }}
              >
                Growing Up Safe<br></br>
                With UnTaboo
              </h1>
              <p
                className="mt-lg-5 mt-4 secondary-b  text-lg-start"
                style={{ lineHeight: "1.8" }}
              >
                Talking to pre-teens and teens about bodies, puberty, sex,
                relationships, or even drugs & safety can get tricky! We
                understand, and at UnTaboo a Safety & Sex Education company, we
                gently introduce them to age-appropriate, expert-created content
                in a fun and positive way. This leads to a healthy mindset,
                empowering them to navigate their teenage years safely and
                responsibly.
              </p>
              <div className="bg-red-800 gap mt-5 text-center text-lg-start">
                <a href="#programDetails" className="me-sm-4 me-0">
                  <Button onClick={GAEventShowMeHow} className="me-lg-3 mb-3">
                    Show Me How
                  </Button>
                </a>
                <Button
                  className="me-lg-3 mb-3"
                  onClick={() =>
                    GAEventAlreadyAMemberLogin("/login?auth=signin")
                  }
                  secondary
                >
                  Already A Member? Login
                </Button>
              </div>
            </div>
            <div className="col-lg-6 col-12 mt-lg-0 mt-5">
              <img className="w-100" src={heros} alt={"untaboo characters"} />
            </div>
          </div>
        </Container>

        <div className="bg_img_b mt-5 pt-5 pt-lg-0">
          <Container className="  d-lg-flex justify-content-between align-items-center">
            <div className="col-12 col-lg-6 mb-4  position-relative text-center">
              <img
                className="w-90 "
                src={video_bg}
                alt={"video_img"}
                style={{
                  borderRadius: "20px",
                  boxShadow: " rgb(0 0 0 / 20%) 0px 3px 10px",
                }}
              />
              <Mvideo
                channel="custom"
                // autoplay
                isOpen={isOpen}
                // videoId="L61p2uyiMSo"
                url="https://api.untaboo.online/uploads/FILM_19_Untaboo_Home.m3u8"
                onClose={() => setOpen(false)}
              />
              {/* <div style={{position: 'absolute' ,top:'0',left:'30%'}}>
              <video autoplay isOpen={isOpen}  onClose={() => setOpen(false)} src={video} width="750" height="450" controls></video>
              </div> */}
              <button
                onClick={() => setOpen(true)}
                className="video-play blue_shadow rounded-circle position-absolute  d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#6BCACF",
                  width: "80px",
                  height: "80px",
                  left: "43%",
                  top: "40%",
                }}
              >
                <div
                  className=""
                  style={{
                    width: "0",
                    height: " 0",
                    borderTop: "12px solid transparent",
                    borderBottom: "12px solid transparent",
                    borderLeft: "12px solid white",
                  }}
                ></div>
              </button>
            </div>

            <div className="col-lg-5 text-center text-lg-start">
              <h3
                className="secondary-blue f-40 px-4 px-lg-0"
                style={{ fontWeight: "600" }}
              >
                EDUCATING & EMPOWERING SINCE 2011
              </h3>

              <p
                className="my-4 secondary-b px-4 px-lg-0"
                style={{ lineHeight: "1.7" }}
              >
                UnTaboo’s programs have helped parents and teachers overcome the
                pressures of providing Safety & Sex education for children and
                teens. Join the growing community of UnTabooers who believe in
                the safe upbringing of children.
              </p>
              <div
                className="mt-4 mx-auto col-lg-10 col-12 rounded-2 d-flex flex-wrap justify-content-around p-4"
                style={{ backgroundColor: "#f4f8ff" }}
              >
                <div
                  className="w-43 w-sm-43 mb-3 rounded-2 text-center d-flex flex-column justify-content-center align-items-center text-white py-4 px-md-2 p-5"
                  style={{ backgroundColor: "#6BCACF", height: "182px" }}
                >
                  <h4 className="Playfair" style={{ fontWeight: "700" }}>
                    <CountUp end={100} duration={2} suffix="k+">
                      100k+
                    </CountUp>
                  </h4>
                  <p>
                    Children <br></br>
                    Empowered
                  </p>
                </div>
                <div
                  className="w-43 w-sm-43 mb-3 rounded-2 text-center d-flex flex-column align-items-center justify-content-center text-white py-4 px-md-2 p-5"
                  style={{ backgroundColor: "#6BCACF", height: "182px" }}
                >
                  <h4 className="Playfair" style={{ fontWeight: "700" }}>
                    <CountUp end={10} duration={2} suffix="k+">
                      10k+
                    </CountUp>
                  </h4>
                  <p>
                    Parents &<br></br> Teachers<br></br>
                    Impacted
                  </p>
                </div>
                <div
                  className="w-43 w-sm-43 mb-3 mb-lg-0 rounded-2 text-center d-flex flex-column align-items-center justify-content-center text-white py-4 px-md-2 p-5"
                  style={{ backgroundColor: "#6BCACF", height: "182px" }}
                >
                  <h4 className="Playfair" style={{ fontWeight: "700" }}>
                    <CountUp end={100} duration={2} suffix="+">
                      100+
                    </CountUp>
                  </h4>
                  <p>
                    School <br></br>
                    Programs
                  </p>
                </div>
                <div
                  className="w-43 w-sm-43 rounded-2 text-center d-flex flex-column justify-content-center align-items-center text-white py-4 px-md-2 p-5"
                  style={{ backgroundColor: "#6BCACF", height: "182px" }}
                >
                  <h4 className="Playfair" style={{ fontWeight: "700" }}>
                    <CountUp end={30} duration={2} suffix="k+">
                      30k+
                    </CountUp>
                  </h4>
                  <p>
                    Learning <br></br>
                    Hours
                  </p>
                </div>
                <div className="my-5">
                  <a href="#programDetails">
                    <Button secondary>I want this for my child</Button>
                  </a>
                </div>
              </div>
            </div>
          </Container>
        </div>

        {/* --------------- Courses --------------------------- */}
        <Container className="pt-lg-10" id="programDetails">
          <div className="my-10 text-center ">
            <div className="mx-auto px-4 px-lg-0 col-12 col-lg-9 ">
              <h2
                className="secondary-blue mb-4 f-40"
                style={{ fontWeight: "600" }}
              >
                ENGAGING ONLINE PROGRAMS
              </h2>
              <p className="secondary-b pt-2">
                From explaining concepts simply to helping shape their thinking,
                from helping them develop coping skills to teaching them to be
                safe...we are with you from the start to the finish, to help
                nurture your kids into well-informed and responsible young
                adults.<br></br>
                <b>
                  {" "}
                  Buy a program for your child - it will be the gift of a
                  lifetime!{" "}
                </b>
              </p>
            </div>

            <div className="pb-5 text center d-flex mt-5 filterbtn">
              <div className="mx-auto col-lg-9 col-12 d-lg-flex justify-content-around">
                <button
                  defaultActiveKey="all"
                  data-filter="filtercard"
                  onClick={filterPrograms}
                  type="button"
                  className="Fbtn processactive filtercard btn btn-outline-primary m-lg-0 m-2 rounded-3 secondary-blue"
                >
                  ALL
                </button>
                <button
                  data-filter="boysgirls"
                  onClick={filterPrograms}
                  type="button"
                  className="Fbtn boysgirls btn btn-outline-primary rounded-3 m-lg-0 m-2 secondary-blue"
                >
                  9-12 years
                </button>
                <button
                  data-filter="awareness"
                  onClick={filterPrograms}
                  type="button"
                  className="Fbtn awareness btn btn-outline-primary rounded-3 m-lg-0 m-2 secondary-blue"
                >
                  13 to 15 years
                </button>
                <button
                  data-filter="safety"
                  onClick={filterPrograms}
                  type="button"
                  className="Fbtn safety btn btn-outline-primary rounded-3 m-lg-0 m-2 secondary-blue"
                >
                  16 to 18 years
                </button>

                <button
                  onClick={() =>
                    GAEventUpcomingRegisterYourInterest("/contact")
                  }
                  type="button"
                  className="btn btn-outline-primary rounded-3 m-lg-0 m-2 secondary-blue"
                >
                  Upcoming - Register your Interest
                </button>
              </div>
            </div>

            <div className="programsfilter px-2 px-lg-0 d-lg-flex flex-wrap justify-content-around mt-5 pt-3">
              <div
                className="boysgirls mx-auto  mb-5 card filtercard program_cards w-sm-100 rounded-3"
                style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
              >
                <img
                  className="w-100 rounded-3-top"
                  src={pfb_img}
                  alt={"course_img"}
                  style={{ borderRadius: "10px 10px 0 0 " }}
                />

                <div className="boysgirls  mx-auto col-10 py-4">
                  <div className="d-flex">
                    <div className="w-50 text-start">
                      <h3
                        className="secondary-blue fw-bold"
                        style={{ fontSize: "20px", fontWeight: "700" }}
                      >
                        PUBERTY ADVENTURE <br></br> For Boys
                      </h3>
                    </div>
                    <div className="w-50">
                      <h3 className="primary-pink fw-bold">
                        <span
                          style={{
                            color: "grey",
                            fontSize: "20px",
                            textDecoration: "line-through",
                          }}
                        >
                          ₹ 2200.00
                        </span>
                        <br></br>₹ 1499.00
                      </h3>
                    </div>
                  </div>
                  <p
                    className="pb-4 text-start secondary-b p-17"
                    style={{ lineHeight: "1.7", height: "340px" }}
                  >
                    Puberty can be a confusing & stressful time for boys, as
                    most of them grow up with silence around the subject. We
                    open conversations on their changing bodies, emotions and
                    also the changes that girls go through, thus sowing the
                    seeds for a lifetime of body positivity & gender
                    sensitivity.
                  </p>
                  <div className="position-absolute bottom-0 col-10 mx-auto  pb-4">
                    <hr></hr>

                    <Button
                      onClick={() =>
                        GAEventPubertyAdventure9to12yrBoys(
                          "/program/puberty-for-boys"
                        )
                      }
                      style={{ borderRadius: "25px" }}
                    >
                      Start Learning
                    </Button>
                  </div>
                </div>
              </div>

              <div
                className="boysgirls mx-auto mb-5 card filtercard  program_cards w-sm-100 rounded-3"
                style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
              >
                <img
                  className="w-100 rounded-3-top"
                  src={pfg_img}
                  alt={"course_img"}
                  style={{ borderRadius: "10px 10px 0 0 " }}
                />

                <div className="mx-auto col-10 py-4">
                  <div className="d-flex">
                    <div className="w-50 text-start">
                      <h3
                        className="secondary-blue fw-bold"
                        style={{ fontSize: "20px", fontWeight: "700" }}
                      >
                        PUBERTY ADVENTURE<br></br>For Girls
                      </h3>
                    </div>
                    <div className="w-50">
                      <h3 className="primary-pink fw-bold">
                        <span
                          style={{
                            color: "grey",
                            fontSize: "20px",
                            textDecoration: "line-through",
                          }}
                        >
                          ₹ 2200.00
                        </span>
                        <br></br>₹ 1499.00
                      </h3>
                    </div>
                  </div>
                  <p
                    className="pb-4 text-start secondary-b p-17"
                    style={{ lineHeight: "1.7", height: "340px" }}
                  >
                    Puberty is happening very early on for girls today. It can
                    be a confusing & difficult phase, but learning about it
                    before it happens can make puberty a smooth ride for them.
                    We prepare girls, so they feel comfortable with their
                    changing bodies & emotions, empowering them with skills to
                    cope with it positively & happily.
                  </p>
                  <div className="position-absolute bottom-0 col-10 mx-auto  pb-4">
                    <hr></hr>

                    <Button
                      onClick={() =>
                        GAEventPubertyAdventure9to12yrGirls(
                          "/program/puberty-for-girls"
                        )
                      }
                      style={{ borderRadius: "25px" }}
                    >
                      Start Learning
                    </Button>
                  </div>
                </div>
              </div>

              <div
                className="boysgirls mx-auto mb-5 card filtercard  program_cards w-sm-100 rounded-3"
                style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
              >
                <img
                  className="w-100 rounded-3-top"
                  src={pfbg_img}
                  alt={"course_img"}
                  style={{ borderRadius: "10px 10px 0 0 " }}
                />

                <div className="mx-auto col-10 py-4">
                  <div className="d-flex">
                    <div className="w-50 text-start">
                      <h3
                        className="secondary-blue fw-bold"
                        style={{ fontSize: "20px", fontWeight: "700" }}
                      >
                        PUBERTY ADVENTURE<br></br>For Boys & Girls
                      </h3>
                    </div>
                    <div className="w-50">
                      <h3 className="primary-pink fw-bold">
                        <span
                          style={{
                            color: "grey",
                            fontSize: "20px",
                            textDecoration: "line-through",
                          }}
                        >
                          ₹ 2200.00
                        </span>
                        <br></br>₹ 1899.00
                      </h3>
                    </div>
                  </div>
                  <p
                    className="pb-4 text-start secondary-b p-17"
                    style={{ lineHeight: "1.7", height: "340px" }}
                  >
                    Puberty can be a confusing and stressful period for your
                    child, but it needn’t be. Our program turns puberty into an
                    exciting adventure, where every change of both boys & girls
                    is taught in a fun manner. The end result - a positively
                    informed child, ready to face puberty head on and be a
                    supportive and empathetic ally!
                  </p>
                  <div className="position-absolute bottom-0 col-10 mx-auto  pb-4">
                    <hr></hr>
                    <Link to="/program/puberty-adventure-for-boys-and-girls">
                      <Button style={{ borderRadius: "25px" }}>
                        Start Learning
                      </Button>
                    </Link>
                  </div>
                </div>
              </div>

              <div
                className="awareness mx-auto mb-5 card filtercard  program_cards w-sm-100 rounded-3"
                style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
              >
                <img
                  className="w-100 rounded-3-top"
                  src={prog3}
                  alt={"course_img"}
                  style={{ borderRadius: "10px 10px 0 0 " }}
                />

                <div className="mx-auto col-10 py-4">
                  <div className="d-flex">
                    <div className=" text-start">
                      <h3
                        className="secondary-blue fw-bold"
                        style={{
                          fontSize: "20px",
                          fontWeight: "700",
                          height: "90px",
                        }}
                      >
                        SEXUAL LITERACY <br></br> (For 13 -15 Year Olds)
                      </h3>
                    </div>
                  </div>
                  <div>
                    <p
                      className="pb-4 text-start secondary-b p-17"
                      style={{ lineHeight: "1.7", height: "340px" }}
                    >
                      Teenage is a time of curiosity and teens are growing up
                      with age-inappropriate information from the internet. We
                      gently introduce them to the concepts of sex, sexuality,
                      reproduction, sexual changes & positively handling them,
                      so that they grow up safely with responsible attitudes.
                    </p>
                  </div>
                  <div className="position-absolute bottom-0 col-10 mx-auto  pb-4">
                    <hr></hr>

                    <Button
                      onClick={() => GAEventSexualliteracy13to15yr("/contact")}
                      style={{ borderRadius: "25px" }}
                    >
                      Coming soon - Express Interest
                    </Button>
                  </div>
                </div>
              </div>

              <div
                className="awareness mx-auto mb-5 card filtercard  program_cards w-sm-100 rounded-3"
                style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
              >
                <img
                  className="w-100 rounded-3-top"
                  src={prog4}
                  alt={"course_img"}
                  style={{ borderRadius: "10px 10px 0 0 " }}
                />

                <div className="mx-auto col-10 py-4">
                  <div className="d-flex">
                    <div className=" text-start">
                      <h3
                        className="secondary-blue fw-bold"
                        style={{
                          fontSize: "20px",
                          fontWeight: "700",
                          height: "80px",
                        }}
                      >
                        SAFETY - PERSONAL &amp; ONLINE <br></br> (For 13 - 15
                        Year Olds)
                      </h3>
                    </div>
                  </div>
                  <p
                    className="pb-4 text-start secondary-b p-17"
                    style={{ lineHeight: "1.7", height: "340px" }}
                  >
                    Children live in an online world today and are exposed to
                    all kinds of information and unsafe situations there. Our
                    program teaches them to safely navigate the online world
                    while developing responsible behaviour - both in their
                    personal and online world so that they grow up safely.
                  </p>

                  <div className="position-absolute bottom-0 col-10 mx-auto  pb-4 ">
                    <hr></hr>

                    <Button
                      onClick={() => GAEventSafety13to15yr("/contact")}
                      style={{ borderRadius: "25px" }}
                    >
                      Coming soon - Express Interest
                    </Button>
                  </div>
                </div>
              </div>

              <div
                className="safety mx-auto mb-5 card filtercard  program_cards w-sm-100 rounded-3"
                style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
              >
                <img
                  className="w-100 rounded-3-top"
                  src={prog6}
                  alt={"course_img"}
                  style={{ borderRadius: "10px 10px 0 0 " }}
                />

                <div className="mx-auto col-10 py-4">
                  <div className="d-flex">
                    <div className=" text-start">
                      <h3
                        className="secondary-blue fw-bold"
                        style={{
                          fontSize: "20px",
                          fontWeight: "700",
                          height: "75px",
                        }}
                      >
                        SEX- SEXUALITY- SAFETY<br></br> (For 16 -18 Year Olds)
                      </h3>
                    </div>
                  </div>
                  <p
                    className="pb-4 text-start secondary-b p-17"
                    style={{ lineHeight: "1.7", height: "340px" }}
                  >
                    We provide teenagers edging towards young adulthood with
                    accurate information on sex, sexuality, and safety so that
                    they are able to make well-informed, responsible decisions.
                    We help them cultivate gender sensitivity & inclusivity,
                    enable them to develop healthy relationships, and take care
                    of themselves - both physically and emotionally.
                  </p>
                  <div className="position-absolute bottom-0 col-10 mx-auto  pb-4">
                    <hr></hr>

                    <Button
                      onClick={() =>
                        GAEventSexsexualitySafety16to18yr("/contact")
                      }
                      style={{ borderRadius: "25px" }}
                    >
                      Coming soon - Express Interest
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        {/* <Container>
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-start items px-4 px-lg-0 gap-2 mb-4">
            <div className="w-100 w-md-50 mb-4 mb-md-0">
              <h3 className="secondary-blue f-40" style={{ fontWeight: "600" }}>
                EXPLORE OUR WORKSHOP
              </h3>
              <Calendar
                selectRange={false}
                value={[new Date("5/18/2024"), new Date("5/25/2024")]}
                id="calendar"
                className="mt-4"
              />
            </div>
            <div className="d-flex flex-column flex-md-row justify-content-end w-100 w-md-50 h-md-100">
              <div className="w-100 px-4 px-lg-0">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="border h-100 rounded">
                      <div
                        className="d-flex flex-column text-start p-2"
                        style={{ backgroundColor: "#fff" }}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            className="w-50"
                            style={{ height: "15rem" }}
                            src={pubertyGirl}
                            alt={"Positive Puberty for Girls"}
                          />
                        </div>
                        <span
                          className="p-2 font-weight-light w-50 rounded align-items-center"
                          style={{
                            backgroundColor: "#f4f9ff",
                            color: "#f69493",
                          }}
                        >
                          Workshop
                        </span>
                        <h5
                          className="text-start p-17 secondary-b p-2 "
                          style={{ lineHeight: "1.7" }}
                        >
                          School Workshop for Only Girls in 2024
                        </h5>
                        <div className="d-flex justify-content-between p-2">
                          <div className="d-flex gap-1 align-items-center">
                            <MdOutlineWatchLater
                              width={"10px"}
                              color="#6ecbd0"
                            />
                            <span
                              style={{ fontSize: "12px", color: "#787878" }}
                            >
                              45mins
                            </span>
                          </div>
                          <div className="d-flex gap-1 align-items-center">
                            <FaRegUser width={"10px"} color="#6ecbd0" />
                            <span
                              style={{ fontSize: "12px", color: "#787878" }}
                            >
                              7.3k
                            </span>
                          </div>
                        </div>
                        <Button
                          onClick={() =>
                            window.open(
                              "https://tinyurl.com/yckx8yej",
                              "_blank"
                            )
                          }
                          style={{ borderRadius: "25px" }}
                        >
                          Enroll Now
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="border h-100 rounded">
                      <div
                        className="d-flex flex-column text-start p-2"
                        style={{ backgroundColor: "#fff" }}
                      >
                        <div className="d-flex justify-content-center align-items-center">
                          <img
                            className="w-50"
                            style={{ height: "15rem" }}
                            src={pubertyGirlNBoy}
                            alt={"Positive Puberty for Girls"}
                          />
                        </div>
                        <span
                          className="p-2 font-weight-light w-50 rounded align-items-center"
                          style={{
                            backgroundColor: "#f4f9ff",
                            color: "#f69493",
                          }}
                        >
                          Workshop
                        </span>
                        <h5
                          className="text-start p-17 secondary-b p-2"
                          style={{ lineHeight: "1.7" }}
                        >
                          How to do Parenting via Untaboo online programs
                        </h5>
                        <div className="d-flex justify-content-between p-2">
                          <div className="d-flex gap-1 align-items-center">
                            <MdOutlineWatchLater
                              width={"10px"}
                              color="#6ecbd0"
                            />
                            <span
                              style={{ fontSize: "12px", color: "#787878" }}
                            >
                              45mins
                            </span>
                          </div>
                          <div className="d-flex gap-1 align-items-center">
                            <FaRegUser width={"10px"} color="#6ecbd0" />
                            <span
                              style={{ fontSize: "12px", color: "#787878" }}
                            >
                              7.3k
                            </span>
                          </div>
                        </div>
                        <Button
                          onClick={() =>
                            window.open(
                              "https://tinyurl.com/2p59nunn",
                              "_blank"
                            )
                          }
                          className="bottombutton"
                          style={{ borderRadius: "25px", bottom: " 0px" }}
                        >
                          Enroll Now
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container> */}
        <Container>
          <div className="text-center px-4 px-lg-0 mt-4">
            <h3 className="secondary-blue f-40" style={{ fontWeight: "600" }}>
              EXPERIENCE THE PROGRAM{" "}
            </h3>
            <p className="my-4">
              Our child-friendly programs are carefully designed & curated by
              experts using the Watch ~ Learn ~ Discuss ~ Play pedagogy, which
              immerses the child into a learning journey, with takeaways that
              are easy to absorb and practical to follow. The programs enable
              independent learning and can be accessed from any corner of the
              world.
            </p>
            <h4 className="fw-bold">Take a Peek!</h4>

            {/* <video alt="VIDEO EXPLAINING PAIN POINT & PEDAGOGY (Online trailer video- new UVA)"/> */}
            <div className="mt-5 gap">
              <Button
                className="me-lg-5 mb-5"
                onClick={() => {
                  navigate(`/program/puberty-for-boys/demo`);
                }}
              >
                Puberty for Boys
              </Button>

              <Button
                className="me-lg-5 mb-5"
                onClick={() => {
                  navigate(
                    `/program/puberty-adventure-for-boys-and-girls/demo`
                  );
                }}
              >
                Puberty for Boys & Girls
              </Button>

              <Button
                className="me-lg-5 mb-5"
                onClick={() => {
                  navigate(`/program/puberty-for-girls/demo`);
                }}
              >
                Puberty for Girls
              </Button>
            </div>
          </div>
        </Container>
        <div
          className="mt-5 pt-10 pb-10 px-4 px-lg-0"
          style={{ backgroundColor: "#F3F3F3" }}
        >
          <Container className="d-flex flex-wrap justify-content-between">
            <div className="col-lg-7 px-2 px-auto d-flex flex-wrap">
              <h2 className="mt-3 mb-lg-5 mb-3 f-40 fw-bold primary-blue">
                STILL WONDERING IF <br></br>YOUR CHILD NEEDS THIS?
              </h2>
              <p className=" col-12 mt-3  mb-5 pb-2">
                <b>Hey, I’m Anju & I am here to help.</b>
                <br></br>
                <br></br> A mother of two, I forayed into the space of sex
                education after struggling to find suitable resources for
                teaching my own kids. Being a writer, I decided to take on the
                challenge of simplifying conversations and taking away the
                awkwardness of talking to children on this sensitive topic.
                <br></br>
                <br></br>Don’t be afraid of sex education, and don’t shy away
                from it- it is not just about sex! It is an essential life skill
                to help children navigate the tricky phase of growing up and to
                transition smoothly & safely from childhood to adulthood.{" "}
                <br></br>
                <br></br>Make UnTaboo a part of your child’s life, and partner
                with us to raise a happy, confident & responsible generation.
              </p>
            </div>
            <div className="col-lg-4 col-12 text-lg-start text-center">
              <img
                className="w-50 rounded-3"
                src={about_img7}
              //  style={{boxShadow: "0 3px 10px rgb(0 0 0 / 20%)"}}
              />
              <h3
                className="secondary-blue  mt-5 fw-bold "
                style={{ fontSize: "25px" }}
              >
                Anju Kish
              </h3>
              <p className="col-lg-10 col-12 fw-bold mt-3">
                Founder & CEO, UnTaboo <br></br> Certified Sexuality Educator{" "}
                <span className="secondary-blue">I</span> Law Graduate{" "}
                <span className="secondary-blue">I</span> TEDx Speaker{" "}
                <span className="secondary-blue">I</span> Puberty Play Producer{" "}
                <span className="secondary-blue">I</span> Sex-ed Columnist{" "}
                <span className="secondary-blue">I</span> Blogger{" "}
                <span className="secondary-blue">I</span> Award-winning Author
              </p>
            </div>
            <div className=" col-7 "></div>
            <div className="col-lg-12 col-12 mt-5">
              <div>
                {/* <BookCarousel /> */}
                <div className="d-lg-flex justify-content-between ">
                  <div className="col-lg-4 col-12  text-center mb-lg-0 mb-3">
                    <img
                      className="w-90"
                      src={anjubook}
                      alt={"booklaunch"}
                      style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
                    />
                  </div>
                  <div className="col-lg-8 col-12">
                    <img
                      className="w-95 w-sm-100"
                      src={booklaunch}
                      alt={"booklaunch"}
                      style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className=" col-11 mx-auto mt-10 pt-5">
                <h3 className="primary-blue text-center">
                  <b className="f-40">THEY ARE TALKING ABOUT US</b>
                </h3>
                <p
                  className="col-lg-11 col-12 mx-auto pt-4 f-25 text-center"
                  style={{ fontWeight: "600" }}
                >
                  We have been acknowledged and lauded by leading media houses
                  nationally, and some internationally, for our pioneering work
                  in the space of Safety & Sex Education
                </p>
              </div>
              <div className="text-center mb-5">
                <img
                  className="col-lg-11 col-12 mt-lg-5 mt-3 pt-4"
                  src={about_img5}
                // style={{boxShadow: "0 3px 10px rgb(0 0 0 / 20%)"}}
                />
              </div>
            </div>
          </Container>
        </div>
        <div
          className=" text-center pb-10"
          style={{ backgroundColor: "#26547C" }}
        >
          <Container>
            <h2 className="text-white fw-bold pt-10 mb-5 f-40">
              Heart-Warming Feedback
            </h2>

            <TestimonialCarousel></TestimonialCarousel>
          </Container>
        </div>

        <div
          className=" pb-10 text-center"
          style={{ backgroundColor: "#F69494 " }}
        >
          <Container>
            <h2
              className="pt-10 px-2 text-white f-40"
              style={{ fontWeight: "600" }}
            >
              FREQUENTLY ASKED QUESTIONS
            </h2>
            <p className="mb-5 fs-4 text-white">
              Got doubts? We got it covered!
            </p>
            <div className="col-12 col-lg-12 mx-auto d-lg-flex justify-content-around">
              <div className="w-100 w-sm-100">
                <Faq></Faq>
              </div>
              <div className="w-100 w-sm-100 mt-5">
                <div
                  className="card "
                  style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
                >
                  <div className="p-5 text-center">
                    <img
                      className="program_cards w-100"
                      style={{ height: "auto" }}
                      src={cat}
                      alt={"cat_icon"}
                    />
                    <h3 className="secondary-blue">Hey UnTabooer</h3>
                    <p className="secondary-b">
                      Get expert tips and free invite to webinars delivered to
                      your mailbox
                    </p>
                    <form className="mb-2" onSubmit={subscribe}>
                      <input
                        type="text"
                        className="w-100 mt-4 p-2"
                        placeholder="First Name"
                        style={{
                          fontSize: "13px",
                          border: "none",
                          borderBottom: "1px solid #828bb2",
                        }}
                        name="first_name"
                        required
                      />
                      <input
                        type="text"
                        className="w-100 mt-4 p-2"
                        placeholder="Last Name"
                        style={{
                          fontSize: "13px",
                          border: "none",
                          borderBottom: "1px solid #828bb2",
                        }}
                        name="last_name"
                      />
                      <input
                        type="email"
                        className="w-100 mt-4 p-2"
                        placeholder="Email Name"
                        style={{
                          fontSize: "13px",
                          border: "none",
                          borderBottom: "1px solid #828bb2",
                        }}
                        name="email"
                        required
                      />
                      <Button className="mt-4" type="submit">
                        {loading ? "Submitting" : "Submit"}
                      </Button>
                    </form>
                    {success && (
                      <span className="text-success">Added successfully</span>
                    )}
                    {error && <span className="text-danger">{error}</span>}
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div className="pb-10 pt-10 " style={{ backgroundColor: "#B7c4D1" }}>
          <Container>
            <div className="col-lg-12 text-center col-12 px-4 px-lg-0">
              <h3 className="secondary-blue text-center f-40 fw-bold">
                READY TO START?
              </h3>
              <p
                className="f-25 col-12 secondary-blue text-center col-lg-7 mx-auto  mt-4 poppins"
                style={{
                  lineHeight: "1.7",

                  fontWeight: "600",
                }}
              >
                Join thousands of parents and teachers from across India,
                consciously empowering their children and teens with Safety and
                Sex education.
              </p>
              <div className="mt-5  text-center col-12">
                <a href="#programDetails">
                  <Button
                    type="button"
                    className="btn btn-light poppins mb-lg-0 mb-3"
                    style={{ backgroundColor: "white", color: "#415094" }}
                  >
                    Show me how
                  </Button>
                </a>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
};
