import React from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Assest from "../../assets/images/neel_kiara.jpg";
import badge from "../../assets/images/badge.png";
import Spinner from "react-bootstrap/Spinner";
import { Badge } from "../../atoms/image/badge";

export const ReportAndActivity = (props) => {
  return (
    <Container className="my-3 bg-white rounded-3 poppins">
      <h2 className="p-3 " style={{ fontSize: "35px" }}>
        Recent Activities
      </h2>
      <Table responsive="md p-3">
        <thead>
          <tr>
            <th className="dashboard-blue fs-5">Program</th>
            <th className="dashboard-blue fs-5">Level</th>
            <th className="dashboard-blue fs-5">Learner</th>
            <th className="dashboard-blue fs-5">Score</th>
            <th className="dashboard-blue fs-5">Badge</th>
            <th className="dashboard-blue fs-5">Completed On</th>
          </tr>
        </thead>
        <tbody>
          {props.loading ? (
            <tr>
              <td>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </td>
            </tr>
          ) : props.error ? (
            <tr>
              <span className="text-danger">{props.error}</span>
            </tr>
          ) : (
            props.activities.map((a, index) => (
              <tr key={index}>
                <td className="w-20 align-middle ">
                  <div className="d-flex">
                    <img className="w-20 rounded me-2" src={Assest} />
                    {a?.enrollment?.program?.name}
                  </div>
                </td>
                <td className="align-middle">{a?.level?.title}</td>
                <td className="align-middle">
                  {a?.enrollment?.learner?.first_name +
                    " " +
                    a?.enrollment?.learner?.last_name}
                </td>
                <td className="align-middle">
                  {a?.is_faceoff
                    ? a?.score >= 0
                      ? "Your child won"
                      : "You won"
                    : a?.score}
                </td>
                <td className="align-middle w-10">
                  <Badge score={a?.score} notResponse />
                </td>
                <td className="align-middle">{a?.createdAt}</td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </Container>
  );
};
