import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
// import anjubook from "../../assets/images/anjubook.png";
// import booklaunch from "../../assets/images/booklaunch.jpg";
import about_img12 from "../../assets/images/school Workshops.png";
import about_img13 from "../../assets/images/Mind Unblocking.png";
import about_img14 from "../../assets/images/NGO Workshops.png";
import about_img15 from "../../assets/images/Play.png";
import about_img16 from "../../assets/images/Music Video.png";
import about_img17 from "../../assets/images/TedX.png";
import about_img18 from "../../assets/images/Comedy.png";
import karan from "../../assets/images/book.png";
import about_img20 from "../../assets/images/Columns.png";
import about_img21 from "../../assets/images/Blogs.png";
import about_img22 from "../../assets/images/Memes.png";
import about_img23 from "../../assets/images/mind umblocking.png";

export const Workcarousel = (props) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel
      className="text-center"
      activeIndex={index}
      onSelect={handleSelect}
    >
      <Carousel.Item
        className=""
        style={{
          backgroundColor: "#26547C",
          objectFit: "contain",
        }}
      >
        <div className="col-10  my-5 mb-4 mx-auto d-lg-flex justify-content-around  align-items-center ">
          <div
            className="px-auto pt-5 w-45 mx-auto  mb-5 text-center card-height"
            style={{
              backgroundColor: "#6BCACF",
              
              position: "relative",
            }}
          >
            <div className=" text-center mx-auto">
              <img
                className="w-80"
                style={{ border: "6px solid #fff" }}
                src={about_img12}
              />
            </div>
            <div
              className=" my-3 w-100"
              style={{ color: "white", position: "absolute", bottom: " 4px" }}
            >
              <h4>
                <b>School Workshop</b>
              </h4>
              <p>
                <b>Grade 4 to 12</b>
              </p>
            </div>
          </div>

          <div
            className="px-auto pt-5 w-45 mb-5 mx-auto  text-center card-height"
            style={{
              backgroundColor: "#6BCACF",
              
              position: "relative",
            }}
          >
            <img
              className="w-80"
              style={{ border: "6px solid #fff" }}
              src={about_img13}
            />
            <div
              className=" my-3 w-100"
              style={{ color: "white", position: "absolute", bottom: " 4px" }}
            >
              <h4>
                <b>Mind Unblocking</b>
              </h4>
              <p>
                <b>For Parents, Teachers and other Stakeholders</b>
              </p>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item
        className=""
        style={{
          backgroundColor: "#26547C",
          objectFit: "contain",
        }}
      >
        <div className="col-10  my-5 mb-4 mx-auto d-lg-flex justify-content-around  align-items-center ">
          <div
            className="px-auto pt-5 w-45 mb-5 mx-auto  text-center card-height"
            style={{
              backgroundColor: "#6BCACF",
             
              position: "relative",
            }}
          >
            <img
              className="w-80"
              style={{ border: "6px solid #fff" }}
              src={about_img23}
            />
            <div
              className=" my-3 w-100"
              style={{ color: "white", position: "absolute", bottom: " 4px" }}
            >
              <h4>
                <b>Mind Unblocking Webinars</b>
              </h4>
              <p>
                <b>For Parents, Teachers and other Stakeholders</b>
              </p>
            </div>
          </div>

          <div
            className="px-auto pt-5 w-45 mx-auto  mb-5 text-center card-height"
            style={{
              backgroundColor: "#6BCACF",
             
              position: "relative",
            }}
          >
            <img
              className="w-80"
              style={{ border: "6px solid #fff" }}
              src={about_img14}
            />
            <div
              className=" my-3 w-100"
              style={{ color: "white", position: "absolute", bottom: " 4px" }}
            >
              <h4>
                <b>NGO Partnered Programs</b>
              </h4>
              <p>
                <b>Light a lamp program</b>
              </p>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item
        className=""
        style={{
          backgroundColor: "#26547C",
          objectFit: "contain",
        }}
      >
        <div className="col-10  my-5 mb-4 mx-auto d-lg-flex justify-content-around  align-items-center ">
          <div
            className="px-auto pt-5 w-45 mx-auto  mb-5 text-center card-height"
            style={{
              backgroundColor: "#6BCACF",
             
              position: "relative",
            }}
          >
            <img
              className="w-80"
              style={{ border: "6px solid #fff" }}
              src={about_img15}
            />
            <div
              className=" my-3 w-100"
              style={{ color: "white", position: "absolute", bottom: " 4px" }}
            >
              <h4>
                <b>Play on Puberty and Safety</b>
              </h4>
              <p>
                <b>Promoting Positive Puberty</b>
              </p>
            </div>
          </div>

          <div
            className="px-auto pt-5 w-45 mx-auto  mb-5 text-center card-height"
            style={{
              backgroundColor: "#6BCACF",
              
              position: "relative",
            }}
          >
            <img
              className="w-80"
              style={{ border: "6px solid #fff" }}
              src={about_img16}
            />
            <div
              className=" my-3 w-100"
              style={{ color: "white", position: "absolute", bottom: " 4px" }}
            >
              <h4>
                <b>Music Video</b>
              </h4>
              <p>
                <b>Showcasing ground realities</b>
              </p>
            </div>
          </div>
        </div>
      </Carousel.Item>
      <Carousel.Item
        className=""
        style={{
          backgroundColor: "#26547C",
          objectFit: "contain",
        }}
      >
        <div className="col-10  my-5 mb-4 mx-auto d-lg-flex justify-content-around  align-items-center ">
          <div
            className="px-auto pt-5 w-45 mx-auto  mb-5 text-center card-height"
            style={{
              backgroundColor: "#6BCACF",
            
              position: "relative",
            }}
          >
            <img
              className="w-80"
              style={{ border: "6px solid #fff" }}
              src={about_img17}
            />
            <div
              className=" my-3 w-100"
              style={{ color: "white", position: "absolute", bottom: " 4px" }}
            >
              <h4>
                <b>Ted X Speaker</b>
              </h4>
              <p>
                <b>Four talks and more to come</b>
              </p>
            </div>
          </div>

          <div
            className="px-auto pt-5 w-45 mx-auto  mb-5 text-center card-height"
            style={{
              backgroundColor: "#6BCACF",
            
              position: "relative",
            }}
          >
            <img
              className="w-70"
              style={{ border: "6px solid #fff" }}
              src={about_img18}
            />
            <div
              className=" my-3 w-100"
              style={{ color: "white", position: "absolute", bottom: " 4px" }}
            >
              <h4>
                <b>Stand up Comedy</b>
              </h4>
              <p>
                <b>Untabooing Minds through humour</b>
              </p>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item
        className=""
        style={{
          backgroundColor: "#26547C",
          objectFit: "contain",
        }}
      >
        <div className="col-10  my-5 mb-4 mx-auto d-lg-flex justify-content-around  align-items-center ">
          <div
            className="px-auto pt-5 w-45 mx-auto  mb-5 text-center card-height"
            style={{
              backgroundColor: "#6BCACF",
            
              position: "relative",
            }}
          >
            <div className="w-100 text-center mx-auto">
              <img
                className="w-80"
                style={{ border: "6px solid #fff" }}
                src={karan}
              />
            </div>
            <div
              className=" my-3 w-100"
              style={{ color: "white", position: "absolute", bottom: " 4px" }}
            >
              <h4>
                <b>Book on Growing up</b>
              </h4>
              <p>
                <b>Award winning</b>
              </p>
            </div>
          </div>

          <div
            className="px-auto pt-5 w-45 mx-auto  mb-5 text-center card-height"
            style={{
              backgroundColor: "#6BCACF",
             
              position: "relative",
            }}
          >
            <img
              className="w-80"
              style={{ border: "6px solid #fff" }}
              src={about_img20}
            />
            <div
              className=" my-3 w-100"
              style={{ color: "white", position: "absolute", bottom: " 4px" }}
            >
              <h4>
                <b>Expert Columns</b>
              </h4>
              <p>
                <b>On Twinkle Khanna’s Platform</b>
              </p>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item
        className=""
        style={{
          backgroundColor: "#26547C",
          objectFit: "contain",
        }}
      >
        <div className="col-10  my-5 mb-4 mx-auto d-lg-flex justify-content-around  align-items-center ">
          <div
            className="px-auto pt-5 w-45 mx-auto  mb-5 text-center card-height"
            style={{
              backgroundColor: "#6BCACF",
             
              position: "relative",
            }}
          >
            <img
              className="w-80"
              style={{ border: "6px solid #fff" }}
              src={about_img21}
            />
            <div
              className=" my-3 w-100"
              style={{ color: "white", position: "absolute", bottom: " 4px" }}
            >
              <h4>
                <b>Self Help Blogs</b>
              </h4>
              <p>
                <b>For parents to open up channels of communication</b>
              </p>
            </div>
          </div>

          <div
            className="px-auto pt-5 w-45 mx-auto  mb-5 text-center card-height"
            style={{
              backgroundColor: "#6BCACF",
             
              position: "relative",
            }}
          >
            <div className="w-80 mx-auto" style={{ backgroundColor: "white" }}>
              <img
                className="w-80"
                style={{ border: "6px solid #fff" }}
                src={about_img22}
              />
            </div>
            <div
              className=" my-3 w-100"
              style={{ color: "white", position: "absolute", bottom: " 4px" }}
            >
              <h4>
                <b>Thought provoking posts</b>
              </h4>
              <p>
                <b>Unblocking Mindsets</b>
              </p>
            </div>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
  );
};
