import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";

export const ControlledCarousel = (props) => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel className="my-10" activeIndex={index} onSelect={handleSelect}>
      {props.children}
    </Carousel>
  );
};
