import React, { useState } from "react";
import "../../cstyle.scss";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import { Formik } from "formik";
import * as yup from "yup";
import { app_api } from "../../atoms/api";
import { Banner } from "../../pages/program";
import Accordion from "react-bootstrap/Accordion";
import AccordionItem from "react-bootstrap/AccordionItem";
import AccordionHeader from "react-bootstrap/AccordionHeader";
import AccordionBody from "react-bootstrap/AccordionBody";
import { UtImg } from "../../atoms/image";
import FormCheck from "react-bootstrap/FormCheck";

import cat from "../../assets/images/discussCat.png";
import discuss_girl from "../../assets/images/dicuss_girls.png";
import askUsAnything from "../../assets/images/question_own.png";
import discussBanner from "../../assets/images/curiosity_curve.PNG";
import discuss_other from "../../assets/images/curiosityCaveOther.png";
import axios from "axios";
import Cookies from "js-cookie";

const AnythingImage = styled.img`
  width: 100%;

  @media screen and (min-width: 554px) {
    width: 75%;
  }

  @media screen and (min-width: 768px) {
    width: 50%;
  }
`;

export const Discuss = (props) => {
  const [submitQuestion, setSubmitQuestion] = useState(false);
  const [submitQuestionError, setSubmitQuestionError] = useState(null);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const learnerQuestionSchema = yup.object().shape({
    question_text: yup.string().required("The question cannot be empty"),
    is_anonymous: yup.bool().optional(),
  });
  let user = {};
  if (!!Cookies.get("ut_user")) {
    user = JSON.parse(Cookies.get("ut_user"));
  }
  return (
    <Container
      fluid={true}
      className={`discuss flex-column align-items-center ${
        props.show ? "d-flex" : "d-none"
      }`}
    >
      {/* <h1 className="f-55 poppins mb-5">{props?.title}</h1> */}
      <Banner
        imported
        alt="Discuss banner pt-5"
        src={
          props.directed_at === "girls"
            ? discuss_girl
            : props.directed_at === "boys"
            ? discussBanner
            : discuss_other
        }
      />
      <p>{props?.heading}</p>
      <Container fluid={true} className=" rounded-3 col-12 px-lg-4 px-2 py-3">
        <div className="">
          <img className="w-100" src={cat} />
        </div>
        <Accordion defaultActiveKey="0" flush={true}>
          {Array.isArray(props.sequence) &&
            Array.isArray(props.content) &&
            props?.sequence.length > 0 &&
            props.content.length >= props.sequence.length &&
            props.content
              .sort(
                (a, b) =>
                  props.sequence.indexOf(a.id) - props.sequence.indexOf(b.id)
              )
              .map((d, index) => (
                <AccordionItem eventKey={index} key={index}>
                  <AccordionHeader>
                    <div className="text-center w-100 poppins">
                      <p style={{ marginBottom: "0" }}>{d?.question}</p>
                    </div>
                  </AccordionHeader>
                  <AccordionBody>
                    <div
                      className="fs-5 px-md-5 d-flex justify-content-between flex-md-row flex-column g-3 align-items-center poppins"
                      style={{ minHeight: "250px" }}
                    >
                      <UtImg
                        src={d.image?.filePath}
                        alt={d.image?.file}
                        width="140px"
                        height="auto"
                        className="mb-3 mb-md-0 me-md-3 scale_img"
                      />

                      {d?.response}
                    </div>
                  </AccordionBody>
                </AccordionItem>
              ))}
          {user?.role !== "teacher" && !props.isDemo && (
            <AccordionItem>
              <AccordionHeader>
                <AnythingImage src={askUsAnything} alt="ask us anything" />
              </AccordionHeader>
              <AccordionBody>
                <Formik
                  validationSchema={learnerQuestionSchema}
                  initialValues={{
                    question_text: "",
                    is_anonymous: false,
                  }}
                  onSubmit={(values) => {
                    setSubmitQuestion(true);
                    app_api
                      .post("user-questions", {
                        learner_id: props.learner_id,
                        level_id: props.level_id,
                        ...values,
                      })
                      .then((res) => {
                        setSubmitQuestion(false);
                        setSubmitSuccess(true);
                        setSubmitQuestionError(null);
                      })
                      .catch((err) => {
                        setSubmitQuestion(false);
                        setSubmitSuccess(false);
                        setSubmitQuestionError(
                          err?.response?.data?.message || "An error has occured"
                        );
                      });
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                  }) => (
                    <Form noValidate={true} onSubmit={handleSubmit}>
                      {submitQuestionError !== null && (
                        <Alert
                          variant="danger"
                          dismissible={true}
                          onClose={() => setSubmitQuestionError(null)}
                        >
                          {submitQuestionError}
                        </Alert>
                      )}
                      {submitSuccess && (
                        <Alert
                          variant="success"
                          dismissible={true}
                          onClose={() => setSubmitSuccess(false)}
                        >
                          Thank you for your question and we'll answer it in the
                          live webinar. Do get your parent to register for it
                          after you complete the program.
                        </Alert>
                      )}
                      <FormGroup className="mb-1">
                        <FormControl
                          name="question_text"
                          id="discuss.question_text"
                          value={values.question_text}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          as="textarea"
                          placeholder={`Ask us anything! We absolutely love questions. If your question is different and out of the box, we may just add it here for ${
                            props.directed_at === "girls"
                              ? "other girls"
                              : props.directed_at === "boys"
                              ? "other boys"
                              : "others"
                          } to read and learn from! Go for it.`}
                          style={{ minHeight: "100px" }}
                          isValid={
                            touched.question_text && !errors.question_text
                          }
                          isInvalid={
                            touched.question_text && errors.question_text
                          }
                        />
                        <FormControl.Feedback type="invalid">
                          {errors.question_text}
                        </FormControl.Feedback>
                      </FormGroup>
                      <FormGroup className="mb-3">
                        <FormCheck
                          name="is_anonymous"
                          id="discuss.is_anonymous"
                          checked={values.is_anonymous}
                          value={values.is_anonymous}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.is_anonymous && !errors.is_anonymous}
                          isInvalid={
                            touched.is_anonymous && errors.is_anonymous
                          }
                          label="Submit anonymously?"
                          inline
                          className="col-lg-3"
                        />
                        <FormControl.Feedback type="invalid">
                          {errors.is_anonymous}
                        </FormControl.Feedback>
                      </FormGroup>

                      <Button
                        type={"submit"}
                        variant="primary"
                        className="text-black"
                        disabled={submitQuestion || !isValid}
                      >
                        {submitQuestion
                          ? [
                              <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                              />,
                              " Submitting...",
                            ]
                          : "Submit"}
                      </Button>
                    </Form>
                  )}
                </Formik>
              </AccordionBody>
            </AccordionItem>
          )}
        </Accordion>
      </Container>
    </Container>
  );
};
