import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import Container from "react-bootstrap/Container";
import { ParentDashboard } from "../../templates/parentDashboard";
import { LearnerDashboard } from "../../templates/learnerDashboard";

const ContainerDiv = styled.div`
  background-color: #f3f6f9;
`;

export const Dashboard = (props) => {
  document.title = `Dashboard | ${props.userType}`;
  return (
    <ContainerDiv>
      <Container
        fluid={true}
        className="py-3 px-2 col-sm-11 col-md-10 mx-sm-auto min-vh-100 px-4 px-lg-0"
      >
        <h1 className="Yanone_Kaffeesatz mt-4 f-50 " style={{color:"#464E5F",fontWeight:"500"}}>
          {props.userType === "parent" ? "Welcome to the Parent Dashboard!" : "Welcome to the Student Dashboard!"}
        </h1>
        {props.userType === "parent" && <ParentDashboard />}
        {props.userType === "learner" && <LearnerDashboard />}
      </Container>
    </ContainerDiv>
  );
};

Dashboard.propTypes = {
  userType: PropTypes.oneOf(["parent", "learner"]),
};

Dashboard.defaultProps = {
  userType: "parent",
};
