import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import PopoverBody from "react-bootstrap/PopoverBody";
import pt from "prop-types";
import FigureImage from "react-bootstrap/FigureImage";
import { Link, useNavigate } from "react-router-dom";
import { app_api } from "../../atoms/api";
import Cookies from "js-cookie";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import boy_img from "../../assets/images/boy.png";
import styled from "styled-components";
import ButtonBootstrap from "react-bootstrap/Button";
import CardBootstrap from "react-bootstrap/Card";
import MoodBoard from "../../assets/images/MoodBoard1.png";
import MoodBoard_asset from "../../assets/images/Moodboard_new.jpg";
import badge from "../../assets/images/badge.png";
import { PurchasedProgram } from "../../molecules/purchasedProgram";
import { Badge } from "../../atoms/image/badge";

const KitCard = styled(CardBootstrap)`
  background-color: #fffff;
  border: none;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);

  & > h2 {
    color: var(--bs-info);
  }

  & > p {
    color: #464e5f;
  }
`;

const StarterKitButton = styled(ButtonBootstrap)`
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  border-radius: 0.85rem;
  color: #ffffff;
  background-color: #ffa800;
  margin: 17px 0;
  :hover {
    color: #ffffff;
    background-color: #d98f00;
  }
`;

const formatDate = (date) => {
  try {
    let thisDate = new Date(date);
    return `${thisDate.getFullYear()}/${
      thisDate.getMonth() + 1
    }/${thisDate.getDate()}`;
  } catch {
    return "No date";
  }
};

const moreInfoPopover = (props) => (
  <Popover>
    <PopoverBody>
      <Row>
        <Col>
          <span className="text-left">Score</span>
        </Col>
        <Col>
          <span className="text-left">
            {props.is_faceoff
              ? props.score >= 0
                ? "You won"
                : "Your parent won"
              : props.score}
          </span>
        </Col>
      </Row>
      <Row>
        <Col>
          <span className="text-left">Date completed</span>
        </Col>
        <Col>
          <span className="text-left">{formatDate(props.dateCompleted)}</span>
        </Col>
      </Row>
    </PopoverBody>
  </Popover>
);

moreInfoPopover.propTypes = {
  score: pt.number.isRequired,
  dateCompleted: pt.string.isRequired,
};

export const LearnerDashboard = (props) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [programs, setPrograms] = useState([]);
  const [actLoading, setActLoading] = useState(true);
  const [actError, setActError] = useState(null);
  const [activities, setActivities] = useState([]);
  const navigate = useNavigate();
  let user = {};
  if (!!Cookies.get("ut_user")) {
    user = JSON.parse(Cookies.get("ut_user"));
  }

  useEffect(() => {
    app_api
      .get(`enrollment/learner/${user?.id}?school=${user?.school_id}`)
      .then((res) => {
        setLoading(false);
        setPrograms(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Error");
      });
    app_api
      .get(`user-activity/learner/${user?.id}`)
      .then((res) => {
        setActLoading(false);
        setActivities(res.data);
      })
      .catch((err) => {
        setActLoading(false);
        setActError(
          err?.response?.data?.message || "Error fetching activities"
        );
      });
  }, []);

  const goToProgram = (enrollmentId) => {
    navigate(`/dashboard/program/${enrollmentId}`);
  };
  // var element = document.getElementsByClassName("whatsapp_float");
  // element.classList.remove("whatsapp_float");
  return (
    <Container className="mt-4 poppins learnerDash">
      <Row className="g-2 ">
        <Col
          className="rounded-3"
          md="12"
          lg="8"
          style={{
            backgroudnColor: "transparent",
            boxShadow: "0px 0px 30px 0px rgb(82 63 105 / 5%)",
          }}
        >
          <Card
            className="py-3 px-lg-3 rounded-3 "
            style={{ backgroundColor: "transparent", border: "0" }}
          >
            <div className="d-flex flex-column flex-md-row mx-2 mb-4 mt-3 ">
              <span className="Yanone_Kaffeesatz font-weight-bold fs-1">
                My Programs
              </span>
              {user?.learner_code && (
                <span className="Yanone_Kaffeesatz ms-auto fs-4">
                  Your code: {user?.learner_code}
                </span>
              )}
            </div>
            <Row className="g-2">
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : !!error ? (
                <label className="text-danger text-capitalize">{error}</label>
              ) : programs.length > 0 ? (
                programs.map((p, index) => {
                  let expiring_soon = false;
                  if (p.expires_at) {
                    let today = new Date();
                    let expires_at = new Date(p?.expires_at);
                    expiring_soon =
                      expires_at - today < 1000 * 60 * 60 * 24 * 7;
                  }
                  return (
                    <PurchasedProgram
                      key={index}
                      id={p.id}
                      programName={p?.program?.name}
                      directed_at={p?.program?.directed_at || "girls"}
                      currentLevel={p?.current_level}
                      currentLevelName={p?.current_level_name}
                      level_length={
                        Array.isArray(p?.program?.level_sequence)
                          ? p?.program?.level_sequence.length
                          : 1
                      }
                      firstName={p?.learner?.first_name}
                      goToLearner={goToProgram}
                      hasSurveyed={!!p?.surveyed_on}
                      expiringSoon={expiring_soon}
                    />
                  );
                })
              ) : (
                <span className="text-muted mt-3 col-6">
                  Hey! This page is empty, &#128533;.
                </span>
              )}
              <Col
                md="6"
                sm="12"
                lg="6"
                className="d-flex justify-content-center"
              >
                <KitCard
                  className="mxh-500  pt-5 pb-4 col-md-12 col-lg-11 p-4 card"
                  style={{ borderRadius: "10px" }}
                >
                  <h2
                    className="mb-4 info Yanone_Kaffeesatz fs-1"
                    style={{ fontWeight: "600" }}
                  >
                    My Mood Board
                  </h2>
                  <p
                    className="mb-2 mb-md-2 text-black Yanone_Kaffeesatz"
                    style={{ fontSize: "20px" }}
                  >
                    Do you ever wish your parents would magically know how you
                    were feeling without you having to tell them? Well, here is
                    a way.
                  </p>
                  <img src={MoodBoard} />
                  <a className="text-center" href={MoodBoard_asset} download>
                    <StarterKitButton
                      variant="info"
                      className="mx-auto mt-md-2 align-self-start"
                    >
                      Download
                    </StarterKitButton>
                  </a>
                </KitCard>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col md="12" lg="4">
          <Card
            className="p-3 rounded-3 overflow-auto"
            style={{ height: "650px" }}
          >
            <h3
              className="Yanone_Kaffeesatz"
              style={{ fontWeight: "500", fontSize: "35px" }}
            >
              Badges Earned
            </h3>
            <Table responsive="md" hover={true}>
              <thead>
                <tr style={{ color: "#B5B5C3" }}>
                  <th className="fw-normal">BADGE</th>
                  <th className="fw-normal">PROGRAM</th>
                </tr>
              </thead>
              <tbody>
                {actLoading ? (
                  <tr>
                    <td colSpan="2">
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    </td>
                  </tr>
                ) : !!actError ? (
                  <tr>
                    <td colSpan="2" className="text-danger">
                      {actError}
                    </td>
                  </tr>
                ) : activities.length > 0 ? (
                  activities.map((a, index) => (
                    <OverlayTrigger
                      key={index}
                      // trigger={['hover', 'focus']} default
                      placement="left"
                      overlay={moreInfoPopover({
                        score: a?.score,
                        is_faceoff: a?.is_faceoff,
                        dateCompleted: a?.createdAt,
                      })}
                    >
                      <tr>
                        <td className="w-40 align-middle">
                          <Badge notResponse score={a?.score} />
                        </td>
                        <td className="align-middle ">
                          <p className="fw-bold text-capitalize primary-color mb-1">
                            {a?.enrollment?.program?.name}
                          </p>

                          <p>{a?.level?.title}</p>
                        </td>
                      </tr>
                    </OverlayTrigger>
                  ))
                ) : (
                  <h4
                    className="mx-auto mt-5 col-8 text-center position-relative"
                    style={{
                      left: "15%",
                      top: "50%",
                      color: "grey",
                      fontWeight: "300",
                      fontSize: "18px",
                      // zIndex:"-1"
                    }}
                  >
                    Start your learning journey now to earn badges
                  </h4>
                )}
              </tbody>
            </Table>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

LearnerDashboard.propTypes = {};
