import React, { useEffect, useState } from "react";
import "../../cstyle.scss";
import styled from "styled-components";
import pt from "prop-types";
import Container from "react-bootstrap/Container";
import { Banner } from "../../pages/program";
import Carousel from "react-bootstrap/Carousel";
// import CarouselCaption from "react-bootstrap/CarouselCaption";
import CarouselItem from "react-bootstrap/CarouselItem";
import { UtImg } from "../../atoms/image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import "animate.css";
// import {learn} from "../../assets/images/learn.png";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import "../../style.scss";
// import imagea from "../../assets/images/sign_up.png
// import { HiSpeakerWave } from "react-icons/hi2";
import Cookies from "js-cookie";
import { useLocation } from "react-router-dom";

const LearnPara = styled.p`
  & span[data-dictionary] {
    background-color: lightblue;
    font-weight: bold;
    padding: 0 2px;
    border-radius: 5px;
    font-size: 18px;
    box-shadow: 1px 1px 2px gray;
    cursor: help;
    position: relative;

    @media screen and (min-width: 554px) {
      padding: 0.3rem;
    }

    &::before {
      content: attr(data-hover);
      visibility: hidden;
      opacity: 0;
      min-width: 200px;
      background-color: white;
      color: #444;
      text-align: center;
      border-radius: 5px;
      border: 1px solid #444;
      padding: 1rem;
      transition: opacity 0.5s ease-in-out;
      position: absolute;
      z-index: 10;
      right: 50%;
      transform: translateX(50%);
      top: 110%;
    }

    &:hover::before {
      visibility: visible;
      opacity: 1;
    }
  }
`;

const LearnImage = styled(UtImg)`
  object-fit: cover;
  transition: 0.5s ease;

  :hover {
    transform: scale(1.5);
  }
`;

export const Learn = (props) => {
  const location = useLocation();

  const [audioUrl, setAudioUrl] = useState("");
  const audios =
    Array?.isArray(props.content) &&
    props?.sequence?.length > 0 &&
    props?.content?.length >= props.sequence?.length &&
    props?.content?.sort(
      (a, b) => props?.sequence?.indexOf(a.id) - props.sequence?.indexOf(b.id)
    );

  const [isPlaying, setIsPlaying] = useState(false);
  const audioElement = document.getElementById("audio");
  const togglePlay = (change) => {
    if (isPlaying) {
      audioElement.pause();
    } else {
      audioElement.play();
    }
    setIsPlaying(!isPlaying);
  };
  const handleAudioChange = async (id) => {
    let _audio = audios[id];
    let _str = `${process.env.REACT_APP_BACKEND_URL}${_audio?.audio?.filePath}`;
    if (audioUrl !== _str) {
      setAudioUrl(_str);
    }
    if (isPlaying) {
      await audioElement.pause();
      setTimeout(() => {
        if (audioUrl !== null) audioElement.play();
      }, 1000);
    }
  };
  let user = {};
  if (!!Cookies.get("ut_user")) {
    user = JSON.parse(Cookies.get("ut_user"));
  }

  useEffect(() => {
    if (audioUrl) {
      audioElement.pause();
      setAudioUrl("");
      setIsPlaying(false);
    }
  }, [location.pathname]);

  return (
    <Container
      fluid={true}
      className={`learn flex-column align-items-center ${
        props.show ? "d-flex" : "d-none"
      }`}
      style={{ paddingRight: "0", paddingLeft: "0" }}
    >
      {/* <h1 className="f-40 poppins mb-5">{props?.title}</h1> */}
      <Banner key="learn" alt="Learn banner" src={props.banner?.filePath} />
      <p
        className="col-11  fw-bold fst-italic text-center my-5 poppins"
        style={{ color: "#663259" }}
      >
        {props?.heading}
      </p>
      <div
        className="px-5 col-12 bg-img-learn d-none d-lg-flex d-md-flex position-relative"
        style={{ backgroundImg: "#E9F9F8", position: "relative" }}
      >
        <h4
          className="position-absolute poppins"
          style={{
            right: "14%",
            top: "10%",
            color: "black",
            fontSize: "12px",
            display: "flex",
            flexDirection: "column",
            gap: "2px",
          }}
        >
          <span
            style={{
              display: "flex",
            }}
          >
            <FontAwesomeIcon className="me-2" icon={faInfoCircle} />
            Hover over highlighted text to know its meaning
          </span>
          <span
            style={{
              display: "flex",
            }}
          >
            <FontAwesomeIcon
              style={{
                color: "black",
                width: "14px",
                height: "14px",
                zIndex: 50,
                left: 100,
                marginRight: "5px",
              }}
              icon={faVolumeUp}
              disabled={audioUrl === ""}
              onClick={togglePlay}
            />
            Click to listen to text
          </span>
        </h4>
        <Carousel
          className="pb-5"
          indicatorLabels={["slide1", "5"]}
          wrap={false}
          onSelect={(e) => {
            handleAudioChange(e);
          }}
        >
          {Array.isArray(props.sequence) &&
            Array.isArray(props.content) &&
            props?.sequence.length > 0 &&
            props.content.length >= props.sequence.length &&
            props.content
              .sort(
                (a, b) =>
                  props.sequence.indexOf(a.id) - props.sequence.indexOf(b.id)
              )
              .map((p, index) => (
                <CarouselItem interval={300000} key={index}>
                  {" "}
                  <div></div>
                  <div
                    className="d-lg-flex flex-row "
                    style={{ height: "600px" }}
                  >
                    <div
                      className={`col-lg-${
                        p?.image?.filePath ? "7" : "10"
                      } d-flex flex-column justify-content-start overflow-y-scroll`}
                      style={{ padding: "0 0em 5em 6em" }}
                    >
                      {" "}
                      {/* dfd */}
                      <h3
                        className="pb-3 fs-1 poppins user-select-none"
                        style={{ color: "#E55681" }}
                      >
                        {" "}
                        {p?.heading}{" "}
                        {p.audio_id ? (
                          user?.role === "teacher" || user?.school_id ? (
                            <>
                              <FontAwesomeIcon
                                style={{
                                  color: isPlaying ? "black" : "gray",
                                  width: "34px",
                                  height: "34px",
                                  zIndex: 50,
                                  cursor: "pointer",
                                }}
                                icon={faVolumeUp}
                                disabled={audioUrl === ""}
                                onClick={() => {
                                  if (audioUrl === "" && p.audio_id) {
                                    setAudioUrl(
                                      `${process.env.REACT_APP_BACKEND_URL}${p?.audio?.filePath}`
                                    );
                                    console.log(
                                      `${process.env.REACT_APP_BACKEND_URL}${p?.audio?.filePath}`
                                    );

                                    setTimeout(() => {
                                      if (audioUrl !== null) togglePlay();
                                    }, 1000);
                                  } else {
                                    togglePlay();
                                  }
                                }}
                              />
                              <audio id="audio" src={audioUrl}></audio>
                            </>
                          ) : null
                        ) : null}
                      </h3>
                      <LearnPara
                        className="poppins user-select-none"
                        style={{
                          color: "#204462",
                          fontSize: "20px",
                          fontWeight: "600",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: Array.isArray(p?.textHtml)
                            ? p?.textHtml?.join(" ")
                            : p?.textHtml,
                        }}
                      ></LearnPara>
                    </div>
                    {p?.image?.filePath && (
                      <div className=" col-lg-4 text-end py-auto d-flex flex-column justify-content-center">
                        <LearnImage
                          className="w-60 mx-auto"
                          // style={{PointerEvent}}
                          src={p?.image?.filePath}
                          alt={p?.image?.file}
                        />
                      </div>
                    )}
                  </div>
                </CarouselItem>
              ))
              .concat(
                <CarouselItem
                  interval={300000}
                  // className="animate__animated animate__flipInY"
                >
                  <p
                    className="col-lg-11 col-12 px-5  fw-bold fst-italic text-center my-5 d-flex align-items-center"
                    style={{ color: "#663259", height: "600px" }}
                  >
                    {props?.footer}
                  </p>
                </CarouselItem>
              )}
        </Carousel>
      </div>

      {/* -----------Carousel for Mobile devices------------ */}
      <div
        className=" col-12 bg-img-learn-sm d-block d-lg-none d-md-none"
        style={{ backgroundImg: "#E9F9F8" }}
      >
        <Carousel
          className="pb-5"
          indicatorLabels={["slide1", "5"]}
          wrap={false}
        >
          {Array.isArray(props.sequence) &&
            Array.isArray(props.content) &&
            props?.sequence.length > 0 &&
            props.content.length >= props.sequence.length &&
            props.content
              .sort(
                (a, b) =>
                  props.sequence.indexOf(a.id) - props.sequence.indexOf(b.id)
              )
              .map((p, index) => (
                <CarouselItem
                  interval={300000}
                  key={index}
                  // className="animate__animated animate__flipInY"
                >
                  <div className=" d-lg-flex flex-row ">
                    <div
                      className={`col-lg-${
                        p?.image?.filePath ? "12" : "10"
                      } d-flex flex-column justify-content-center`}
                      style={{ padding: "0 20px" }}
                    >
                      <h3
                        className="pb-3 fs-1 poppins"
                        style={{ color: "#E55681" }}
                      >
                        {p?.heading}
                      </h3>
                      <LearnPara
                        className="poppins"
                        style={{
                          color: "#204462",
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: Array.isArray(p?.textHtml)
                            ? p?.textHtml?.join(" ")
                            : p?.textHtml,
                        }}
                      ></LearnPara>
                    </div>
                    {p?.image?.filePath && (
                      <div className=" col-lg-4 col-12 text-end py-auto d-flex flex-column justify-content-center">
                        <LearnImage
                          className="w-60 mx-auto"
                          src={p?.image?.filePath}
                          alt={p?.image?.file}
                        />
                      </div>
                    )}
                  </div>
                </CarouselItem>
              ))}
        </Carousel>
      </div>
    </Container>
  );
};

Learn.propTypes = {
  show: pt.bool.isRequired,
  title: pt.string.isRequired,
  sequence: pt.array.isRequired,
  content: pt.array.isRequired,
  heading: pt.string,
  footer: pt.string,
};
