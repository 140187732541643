import React from "react";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import { Banner } from "../../pages/program";
import pt from "prop-types";
import { UtVideo } from "../../atoms/video";
import { UtImg } from "../../atoms/image";

import Badge1 from "../../assets/images/Badge_1.png";
import Badge2 from "../../assets/images/Badge_2.png";
import Badge3 from "../../assets/images/Badge_3.png";
import Badge4 from "../../assets/images/Badge_4.png";
import Badge5 from "../../assets/images/Badge_5.png";
import { useEffect } from "react";

const Video = styled(UtVideo)`
  width: 90%;
`;

const Badge = styled(UtImg)`
  width: 100%;
`;

export const Watch = (props) => {
  useEffect(()=> {
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
    });
  })
  useEffect(() => {
    if (!props.show) {
      let videos = document.querySelectorAll("video");
      videos.forEach((v) => v.pause());
    }
  }, [props.show]);

  return (
    <Container
      fluid={true}
      className={`flex-column align-items-center ${
        props.show ? "d-flex" : "d-none"
      }`}
    >
      {/* <h1 className="f-55 poppins mb-5" >{props?.title}</h1> */}
      <Banner
        key="watch"
        alt={props.banner?.file}
        src={props.banner?.filePath}
      />
      {props.isFirstLevel && (
        <Container fluid={true} className="text-center mt-5">
          <p>
            <b>
              Welcome! You will be going on an exciting adventure to scale the
              Puberty Mountain. It is going to be challenging and fun.
              <br />
              Your training camp consists of exciting levels. At each level,
              this is what you can expect:
              <br />
              <br />
              <h5>WATCH</h5>A fun video to understand puberty concepts &amp; be a part of Neal &amp; Kiara's
              Puberty journey
              <br />
              <br />
              <h5>LEARN</h5>How Puberty could affect you by reading the
              interesting text
              <br />
              <br />
              <h5>DISCUSS</h5>Questions other boys and girls your age have asked us in the
              Curiosity Cave
              <br />
              <br />
              <h5>PLAY</h5>A fun quiz to check your fitness level. Finishing
              this will unlock the next level for you!
              <br />
              <br />
              <h5>WIN BADGES</h5>Oh and by the way, this fitness test also gets
              you a rank badge - the more you score at each level, the higher
              your rank is going to be.
              <Container
                fluid={true}
                className="d-flex justify-content-center flex-wrap mt-5"
              >
                <div className="col-12 col-md-4">
                  <Badge imported src={Badge1} alt="badge for 20%" />
                </div>
                <div className="col-12 col-md-4">
                  <Badge imported src={Badge2} alt="badge for 40%" />
                </div>
                <div className="col-12 col-md-4">
                  <Badge imported src={Badge3} alt="badge for 60%" />
                </div>
                <div className="col-12 col-md-4">
                  <Badge imported src={Badge4} alt="badge for 80%" />
                </div>
                <div className="col-12 col-md-4">
                  <Badge imported src={Badge5} alt="badge for more than 80%" />
                </div>
              </Container>
            </b>
          </p>
        </Container>
      )}
      <Container className="d-flex flex-column align-items-center" fluid={true}>
        {Array.isArray(props.sequence) &&
          Array.isArray(props.content) &&
          props?.sequence.length > 0 &&
          props.content.length >= props.sequence.length &&
          props.content
            .sort(
              (a, b) =>
                props.sequence.indexOf(a.id) - props.sequence.indexOf(b.id)
            )
            .map((w, index) => (
              <>
                <p
                  className="fs-4 fw-bold fst-italic text-center my-3"
                  style={{ color: "#663259" }}
                >
                  {w?.description}
                </p>
                <Video
                  controls
                  width="100%"
                  height="auto"
                  src={w?.video_url || w?.video?.filePath}
                  subtitle={w?.subtitle?.filePath}
                  controlsList="nodownload"
                  style={{boxShadow:"rgb(0 0 0 / 20%) 0px 3px 10px", borderRadius:"10px"}}
                  
                />
              </>
            ))}
      </Container>
    </Container>
  );
};

Watch.propTypes = {
  show: pt.bool.isRequired,
  title: pt.string.isRequired,
  sequence: pt.array.isRequired,
  content: pt.object.isRequired,
};
