import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { app_api } from "../../atoms/api";
import Cookies from "js-cookie";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Spinner from "react-bootstrap/Spinner";
import pfg from "../../assets/images/pfb.png";
import { AiOutlineClear } from "react-icons/ai";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormControl from "react-bootstrap/FormControl";
import {
  faTrash,
  faDesktop,
  faPuzzlePiece,
  faCalendar,
  faMoneyCheck,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import { useGAEventProceedToPay } from "../../hoc/hooks/useGAEventTracker";

export const GiftCheckout = (props) => {
  const GAEventProceedToPay = useGAEventProceedToPay();
  const params = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [transaction, setTransaction] = useState({});

  const [creating, setCreating] = useState(false);

  const [cost, setCost] = useState(0);
  let [affiliate_cost, setAffiliateCost] = useState(0);
  const [amount, setAmount] = useState(0);

  const [isActive, setIsActive] = useState(true);

  const [del, setDel] = useState(false);
  const [delError, setDelError] = useState(null);
  // const [dcLoading, setdcLoading] = useState(true);
  // const [dbError, setdbError] = useState(null);
  // const [discountCodes, setDiscountCodes] = useState([]);
  // const [selectedCoupon, setSelectedCoupon] = useState({});
  const [user, setUser] = useState({});
  const isLoggedIn = !!user;

  const [showAffiliate, setShowAffiliate] = useState(true);

  const [aff_loading, setAffLoading] = useState(false);
  const [aff_code, setAffCode] = useState("");
  const [aff_touched, setAffTouched] = useState(false);
  const [aff_error, setAffError] = useState(false);

  const [promo_loading, setPromoLoading] = useState(false);
  const [promo_code, setPromoCode] = useState("");
  const [promo, setPromo] = useState(null);
  const [promo_touched, setPromoTouched] = useState(false);
  const [promo_error, setPromoError] = useState(false);

  const onAffChange = (event) => {
    setAffTouched(true);
    setAffCode(event.target.value);
    setAffError(false);
    setAffLoading(true);
    if (event.target.value === "") {
      onAffClear();
    } else {
    }
  };

  const onAffClear = () => {
    setAffLoading(false);
    setAffTouched(false);
    setAffCode("");
    setAffError(false);
    setAmount(((18 / 100) * cost + cost).toString());
  };

  useEffect(() => {
    if (aff_code) {
      const getData = setTimeout(() => {
        app_api
          .get(`affiliate/valid/${aff_code.trim()}`)
          .then((res) => {
            setAffLoading(false);
            if (res.data) {
              setAffError(false);
              setAmount(affiliate_cost.toFixed(2));
            } else {
              setAffError(true);
              setAmount(((18 / 100) * cost + cost).toString());
            }
          })
          .catch((err) => {
            onAffClear();
          });
      }, 1000);
      return () => clearTimeout(getData);
    }
  }, [aff_code]);

  const dismissError = () => setError(null);

  const onPromoChange = (event) => {
    setPromoTouched(true);
    setPromoCode(event.target.value);
    setPromoError(false);
    setPromoLoading(true);
    if (event.target.value === "") {
      onPromoClear();
    } else {
    }
  };

  const onPromoClear = () => {
    setPromoLoading(false);
    setPromoTouched(false);
    setPromoCode("");
    setPromoError(false);
    setAmount(((18 / 100) * cost + cost).toString());
    setPromo(null);
  };

  useEffect(() => {
    try {
      let user = JSON.parse(Cookies.get("ut_user"));
      setUser(user);
      if (user?.id) {
        // app_api
        //   .get(`discount-codes-chosen/parent/${user?.id}`)
        //   .then((res) => {
        //     setdcLoading(false);
        //     setDiscountCodes(res.data);
        //   })
        //   .catch((err) => {
        //     setdcLoading(false);
        //     setdbError(
        //       err?.response?.data?.message || "Error fetching discount codes"
        //     );
        //   });
      } else {
        // setdcLoading(false);
      }
    } catch {
      // do nothing
      // setdcLoading(false);
    }
  }, [user?.id]);

  useEffect(() => {
    if (promo_code) {
      const getData = setTimeout(() => {
        app_api
          .patch(`promo-code/valid`, { code: promo_code.trim() })
          .then((res) => {
            setPromoLoading(false);
            if (res.data) {
              setPromo(res.data);
              setPromoError(false);
              if (res.data.type === "absolute") {
                if (res.data.value > cost) setAmount(0);
                else
                  setAmount(
                    (
                      0.18 * (cost - res.data.value) +
                      cost -
                      res.data.value
                    ).toFixed(2)
                  );
              } else {
                if (res.data.value > 100) setAmount(0);
                else
                  setAmount(
                    (
                      0.18 * (cost - (res.data.value / 100) * cost) +
                      cost -
                      (res.data.value / 100) * cost
                    ).toFixed(2)
                  );
              }
            } else {
              setPromoError(true);
              setPromo(null);
              setAmount(((18 / 100) * cost + cost).toFixed(2));
            }
          })
          .catch((err) => {
            onPromoClear();
          });
      }, 1000);
      return () => clearTimeout(getData);
    }
  }, [promo_code]);

  const onAffiliateClick = () => {
    setShowAffiliate(true);
    onPromoClear();
  };

  const onPromoClick = () => {
    setShowAffiliate(false);
    onAffClear();
  };

  useEffect(() => {
    app_api
      .get(`transaction/${params.transaction_id}`)
      .then((res) => {
        setLoading(false);
        setTransaction(res.data);
        if (res.data?.temp_enrollment_id) {
          setIsActive(res.data?.temp_enrollment?.is_active || false);
        } else if (res.data?.temp_gift_id) {
          setIsActive(res.data?.temp_gift?.is_active || false);
        } else {
          setIsActive(false);
        }
        setCost(
          res.data?.temp_enrollment?.program?.cost ||
            res.data?.temp_gift?.program?.cost
        );
        setAmount(
          (18 / 100) * res.data?.temp_enrollment?.program?.cost +
            res.data?.temp_enrollment?.program?.cost ||
            (18 / 100) * res.data?.temp_gift?.program?.cost +
              res.data?.temp_gift?.program?.cost
        );
        setAffiliateCost(
          0.9 *
            ((18 / 100) * res.data?.temp_enrollment?.program?.cost +
              res.data?.temp_enrollment?.program?.cost ||
              (18 / 100) * res.data?.temp_gift?.program?.cost +
                res.data?.temp_gift?.program?.cost)
        );
      })
      .catch((err) => {
        setLoading(false);
        setError(
          err?.response?.data?.message || "Error fetching transaction details"
        );
      });
  }, [params.transaction_id]);

  // const selectCoupon = (chosencode) => {
  //   let amount = (
  //     cost -
  //     (chosencode?.discount?.deduction_type === "perc"
  //       ? (cost * chosencode?.discount?.deduction_value) / 100
  //       : chosencode?.discount?.deduction_value || 0)
  //   ).toFixed(2);
  //   setSelectedCoupon(chosencode);
  //   setAmount(amount);
  // };

  // const clearSelectedCoupon = () => {
  //   setAmount(cost.toString());
  //   setSelectedCoupon({});
  // };

  const initializeRazorpay = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const makePayment = async () => {
    GAEventProceedToPay();
    setLoading(true);
    const res = await initializeRazorpay();
    if (!res) {
      alert("Razorpay SDK Failed to load");
      return;
    }
    app_api
      .post(`transaction/order/${params.transaction_id}`, {
        amount: amount.toString(),
      })
      .then((res) => {
        setLoading(false);
        var options = {
          key: process.env.RAZORPAY_KEY_ID,
          name: "UnTaboo",
          currency: res.data.currency,
          amount: res.data.amount,
          order_id: res.data.id,
          // description: "This is description",
          image: "https://api.untaboo.online/uploads/logo.png",
          handler: function (response) {
            setCreating(true);
            app_api
              .patch(`transaction/${params.transaction_id}`, {
                order_id: res.data.id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_order_id: response.razorpay_order_id,
                razorpay_signature: response.razorpay_signature,
                // discount_code_id: selectedCoupon?.id || null,
                affiliate_code: aff_code || null,
                promo_code: promo_code || null
              })
              .then((res) => {
                setLoading(false);
                setCreating(false);
                navigate(`/checkout/success`);
              })
              .catch((err) => {
                setLoading(false);
                setCreating(false);
                navigate("/checkout/failure");
              });
          },
          prefill: {
            name: transaction?.temp_gift?.gifted_by_name,
            email: transaction?.temp_gift?.gifted_by_email_id,
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.on("payment.failed", function (response) {
          setError(`${response.code}: ${response.description}`);
        });
        paymentObject.open();
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Error");
      });
  };

  const goToDashboard = () => {
    navigate(`/dashboard/parent`);
  };

  const deleteEnrollment = () => {
    if (window.confirm("Are you sure you want to cancel this transaction?")) {
      setDel(true);
      app_api
        .patch(`enrollment-temp/${transaction.temp_enrollment_id}`, {
          is_active: false,
        })
        .then((res) => {
          setDel(false);
          navigate(`/dashboard/parent`);
        })
        .catch((err) => {
          setDel(false);
          setDelError(err?.response?.data?.message || "Error");
        });
    }
  };

  return (
    <Container className="poppins">
      <div className="col-12 d-lg-flex " style={{ backgroundColor: "#F3F5F9" }}>
        <div className="col-lg-8 col-12">
          <div className="d-lg-flex">
            <div className="m-3 rounded-3 px-4 py-5 bg-white">
              <img
                className="w-15 mb-3"
                src={pfg}
                alt={
                  transaction?.temp_enrollment?.program?.name ||
                  transaction?.temp_gift?.program?.name
                }
              />
              <h3
                className="rounded-4 fs-3 poppins"
                style={{ fontWeight: "600" }}
              >
                {transaction?.temp_enrollment?.program?.name ||
                  transaction?.temp_gift?.program?.name}
              </h3>
              <p>Full Course. Expert Verified.</p>
              <p>
                Puberty will not seem like a dreaded phase when every change is
                taught in a fun manner. This program turns Puberty into an
                exciting adventure for a child. The end result - a positively
                informed child, ready to face puberty head on!
              </p>
              <hr></hr>
              <div className="d-flex flex-lg-row flex-wrap justify-content-lg-between ">
                <div className="d-flex align-items-center">
                  <div className="me-2">
                    <FontAwesomeIcon icon={faDesktop}></FontAwesomeIcon>
                  </div>
                  <div className="">
                    <h4 className="fs-6">
                      Chapters
                      <br></br>
                      <span style={{ color: "red" }}>
                        {transaction?.temp_enrollment?.program?.level_sequence
                          ?.length ||
                          transaction?.temp_gift?.program?.level_sequence
                            ?.length}
                      </span>
                    </h4>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <div className="me-2">
                    <FontAwesomeIcon icon={faPuzzlePiece}></FontAwesomeIcon>
                  </div>
                  <div className="">
                    <h4 className="fs-6">
                      Activities
                      <br></br>
                      <span style={{ color: "red" }}>3</span>
                    </h4>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <div className="me-2">
                    <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
                  </div>
                  <div className="">
                    <h4 className="fs-6">
                      Learning Time
                      <br></br>
                      <span style={{ color: "red" }}>
                        {transaction?.temp_enrollment?.program
                          ?.learning_hours ||
                          transaction?.temp_gift?.program?.learning_hours}{" "}
                        Hours
                      </span>
                    </h4>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <div className="me-2">
                    <FontAwesomeIcon icon={faMoneyCheck}></FontAwesomeIcon>
                  </div>
                  <div className="">
                    <h4 className="fs-6">
                      Program Cost
                      <br></br>
                      <span style={{ color: "red" }}>Rs. {cost}</span>
                    </h4>
                  </div>
                </div>

                <div className="d-flex align-items-center">
                  <div className="me-2">
                    <FontAwesomeIcon icon={faClock}></FontAwesomeIcon>
                  </div>
                  <div className="">
                    <h4 className="fs-6">
                      Validity
                      <br></br>
                      <span style={{ color: "red" }}>
                        {transaction?.temp_enrollment?.program?.validity ||
                          transaction?.temp_gift?.program?.validity}{" "}
                        Months
                      </span>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="col-lg-4 rounded-3 my-3 me-3"
          style={{ backgroundColor: "#B7c4D1" }}
        >
          <div className="p-5 ">
            <h3 className="Yanone_Kaffeesatz">Cart Details</h3>
            <div className=" mt-5 d-flex justify-content-between">
              <div>
                <p>Description</p>
              </div>
              <div>
                <p>Value</p>
              </div>
            </div>
            <hr style={{ backgroundColor: "#fff" }}></hr>
            <div className="  d-flex justify-content-between">
              <div>
                <p>Program Cost</p>
              </div>
              <div>
                <p>Rs. {cost}</p>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div>
                <p>Units Added</p>
              </div>
              <div>
                <p>1</p>
              </div>
            </div>
            {/* <div className="d-flex justify-content-between">
              <div>
                <p>Discount applied</p>
              </div>
              <div>
                <p>
                  {selectedCoupon?.discount?.deduction_type === "abs" && "Rs."}{" "}
                  {selectedCoupon?.discount?.deduction_value}
                  {selectedCoupon?.discount?.deduction_type === "perc" && "%"}
                </p>
              </div>
            </div> */}

            <div className="d-flex flex-column justify-content-between">
              <div className="d-flex justify-content-">
                <Button
                  variant={showAffiliate ? "secondary" : "primary"}
                  className="me-3"
                  id="affiliate"
                  onClick={onAffiliateClick}
                >
                  Affiliate Code
                </Button>
                <Button
                  variant={showAffiliate ? "primary" : "secondary"}
                  id="promo"
                  onClick={onPromoClick}
                >
                  Promo Code
                </Button>
              </div>
              <div className="d-flex justify-content-between align-items-start my-3">
                {showAffiliate ? (
                  <div className="col-11">
                    <FloatingLabel
                      controlId="affiliate_code"
                      label="Have an affiliate code?"
                    >
                      <FormControl
                        type="text"
                        name="affiliate_code"
                        placeholder="Have an affiliate code?"
                        onChange={onAffChange}
                        value={aff_code}
                        isValid={aff_touched && !aff_error}
                        isInvalid={aff_touched && aff_error}
                      />
                      {aff_touched && (
                        <FormControl.Feedback type="invalid">
                          No such code found.
                        </FormControl.Feedback>
                      )}
                      {aff_loading && (
                        <FormControl.Feedback type="valid">
                          Checking validity, please wait.
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </div>
                ) : (
                  <div className="col-11">
                    <FloatingLabel
                      controlId="promo_code"
                      label="Have a promo code?"
                    >
                      <FormControl
                        type="text"
                        name="promo_code"
                        placeholder="Have a promo code?"
                        onChange={onPromoChange}
                        value={promo_code}
                        isValid={promo_touched && !promo_error}
                        isInvalid={promo_touched && promo_error}
                      />
                      {promo_touched && (
                        <FormControl.Feedback type="invalid">
                          No such code found or has expired.
                        </FormControl.Feedback>
                      )}
                      {promo_loading && (
                        <FormControl.Feedback type="valid">
                          Checking validity, please wait.
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </div>
                )}
                <div>
                  <button
                    className="border d-flex align-items-center rounded-1 px-2"
                    onClick={() => {
                      onAffClear();
                      onPromoClear();
                    }}
                    style={{
                      border: "none",
                      borderBottom: "1px solid black",
                      backgroundColor: "transparent",
                    }}
                  >
                    <p
                      className="mb-0 fs-6"
                      // style={{ borderBottom: "1px solid black" }}
                    >
                      <AiOutlineClear />
                      clear
                    </p>
                  </button>
                </div>
              </div>
            </div>
            <div>
              <p>
                {aff_code && !aff_error && (
                  <div className="d-flex justify-content-between">
                    <div>
                      <p>Affiliate code discount</p>
                    </div>
                    <div>
                      <p> 10%</p>
                    </div>
                  </div>
                )}
                {promo_code && !promo_error && (
                  <div className="d-flex justify-content-between">
                    <div>
                      <p>Promo code discount</p>
                    </div>
                    <div>
                      <p>
                        {promo?.type === "absolute" && "INR"}
                        {promo?.value}
                        {promo?.type === "relative" && "%"}
                      </p>
                    </div>
                  </div>
                )}

                {transaction?.user &&
                transaction?.user?.location == "Maharashtra" ? (
                  <div className="d-flex justify-content-between">
                    <div>
                      <p>
                        SGST<br></br>CGST
                      </p>
                    </div>
                    <div>
                      <p>
                        {" "}
                        9%<br></br>
                        9%
                      </p>
                    </div>
                  </div>
                ) : transaction?.user &&
                  transaction?.user?.location == "Outside India" ? (
                  <div className="d-flex justify-content-between">
                    <div>
                      <p>Tax</p>
                    </div>
                    <div>
                      <p> 18%</p>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between">
                    <div>
                      <p>IGST</p>
                    </div>
                    <div>
                      <p> 18%</p>
                    </div>
                  </div>
                )}
              </p>
            </div>
            {/* <div>
              <span>Discount coupons:</span>
              <br />
              {dcLoading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : dbError !== null ? (
                <Alert variant="danger" dismissible onClose={dismissdcError}>
                  {dbError}
                </Alert>
              ) : (
                discountCodes.map((coupon) => {
                  return (
                    <Button
                      variant="light mx-2"
                      disabled={
                        selectedCoupon?.discount?.name === coupon.discount.name
                      }
                      onClick={() => {
                        selectCoupon(coupon);
                      }}
                      className="ms-auto"
                    >
                      {coupon.discount.name}
                    </Button>
                  );
                })
              )}
              <u style={{ cursor: "pointer" }} onClick={clearSelectedCoupon}>
                Clear
              </u>
            </div>
            <span>{selectedCoupon?.discount?.description}</span> */}

            <hr style={{ Color: "#000" }}></hr>
            <div className=" d-flex justify-content-between">
              <div>
                <h5>
                  Checkout<br></br>Amount
                </h5>
              </div>
              <div>
                <h5>
                  Rs.<br></br>
                  {amount}
                </h5>
              </div>
            </div>
            <div className="text-center mt-5">
              {error !== null && (
                <Alert
                  variant="danger"
                  dismissible={true}
                  onClose={dismissError}
                >
                  {error}
                </Alert>
              )}
              {isActive ? (
                <Button
                  type="button"
                  variant="primary"
                  className="text-white"
                  onClick={makePayment}
                  disabled={
                    loading ||
                    creating ||
                    aff_loading ||
                    aff_error ||
                    promo_loading ||
                    promo_error
                  }
                >
                  {loading || creating
                    ? [
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />,
                        loading ? " Processing..." : " Creating invoice...",
                      ]
                    : "PROCEED TO PAY"}
                </Button>
              ) : (
                <>
                  <p>This transaction is not active anymore.</p>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={goToDashboard}
                  >
                    Go to dashboard
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
