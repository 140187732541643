/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import ProgressBar from "react-bootstrap/ProgressBar";
import Assest from "../../assets/images/neel_kiara.jpg";
import badge from "../../assets/images/badge.png";
import Spinner from "react-bootstrap/Spinner";
import { Badge } from "../../atoms/image/badge";
import { app_api } from "../../atoms/api";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const GradeDetail = styled.details``;

export const TeacherDetails = (props) => {
  const params = useParams();
  const [teacher, setTeacher] = useState({});

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [classDiv, setClassDiv] = useState({});

  const [program, setProgram] = useState({});
  const [levelCount, setLevelCount] = useState(1);
  const [ploading, setploading] = useState(true);
  const [perror, setperror] = useState(null);

  useEffect(() => {
    try {
      let teacher = JSON.parse(Cookies.get("ut_user"));
      setTeacher(teacher);
      setLoading(true);
      app_api
        .get(
          `school/grouped-subscription/${teacher.schoolId}/program/${params.program_id}`
        )
        .then((res) => {
          setLoading(false);
          setClassDiv(res.data);
        })
        .catch((err) => {
          setLoading(false);
          setError(err?.response?.data?.message || "Error getting enrollments");
        });
      app_api
        .get(`programs/public/id/${params.program_id}`)
        .then((res) => {
          setploading(false);
          setProgram(res.data);
          setLevelCount(res.data.level_sequence?.length || 1);
        })
        .catch((err) => {
          setploading(false);
          setperror(
            err?.response?.data?.message || "Error getting program details"
          );
        });
    } catch {
      // do nothing
    }
  }, [teacher.id]);

  const unlockLevel = (cls, division) => {
    app_api
      .patch("/enrollment/update-lock", {
        class: cls,
        division,
        schoolId: teacher.schoolId,
        programId: parseInt(params.program_id),
      })
      .then(() => {
        alert("Done");
      })
      .catch((err) => {
        alert(err?.response?.data?.message || "error updating");
      });
  };
  return (
    <Container className="my-3 bg-white rounded-3 poppins mt-10 ">
      <h1
        className=" secondary-blue f-55 f-sm-38 mb-5"
        style={{ color: "#1e4363" }}
      >
        <b>{program?.name || "Program name"}</b>
      </h1>
      <div
        className="p-3"
        style={{ boxShadow: "rgb(0 0 0 / 20%) 0px 3px 10px" }}
      >
        <h2 className="p-3 dashboard-blue" style={{ fontSize: "35px" }}>
          Grades
        </h2>
        {loading ? (
          <span>Loading...</span>
        ) : error !== null ? (
          <span>{error}</span>
        ) : (
          Object.keys(classDiv).map((cls) => (
            <GradeDetail>
              <summary className="border">Grade {cls}</summary>
              {Object.keys(classDiv[cls]).map((div) => (
                <details className="my-3">
                  <summary>
                    Division {div}{" "}
                    <button
                      className="btn btn-secondary"
                      onClick={() => unlockLevel(cls, div)}
                      type="button"
                    >
                      Unlock next level
                    </button>
                    {" " +
                      "Currently Locked at " +
                      " " +
                      program?.levels?.find(
                        (l) =>
                          Number(l.id) ===
                          Number(
                            program?.level_sequence?.[
                              classDiv[cls][div]?.[0]?.lockedAt
                            ]
                          )
                      )?.title}
                  </summary>
                  <Table
                    hover={true}
                    striped={true}
                    responsive="sm"
                    bordered={true}
                  >
                    <thead>
                      <tr>
                        <th>Student Name</th>
                        <th>Level Name</th>
                        <th>Comparision Report</th>
                      </tr>
                    </thead>
                    <tbody>
                      {classDiv[cls][div].map((e, idx) => (
                        <tr key={idx}>
                          <td>
                            {e?.learner?.first_name +
                              " " +
                              e?.learner?.last_name}
                          </td>
                          <td>{e?.current_level_name}</td>
                          <td>
                            {e?.learner_comparision_report ? (
                              <a
                                href={
                                  process.env.REACT_APP_BACKEND_URL +
                                  e?.learner_comparision_report
                                }
                                target="_blank"
                                rel="noreferrer"
                              >
                                View
                              </a>
                            ) : (
                              "N/A"
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </details>
              ))}
            </GradeDetail>
          ))
        )}
      </div>
    </Container>
  );
};
export default TeacherDetails;
