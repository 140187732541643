import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CardBootstrap from "react-bootstrap/Card";
import styled, { css } from "styled-components";
import pt from "prop-types";

export const Card = styled(CardBootstrap)`
  background-color: rgba(107, 202, 208, 0.3);
  color: #275076;
  border: 1px solid transparent;
  cursor: pointer;

  & > h6 {
    font-weight: 600;
  }

  &:hover {
    color: #0a2a48;
    background-color: rgba(107, 202, 208, 0.35);
  }

  ${(props) =>
    props.selected &&
    css`
      border: 2px solid #0f3d67;
      background-color: rgba(107, 202, 208, 0.5);
    `}

  @media screen and (min-width: 768px) {
    width: 100%;
    & > :not(h6) {
      font-size: 30px;
    }
  }
  @media screen and (max-width: 540px) {
   h6{
      font-size: 13px;
   }
   .sm-card{
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
    min-width:111px!important;
   }
   .card{
    width:111px!important;
   }
  }

  @media screen and (min-width: 992px) {
    // & > h6 {
    //   width: 90%;
    // }
    & > :not(h6) {
      font-size: 40px;
    }
  }
`;

export const InfoCard = (props) => (
<div>
<div className="d-flex justify-content-start flex-row  col-12">
  <Card
    className="d-lg-flex d-none sm-card w-md-100  col-md-0  col-md-0 py-3 px-2 p-lg-5 p-md-3 m-1 align-items-center"
    selected={props.selected}
    onClick={props.onClick}
  >
    <FontAwesomeIcon icon={props.icon} size="lg" />
    <h6 className=" d-md-block text-capitalize text-center mt-2 px-1">
      {props.info}
    </h6>
  </Card>
</div>
  <Card
    className="d-flex d-lg-none sm-card w-md-100 col-11 col-md-0  col-md-0 py-3 px-2 p-lg-5 p-md-3 m-1 align-items-center"
    style={{width:"111px"}}
    selected={props.selected}
    onClick={props.onClick}
  >
    <FontAwesomeIcon icon={props.icon} size="lg" />
    <h6 className=" d-md-block text-capitalize text-center mt-2 px-1">
      {props.info}
    </h6>
  </Card>

</div>

);

InfoCard.propTypes = {
  icon: pt.any.isRequired,
  info: pt.string.isRequired,
  selected: pt.bool,
  onClick: pt.func,
};
