import React, { useState } from "react";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardBootstrap from "react-bootstrap/Card";
import ButtonBootstrap from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import { PurchasedProgram as ProgramCard } from "../../molecules/purchasedProgram";
// import kit from "../../assets/pdf/kit.pdf";
import { Link } from "react-router-dom";

const KitCard = styled(CardBootstrap)`
  background-color: #fff4de;
  border: none;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);

  & > h2 {
    color: var(--bs-info);
  }

  & > p {
    color: #464e5f;
  }
`;

const StarterKitButton = styled(ButtonBootstrap)`
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  border-radius: 0.85rem;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  color: #ffffff;
  background-color: #ffa800;
  margin-bottom: 30px;
  :hover {
    color: #ffffff;
    background-color: #d98f00;
  }
`;

export const PurchasedPrograms = (props) => {
  return (
    <Container>
      <Row className="g-1 py-3">
        <Col md="6" lg="4">
          <KitCard
            className="mxh-500 col-lg-11 col-12 mx-lg-3 mt-lg-0 mt-md-3 mb-3 starter-kit"
            style={{ borderRadius: "10px" }}
          >
            <h2 className="info mb-5">Getting started kit</h2>
            <p className="mb-3 mb-md-5 p-17">
              Thank you for choosing unTaboo.
              <br />
              We have hand crafted a quick starter kit for you
            </p>
            <a href="https://api.untaboo.online/uploads/pdf/getting_started_kit.pdf" download>
              <StarterKitButton
                variant="info"
                className="mt-3 mt-md-5 align-self-start"
              >
                Download
              </StarterKitButton>
            </a>
          </KitCard>
        </Col>
        {props.uLoading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : !!props.uError ? (
          <Col>
            <label className="text-danger text-capitalize">
              {props.uError}
            </label>
          </Col>
        ) : (
          props.unassignedPrograms.map((p, index) => {
            let expiring_soon = false;
            if (p.expires_at) {
              let today = new Date();
              let expires_at = new Date(p?.expires_at);
              expiring_soon = expires_at - today < 1000 * 60 * 60 * 24 * 7;
            }
            return (
              <ProgramCard
                key={index}
                parent
                id={p.id}
                programName={p?.program?.name}
                directed_at={p?.program?.directed_at || "girls"}
                currentLevel={0}
                level_length={
                  Array.isArray(p?.program?.level_sequence)
                    ? p?.program?.level_sequence.length
                    : 1
                }
                goToLearner={props.assignLearner}
                expiringSoon={expiring_soon}
              />
            );
          })
        )}
        {props.loading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : !!props.error ? (
          <Col>
            <label className="text-danger text-capitalize">{props.error}</label>
          </Col>
        ) : (
          props.programs.map((p, index) => {
            let today = new Date();
            let expires_at = new Date(p?.expires_at);
            let expiring_soon = expires_at - today < 1000 * 60 * 60 * 24 * 7;
            return (
              <ProgramCard
                key={index}
                parent
                id={p.id}
                programName={p?.program?.name}
                directed_at={p?.program?.directed_at || "girls"}
                currentLevel={p?.current_level}
                currentLevelName={p?.current_level_name}
                level_length={
                  Array.isArray(p?.program?.level_sequence)
                    ? p?.program?.level_sequence.length
                    : 1
                }
                firstName={p?.learner?.first_name}
                goToLearner={props.goToLearner}
                hasSurveyed={!!p?.surveyed_on}
                expiringSoon={expiring_soon}
                parentReport={p?.parent_comparision_report || null}
              />
            );
          })
        )}
        {props.programs?.length === 0 &&
          props.unassignedPrograms?.length === 0 && (
            <span className="text-muted mt-3 col-md-6 col-lg-8 fs-5">
              Thank you for joining the UnTaboo community. You are now just one
              step away from giving your child a gift of a lifetime - The
              Positive Puberty Program!&nbsp;
              <Link to="/programs">Click here</Link> to buy.
            </span>
          )}
      </Row>
    </Container>
  );
};

PurchasedPrograms.propTypes = {};
