import React from "react";
import { Accordion } from "react-bootstrap";

const AffiliateFaq = () => {
  return (
    <Accordion
      className="mb-3 rounded-3 px-4 px-lg-0"
      style={{ fontSize: "17px" }}
    >
      <Accordion.Item eventKey="0" className="accordion-item mt-4">
        <Accordion.Header
          style={{ color: "#1e4363", fontSize: "20px!important" }}
        >
          What do I need to become an affiliate?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Becoming an affiliate with us does not require any prior knowledge or
          any sort of educational qualifications. You only need wifi and your
          laptop/mobile phone to get started, to leverage your personal and
          professional network to promote UnTaboo Programs and start earning
          money.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header style={{ color: "#1e4363" }}>
          What type of data/information will I have access to get started?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          You will receive a complete UnTaboo Hand Book with List of Hashtags
          and Keywords to use, suggested Digital Media Channels, FAQs - to solve
          for customer queries if any. Not just that, we will support you by
          providing social media creatives to start Championing us right away.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header style={{ color: "#1e4363" }}>
          What additional details besides the Handbook will you give me to help
          me sell?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          You would get to offer your network a flat 10% discount, over & above
          any promotional offers that we keep running regularly. We will also be
          sharing social media creatives with you to market our programs. You
          get to attend our scheduled Webinars for free (to help you build your
          own expertise in the subject!) You can also leverage UnTaboo experts
          to host a Webinar or a Live session with your network on your channel
          of choice (For affiliates who sell 50+ programs in a quarter). And you
          get the right to display a specially-designed UnTaboo Champion badge
          on your Social Media handles!
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Who do I reach out to with my queries?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          As an UnTaboo Champion, you would be a part of a dedicated Champions
          Whatsapp support group hosted by UnTaboo - to address your queries &
          challenges on a real-time basis.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Do you have a Customer Service department to resolve customer queries?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          We take care of each Customer’s concerns & queries in a personal
          manner, directly through the Whatsapp chat option on our platform, or
          email option at{" "}
          <a href="mailto:team.untaboo@gmail.com">team.untaboo@gmail.com</a>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header style={{ color: "#1e4363" }}>
          How will sales be tracked? Will I receive updates?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          You can log onto the platform using your email to get a list of sales
          made using your affiliate code.At the end of the first week of every
          month, you will receive a purchase statement and applicable affiliate
          commission will be transferred to the bank account provided by you.
          Regular updates will also be posted on the UnTaboo Champions Whatsapp
          support group.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Will I have sales targets?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          We are a purpose driven company with a mission of making safety & sex
          education available to every child in India. The purpose is much
          larger than just sales and as such we do not have any set sales
          targets for you. But sky's the limit in what you want to drive &
          achieve. We are planning a special rewards & recognition program for
          our top performers regularly - so make sure you feature in the Stars
          List!
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Can I recruit other affiliate partners?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Sure you can! Just copy and share this message link with them:
          <br></br> Hey - UnTaboo, a Safety & Sex education company has some
          amazing programs for children & teens. They are offering an
          opportunity to be an UnTaboo Champion and earn money while making a
          positive change in society. I thought you might be interested. Check
          them out at
          <a href="https://untaboo.online/affiliate">
            {" "}
            https://untaboo.online/affiliate
          </a>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="9">
        <Accordion.Header style={{ color: "#1e4363" }}>
          How much will I earn? How and when will I get paid?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          You get paid between 10% to 30% commission, based on the B2C
          Commercials grid listed in the Affiliate program details above. The
          more programs you sell, the more potential to earn! All payments will
          be done via bank transfer by the 1st week of every month
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="10">
        <Accordion.Header style={{ color: "#1e4363" }}>
          If a customer is buying programs in bulk can I give them a higher
          discount?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          The discount structure that we offer our UnTaboo Champions for their
          network is a flat 10%. Our programs run frequent promotional rates and
          this discount is applicable over & above that. So that's already a
          pretty good price! If it is a 100+ program purchase, please reach out
          to us at team.untaboo@gmail.com for a special bulk discount plan.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="11">
        <Accordion.Header style={{ color: "#1e4363" }}>
          A school may be interested in buying your programs in bulk. Do I get a
          commission for getting a big client ?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          We are coming up with a B2B model for large organizations, schools,
          corporates, and bulk B2B. The product pricing for these bulk buy-ins
          will be different from the B2C pricing. Watch this space for the B2B
          Commercials Grids for our Champions and as an existing UnTaboo
          Champion, you will automatically be upgraded as an affiliate for both
          B2C & B2B products. Till we have the Commercial grid for B2B in
          place,, do reach out to us for any leads you have for
          schools/corporates - and we can work on offering the best deal to your
          lead & you! (Do note - Bulk deals of 100+ at a go, qualify as B2B
          program purchases.)
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default AffiliateFaq;
