import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Cookie from "js-cookie";
import pt from "prop-types";
import { schoolSignIn, signInUser, teacherSignIn } from "../../atoms/schema";
import {
  ChangeScreenMessage,
  ContainerDiv,
  SubmitButtonFormGroup,
  InputBox,
  PrimaryButton,
  SecondaryButton,
  SelectBox,
} from "../../pages/login";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormControl from "react-bootstrap/FormControl";
import Alert from "react-bootstrap/Alert";
import { app_api } from "../../atoms/api";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { Formik } from "formik";
import {
  useGAEventSchoolLogin,
  useGAEventSignUp,
} from "../../hoc/hooks/useGAEventTracker";

export const Links = styled.a`
  color: #0f3d67;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;

  :hover {
    color: #6bcacf;
    text-decoration: none;
  }
`;

export const TeacherSignIn = (props) => {
  let navigate = useNavigate();
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);

  const SignUp = () => {
    props.switchSection("signin");
  };

  return (
    <ContainerDiv
      className="flex-column justify-content-start position-absolute col-12 col-lg-11 col-xl-10"
      {...props}
    >
      <ChangeScreenMessage>Teacher login</ChangeScreenMessage>
      <Formik
        initialValues={{ emailId: "", password: "", schoolId: "" }}
        validationSchema={teacherSignIn}
        onSubmit={(values) => {
          setLoginLoading(true);
          app_api
            .post("teacher/login", {
              ...values,
              schoolId: parseInt(values.schoolId),
            })
            .then((res) => {
              setLoginLoading(false);
              Cookie.set("ut_user", JSON.stringify(res["data"]));
              navigate(props.go_back ? -1 : "/dashboard/teacher");
            })
            .catch((err) => {
              setLoginLoading(false);
              setLoginError(err?.response?.data?.message);
            });
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          errors,
          isValid,
          resetForm,
        }) => (
          <Form
            noValidate={true}
            onSubmit={handleSubmit}
            className="mt-5 pb-5 pb-lg-0 mb-4 mb-lg-0"
          >
            {loginError !== null && (
              <Alert
                variant="danger"
                dismissible={true}
                onClose={() => setLoginError(null)}
              >
                {loginError}
              </Alert>
            )}
            {props.loading ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : props.error !== null ? (
              <span className="text-danger">{props.error}</span>
            ) : (
              <div className="mb-3">
                <SelectBox
                  name="schoolId"
                  value={values.schoolId}
                  required={true}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.schoolId && !errors.schoolId}
                  isInvalid={touched.schoolId && !!errors.schoolId}
                >
                  <option value="">Select your school</option>
                  {props.schools.map((s) => (
                    <option value={s?.id}>{s?.name}</option>
                  ))}
                </SelectBox>
                {touched.schoolId && (
                  <FormControl.Feedback type="invalid">
                    {errors.schoolId}
                  </FormControl.Feedback>
                )}
              </div>
            )}
            <FloatingLabel
              controlId="teacher-signin.emailId"
              label="Email ID"
              className="mb-3"
            >
              <InputBox
                type="text"
                placeholder="Email ID"
                autoComplete="emailId"
                name="emailId"
                value={values.emailId}
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                isValid={touched.emailId && !errors.emailId}
                isInvalid={touched.emailId && !!errors.emailId}
              />
              {touched.emailId && (
                <FormControl.Feedback type="invalid">
                  {errors.emailId}
                </FormControl.Feedback>
              )}
            </FloatingLabel>
            <FloatingLabel
              controlId="teacher-signin.password"
              label="Password"
              className="mb-3"
            >
              <InputBox
                type="password"
                placeholder="Password"
                autoComplete="new-password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                isValid={touched.password && !errors.password}
                isInvalid={touched.password && !!errors.password}
              />
              {touched.password && (
                <FormControl.Feedback type="invalid">
                  {errors.password}
                </FormControl.Feedback>
              )}
            </FloatingLabel>
            <ChangeScreenMessage
              style={{ marginBottom: "3rem", display: "block" }}
            >
              <span>
                <a href="/forgotpassword?type=teacher">Forgot Password</a>
              </span>
            </ChangeScreenMessage>
            <SubmitButtonFormGroup className="d-flex flex-column align-items-lg-start flex-sm-row text-center text-lg-start">
              <PrimaryButton
                variant="primary"
                type="submit"
                disabled={loginLoading || !isValid}
              >
                {loginLoading
                  ? [
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />,
                      " Logging in...",
                    ]
                  : "Login"}
              </PrimaryButton>
              {/* <SecondaryButton variant="secondary">
                Sign in with Google
              </SecondaryButton> */}
              <SecondaryButton
                variant="secondary"
                onClick={() => {
                  SignUp();
                  resetForm();
                }}
              >
                Cancel
              </SecondaryButton>
            </SubmitButtonFormGroup>
          </Form>
        )}
      </Formik>
    </ContainerDiv>
  );
};
