import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export const Policy = () => {
  return (
    <Container>
      <div className="poppins py-3 ">
        <h2 className="primary fw-bold fs-1 primary-pink">PRIVACY POLICY</h2>
        <h1 className="secondary-blue py-4  fs-2">
          Thank you for using UnTaboo Online
        </h1>
        <p className="secondary-b col-11 pt-3 pb-3 mb-5" style={{ fontSize: "17px" }}>
          We value your trust in us. In order to honour that trust, UnTaboo
          Online adheres to ethical standards in gathering, using, and
          safeguarding any information you provide.<br></br>
          <br></br> UnTaboo Online’s services are provided by UnTaboo Education
          Private Ltd. (‘Company’).<br></br>
          <br></br> This privacy policy governs your use of the learning
          platform (‘Platform’) and other associated services managed by the
          Company.<br></br>
          <br></br> Please read this privacy policy ('Policy') carefully before
          using the Platform or our Services, along with the Terms of Use (‘ToU)
          provided on the Website. By using our Services, you agree to be bound
          by these Terms, including the policies referenced in these Terms.
        </p>
        <h1 className="secondary-blue pt-3 pb-3 fs-2">User Provided Information</h1>
        <p className="secondary-b col-11 pt-3 pb-3 mb-5" style={{ fontSize: "17px" }}>
          When you register with us, you generally provide (a) your name, age,
          email address, location, phone number, password and your ward's name
          and age; (b) transaction-related information, such as when you make
          purchases, respond to any offers, or download or use applications from
          us; (c) information you provide us when you contact us for help; (d)
          information you enter into our system when using the
          Platform/Services, such as while asking doubts, participating in
          discussions and taking tests. The said information collected from the
          users could be categorized as “Personal Information”, “Sensitive
          Personal Information” and “Associated Information”. Personal
          Information, Sensitive Personal Information and Associated Information
          (each as individually defined under this Information Technology
          (Reasonable security practices and procedures and sensitive personal
          data or information) Rules, 2011 (the “Data Protection Rules”)) shall
          collectively be referred to as 'Information' in this Policy.
          <br></br>
          <br></br> We may use the Information to contact you from time to time,
          to provide you with the Services, important information, required
          notices and marketing promotions. We will ask you when we need more
          information that personally identifies you (personal information) or
          allows us to contact you.<br></br>
          <br></br> We will not differentiate between who is using the device to
          access the Platform, or Services so long as the log in/access
          credentials match with yours. In order to make the best use of the
          Platform/Services and enable your Information to be captured
          accurately on the Platform/Services, it is essential that you have
          logged in using your own credentials.<br></br>
          <br></br> We will, at all times, provide the option to you to not
          provide the Personal Information or Sensitive Personal Information,
          which we seek from you. Further, you shall, at any time while using
          the Platform/Services, also have an option to withdraw your consent
          given earlier to us to use such Personal Information or Sensitive
          Personal Information. Such withdrawal of the consent is required to be
          sent in writing to us at the contact details provided in this Policy
          below. In such event, however, the Company fully reserves the right
          not to allow further usage of the Platform or provide any Services
          thereunder to you.
        </p>
        <h1 className="secondary-blue pt-3 pb-3 fs-2">
          Automatically Collected Information
        </h1>
        <p className="secondary-b col-11 pt-3 pb-3 mb-5" style={{ fontSize: "17px" }}>
          In addition, the Platform/Services may collect certain information
          automatically, including, but not limited to, the type of mobile
          device you use, your mobile devices unique device ID, the IP address
          of your mobile device, your mobile operating system, the type of
          mobile Internet browsers you use, and information about the way you
          use the Platform/Services. As is true of most Mobile applications, we
          also collect other relevant information as per the permissions that
          you provide.<br></br>
          <br></br> We use an outside credit card processing company to bill you
          for goods and services. These companies do not retain, share, store or
          use personally identifiable information for any other purpose.
        </p>

        <h1 className="secondary-blue pt-3 pb-3 fs-2">
          Use of your Personal Information
        </h1>
        <p className="secondary-b col-11 pt-3 pb-3 mb-5" style={{ fontSize: "17px" }}>
          We use the collected Information to analyze trends, to conduct
          research, to administer the Platform/Services, to learn about each
          user's learning patterns and movements around the Platform/Services to
          gather demographic information and usage behaviour about our user base
          as a whole. Aggregated and individual, anonymized and non-anonymized
          data may periodically be transmitted to external service providers to
          help us improve the Platform and our Services. We will share your
          information with third parties only in the ways that are described
          below in this Policy.<br></br>
          <br></br> We may use the individual data and behavior patterns
          combined with personal information to provide you with personalized
          content, and better your learning objectives. Third parties provide
          certain services which we may use to analyze the data and information
          to personalize, drive insights and help us better your experience or
          reach out to you with more value added information and services.
          However, these third party companies do not have any independent right
          to share this information. We do not sell, trade or rent your
          Information to any third party unless, we have been expressly
          authorized by you either in writing or electronically to do so. We may
          at times provide aggregate statistics about our customers, traffic
          patterns, and related site information to reputable third parties,
          however this information when disclosed will be in an aggregate form
          and does not contain any of your Personally Identifiable Information.{" "}
          <br></br>
          <br></br>UnTaboo Online will occasionally send email notices or
          contact you to communicate about our Services and its benefits, as
          they are considered an essential part of the Services you have chosen.
        </p>
        <h1 className="secondary-blue pt-3 pb-3 fs-2">
          We may disclose Information:
        </h1>
        <p className="secondary-b col-11 pt-3 pb-3 mb-5" style={{ fontSize: "17px" }}>
          - as required by law, such as to comply with a subpoena, or similar
          legal process;
          <br></br>
          <br></br>- to enforce applicable ToU, including investigation of
          potential violations thereof;
          <br></br>
          <br></br>- when we believe in good faith that disclosure is necessary
          to protect our rights, protect your safety or the safety of others,
          investigate fraud, address security or technical issues or respond to
          a government request;
          <br></br>
          <br></br>- with our trusted services providers who work on our behalf,
          do not have an independent use of the information we disclose to them,
          and have agreed to adhere to the rules set forth in this Policy;
          <br></br>
          <br></br>- to protect against imminent harm to the rights, property or
          safety of the Application/Website/UnTaboo Education Pvt. Ltd. or its
          users or the public as required or permitted by law;
          <br></br>
          <br></br>- with third party service providers in order to personalize
          the Platform/Services for a better user experience and to perform
          behavioural analysis;
          <br></br>
          <br></br>
          Any portion of the Information containing personal data relating to
          minors provided by you shall be deemed to be given with the consent of
          the minor's legal guardian. Such consent is deemed to be provided by
          your registration with us.
        </p>
        <h1 className="secondary-blue pt-3 pb-3 fs-2">
          Access to your Personal Information
        </h1>
        <p className="secondary-b col-11 pt-3 pb-3 mb-5" style={{ fontSize: "17px" }}>
          We will provide you with the means to ensure that your Personal
          Information is correct and current. If you have filled out a user
          profile, we will provide an obvious way for you to access and change
          your profile from our Platform/Services. We adopt reasonable security
          measures to protect your password from being exposed or disclosed to
          anyone.
        </p>
        <h1 className="secondary-blue pt-3 pb-3 fs-2">Cookies</h1>
        <p className="secondary-b col-11 pt-3 pb-3 mb-5" style={{ fontSize: "17px" }}>
          We send cookies (small files containing a string of characters) to
          your computer, thereby uniquely identifying your browser. Cookies are
          used to track your preferences, help you login faster, and aggregated
          to determine user trends. This data is used to improve our offerings,
          such as providing more content in areas of greater interest to a
          majority of users.<br></br>
          <br></br> Most browsers are initially set up to accept cookies, but
          you can reset your browser to refuse all cookies or to indicate when a
          cookie is being sent. Some of our features and services may not
          function properly if your cookies are disabled.
        </p>
        <h1 className="secondary-blue pt-3 pb-3 fs-2">Links</h1>
        <p className="secondary-b col-11 pt-3 pb-3 mb-5" style={{ fontSize: "17px" }}>
          We may present links in a format that enables us to keep track of
          whether these links have been followed. We use this information to
          improve our customized content. Clicking on links may take you to
          sites outside our domain. We are not responsible for the privacy
          practices of other web sites. We encourage our users to be aware when
          they leave our site to read the privacy statements of each and every
          web site that collects personally identifiable information. This
          Privacy Policy applies solely to information collected by our Website.
        </p>
        <h1 className="secondary-blue pt-3 pb-3 fs-2">Alerts</h1>
        <p className="secondary-b col-11 pt-3 pb-3 mb-5" style={{ fontSize: "17px" }}>
          We may alert you by email or phone (through sms/call) to inform you
          about new service offerings or other information which we feel might
          be useful for you.
        </p>
        <h1 className="secondary-blue pt-3 pb-3 fs-2">Public Forums</h1>
        <p className="secondary-b col-11 pt-3 pb-3 mb-5" style={{ fontSize: "17px" }}>
          When you use certain features on our website like the discussion
          forums and you post or share your personal information such as
          comments, messages, files, photos, will be available to all users, and
          will be in the public domain. All such sharing of information is done
          at your own risk. Please keep in mind that if you disclose personal
          information in your profile or when posting on our forums this
          information may become publicly available.
        </p>
        <h1 className="secondary-blue pt-3 pb-3 fs-2">
          How Long Do We Retain User Data?
        </h1>
        <p className="secondary-b col-11 pt-3 pb-3 mb-5" style={{ fontSize: "17px" }}>
          Currently, we plan to retain user data while an account is active and
          for at least three years afterward. We may alter this practice
          according to legal and business requirements. For example, we may
          lengthen the retention period for some data if needed to comply with
          law or voluntary codes of conduct. Unless otherwise prohibited, we may
          shorten the retention period for some types of data if needed to free
          up storage space.
        </p>
        <h1 className="secondary-blue pt-3 pb-3 fs-2">Log information</h1>
        <p className="secondary-b col-11 pt-3 pb-3 mb-5" style={{ fontSize: "17px" }}>
          When you access our Website, our servers automatically record
          information that your browser sends whenever you visit a website.
          These server logs may include information such as your web request,
          internet protocol address, browser type, browser language, the date
          and time of your request and one or more cookies that may uniquely
          identify your browser.
        </p>
        <h1 className="secondary-blue pt-3 pb-3 fs-2">User communications</h1>
        <p className="secondary-b col-11 pt-3 pb-3 mb-5" style={{ fontSize: "17px" }}>
          When you send an email or other communication to us, we may retain
          those communications in order to process your inquiries, respond to
          your requests and improve our Services.
        </p>
        <h1 className="secondary-blue pt-3 pb-3 fs-2">Changes to this Statement</h1>
        <p className="secondary-b col-11 pt-3 pb-3 mb-5" style={{ fontSize: "17px" }}>
          As the Company evolves, our privacy policy will need to evolve as well
          to cover new situations. You are advised to review this Policy
          regularly for any changes, as continued use is deemed approval of all
          changes.
        </p>
        <h1 className="secondary-blue pt-3 pb-3 fs-2">Your Consent</h1>
        <p className="secondary-b col-11 pt-3 pb-3 mb-5" style={{ fontSize: "17px" }}>
          We believe that, every user of our Platform/Services must be in a
          position to provide an informed consent prior to providing any
          Information required for the use of the Platform/Services. By
          registering with us, you are expressly consenting to our collection,
          processing, storing, disclosing and handling of your information as
          set forth in this Policy now and as amended by us. Processing, your
          information in any way, including, but not limited to, collecting,
          storing, deleting, using, combining, sharing, transferring and
          disclosing information, all of which activities will take place in
          India. If you reside outside India your information will be
          transferred, processed and stored in accordance with the applicable
          data protection laws of India.
        </p>
        <h1 className="secondary-blue pt-3 pb-3 fs-2">Contact Information</h1>
        <p className="secondary-b col-11 pt-3 pb-3 mb-5" style={{ fontSize: "17px" }}>
          Our Grievance Officer shall undertake all reasonable efforts to
          address your grievances at the earliest possible opportunity.<Link to="/contact" className="secondary-blue fw-bold" > Click
          here to contact us.</Link>
        </p>
      </div>
    </Container>
  );
};
