import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import Button from "../../atoms/button";
// import Card from "react-bootstrap/Card";
// import CardHeader from "react-bootstrap/CardHeader";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalFooter from "react-bootstrap/ModalFooter";
import coupon1 from "../../assets/images/coupon1.png";
import coupon2 from "../../assets/images/coupon2.png";
import coupon3 from "../../assets/images/coupon3.png";
import coupon4 from "../../assets/images/coupon4.png";
import pcoupon1 from "../../assets/images/pcoupon1.png";
import pcoupon2 from "../../assets/images/pcoupon2.png";
import pcoupon3 from "../../assets/images/pcoupon3.png";
import pcoupon4 from "../../assets/images/pcoupon4.png";
import ConversationJar from "../../assets/images/ConversationJar.png";
import { app_api } from "../../atoms/api";
import { SurveyForm } from "../../organisms/surveyForm";
import { ProgramSideNav } from "../../templates/programSideNav";
import { Document, Page, pdfjs } from "react-pdf";
import {
  LearnerCertificate,
  LearnerSchoolCertificate,
  ParentCertificate,
} from "../../organisms/pdf-viewer";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const CompletionContainer = styled(Container)`
  background-color: #fef7e1;

  .completed {
    text-decoration: underline solid orange;
  }
`;

const AssetsImage = styled.img`
  width: 50%;
  display: block;

  @media screen and (min-width: 768px) {
    width: 25%;
  }
`;

const PDFDocument = styled(Document)`
  max-height: 710px;
  overflow-y: hidden;
`;

export const Completion = (props) => {
  const navigate = useNavigate();
  const params = useParams();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [enrollment, setEnrollment] = useState({});
  const [program, setProgram] = useState({});
  const [score, setScore] = useState(0);
  const [hasDownloaded, setHasDownloaded] = useState(false);
  const [hdLoading, sethdLoading] = useState(false);
  const [hdError, sethdError] = useState(null);
  // const [discountsTaken, setDiscountsTaken] = useState(false);
  // const [dtLoading, setdtLoading] = useState(false);
  // const [dtError, setdtError] = useState(null);
  const [surveyedOn, setSurveyedOn] = useState(null);
  const [soLoading, setsoLoading] = useState(false);
  const [soError, setsoError] = useState(null);
  const [completedOn, setCompletedOn] = useState(null);
  const [showSurveyModal, setShowSurveyModal] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  // const [cLoading, setcLoading] = useState(true);
  // const [cError, setcError] = useState(null);
  // const [codes, setCodes] = useState([]);
  const [chosen, setChosen] = useState([]);

  let user;
  const isLoggedIn = !!Cookies.get("ut_user");
  if (isLoggedIn) {
    user = JSON.parse(Cookies.get("ut_user"));
  }

  const loaded = () => setLoading(false);
  const dismissError = () => setError(null);
  const dismisshdError = () => sethdError(null);
  // const dismissdtError = () => setdtError(null);
  const dismisssoError = () => setsoError(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    app_api
      .get(`enrollment/${params.enrollmentId}`)
      .then((res) => {
        setEnrollment(res.data);
        setProgram({ program: res.data?.program });
        setHasDownloaded(res.data?.has_downloaded);
        // setDiscountsTaken(res.data?.discount_taken);
        setSurveyedOn(res.data?.surveyed_on);
        setCompletedOn(res.data?.completed_on);
        if (!res.data?.completed_on) {
          navigate(`/dashboard/program/${params.enrollmentId}`);
        } else {
          if (!res.data?.has_downloaded) {
            let levelid =
              res.data?.program?.level_sequence[
                res.data?.program?.level_sequence?.length - 1
              ];
            app_api
              .get(
                `user-activity/score/enrollment/${params.enrollmentId}/level/${levelid}`
              )
              .then((res) => {
                loaded();
                setScore(res.data);
              })
              .catch((err) => {
                loaded();
                setError(err?.response?.data?.message || "Error");
              });
          } else {
            loaded();
          }
        }
      })
      .catch((err) => {
        loaded();
        setError(err?.response?.data?.message || "Error");
      });
    // app_api
    //   .get("/discount-codes/active")
    //   .then((res) => {
    //     setcLoading(false);
    //     setCodes(res.data);
    //   })
    //   .catch((err) => {
    //     setcLoading(false);
    //     setcError(err?.response?.data?.message || "Error");
    //   });
  }, [params.enrollmentId]);

  const updateHasDownloaded = () => {
    // if coupons are not downloaded, download either child or parent depending on score
    // if score is less than 0, download parent coupons
    // else download child coupons

    // ZIP files
    // 1. only child certificate
    // 2. child cert with child coupons
    // 3. child cert with parent coupons

    sethdLoading(true);
    if (!hasDownloaded) {
      app_api
        .patch(`enrollment/${params.enrollmentId}/downloaded`)
        .then((res) => {
          sethdLoading(false);
          setHasDownloaded(true);
          // download cert with coupons
        })
        .catch((err) => {
          sethdLoading(false);
          sethdError(
            err?.response?.data?.message || "Error updating enrollment"
          );
        });
    } else {
      // download only child certificate
    }
  };

  // const updateDiscountsTaken = () => {
  //   setdtLoading(true);
  //   app_api
  //     .post("/discount-codes-chosen/bulk", {
  //       parent_email: user.email,
  //       discount_ids: chosen,
  //     })
  //     .then((res) => {
  //       app_api
  //         .patch(`enrollment/${params.enrollmentId}/discounts`)
  //         .then((res) => {
  //           setdtLoading(false);
  //           setDiscountsTaken(true);
  //         })
  //         .catch((err) => {
  //           setdtLoading(false);
  //           setdtError(
  //             err?.response?.data?.message || "Error updating enrollment"
  //           );
  //         });
  //     })
  //     .catch((err) => {
  //       setError(err?.response?.data?.message || "Error");
  //     });
  // };

  const goToDashboard = () => {
    if (surveyedOn) {
      navigate(`/dashboard/learner`);
    } else {
      setShowSurveyModal(true);
    }
  };

  const goToDashboardCancel = () => {
    navigate(`/dashboard/learner`);
  };

  const toggleSelectCode = (id) => {
    if (chosen.includes(id)) setChosen(chosen.filter((cid) => cid !== id));
    else setChosen([...chosen, id]);
  };

  return (
    <div>
      <ProgramSideNav
        navbar
        program={program}
        loading={loading}
        error={error}
        enrollmentId={params.enrollmentId}
        enrollment={enrollment}
      />
      <Row className="justify-content-center">
        <ProgramSideNav
          program={program}
          loading={loading}
          error={error}
          enrollmentId={params.enrollmentId}
          enrollment={enrollment}
        />
        <Col xs="12" className="p-0 w-80 w-sm-100">
          <CompletionContainer className="p-lg-5 p-3 mt-5 rounded-3 poppins">
            <div
              className={`p-lg-5 p-3 ${props.coupon ? "d-block" : "d-none"}`}
            >
              <h2
                className="mb-4 secondary-blue"
                style={{ fontSize: "55px", fontWeight: "600" }}
              >
                Wowie... That was fun!
              </h2>
              <p className="fs-5">
                Dear Parent, whether you won the fitness challenge or not, what
                is more important is that you bought the ticket for this
                beautiful adventure for your child and now you get to be their
                travel partner on this Puberty journey.
              </p>
              <p className="fs-5">
                We hope it is a beautiful journey. There might be ups and downs
                along the way, but do not worry. Your child is equipped to
                tackle all the puberty challenges and we at UnTaboo are right
                there with both of you too.
              </p>
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : error !== null ? (
                <Alert
                  variant="danger"
                  dismissible={true}
                  onClose={dismissError}
                >
                  {error}
                </Alert>
              ) : !hasDownloaded ? (
                <div>
                  <p className="fs-5">
                    The winner of this face-off can click here to select any one
                    gift coupon.
                  </p>
                  <h3
                    className="mt-5 mb-3 secondary-blue"
                    style={{ fontWeight: "600", fontSize: "35px" }}
                  >
                    Coupons
                  </h3>
                  {score >= 0 ? (
                    <Container fluid={true} className="d-block text-center">
                      <img className="my-4 w-100" src={coupon1} alt="coupon" />
                      <img className="my-4 w-100" src={coupon2} alt="coupon" />
                      <img className="my-4 w-100" src={coupon3} alt="coupon" />
                      <img className="my-4 w-100" src={coupon4} alt="coupon" />
                    </Container>
                  ) : (
                    <Container fluid={true}>
                      <img className="my-4 w-100" src={pcoupon1} alt="coupon" />
                      <img className="my-4 w-100" src={pcoupon2} alt="coupon" />
                      <img className="my-4 w-100" src={pcoupon3} alt="coupon" />
                      <img className="my-4 w-100" src={pcoupon4} alt="coupon" />
                    </Container>
                  )}
                </div>
              ) : (
                <p className="completed">
                  You've already downloaded the coupons
                </p>
              )}
              <Container fluid>
                {enrollment?.is_school ? (
                  <LearnerSchoolCertificate
                    name={
                      enrollment?.learner?.first_name +
                      " " +
                      (enrollment?.learner?.last_name || "")
                    }
                    post_percent={enrollment?.post_assessment_percent}
                    gender={enrollment?.learner?.gender}
                    grade={enrollment?.program?.grade || "5"}
                  />
                ) : (
                  <LearnerCertificate
                    name={
                      enrollment?.learner?.first_name +
                      " " +
                      (enrollment?.learner?.last_name || "")
                    }
                  />
                )}
              </Container>
              <div
                className="d-flex flex-column flex-md-row mb-2"
                style={{ gap: "1rem" }}
              >
                {!hasDownloaded && (
                  <a
                    href={
                      score < 0
                        ? "https://api.untaboo.online/uploads/parentCoupons.zip"
                        : "https://api.untaboo.online/uploads/childCoupons.zip"
                    }
                    download="Coupons"
                  >
                    <Button
                      className="text-center fs-5 w-100"
                      onClick={updateHasDownloaded}
                      disabled={hdLoading}
                    >
                      {hdLoading
                        ? [
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                              key="spinner"
                            />,
                            " Downloading",
                          ]
                        : "Download coupons"}
                    </Button>
                  </a>
                )}
                <Button
                  className="text-center fs-5"
                  variant="primary"
                  onClick={() =>
                    navigate(
                      `/dashboard/program/${params.enrollmentId}/success/survey`
                    )
                  }
                >
                  Get a free gift
                </Button>
                <Button
                  className="text-center fs-5"
                  variant="secondary"
                  onClick={goToDashboard}
                >
                  Go to dashboard
                </Button>
              </div>
              {hdError !== null && (
                <Alert
                  variant="danger"
                  dismissible={true}
                  onClose={dismisshdError}
                >
                  {hdError}
                </Alert>
              )}
            </div>
            <div
              className={`p-lg-5 p-3 ${props.survey ? "d-block" : "d-none"}`}
            >
              <h3
                className="secondary-blue mb-4 px-2 px-lg-0 text-center text-lg-start"
                style={{ fontWeight: "600", fontSize: "35px" }}
              >
                {!surveyedOn
                  ? "Share your feedback and claim your free gift"
                  : "Thank you for your feedback!"}
              </h3>
              {!surveyedOn ? (
                <SurveyForm
                  enrollmentId={params.enrollmentId}
                  enrollment={enrollment}
                  loading={soLoading}
                  error={soError}
                  dismissError={dismisssoError}
                  setSurveyedOn={setSurveyedOn}
                  setLoading={setsoLoading}
                  setError={setsoError}
                />
              ) : (
                <Container fluid={true}>
                  <p className="completed">
                    And here is your gift - a fun conversation jar. Download it
                    and follow the instructions there to have a fun family game
                    which will open up beautiful conversations at home. Go on -
                    enjoy!
                  </p>
                  {/* {discountsTaken ? (
                    <p>You've already taken discounts</p>
                  ) : cLoading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : cError !== null ? (
                    <label className="danger">{cError}</label>
                  ) : (
                    codes.map((c, index) => (
                      <Card className="mb-3" key={index} style={{border:"none"}}>
                        <CardHeader>
                          {c?.name}
                          <Button
                            variant={
                              chosen.includes(c.id) ? "danger" : "primary"
                            }
                            onClick={() => toggleSelectCode(c.id)}
                            disabled={
                              chosen.length === 2 && !chosen.includes(c.id)
                            }
                            className="ms-auto"
                          >
                            {chosen.includes(c.id) ? "Remove" : "Select"}
                          </Button>
                        </CardHeader>
                        <Card.Body>
                          <p>{c?.description}</p>
                          <p>
                            Get a discount of{" "}
                            {c?.deduction_type === "abs" && "INR "}
                            {c?.deduction_value}
                            {c?.deduction_type === "perc" && "%"}
                          </p>
                          {c?.expire_on && (
                            <span>Expires on: {c?.expire_on}</span>
                          )}
                        </Card.Body>
                      </Card>
                    ))
                  )} */}
                  <div
                    className="d-flex flex-column flex-md-row"
                    style={{ gap: "1rem " }}
                  >
                    {/* {codes?.length > 0 && !discountsTaken && (
                      <Button onClick={updateDiscountsTaken}>
                        {dtLoading ? "Submitting" : "Submit"}
                      </Button>
                    )} */}
                    {/* {dtError !== null && (
                      <Alert
                        variant="danger"
                        dismissible={true}
                        onClose={dismissdtError}
                      >
                        {dtError}
                      </Alert>
                    )} */}
                  </div>
                  {surveyedOn && (
                    <div>
                      <div className="mt-5">
                        <h3 className="p-3 fw-bold secondary-blue">
                          Conversation Jar
                        </h3>
                        <AssetsImage
                          src={ConversationJar}
                          alt="Conversation Jar"
                          style={{ pointerEvents: "none" }}
                        />
                        <a
                          href={
                            program?.program?.type === "school"
                              ? program?.program?.grade == 7
                                ? program?.program?.directed_at === "girls"
                                  ? "https://api.untaboo.online/uploads/grade7PG.zip"
                                  : "https://api.untaboo.online/uploads/grade7PB.zip"
                                : program?.program?.grade == 6
                                ? program?.program?.directed_at === "girls"
                                  ? "https://api.untaboo.online/uploads/grade6PG.zip"
                                  : "https://api.untaboo.online/uploads/grade6PB.zip"
                                : program?.program?.directed_at === "girls"
                                ? "https://api.untaboo.online/uploads/grade5PG.zip"
                                : "https://api.untaboo.online/uploads/grade5PB.zip"
                              : program?.program?.directed_at === "girls"
                              ? "https://api.untaboo.online/uploads/GirlsConversationJar.zip"
                              : "https://api.untaboo.online/uploads/BoysConversationJar.zip"
                          }
                          download="ConversationJar"
                        >
                          <Button className="mt-4">Download</Button>
                        </a>
                      </div>
                      <Container className="mt-3">
                        <ParentCertificate
                          isFather={enrollment?.learner?.parent?.gender === "M"}
                          name={
                            enrollment?.learner?.parent?.first_name +
                            " " +
                            (enrollment?.learner?.parent?.last_name || "")
                          }
                        />
                      </Container>
                    </div>
                  )}
                  <Button
                    onClick={() => {
                      navigate(`/dashboard/learner`);
                    }}
                    secondary
                  >
                    Go to dashboard
                  </Button>
                </Container>
              )}
            </div>
          </CompletionContainer>
        </Col>
      </Row>
      <Modal
        centered={true}
        show={showSurveyModal}
        onHide={() => {
          setShowSurveyModal(false);
        }}
      >
        <ModalHeader closeButton={true}>
          <ModalTitle>Are you sure?</ModalTitle>
        </ModalHeader>
        <ModalBody>
          We see that you've not filled our survey form. We urge you to fill
          this form and claim your free gifts.
        </ModalBody>
        <ModalFooter>
          <Button
            variant="primary"
            onClick={() => {
              setShowSurveyModal(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={goToDashboardCancel}>
            Go ahead anyway
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
