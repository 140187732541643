import React, { useState, useEffect } from "react";

import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import CardHeader from "react-bootstrap/CardHeader";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardImg from "react-bootstrap/CardImg";
import Assest from "../../assets/images/Crushes_webinar.png";
import Anju from "../../assets/images/Anju.png";
import asset1 from "../../assets/images/Girls Q_A.png";
import asset2 from "../../assets/images/Boys Q_A.png";
import { Form, Formik } from "formik";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { InputBox } from "../../pages/login";
import * as yup from "yup";
import Cookies from "js-cookie";
import FormControl from "react-bootstrap/FormControl";
import axios from "axios";
import { app_api } from "../../atoms/api";

const UpcomingEventCard = styled(Card)`
  background-color: #f64e60;

  > div {
    row-gap: 2em;
  }
`;

const RegisterWebinar = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup
    .string()
    .email("Should be a valid email ID")
    .required("Email is required"),
  phone: yup
    .number()
    .min(1000000000, "Enter a valid 10 digit number")
    .required("Number is required"),
  age: yup.number().optional(),
});

export const Webinar = (props) => {
  const [show, setShow] = useState(false);
  const [user, setUser] = useState({});
  const [webInfo, setWebInfo] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleClose = () => {
    setShow(false);
    setWebInfo("");
  };
  const handleShow = (name) => {
    setShow(true);
    setWebInfo(name);
  };

  useEffect(() => {
    try {
      setUser(JSON.parse(Cookies.get("ut_user")));
    } catch {}
  }, [user?.id]);

  return (
    <Container className="py-3">
      <Row className="g-3">
        <Col md="8">
          <Card>
            <CardHeader as="h5" style={{ fontSize: "35px" }}>
              All Events
            </CardHeader>
            <Card.Body>
              <Table responsive="md">
                <thead>
                  <tr>
                    <th className="fs-5">Event</th>
                    <th className="fs-5">Title</th>
                    <th className="fs-5">Date &amp;Time</th>
                    <th className="fs-5 text-center">Event Status</th>
                    <th className="fs-5">Access</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="w-10 align-middle">
                      <img className="w-100 rounded me-2" src={Assest} />
                    </td>
                    <td className="align-middle">
                      Why every child needs Sex Education!
                    </td>
                    <td className="align-middle">-</td>
                    <td className="align-middle text-center">
                      <Badge pill={true} bg="secondary">
                        Over
                      </Badge>
                    </td>
                    <td className="align-middle">
                      <a
                        href="https://www.youtube.com/watch?v=7utWyOKIo_4&list=PLcoHSCfbV44q8SwC-_yQTfrdfWu4eFgiU&index=2"
                        target="_blank"
                      >
                        <Button variant="info" className="text-white">
                          Recorded
                        </Button>
                      </a>
                    </td>
                  </tr>

                  <tr>
                    <td className="w-10 align-middle">
                      <img className="w-100 rounded me-2" src={Assest} />
                    </td>
                    <td className="align-middle">
                      Do kids want sex education?
                    </td>
                    <td className="align-middle">-</td>
                    <td className="align-middle text-center">
                      <Badge pill={true} bg="secondary">
                        Over
                      </Badge>
                    </td>
                    <td className="align-middle">
                      <a
                        href="https://www.youtube.com/watch?v=0HdJddH4aqg&list=PLcoHSCfbV44q8SwC-_yQTfrdfWu4eFgiU&index=2&ab_channel=UnTaboo"
                        target="_blank"
                      >
                        <Button variant="info" className="text-white">
                          Recorded
                        </Button>
                      </a>
                    </td>
                  </tr>

                  {/* <tr>
                    <td className="w-10 align-middle">
                      <img className="w-100 rounded me-2" src={asset2} />
                    </td>
                    <td className="align-middle">
                      Q&amp;A Puberty Webinar for boys
                    </td>
                    <td className="align-middle">21st December 2022</td>
                    <td className="align-middle text-center">
                      <Badge pill={true} bg="secondary">
                        Upcoming
                      </Badge>
                    </td>
                    <td className="align-middle">
                      <Button
                        variant="info"
                        className="text-white"
                        onClick={() =>
                          handleShow("Q&A Puberty webinar for boys")
                        }
                      >
                        Register Now
                      </Button>
                    </td>
                  </tr>

                  <tr>
                    <td className="w-10 align-middle">
                      <img className="w-100 rounded me-2" src={asset1} />
                    </td>
                    <td className="align-middle">
                      Q&amp;A Puberty Webinar for girls
                    </td>
                    <td className="align-middle">21st December 2022</td>
                    <td className="align-middle text-center">
                      <Badge pill={true} bg="secondary">
                        Upcoming
                      </Badge>
                    </td>
                    <td className="align-middle">
                      <Button
                        variant="info"
                        className="text-white"
                        onClick={() =>
                          handleShow("Q&A Puberty webinar for girls")
                        }
                      >
                        Register Now
                      </Button>
                    </td>
                  </tr> */}
                </tbody>
              </Table>
            </Card.Body>
          </Card>

          {/* <!-- Modal --> */}
          <Modal show={show} onHide={handleClose} animation={true}>
            <Modal.Header closeButton>
              <Modal.Title className="fs-5 secondary-blue poppins my-3 mx-3 fw-bold">
                Register Now
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h3 className="fs-5 secondary-blue poppins text-center">
                Be among the first to get front row seats to this super
                impactful &amp; interactive webinar by Anju Kish
              </h3>
              <Formik
                initialValues={{
                  name: user?.first_name + " " + user?.last_name,
                  email: user?.email,
                  phone: "",
                  age: "",
                  webinar: webInfo,
                }}
                enableReinitialize
                validationSchema={RegisterWebinar}
                onSubmit={(values) => {
                  setLoading(true);
                  Object.keys(values).forEach((key) => {
                    if (!values[key]) delete values[key];
                  });
                  app_api
                    .post("/user/interested/webinar", values)
                    .then((res) => {
                      setLoading(false);
                      setSuccess(true);
                      setTimeout(() => {
                        setSuccess(false);
                      }, 2000);
                    })
                    .catch((err) => {
                      setLoading(false);
                      setError(
                        err?.response?.data?.message || "Error registering"
                      );
                      setTimeout(() => {
                        setError(null);
                      }, 5000);
                    });
                }}
              >
                {({
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  errors,
                  touched,
                  values,
                  isValid,
                }) => (
                  <Form
                    className="mt-4  pb-lg-0 mb-4 mb-lg-0"
                    noValidate={true}
                    onSubmit={handleSubmit}
                  >
                    <Col md className="mb-3">
                      <FloatingLabel label="Name">
                        <InputBox
                          className="g-2"
                          name="name"
                          type="text"
                          value={values.name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={true}
                          isValid={touched.name && !errors.name}
                          isInvalid={touched.name && !!errors.name}
                        />
                        {touched.name && (
                          <FormControl.Feedback type="invalid">
                            {errors.name}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md className="mb-3">
                      <FloatingLabel label="Email">
                        <InputBox
                          name="email"
                          type="email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required={true}
                          isValid={touched.email && !errors.email}
                          isInvalid={touched.email && !!errors.email}
                        />
                        {touched.email && (
                          <FormControl.Feedback type="invalid">
                            {errors.email}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md className="mb-3">
                      <FloatingLabel label="Phone">
                        <InputBox
                          name="phone"
                          type="number"
                          min="10000000000"
                          value={values.phone}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.phone && !errors.phone}
                          isInvalid={touched.phone && !!errors.phone}
                        />
                        {touched.phone && (
                          <FormControl.Feedback type="invalid">
                            {errors.phone}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md className="mb-3">
                      <FloatingLabel label="Age">
                        <InputBox
                          name="age"
                          type="number"
                          min="0"
                          value={values.age}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.age && !errors.age}
                          isInvalid={touched.age && !!errors.age}
                        />
                        {touched.age && (
                          <FormControl.Feedback type="invalid">
                            {errors.age}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md className="mb-3">
                      <FloatingLabel label="Webinar">
                        <InputBox
                          name="webinar"
                          type="text"
                          value={values.webinar}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          disabled
                        />
                      </FloatingLabel>
                    </Col>
                    <Modal.Footer>
                      {error !== null && (
                        <p className="text-danger fs-6">{error}</p>
                      )}
                      {success && (
                        <p className="text-success fs-6">
                          We have received your request.
                        </p>
                      )}
                      <Button variant="secondary" onClick={handleClose}>
                        Close
                      </Button>
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={!isValid || loading}
                      >
                        {loading ? "Submitting" : "Submit"}
                      </Button>
                    </Modal.Footer>
                  </Form>
                )}
              </Formik>
            </Modal.Body>
          </Modal>
        </Col>
        <Col md="4 ">
          <UpcomingEventCard
            className="poppins px-3 text-white"
            style={{ paddingTop: "10em" }}
          >
            <CardImg
              src={Anju}
              alt="background"
              height="auto"
              style={{ width: "105%" }}
            />
            <Card.ImgOverlay className=" p-5 ">
              <Card.Title
                className="Yanone_Kaffeesatz fw-bold"
                style={{ fontSize: "35px" }}
              >
                Upcoming Expert Webinar
              </Card.Title>
              <Card.Subtitle
                className="poppins py-4"
                style={{ fontSize: "16px" }}
              >
                How to open conversations with children &amp; teens on Puberty,
                Sex &amp; Safety
              </Card.Subtitle>
              {/* <Card.Text style={{ fontSize: "13px" }}>
                <b>
                  Saturday, August 14th 2021,<br></br> 4pm to 6pm IST
                </b>
              </Card.Text> */}
              <Card.Text style={{ fontSize: "16px" }}>
                Free registration
              </Card.Text>
              <Button
                variant="info mt-2"
                className="text-white"
                onClick={() =>
                  handleShow(
                    "How to open conversations with children & teens on Puberty, Sex & Safety"
                  )
                }
              >
                I am Interested
              </Button>
            </Card.ImgOverlay>
          </UpcomingEventCard>
        </Col>
      </Row>
    </Container>
  );
};
