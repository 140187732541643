import React from "react";
import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./index.module.css";
import "./App.scss";
import { Dashboard } from "./pages/dashboard";
import { InvoiceView } from "./pages/invoice";
import { Home } from "./pages/home";
import { Ga } from "./hoc/googleAnalytics";
import { About } from "./pages/about";
import { Policy } from "./pages/privacypolicy";
import { TermsCondition } from "./pages/terms";
import { RefundPolicy } from "./pages/refund-policy";
import { Contact } from "./pages/contact";
// import { Blog } from "./pages/blog";
import { FirstBlog } from "./pages/multi-blog";
import { Login } from "./pages/login";
import { Program } from "./pages/program";
import { Schools } from "./pages/schools";
import { SchoolDashboard } from "./pages/schooldashboard";
import { SchoolDetails } from "./pages/schooldashboard/schooldetails";
import { Resources } from "./pages/resourcespage";
import { ProtectedRoute } from "./molecules/protectedRoute";
import { AppContainer } from "./templates/navbar";
import { Logout } from "./atoms/logout";
import { LandingPage } from "./pages/program/landing";
import { Checkout } from "./pages/checkout";
import { Affiliate } from "./pages/affiliate";
import { CheckAffiliate } from "./pages/affiliate/checkAffiliate";
import { Failed, Success } from "./pages/checkout/success";
import { Terms } from "./pages/affiliate/terms";
import { OldLevel } from "./pages/program/previousLevel";
import { Completion } from "./pages/program/completion";
import { Demo } from "./pages/program/demo";
import { Gift } from "./pages/gift";
import { GiftCheckout } from "./pages/checkout/gift";
import { RedeemGift } from "./pages/gift/redeem";
import ScrollTop from "./atoms/ScrollTop";
import PubertyAdventure from "./templates/program-landing/puberty-adventure";
import { SchoolProtectedRoute } from "./molecules/schoolProtectedRoute";
import { RedeemGiftCode } from "./pages/gift/redeemCode";
import { RedeemAdminGiftCode } from "./pages/gift/redeemSpecial";
import { TeacherProtectedRoute } from "./molecules/teacherProtectedRoute";
import { TeacherDashboard } from "./templates/teachDashboard";
import Forgotpassword from "./templates/forgotpassword";
import ResetPassword from "./templates/resetPassword";
import TeacherDetails from "./templates/teachDashboard/details";
import { SecondBlog } from "./pages/multi-blog/secondblog";
import { ThirdBlog } from "./pages/multi-blog/thirdblog";
import { FourthBlog } from "./pages/multi-blog/fourthblog";
import { FifthBlog } from "./pages/multi-blog/fifthblog";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Ga />
      <ScrollTop />
      <Routes>
        <Route path="school" element={<SchoolProtectedRoute />}>
          <Route path="" element={<AppContainer />}>
            <Route path="" element={<SchoolDashboard />}></Route>
            <Route
              path="details/:program_id"
              element={<SchoolDetails />}
            ></Route>
          </Route>
        </Route>
        <Route path="dashboard" element={<ProtectedRoute />}>
          <Route path="" element={<AppContainer />}>
            <Route
              path="parent"
              element={<Dashboard userType="parent" />}
            ></Route>
            <Route
              path="invoice/:transaction_id"
              element={<InvoiceView userType="parent" />}
            ></Route>
            <Route
              path="learner"
              element={<Dashboard userType="learner" />}
            ></Route>
            <Route path="teacher" element={<TeacherDashboard />}></Route>
            <Route
              path="teacher/details/:program_id"
              element={<TeacherDetails />}
            ></Route>
          </Route>
          <Route path="program">
            <Route path=":enrollmentId" element={<Program />}></Route>
            <Route
              path=":enrollmentId/previous/:previousLevelId"
              element={<OldLevel />}
            ></Route>
            <Route
              path=":enrollmentId/success/coupon"
              element={<Completion coupon />}
            ></Route>
            <Route
              path=":enrollmentId/success/survey"
              element={<Completion survey />}
            ></Route>
          </Route>
        </Route>
        <Route path="/" element={<AppContainer />}>
          <Route path="" element={<Home />}></Route>
          <Route path="about" element={<About />}></Route>
          <Route path="programs" element={<PubertyAdventure />}></Route>
          <Route path="affiliate" element={<Affiliate />}></Route>
          <Route
            path="affiliate/checkaffiliate"
            element={<CheckAffiliate />}
          ></Route>
          <Route path="contact" element={<Contact />}></Route>
          {/* <Route
            path="understanding-consent-what-it-is-and-why-it-matters"
            element={<Blog />}
          ></Route> */}
          <Route
            path="how-to-have-the-first-period-talk-with-your-little-girl"
            element={<FirstBlog />}
          ></Route>
          <Route
            path="a-heart-to-heart-with-boys-about-period"
            element={<SecondBlog />}
          ></Route>
          <Route
            path="first-puberty-talk-with-your-daughter"
            element={<ThirdBlog />}
          ></Route>
          <Route
            path="first-puberty-talk-with-your-son"
            element={<FourthBlog />}
          ></Route>
          <Route path="a-letter-for-red-days" element={<FifthBlog />}></Route>
          <Route path="schools" element={<Schools />}></Route>
          <Route path="resources-page" element={<Resources />}></Route>
          <Route path="privacy-policy" element={<Policy />}></Route>
          <Route
            path="terms-and-conditions"
            element={<TermsCondition />}
          ></Route>
          <Route
            path="cancellation-and-refund-policy"
            element={<RefundPolicy />}
          ></Route>
          <Route path="affiliate/terms" element={<Terms />}></Route>
          <Route path="program">
            <Route path=":slug" element={<LandingPage />}></Route>
          </Route>
        </Route>
        <Route path="program/:slug/demo" element={<Demo />}></Route>
        <Route path="/" element={<AppContainer />}>
          <Route path="program/:slug/gift" element={<Gift />}></Route>
        </Route>
        <Route path="checkout">
          <Route path=":transaction_id" element={<Checkout />}></Route>
          <Route path="success" element={<Success />}></Route>
          <Route path="failure" element={<Failed />}></Route>
        </Route>
        <Route path="gift">
          <Route
            path="checkout/:transaction_id"
            element={<GiftCheckout />}
          ></Route>
          <Route element={<AppContainer />}>
            <Route path="redeem/:gift_id" element={<RedeemGift />}></Route>
            <Route
              path="redeem/code/:code"
              element={<RedeemGiftCode />}
            ></Route>
            <Route
              path="redeem/special/:code"
              element={<RedeemAdminGiftCode />}
            ></Route>
          </Route>
        </Route>
        <Route path="forgotpassword" element={<Forgotpassword />}></Route>
        <Route path="reset-password/:token" element={<ResetPassword />}></Route>
        <Route path="login" element={<Login />}></Route>
        <Route path="logout" element={<Logout />}></Route>
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
