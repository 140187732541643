import React from "react";
import ReactDOM from "react-dom";
// import Blog_Img from "../../assets/images/blog_Image.png";
import boysperiods1 from "../../assets/blogs/boys & periods/boys&periods1.png";
import boysperiods2 from "../../assets/blogs/boys & periods/boys&periods2.png";



import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// blog_Image
// import { Container } from "react-bootstrap";

// Define a functional component
export const SecondBlog = () => {


  const settings = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 200,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="poppins">
      <div
        style={{ marginLeft: "10%", marginRight: "10%" , color:"black" }}
        className="my-4 font-weight-bold poppins"
      >
        <img src={boysperiods2} alt="boysperiods2" class="img-fluid responsive-img" />

        <h6 className="text-primary poppins" style={{ fontSize: "15px" }}>
          Published 13 Jan 2022
        </h6>
        <h1
        className="title-header poppins"
        >
          A HEART TO HEART WITH BOYS ABOUT PERIODS
        </h1>
        <p className="poppins" style={{fontWeight: "bold"}}>
        Dear Boys,
        </p>
        <p className="poppins" style={{fontWeight: "bold"}}>
        I arrive every month in the life of a girl, once she attains puberty.
        </p>
        <p className="poppins" style={{fontWeight: "bold"}}>
        I come and say hello to your Mom, to your sister, to your best friend and even your pet cat.
        </p>
        <p className="poppins" style={{fontWeight: "bold"}}>
        Have you noticed them holding their tummies feeling the cramps when I am around? I am sorry, I do not mean to hurt them, but it just sometimes happens.
        </p>
        <p className="poppins" style={{fontWeight: "bold"}}>
        I am known by many names. Some refer to me as ‘that time of the month’, some address me as ‘Aunt Flo’ and some call me plain ‘chums’.
        </p>
        <p className="poppins" style={{fontWeight: "bold"}}>
        I am actually not so complicated. I am simple. I am biology. I am natural. I am periods.
        </p>
{/* 
        <img src={boysperiods1} alt="blog_img" class="img-fluid responsive-img" /> */}
        




        <p className="poppins">
        So hello, here I am today, writing this letter to you. There is information about me online and there are things your friends may talk about. I have often seen you confused and unsure about me. So I thought that it’s only right and polite that I tell you my story in my own words. Don’t you think so?
        </p>
        <p className="poppins">
        I was born when Eve – the first lady on earth, attained puberty. To begin with, I am not yucky. I am also not a cut or wound. Trust me, I am as natural as any other biological process.
        </p>
        <p className="poppins">
        My friends, the ladies, house an organ in their body called the uterus or the womb. You were also once there – inside your mummy’s uterus, till you were ready to come out.
        </p>
        <p className="poppins">
        So, every month, all ladies’ bodies get ready to have a baby.
        </p>
        <p  className="poppins">
        Ovaries – a part of their body releases a tiny little egg. The excited uterus creates a thick and soft lining of blood and tissues to protect this little guest egg which might grow into a baby. However, if the egg does not grow into a baby, the lining is no longer useful.
        </p>

        <p  className="poppins">
        So the lining then breaks down and quietly exits the body. I am that exit process.
        </p>
        <p  className="poppins">
        I come and say hello every 28 days to some, I may come early or even wait longer for the others. It depends on their bodies, after all every one of these ladies is unique. 
        </p>
        <p  className="poppins">
        You may have seen pads and tampons in television or online advertisements. It’s not used to absorb ink as it is shown, they are used to absorb the lining made of blood and tissues, when it is flushed out of the body.
        </p>
        <p  className="poppins">
        While I am visiting, my lady friends go through a lot of turmoil. Their bodies go through cramps, mood swings and even weakness.
        </p>
        <p  className="poppins">
        So, the next time you see your friend, mother or any other lady in pain, I may be the culprit. You don’t need to do much, just a hot water bottle, maybe chocolates or some words of understanding. Some kindness sprinkled with some warmth. The ladies will be very touched by your thoughtfulness.
        </p>
        <p  className="poppins">
        As I arrive in their lives when they attain puberty, I also bid goodbye when they attain menopause. Menopause? I shall ask menopause to pen down a letter to you too. I am just doing the introductions.
        </p>
        <p  className="poppins">
        So my dear boys, I am a word you must have heard in hushes and whispers. I am nothing to hide or be ashamed of. I am what I am, pure biology and plain natural. Period.
        </p>
        <p  className="poppins">
        Hope you liked getting to know me because I sure enjoyed writing to you.
        </p>
        <p  className="poppins">
        Take care of the ladies in your life when I come to say Hello next. I am nice but you can be nicer.
        </p>
        <p  className="poppins" style={{fontWeight: "bold"}}>
            <b>With love,</b>
        </p>
        <p  className="poppins" style={{fontWeight: "bold"}}>
            <b>Periods.</b>
        </p>
      </div>
    </div>
  );
};
