import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import aanya from "../../assets/images/Aanya Katharani.png";
import ajay from "../../assets/images/ajay kapoor.png";
import diya from "../../assets/images/diya.png";
import arjun from "../../assets/images/Mr. K.V. Arjun Rao.png";

export const PfgCarousel = () => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div>
      <div className="d-none d-lg-flex">
        <Carousel
          className="text-center"
          activeIndex={index}
          onSelect={handleSelect}
          interval={50000}
        >
          <Carousel.Item
            className=""
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
            <div className="col-10 mb-4 mx-auto d-lg-flex justify-content-around  align-items-center ">
              <div className="w-45 ">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "900px" }}
                >
                  <img
                    className="w-30 rounded-3 mb-5"
                    src={aanya}
                    alt={"aanya"}
                  />
                  <p
                    className="text-start secondary-b mb-5 p-17"
                    style={{ lineHeight: "1.7" }}
                  >
                    Before attending the workshop I was worried about what
                    periods are and how to handle them. The Puberty for Girls
                    workshop explained exactly how periods happen, what can we
                    do if we suddenly get them in school or a public space and
                    about lots of other changes that we go through during
                    puberty. They showed us videos, told us stories and played
                    fun games with us! I feel so ready and excited for these
                    changes now! I am very thankful to my mum for enrolling me
                    for such an amazing workshop and to the trainers who made it
                    so easy for me to accept and be ready for the new phase of
                    my life.
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">
                    Aanya Katharani
                  </h4>
                  <h6 className="text-start secondary-b">
                    {" "}
                    Age 10, Grade 5, Mumbai
                  </h6>
                </div>
              </div>

              <div className="w-45 w-sm-100">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3  p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "900px" }}
                >
                  <img
                    className="w-30 rounded-3 mb-5"
                    src={ajay}
                    alt={"ajay kapoor"}
                  />
                  <p
                    className="text-start secondary-b mb-5 p-17 "
                    style={{ lineHeight: "1.7" }}
                  >
                    My daughter had just entered puberty and somehow, despite
                    being a progressive family, we were never able to talk with
                    ease around these subjects. Things have changed dramatically
                    ever since we attended UnTaboo’s workshop. It's no more
                    taboo to discuss openly about puberty and sex, and
                    differences between boys and girls, in our house. There is
                    no more a feeling of cringe with her. My daughter is so much
                    more confident and even the boys are so much more receptive
                    of her conditions. I thank you for the beautiful workshop
                    and have deep gratitude for your contributions in bringing
                    ease in our family. God bless!
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">Ajay Kapoor</h4>
                  <h6 className="text-start secondary-b"> Parent, Nagpur</h6>
                </div>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item
            className=" "
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
            <div className="col-10 mb-4 mx-auto d-lg-flex justify-content-around align-items-center">
              <div className="w-45">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "900px" }}
                >
                  <img
                    className="w-30 rounded-3 mb-5"
                    src={diya}
                    alt={"Diya Ramachandran"}
                  />
                  <p
                    className="text-start secondary-b mb-5 p-17"
                    style={{ lineHeight: "1.7" }}
                  >
                    I signed up my daughter for UnTaboo’s Puberty workshop when
                    she was around 11, because I wanted her to know about the
                    changes that were going to happen to her and everyone around
                    her. The UnTaboo workshop was really helpful in breaking the
                    ice and starting conversations about various topics like
                    periods, breast size, crushes, mood swings etc. My daughter
                    enjoyed the workshop, and was very comfortable doing it,
                    because of the way it was conducted. I see it as a great
                    stepping stone for a pre-teen. Matter of fact, light hearted
                    and sensitive, it hit the sweet spot for us!
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">
                    Diya Ramachandran
                  </h4>
                  <h6 className="text-start secondary-b">Parent, Mumbai</h6>
                </div>
              </div>
              <div className="w-45">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "900px" }}
                >
                  <img
                    className="w-30 rounded-3 mb-5"
                    src={arjun}
                    alt={"Mr. K.V. Arjun Rao"}
                  />
                  <p
                    className="text-start secondary-b mb-5 p-17"
                    style={{ lineHeight: "1.7" }}
                  >
                    Tricky topics can often fumble the best of us! The workshops
                    for our learners and parents were highly engaging and
                    informative. We are certain that they will go a long way to
                    opening doors, having honest conversations and building
                    bridges. The sessions held for the youngsters were child
                    friendly and engaging. They have helped the children express
                    their thoughts and ask questions freely as they felt they
                    were in a safe space. We thank the team at UnTaboo for
                    showing us a way to navigate through this journey and we
                    look forward to more interactive sessions and workshops in
                    the future as well.
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">
                    Mr. K.V. Arjun Rao
                  </h4>
                  <h6 className="text-start secondary-b">
                    Principal, JBCN International School, Oshiwara, Mumbai
                  </h6>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="d-flex d-lg-none">
        <Carousel
          className="text-center"
          activeIndex={index}
          onSelect={handleSelect}
          interval={50000}
        >
        <Carousel.Item
            className="px-4"
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
           
              <div className="w-45 ">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "900px" }}
                >
                  <img
                    className="w-sm-45 rounded-3 mb-5"
                    src={aanya}
                    alt={"aanya"}
                  />
                  <p
                    className="text-start secondary-b mb-5 p-17"
                    style={{ lineHeight: "1.7" }}
                  >
                    Before attending the workshop I was worried about what
                    periods are and how to handle them. The Puberty for Girls
                    workshop explained exactly how periods happen, what can we
                    do if we suddenly get them in school or a public space and
                    about lots of other changes that we go through during
                    puberty. They showed us videos, told us stories and played
                    fun games with us! I feel so ready and excited for these
                    changes now! I am very thankful to my mum for enrolling me
                    for such an amazing workshop and to the trainers who made it
                    so easy for me to accept and be ready for the new phase of
                    my life.
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">
                    Aanya Katharani
                  </h4>
                  <h6 className="text-start secondary-b">
                    {" "}
                    Age 10, Grade 5, Mumbai
                  </h6>
                </div>
              </div>
              </Carousel.Item>
              <Carousel.Item
            className="px-4"
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
              <div className="w-45 w-sm-100">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3  p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "900px" }}
                >
                  <img
                    className="w-sm-45 rounded-3 mb-5"
                    src={ajay}
                    alt={"ajay kapoor"}
                  />
                  <p
                    className="text-start secondary-b mb-5 p-17 "
                    style={{ lineHeight: "1.7" }}
                  >
                    My daughter had just entered puberty and somehow, despite
                    being a progressive family, we were never able to talk with
                    ease around these subjects. Things have changed dramatically
                    ever since we attended UnTaboo’s workshop. It's no more
                    taboo to discuss openly about puberty and sex, and
                    differences between boys and girls, in our house. There is
                    no more a feeling of cringe with her. My daughter is so much
                    more confident and even the boys are so much more receptive
                    of her conditions. I thank you for the beautiful workshop
                    and have deep gratitude for your contributions in bringing
                    ease in our family. God bless!
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">Ajay Kapoor</h4>
                  <h6 className="text-start secondary-b"> Parent, Nagpur</h6>
                </div>
              </div>
   
              </Carousel.Item>
              <Carousel.Item
            className="px-4"
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
           
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "900px" }}
                >
                  <img
                    className="w-sm-45 rounded-3 mb-5"
                    src={diya}
                    alt={"Diya Ramachandran"}
                  />
                  <p
                    className="text-start secondary-b mb-5 p-17"
                    style={{ lineHeight: "1.7" }}
                  >
                    I signed up my daughter for UnTaboo’s Puberty workshop when
                    she was around 11, because I wanted her to know about the
                    changes that were going to happen to her and everyone around
                    her. The UnTaboo workshop was really helpful in breaking the
                    ice and starting conversations about various topics like
                    periods, breast size, crushes, mood swings etc. My daughter
                    enjoyed the workshop, and was very comfortable doing it,
                    because of the way it was conducted. I see it as a great
                    stepping stone for a pre-teen. Matter of fact, light hearted
                    and sensitive, it hit the sweet spot for us!
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">
                    Diya Ramachandran
                  </h4>
                  <h6 className="text-start secondary-b">Parent, Mumbai</h6>
                </div>
              
              </Carousel.Item>
              <Carousel.Item
            className="px-4"
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
              <div className="w-45">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "900px" }}
                >
                  <img
                    className="w-sm-45 rounded-3 mb-5"
                    src={arjun}
                    alt={"Mr. K.V. Arjun Rao"}
                  />
                  <p
                    className="text-start secondary-b mb-5 p-17"
                    style={{ lineHeight: "1.7" }}
                  >
                    Tricky topics can often fumble the best of us! The workshops
                    for our learners and parents were highly engaging and
                    informative. We are certain that they will go a long way to
                    opening doors, having honest conversations and building
                    bridges. The sessions held for the youngsters were child
                    friendly and engaging. They have helped the children express
                    their thoughts and ask questions freely as they felt they
                    were in a safe space. We thank the team at UnTaboo for
                    showing us a way to navigate through this journey and we
                    look forward to more interactive sessions and workshops in
                    the future as well.
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">
                    Mr. K.V. Arjun Rao
                  </h4>
                  <h6 className="text-start secondary-b">
                    Principal, JBCN International School, Oshiwara, Mumbai
                  </h6>
                </div>
              </div>

          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};
