import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Cookies from "js-cookie";
import Hls from "hls.js";
const SVideo = styled.video``;

export const UtVideo = ({ src, subtitle, ...props }) => {
  const [token, setToken] = useState("");
  let ut_user = {};

  useEffect(() => {
    if (!!Cookies.get("ut_user")) {
      ut_user = JSON.parse(Cookies.get("ut_user"));
      console.log({ ut_user });
      setToken(ut_user["access_token"]);
    }
  }, []);

  const videoRef = useRef(null);
  useEffect(() => {}, [src]);

  useEffect(() => {
    const video = videoRef.current;

    if (src.endsWith(".m3u8")) {
      if (Hls.isSupported()) {
        const hls = new Hls({
          xhrSetup: (xhr, url) => {
            const modifiedUrl = `${url}?token=${token}`;
            xhr.open("GET", modifiedUrl, true);
          },
        });
        hls.loadSource(src);
        hls.attachMedia(video);
      }
    } else {
      video.src = src;
    }

    if (props.autoPlay) {
      video.play();
    }
  }, [src, token]);

  let mySrc = src,
    mySub = subtitle;

  try {
    let _ = new URL(src);
  } catch {
    mySrc = `${process.env.REACT_APP_BACKEND_URL}${src}`;
  }
  try {
    let _ = new URL(subtitle);
  } catch {
    mySub = `${process.env.REACT_APP_BACKEND_URL}${subtitle}`;
  }

  return (
    <SVideo
      ref={videoRef}
      {...props}
      src={src + `?token=${token}`}
      key={mySrc}
      autoPlay={false}
      crossOrigin="anonymous"
    >
      {subtitle && (
        <track
          label="English"
          kind="subtitles"
          srcLang="en"
          src={mySub}
          default
        />
      )}
      Sorry, your browser doesn't support embedded videos.
    </SVideo>
  );
};

UtVideo.propTypes = {};
