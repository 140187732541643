import React from "react";
import { Accordion } from "react-bootstrap";

export const Faq = () => {
  return (
    <Accordion className="mb-3 rounded-3 px-4 px-lg-0" style={{fontSize:"17px"}}>
      <Accordion.Item eventKey="0" className="accordion-item mt-4">
        <Accordion.Header style={{ color: "#1e4363", fontSize:"20px!important" }}>
          What is Safety &amp; Sex Education?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Sex Education is an umbrella term which nestles under it a wide range
          of topics relating to the body, behaviours, relationships and more. It
          is age-appropriate education and can range from a talk on good
          touch-bad touch to one on sexuality. Everything in-between like
          puberty, changing emotions, reproduction, relationships, dating,
          consent, peer pressure, etc. comes under this education.
          <br></br>
          Our Safety programs address issues like safe personal and online
          behaviours, resistance to smoking, alcohol, and drugs - helping steer
          teenagers towards responsible decision making &amp; healthy relationships.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Why is Safety &amp; Sex Education necessary?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Research worldwide has shown that age-appropriate Safety &amp; Sex
          Education helps children be positively aware, be better prepared for
          bodily and emotional changes, resist peer pressure, delay
          experimentation, make better decisions and keep themselves safe. It
          also strengthens their interpersonal relationships and creates
          stronger bonds with parents. It provides them with the skills to grow
          up healthy and safe with the right attitudes.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header style={{ color: "#1e4363" }}>
          How will your Safety &amp; Sex Ed programs help my child?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Adolescence is considered one of the most stressful phases in a
          person's life and it can begin as early as 9 years. Our impactful
          Online programs for children and teens, make the growing up years
          easier and less confusing.
          <br></br>
          Our programs cover the physical, emotional, social, behavioural &amp;
          sexual aspects of growing up, equipping your child with the essential
          life skills needed to navigate the tricky phase of growing up and
          transitioning smoothly &amp; safely from childhood to adulthood.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header style={{ color: "#1e4363" }}>
          What is the right age to begin Safety &amp; Sex Education?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Safety &amp; Sex Education is a life skill and is required at every age.
          It can begin as early as 3 years and continues well into adulthood.
          <br></br>
          Our programs equip young people with the tools &amp; techniques to
          understand body safety, puberty, sex, sexuality and relationships in a
          positive manner, helping them develop responsible attitudes and
          empowering them to keep themselves safe - both in-person and online.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header style={{ color: "#1e4363" }}>
          How do I know my child is ready for Safety &amp; Sex Ed programs?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Whether you think your child is ready or not, the talk on Safety &amp; Sex
          Ed is recommended before the child hits puberty, which can be anytime
          between the ages of 9 to 14. Puberty actually starts internally 1 or 2
          years before you start seeing the changes externally and it is
          important to start conversations on it early. These awareness programs
          help the children cope with the confusion they are experiencing and
          accept their bodily and emotional changes with a positive attitude.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Will my child become more curious after the program
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Quite the opposite in fact, as our awareness program would have
          satisfied your child’s curiosity in a positive way. Some questions may
          come your way while your child is processing all the new information
          and that is a good sign! The ice-breaking parent-child activity in our
          program and the downloadable toolkit will help you tackle their
          queries.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header style={{ color: "#1e4363" }}>
          The schools have sessions on this, does my child still need it?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Safety &amp; Sex Education is not a one-time talk but an ongoing
          conversation - from childhood to adulthood and sometimes even beyond.
          <br></br>
          Most schools usually do just one or two sessions and do not have the
          bandwidth (or expertise) to cover the range of topics that we offer
          through our various programs. The other unique aspect of our programs
          is the non-academic, non-preaching, fun &amp; conversational tone that we
          use to get the message across to youngsters - a message which is
          better understood, retained and practised.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Is doing an online program as impactful as an offline in-person
          program?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Our online programs have been designed using the best learnings from
          our highly successful offline workshops, which have been perfected by
          engaging with lakhs of children, parents, and educators across India.
          Our unique pedagogy enables children to take these online programs
          independently and retain the learnings with ease. The added advantage
          of doing these programs online is that it can be vetted and tracked by
          you while your child learns in the privacy and safety of their homes,
          at a time convenient to them.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="9">
        <Accordion.Header style={{ color: "#1e4363" }}>
          My child has a low attention span – will they be able to do this
          Online program?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Our awareness programs are designed keeping in mind the low attention
          span of children. We combine education with entertainment using
          videos, games, and anecdotes. We also ensure that various Learning
          Styles are catered to in the program design, to keep the youngsters
          engaged and make things relatable, retainable, and enjoyable.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="10">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Are these programs available in other countries?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Our Online programs are developed by experts and universally relevant,
          as the changes and concerns of growing up are more or less the same
          for children and teenagers across the globe. They can be purchased and
          accessed from any corner of the world.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="11">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Is there a free trial period before I purchase the program?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          You can sample our Online programs by seeing the free demo! You will
          be able to experience our unique teaching pedagogy using the WLDPB
          method - <b>W</b>atch learning videos, <b>L</b>earn via text, <b>D</b>iscuss using FAQs,
           <b> P</b>lay to check child's learning and <b>B</b>ond with a parent-child activity.
          This is the format we follow for the entire program, ensuring that it
          is a truly child-friendly experience.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
