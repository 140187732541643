import React from "react";
import { useState } from "react";
import Container from "react-bootstrap/Container";
import ToastContainer from "react-bootstrap/ToastContainer";
import Toast from "react-bootstrap/Toast";
import ToastBody from "react-bootstrap/ToastBody";
import { app_api } from "../../atoms/api";
import Button from "../../atoms/button";
import AffiliateFaq from "../../organisms/faq/affiliate";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import { affiliateSchema } from "../../atoms/schema";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormCheck from "react-bootstrap/FormCheck";
import { Links } from "../../templates/sign-in";
import styled from "styled-components";
import { InputBox, SelectBox } from "../../pages/login";
import Alert from "react-bootstrap/Alert";

const TermsAndConditions = styled(Links)`
  color: inherit;
`;
export const Affiliate = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [show, setShow] = useState(false);

  const dismissSuccess = () => {
    setSuccess(false);
  };

  const handleShow = () => {
    setShow(true);
  };
  return (
    <div className="poppins bg-img-about position-relative">
      <Container>
        <div className="pt-10 px-4  px-lg-0">
          {/* <div
            className=" mt-3 mb-lg-10 pb-5 f-55 f-sm-38"
            style={{
              fontWeight: "700",
              color: "#1e4363",
              lineHeight: "1.3",
            }}
          >
            {" "}
            Come be an UnTaboo Champion<br></br>
            <span className="f-45"> Help drive Change and be Valued</span>
          </div> */}
          <div className="col-lg-6">
            <h3
              className="secondary-blue my-3 f-35 f-35"
              style={{ fontWeight: "600" }}
            >
              Looking for an exciting opportunity to be a Change- Maker?
            </h3>
            <p className="fs-5 mb-5 pb-3">
              Well, you are in just the right place!<br></br>
            </p>
          </div>

          <h3
            style={{ fontWeight: "600" }}
            className="secondary-blue my-3 f-35"
          >
            Partner with us on that
          </h3>
          <p className="fs-5 mb-1 pb-2 ">
            Talk about our Programs & share them with your network<br></br> Get
            discounts for your network <br></br>Drive change in society<br></br>{" "}
            Earn yourself the badge of being an UnTaboo Champion<br></br>{" "}
            <span className="secondary-blue fw-bold">And best of all…</span>
          </p>

          <b className="fs-5">YOU EARN MONEY WHILE DOING ALL OF THIS!</b>

          <ul className="affiliate_list">
            <li>Risk free - No investment</li>
            <li>Unlimited income</li>
            <li>Work from the comfort of your home & Phone</li>
            <li>No targets or time limits to achieve</li>
            <li>Entire world is your market</li>
          </ul>

          <h3
            style={{ fontWeight: "600" }}
            className="secondary-blue my-3 f-35"
          >
            Here’s how it works:
          </h3>
          <ul className="fs-5 mb-5 pb-3">
            <li>
              Fill the form below to sign on as UnTaboo Champion (Affiliate)
            </li>
            <li>
              Receive a mail with affiliate code, handbook with details &
              creatives
            </li>
            <li>
              Talk about our work & share our program creatives in your network
            </li>
            <li>Get a 10% discount offer for all people in your network</li>
            <li>
              Get rewarded when anyone in your network purchases a program
            </li>
            <li>Commission as per grid below</li>
            <li>Payouts: Monthly bank transfer (1st week of every month)</li>
          </ul>
          <h3
            style={{ fontWeight: "600" }}
            className="secondary-blue my-2 f-35"
          >
            Commercials Grid for B2C (Individuals)*
          </h3>
          <ul>
            <li>Program pricing: Ranges from INR 1499/- to 2499/-</li>
          </ul>
        </div>
          <div className="col-lg-10 fs-5 lh-md pt-lg-5 pt-3 px-4 px-lg-0">
        <table class="table table-bordered text-center">
          <thead>
            <tr>
              <th scope="col" className="text-center">
                Programs Sold
              </th>
              <th scope="col">
                Commission (%)<br></br>
                Per Sale
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1-50</td>
              <td>12%</td>
            </tr>
            <tr>
              <td>51-150</td>
              <td>15%</td>
            </tr>
            <tr>
              <td>151 – 250</td>
              <td colspan="2">20%</td>
            </tr>
            <tr>
              <td>251 – 350</td>
              <td colspan="2">25%</td>
            </tr>
            <tr>
              <td>351 and above</td>
              <td colspan="2">30%</td>
            </tr>
          </tbody>
        </table>
        </div>

        <p className="fs-6 col-lg-10 lh-md pt-lg-5 pt-3 px-4 px-lg-0">
          *Commission as per Program Price on untaboo.online platform ( paid on
          final discount amount){" "}
        </p>
        <p className="text-center fs-4 mt-5">
          Do feel free to reach out to us on{" "}
          <a href="mailto:team.untaboo@gmail.com">team.untaboo@gmail.com</a>{" "}
          <br></br>
          for any queries, concerns or bulk deals for schools, corporates etc
        </p>
        <h2 className="secondary-blue fs-1 mb-5 text-center fw-bold">
          We want YOU on our team!
        </h2>
        <div className="my-4  ">
          <div className="d-flex justify-content-center gap-4 px-4 flex-wrap flex-sm-nowrap">
            <a href="#affiliateForm">
              <Button> Click here to be an Affiliate (UnTaboo Champion)</Button>
            </a>
            <Button className="ms-lg-4" onClick={handleShow}>
              {" "}
              I want to refer a friend for this
            </Button>
          </div>
          <div>
            {show && (
              <>
                <p className="fs-5 mt-5 mb-2 pb-1">
                  If you want your friends or family to join in this fabulous
                  journey and be UnTaboo champions too, copy and share this
                  message link with them.
                </p>
                <ul>
                  <li className="fs-5">
                    Hey - UnTaboo, a Safety & Sex education company has some
                    amazing programs for children & teens. They are offering an
                    opportunity to be an UnTaboo Champion and earn money while
                    making a positive change in society. I thought you might be
                    interested. Check them out at
                    <a href="https://untaboo.online/affiliate">
                      {" "}
                      https://untaboo.online/affiliate
                    </a>
                  </li>
                </ul>
              </>
            )}
          </div>
        </div>

        <div
          className="d-flex flex-column my-5 px-4 px-lg-0"
          id="affiliateForm"
        >
          <h3
            style={{ fontWeight: "600" }}
            className="secondary-blue my-2 f-35 mt-5 f-35"
          >
            Want to know more or want to join us as an affiliate?
          </h3>
          <p className="fs-5 mb-3 pb-3">
            Fill up this short form and be on your way to be an UnTaboo Champion
            <span className=""></span>(only for 18+)
          </p>

          <Formik
            validationSchema={affiliateSchema}
            onSubmit={(values) => {
              setLoading(true);
              app_api
                .post("affiliate", values)
                .then((res) => {
                  setLoading(false);
                  setSuccess(true);
                })
                .catch((err) => {
                  setLoading(false);
                  setError(err?.response?.data?.message || "Error");
                });
            }}
            initialValues={{
              firstName: "",
              lastName: "",
              dob: "",
              state: "Maharashtra",
              email: "",
              contact_no: "",
              city: "",
              knowDetails: false,
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              touched,
              isValid,
              errors,
            }) => (
              <Form
                className="col-12 mx-auto row g-3"
                onSubmit={handleSubmit}
                noValidate={true}
              >
                <div className="col-md-4">
                  <FloatingLabel
                    controlId="register.firstName"
                    label="First name"
                  >
                    <InputBox
                      placeholder="First name"
                      name="firstName"
                      value={values.firstname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      type="text"
                      isValid={touched.firstName && !errors.firstName}
                      isInvalid={touched.firstName && !!errors.firstName}
                    />
                    {touched.firstName && (
                      <FormControl.Feedback type="invalid">
                        {errors.firstName}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                  <div className="valid-feedback">Looks good!</div>
                </div>

                <div className="col-md-4">
                  <FloatingLabel
                    controlId="register.lastName"
                    label="Last name"
                  >
                    <InputBox
                      placeholder="Last name"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      type="text"
                      isValid={touched.lastName && !errors.lastName}
                      isInvalid={touched.lastName && !!errors.lastName}
                    />
                    {touched.lastName && (
                      <FormControl.Feedback type="invalid">
                        {errors.lastName}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </div>

                <div className="col-md-4">
                  <FloatingLabel controlId="register.dob" label="Date of birth">
                    <InputBox
                      type="date"
                      placeholder="D.O.B."
                      name="dob"
                      value={values.dob}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      isValid={touched.dob && !errors.dob}
                      isInvalid={touched.dob && !!errors.dob}
                    />
                    {touched.dob && (
                      <FormControl.Feedback type="invalid">
                        {errors.dob}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </div>

                <div className="col-md-4">
                  <FloatingLabel controlId="register.email" label="Email">
                    <InputBox
                      type="email"
                      placeholder="Email"
                      autoComplete="email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      isValid={touched.email && !errors.email}
                      isInvalid={touched.email && !!errors.email}
                    />
                    {touched.email && (
                      <FormControl.Feedback type="invalid">
                        {errors.email}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </div>

                <div className="col-md-4">
                  <FloatingLabel
                    controlId="register.contact_no"
                    label="Contact no."
                  >
                    <InputBox
                      placeholder="Contact"
                      name="contact_no"
                      value={values.contact_no}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      type="text"
                      isValid={touched.contact_no && !errors.contact_no}
                      isInvalid={touched.contact_no && !!errors.contact_no}
                    />
                    {touched.contact_no && (
                      <FormControl.Feedback type="invalid">
                        {errors.contact_no}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </div>

                <div className="col-md-4">
                  <FloatingLabel controlId="register.state" label="Location">
                    <SelectBox
                      type="text"
                      name="state"
                      placeholder="Location"
                      value={values.state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.state && !errors.state}
                      isInvalid={touched.state && !!errors.state}
                    >
                      <option value="Outside India">Outside India</option>
                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                      <option value="Andaman and Nicobar Islands">
                        Andaman and Nicobar Islands
                      </option>
                      <option value="Arunachal Pradesh">
                        Arunachal Pradesh
                      </option>
                      <option value="Assam">Assam</option>
                      <option value="Bihar">Bihar</option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Dadar and Nagar Haveli">
                        Dadar and Nagar Haveli
                      </option>
                      <option value="Daman and Diu">Daman and Diu</option>
                      <option value="Delhi">Delhi</option>
                      <option value="Lakshadweep">Lakshadweep</option>
                      <option value="Puducherry">Puducherry</option>
                      <option value="Goa">Goa</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Haryana">Haryana</option>
                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                      <option value="Jammu and Kashmir">
                        Jammu and Kashmir
                      </option>
                      <option value="Jharkhand">Jharkhand</option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Kerala">Kerala</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Manipur">Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>
                      <option value="Mizoram">Mizoram</option>
                      <option value="Nagaland">Nagaland</option>
                      <option value="Odisha">Odisha</option>
                      <option value="Punjab">Punjab</option>
                      <option value="Rajasthan">Rajasthan</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="Telangana">Telangana</option>
                      <option value="Tripura">Tripura</option>
                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                      <option value="Uttarakhand">Uttarakhand</option>
                      <option value="West Bengal">West Bengal</option>
                    </SelectBox>
                    {touched.state && (
                      <FormControl.Feedback type="invalid">
                        {errors.state}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </div>

                <div className="col-md-4">
                  <FloatingLabel controlId="register.city" label="City">
                    <InputBox
                      type="text"
                      placeholder="City"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.city && !errors.city}
                      isInvalid={touched.city && !!errors.city}
                      required={true}
                    />
                    {touched.city && (
                      <FormControl.Feedback type="invalid">
                        {errors.city}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </div>

                <div className="col-12 ">
                  <div className="col-lg-7 my-3">
                    <FormCheck type="checkbox">
                      <FormCheck.Input
                        type="checkbox"
                        name="knowDetails"
                        value={true}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={touched.knowDetails && !errors.knowDetails}
                        isInvalid={touched.knowDetails && !!errors.knowDetails}
                        required={true}
                      ></FormCheck.Input>
                      <FormCheck.Label>
                        &nbsp;
                        <TermsAndConditions
                          href="https://drive.google.com/file/d/12TtN3W91a_3xRON-zi66oUmu1rnfGFi3/view?usp=sharing"
                          target="_blank"
                        >
                          Terms and Conditions
                        </TermsAndConditions>
                      </FormCheck.Label>

                      {touched.knowDetails && (
                        <FormControl.Feedback type="invalid">
                          {errors.knowDetails}
                        </FormControl.Feedback>
                      )}
                    </FormCheck>
                  </div>
                  <div className="col-12">
                    <button
                      className="btn btn-primary text-white"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? "Submitting" : "Submit"}
                    </button>
                    {error && <span className="ms-2 text-danger">{error}</span>}
                    <Alert
                      show={success}
                      variant="success"
                      className="mt-2"
                      dismissible
                      onClose={dismissSuccess}
                    >
                      <p>
                        Yayy! You are on your way to be an UnTaboo Champion.
                        Look out for a Welcome email and Handbook in your mail.
                        <br></br> PS: sometimes the emails get naughty & hide in
                        the Junk/Spam folder! Do keep a look out for an email
                        from anjukish.untaboo@gmail.com and mark it as safe!
                      </p>
                    </Alert>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
      <div
        className=" pb-10 text-center"
        style={{ backgroundColor: "#F69494 " }}
      >
        <Container>
          <h2
            className="pt-10 px-2 text-white f-40"
            style={{ fontWeight: "600" }}
          >
            FREQUENTLY ASKED QUESTIONS
          </h2>
          <p className="mb-5 fs-4 text-white">Got doubts? We got it covered!</p>
          <div className="col-12 mx-auto d-lg-flex justify-content-around">
            <div className="">
              <AffiliateFaq></AffiliateFaq>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
