import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { InfoCard } from "../../molecules/infoCard";
import {
  faChartLine,
  faFileDownload,
  faFileImage,
  faLaptop,
} from "@fortawesome/free-solid-svg-icons";
import { Webinar } from "../../organisms/webinar";
import { Resources } from "../../organisms/resources";
import { Link } from "react-router-dom";
import Cookies from "js-cookie";
import { Navigate, useNavigate } from "react-router-dom";
import { app_api } from "../../atoms/api";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import boy_img from "../../assets/images/boy.png";
import girl_img from "../../assets/images/girl.png";
import boy_girl_img from "../../assets/images/boy_and_girl.png";
import CardBootstrap from "react-bootstrap/Card";
import { StartLearningButton } from "../../pages/schooldashboard";
import { PptViewer } from "../../organisms/ppt";
import { Notes } from "../../organisms/Notes";

const InfoSpan = styled.span`
  font-size: 12px;
  color: gray;
`;

export const TeacherDashboard = (props) => {
  const [info, setInfo] = useState("program");
  const [teacher, setTeacher] = useState({});

  const [loading, setLoading] = useState(false);

  const [programLoading, setProgramLoading] = useState(true);
  const [programError, setProgramError] = useState(null);
  const [programs, setPrograms] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    try {
      let teacher = JSON.parse(Cookies.get("ut_user"));
      setTeacher(teacher);
      setLoading(true);
      app_api
        .get(`school/subscription/${teacher.schoolId}`)
        .then((res) => {
          setProgramLoading(false);
          setPrograms(res.data);
        })
        .catch((err) => {
          setProgramLoading(false);
          setProgramError(
            err?.response?.data?.message || "Error getting enrollments"
          );
        });
    } catch {
      // do nothing
    }
  }, [teacher.id]);

  return (
    <Container className="poppins">
      <div className="d-none d-lg-flex justify-content-md-center flex-wrap  flex-md-nowrap poppins ">
        <>
          <InfoCard
            icon={faLaptop}
            info="program purchased"
            selected={info === "program"}
            onClick={() => setInfo("program")}
          />
          <InfoCard
            icon={faFileDownload}
            info="Discussion Notes"
            selected={info === "notes"}
            onClick={() => setInfo("notes")}
          />
          <InfoCard
            icon={faFileImage}
            info="Discussion PPT"
            selected={info === "PPT"}
            onClick={() => setInfo("PPT")}
          />
        </>
      </div>
      <div className="d-flex d-md-flex d-lg-none justify-content-md-center flex-row  flex-md-nowrap poppins overflow-auto ">
        <InfoCard
          icon={faLaptop}
          info="program purchased"
          selected={info === "program"}
          onClick={() => setInfo("program")}
        />
        <InfoCard
          icon={faFileDownload}
          info="free resources"
          selected={info === "resources"}
          onClick={() => setInfo("resources")}
        />
      </div>
      {info === "program" &&
        (programs.length > 0 ? (
          <div className="my-5 d-flex flex-wrap">
            {programs.map((p, index) => (
              <Col sm="8" md="4" key={index}>
                <CardBootstrap
                  className="pt-lg-4 pt-5 pb-4 px-4 d-flex flex-column justify-content-center mx-lg-3 my-lg-0 my-3 h-100 dashboard_bg_img"
                  style={{ borderRadius: "10px" }}
                >
                  <img
                    className="w-30 position-absolute"
                    style={{ top: "30%", right: "30px", zIndex: "1" }}
                    src={
                      p?.program?.directed_at === "girls"
                        ? girl_img
                        : p?.program?.directed_at === "boys"
                        ? boy_img
                        : boy_girl_img
                    }
                  />
                  <h2 className="info mb-1" style={{ color: "#1E4363" }}>
                    {p?.program?.name || "UnTaboo Program"}
                  </h2>
                  <div
                    className="my-5 d-flex flex-column align-items-center justify-content-center"
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                      border: "5px solid #1E4363",
                      borderColor: "#1E4363",
                    }}
                  >
                    <h3 className="text-white my-1">{p?.learners || 0}</h3>
                    <p
                      className="lh-1 text-center"
                      style={{ fontWeight: "600" }}
                    >
                      Students<br></br>
                      <span style={{ fontSize: "12px", fontWeight: "600" }}>
                        Enrolled
                      </span>
                    </p>
                  </div>

                  <StartLearningButton
                    variant="primary"
                    className="align-self-start fw-bold"
                    style={{ zIndex: "2" }}
                    as={Link}
                    to={`details/${p?.program?.id}`}
                  >
                    View Student Details →
                  </StartLearningButton>
                  <StartLearningButton
                    variant="primary"
                    className="align-self-start fw-bold"
                    style={{ zIndex: "2", marginTop: "10px" }}
                    as={Link}
                    to={`/dashboard/program/all/previous/${p?.first_level}`}
                  >
                    View program
                  </StartLearningButton>
                </CardBootstrap>
              </Col>
            ))}
          </div>
        ) : (
          <span class="text-muted">
            No programs enrolled yet. Please contact the administrator.
          </span>
        ))}
      {info === "resources" && <Resources />}
      {info === "PPT" && <PptViewer />}
      {info === "notes" && <Notes />}
    </Container>
  );
};

TeacherDashboard.propTypes = {};
