import { useFormik } from "formik";
import React, { useState } from "react";
import { app_api } from "../../atoms/api";
import {
  InputBox,
  PrimaryButton,
  SecondaryButton,
  SelectBox,
  SubmitButtonFormGroup,
} from "../../pages/login";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FloatingLabel } from "react-bootstrap";

const Forgotpassword = () => {
  const [done, setDone] = useState(false);
  const [error, setError] = useState(null);

  const [searchParam, setSearchParam] = useSearchParams();
  const navigate = useNavigate();

  const { handleBlur, handleChange, handleSubmit, values } = useFormik({
    initialValues: {
      userEmail: "",
      userName: "",
      userType: searchParam.get("type") || "parent",
    },
    onSubmit: (values) => {
      app_api
        .post("forgot-password", values)
        .then((res) => res.data)
        .then((res) => {
          setDone(true);
          setError(null);
        })
        .catch((err) => {
          setError(err?.response?.data?.message || "Error making request");
          setDone(false);
        });
    },
  });

  const goBack = () =>
    navigate(`/login?auth=${searchParam.get("type") || "signin"}`);

  return (
    <div className="mx-auto col-10 col-sm-6 col-md-4 text-center mt-10">
      {done ? (
        <span>
          A mail has been sent to your{" "}
          {values.userType === "learner"
            ? "parent's"
            : values.userType === "school"
            ? "contact's"
            : "registered"}{" "}
          email.
        </span>
      ) : (
        <>
          <h1 className="secondary-blue mb-5">Forgot password?</h1>
          <form onSubmit={handleSubmit} noValidate>
            <h2 className="text-start fs-5">
              Enter{" "}
              {values.userType === "learner"
                ? "parent's"
                : values.userType === "school"
                ? "contact's"
                : "registered"}{" "}
              email address :
            </h2>

            <FloatingLabel controlId="register.email" label="Email Address">
              {/* {console.log(errors)} */}
              <InputBox
                type="email"
                name="userEmail"
                placeholder="example@gmail.com"
                className="form-control mt-2 mb-4"
                value={values.userEmail}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FloatingLabel>
            {values.userType === "learner" ? (
              <div>
                <h2 className="text-start fs-5">Enter your username:</h2>

                <FloatingLabel controlId="register.username" label="Username">
                  {/* {console.log(errors)} */}
                  <InputBox
                    type="text"
                    name="userName"
                    placeholder="egUser"
                    className="form-control mt-2 mb-5"
                    value={values.userName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FloatingLabel>
              </div>
            ) : null}

            <h2 className="text-start fs-6">
              Kind of use with our application?
            </h2>
            <FloatingLabel controlId="register.location" label="Select a Role">
              <SelectBox
                name="userType"
                placeholder="Kind of use with our application?"
                className="form-select mt-4 mb-5"
                value={values.userType}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                <option value="parent">Parent</option>
                <option value="learner">Learner</option>
                <option value="school">School</option>
                <option value="teacher">Teacher</option>
              </SelectBox>
            </FloatingLabel>
            <SubmitButtonFormGroup className="d-flex flex-column align-items-lg-start justify-content-sm-center flex-sm-row text-center text-lg-start">
              <PrimaryButton type="submit">Submit</PrimaryButton>
              <SecondaryButton type="button" onClick={goBack}>
                Go back
              </SecondaryButton>
            </SubmitButtonFormGroup>
            {error !== null && (
              <span className="d-block text-danger fs-6">{error}</span>
            )}
          </form>
        </>
      )}
    </div>
  );
};

export default Forgotpassword;
