import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Cookie from "js-cookie";
import pt from "prop-types";
import { signInUser } from "../../atoms/schema";
import {
  ChangeScreenMessage,
  ContainerDiv,
  SubmitButtonFormGroup,
  InputBox,
  PrimaryButton,
  SecondaryButton,
} from "../../pages/login";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormControl from "react-bootstrap/FormControl";
import Alert from "react-bootstrap/Alert";
import { app_api } from "../../atoms/api";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { Formik } from "formik";
import {
  useGAEventLearnerLogin,
  useGAEventSignUp,
  useGAEventSchoolLogin,
  useGAEventRegister,
  useGAEventTeacherLogin,
} from "../../hoc/hooks/useGAEventTracker";
export const Links = styled.a`
  color: #0f3d67;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;

  :hover {
    color: #6bcacf;
    text-decoration: none;
  }
`;

export const SignIn = (props) => {
  const GAEventRegister = useGAEventRegister();
  const GAEventLearnerLogin = useGAEventLearnerLogin();
  const GAEventSchoolLogin = useGAEventSchoolLogin();
  const GAEventTeacherLogin = useGAEventTeacherLogin();

  let navigate = useNavigate();
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const forgotPasswordLink = "#";

  const LearnerLogin = () => {
    props.switchSection("learner");
    GAEventLearnerLogin();
  };
  const SchoolLogin = () => {
    props.switchSection("school");
    GAEventSchoolLogin();
  };
  const TeacherLogin = () => {
    props.switchSection("teacher");
    GAEventTeacherLogin();
  };
  const CreateAccount = () => {
    props.switchSection("register");
    if (props.show) {
      GAEventRegister();
    }
  };

  return (
    <ContainerDiv
      className="flex-column justify-content-start position-absolute col-12 col-lg-11 col-xl-10"
      {...props}
    >
      <ChangeScreenMessage>
        New&nbsp;here?{" "}
        <span onClick={CreateAccount}>Create&nbsp;an&nbsp;account</span>
      </ChangeScreenMessage>
      <Formik
        initialValues={{ email_id: "", password: "" }}
        validationSchema={signInUser}
        onSubmit={(values) => {
          setLoginLoading(true);
          app_api
            .post("/auth/login", values)
            .then((res) => {
              setLoginLoading(false);
              Cookie.set("ut_user", JSON.stringify(res["data"]));
              navigate(
                props.go_back
                  ? -1
                  : props.next_to
                  ? `/programs?program_id=${props.next_to}`
                  : "/dashboard/parent"
              );
            })
            .catch((err) => {
              setLoginLoading(false);
              setLoginError(err?.response?.data?.message);
            });
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          errors,
          isValid,
        }) => (
          <Form noValidate={true} onSubmit={handleSubmit} className="mt-5 ">
            {loginError !== null && (
              <Alert
                variant="danger"
                dismissible={true}
                onClose={() => setLoginError(null)}
              >
                {loginError}
              </Alert>
            )}
            <FloatingLabel
              controlId="sign-in.email_id"
              label="Email"
              className="mb-3"
            >
              <InputBox
                type="email"
                placeholder="Email"
                autoComplete="email"
                name="email_id"
                value={values.email_id}
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                isValid={touched.email_id && !errors.email_id}
                isInvalid={touched.email_id && !!errors.email_id}
              />
              {touched.email_id && (
                <FormControl.Feedback type="invalid">
                  {errors.email_id}
                </FormControl.Feedback>
              )}
            </FloatingLabel>
            <FloatingLabel
              controlId="sign-up.password"
              label="Password"
              className="mb-3"
            >
              <InputBox
                type="password"
                placeholder="Password"
                autoComplete="new-password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                isValid={touched.password && !errors.password}
                isInvalid={touched.password && !!errors.password}
              />
              {touched.password && (
                <FormControl.Feedback type="invalid">
                  {errors.password}
                </FormControl.Feedback>
              )}
            </FloatingLabel>
            <ChangeScreenMessage
              style={{ marginBottom: "3rem", display: "block" }}
            >
              <span>
                <a href="/forgotpassword">Forgot Password</a>
              </span>
            </ChangeScreenMessage>
            <SubmitButtonFormGroup className="d-flex flex-column align-items-lg-start flex-sm-row text-center text-lg-start">
              <PrimaryButton
                variant="primary"
                type="submit"
                disabled={loginLoading || !isValid}
              >
                {loginLoading
                  ? [
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />,
                      " Logging in...",
                    ]
                  : "Login"}
              </PrimaryButton>
              {/* <SecondaryButton variant="secondary">
                Sign in with Google
              </SecondaryButton> */}
              <SecondaryButton variant="secondary" onClick={LearnerLogin}>
                Learner login
              </SecondaryButton>
              <SecondaryButton variant="secondary" onClick={SchoolLogin}>
                School login
              </SecondaryButton>
              <SecondaryButton variant="secondary" onClick={TeacherLogin}>
                Teacher login
              </SecondaryButton>
            </SubmitButtonFormGroup>
          </Form>
        )}
      </Formik>
    </ContainerDiv>
  );
};

SignIn.propTypes = {
  switchSection: pt.func.isRequired,
  show: pt.bool.isRequired,
  go_back: pt.bool,
  next_to: pt.string,
};
