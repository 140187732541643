import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CardBootstrap from "react-bootstrap/Card";
import ButtonBootstrap from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import boy_img from "../../assets/images/boy.png";
import girl_img from "../../assets/images/girl.png";
import boy_girl_img from "../../assets/images/boy_and_girl.png";
import Cookies from "js-cookie";
import { app_api, school_api } from "../../atoms/api";
import {
  PrimaryButton,
  SecondaryButton,
  SubmitButtonFormGroup,
} from "../login";
import Button from "react-bootstrap/Button";
import FormLabel from "react-bootstrap/FormLabel";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormGroup from "react-bootstrap/FormGroup";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Spinner from "react-bootstrap/Spinner";
import { Formik } from "formik";
import { FormSelect, Toast, ToastBody, ToastContainer } from "react-bootstrap";
import useFetchSchool from "../../hoc/hooks/useFetchSchool";
import { NewAddLearnerSchema } from "../../atoms/schema";
import { SchoolCertificate } from "../../organisms/pdf-viewer";

export const StartLearningButton = styled(ButtonBootstrap)`
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  text-decoration: none;
  padding: 6px 10px;
  border-radius: 0.85rem;
  color: #0f3d67;
  background-color: #eee5ff;
  :hover {
    color: #ffffff;
    background-color: #0f3d67;
  }
`;
export const SchoolDashboard = (props) => {
  const [school, setSchool] = useState({});
  const [programs, setPrograms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [feedback, setFeedback] = useState({
    isDone: false,
    path: "",
  });
  const _school = JSON.parse(Cookies.get("ut_school"));
  const _programs = useFetchSchool(`school/programs/${_school.id}`);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false);
  const [toast, setToast] = useState(false);
  const [feedBackToast, setFeedBackToast] = useState(false);

  useEffect(() => {
    try {
      let school = JSON.parse(Cookies.get("ut_school"));
      setSchool(school);
      setLoading(true);
      school_api
        .get(`school/feedback/${school.id}`)
        .then((res) => {
          console.log(res.data);
          setFeedback((prev) => ({
            isDone: res.data.is_link,
            path: res.data.link,
          }));
        })
        .catch((err) => {
          console.log(err);
        });
      school_api
        .get(`school/subscription/${school.id}`)
        .then((res) => {
          setLoading(false);
          setPrograms(res.data);
        })
        .catch((err) => {
          setLoading(false);
          setError(err?.response?.data?.message || "Error getting enrollments");
        });
    } catch {
      // do nothing
    }
  }, [school.id]);
  return (
    <div>
      <Container>
        <div className="mt-10">
          <h1
            className="secondary-blue f-55 f-sm-38"
            style={{
              color: "#1e4363",
              display: "flex",
              alignItems: "start",
              justifyContent: "space-between",
            }}
          >
            <b>
              Welcome! <br></br>
              {school?.name}
            </b>
            {feedback.path ? (
              <SecondaryButton>
                <SchoolCertificate name={school?.name} />
              </SecondaryButton>
            ) : (
              <SecondaryButton
                onClick={() => {
                  setFeedbackModal((prev) => !prev);
                }}
              >
                Submit Feedback
              </SecondaryButton>
            )}
          </h1>
        </div>
        <SubmitButtonFormGroup className="d-flex flex-column align-items-start flex-sm-row">
          <PrimaryButton
            onClick={(e) => setModal((prev) => !prev)}
            type="button"
          >
            Add New Student
          </PrimaryButton>
        </SubmitButtonFormGroup>
        <Row className="g-lg-5 pt-3 pb-5">
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : error !== null ? (
            <span className="text-danger">{error}</span>
          ) : programs.length > 0 ? (
            programs.map((p, index) => (
              <Col sm="6" md="4" key={index}>
                <CardBootstrap
                  className="mxh-500 col-lg-11 col-12 pt-lg-4 pt-5 pb-4 px-4 d-flex flex-column justify-content-center mx-lg-3 my-lg-0 my-3 h-100 dashboard_bg_img"
                  style={{ borderRadius: "10px" }}
                >
                  <img
                    alt="img-here"
                    className="w-30 position-absolute"
                    style={{ top: "30%", right: "30px", zIndex: "1" }}
                    src={
                      p?.program?.directed_at === "girls"
                        ? girl_img
                        : p?.program?.directed_at === "boys"
                        ? boy_img
                        : boy_girl_img
                    }
                  />
                  <h2 className="info mb-1" style={{ color: "#1E4363" }}>
                    {p?.program?.name || "UnTaboo Program"}
                  </h2>
                  <div
                    className="my-5 d-flex flex-column align-items-center justify-content-center"
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                      border: "5px solid #1E4363",
                      borderColor: "#1E4363",
                    }}
                  >
                    <h3 className="text-white my-1">{p?.learners || 0}</h3>
                    <p
                      className="lh-1 text-center"
                      style={{ fontWeight: "600" }}
                    >
                      Students<br></br>
                      <span style={{ fontSize: "12px", fontWeight: "600" }}>
                        Enrolled
                      </span>
                    </p>
                  </div>

                  <StartLearningButton
                    variant="primary"
                    className="align-self-start fw-bold"
                    style={{ zIndex: "2" }}
                    as={Link}
                    to={`details/${p?.program?.id}`}
                  >
                    View Student Details →
                  </StartLearningButton>
                </CardBootstrap>
              </Col>
            ))
          ) : (
            <span class="text-muted">
              No programs enrolled yet. Please contact the administrator.
            </span>
          )}
        </Row>
      </Container>
      <Modal show={modal} onHide={(e) => setModal((prev) => !prev)}>
        <ModalHeader closeButton={true}>
          <ModalTitle>Add a new Student</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              parent_first_name: "",
              parent_last_name: "",
              email_id: "",
              parent_date_of_birth: "",
              parent_gender: "",
              learner_first_name: "",
              learner_last_name: "",
              learner_gender: "",
              learner_date_of_birth: "",
              learner_class: "",
              learner_division: "",
              program_id: "",
            }}
            validationSchema={NewAddLearnerSchema}
            onSubmit={(values) => {
              let payload = values;
              payload.program_id = Number(values.program_id);
              let school = JSON.parse(Cookies.get("ut_school"));
              school_api
                .post(`/school/create-student/${school.id}`, values)
                .then((res) => {
                  setModal((prev) => !prev);
                  setToast(true);
                })
                .catch((err) => {});
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              touched,
              errors,
              values,
            }) => (
              <Form noValidate={true} onSubmit={handleSubmit}>
                <Col md>
                  <FloatingLabel
                    controlId="new-school.location"
                    label="Select Program"
                  >
                    <FormSelect
                      className="mb-2"
                      name="program_id"
                      value={values.program_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.program_id && !errors.program_id}
                      isInvalid={touched.program_id && !!errors.program_id}
                    >
                      <option value="">Select program</option>
                      {_programs.data?.map((p, index) => (
                        <option key={index} value={p.id}>
                          {p.name}
                        </option>
                      ))}
                    </FormSelect>
                    {touched.program_id && (
                      <FormControl.Feedback type="invalid">
                        {errors.program_id}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="new-school.parent.name"
                    label="Parent First Name"
                  >
                    <FormControl
                      type="text"
                      className="mb-2"
                      placeholder="Parent First Name"
                      autoComplete="parent-first-name"
                      name="parent_first_name"
                      value={values.parent_first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required={true}
                      isValid={
                        touched.parent_first_name && !errors.parent_first_name
                      }
                      isInvalid={
                        touched.parent_first_name && !!errors.parent_first_name
                      }
                    />
                    {touched.password && (
                      <FormControl.Feedback type="invalid">
                        {errors.password}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="new-school.confirm-password"
                    label="Parent Last Name"
                  >
                    <FormControl
                      type="text"
                      className="mb-2"
                      placeholder="Parent Last Name"
                      autoComplete="new-parent_last_name"
                      name="parent_last_name"
                      value={values.parent_last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={
                        touched.parent_last_name && !errors.parent_last_name
                      }
                      isInvalid={
                        touched.parent_last_name && !!errors.parent_last_name
                      }
                      required={true}
                    />
                    {touched.parent_last_name && (
                      <FormControl.Feedback type="invalid">
                        {errors.parent_last_name}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="new-school.confirm-password"
                    label="Parent Date of Birth"
                  >
                    <FormControl
                      type="date"
                      className="mb-2"
                      placeholder="Parent Date of Birth"
                      autoComplete="new-parent"
                      name="parent_date_of_birth"
                      value={values.parent_date_of_birth}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={
                        touched.parent_date_of_birth &&
                        !errors.parent_date_of_birth
                      }
                      isInvalid={
                        touched.parent_date_of_birth &&
                        !!errors.parent_date_of_birth
                      }
                      required={true}
                    />
                    {touched.parent_date_of_birth && (
                      <FormControl.Feedback type="invalid">
                        {errors.parent_date_of_birth}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel
                    label="Parent Email Id"
                    controlId="school.name"
                  >
                    <FormControl
                      name="email_id"
                      className="mb-2"
                      placeholder="email_id"
                      value={values.email_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.email_id && !errors.email_id}
                      isInvalid={touched.email_id && !!errors.email_id}
                    />
                    {touched.email_id && (
                      <FormControl.Feedback type="invalid">
                        {errors.email_id}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Col>

                <Col md>
                  <FloatingLabel
                    controlId="new-school.location"
                    label="Parent Gender"
                  >
                    <FormSelect
                      className="mb-2"
                      name="parent_gender"
                      value={values.parent_gender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.parent_gender && !errors.parent_gender}
                      isInvalid={touched.parent_gender && !!errors.type}
                    >
                      <option value="">Select Gender</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </FormSelect>
                    {touched.parent_gender && (
                      <FormControl.Feedback type="invalid">
                        {errors.parent_gender}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="new-school.confirm-password"
                    label="Learner First Name"
                  >
                    <FormControl
                      type="text"
                      placeholder="Learner First Name"
                      autoComplete="new-learner_first_name"
                      name="learner_first_name"
                      value={values.learner_first_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={
                        touched.learner_first_name && !errors.learner_first_name
                      }
                      isInvalid={
                        touched.learner_first_name &&
                        !!errors.learner_first_name
                      }
                      required={true}
                    />
                    {touched.learner_first_name && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_first_name}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Col>

                <Col md>
                  <FloatingLabel
                    controlId="new-school.confirm-password"
                    label="Learner Last Name"
                  >
                    <FormControl
                      type="text"
                      className="mb-2"
                      placeholder="Learner Last Name"
                      autoComplete="new-learner_last_name"
                      name="learner_last_name"
                      value={values.learner_last_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={
                        touched.learner_last_name && !errors.learner_last_name
                      }
                      isInvalid={
                        touched.learner_last_name && !!errors.learner_last_name
                      }
                      required={true}
                    />
                    {touched.learner_last_name && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_last_name}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="new-school.location"
                    label="Learner Gender"
                  >
                    <FormSelect
                      className="mb-2"
                      name="learner_gender"
                      required={true}
                      value={values.learner_gender}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.learner_gender && !errors.learner_gender}
                      isInvalid={
                        touched.learner_gender && !!errors.learner_gender
                      }
                    >
                      <option value="">Select Gender</option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                    </FormSelect>
                    {touched.learner_gender && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_gender}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Col>

                <Col md>
                  <FloatingLabel
                    controlId="new-school.confirm-password"
                    label="Learner Date of Birth"
                  >
                    <FormControl
                      type="date"
                      className="mb-2"
                      placeholder="Learner Last Name"
                      autoComplete="new-learner_date_of_birth"
                      name="learner_date_of_birth"
                      value={values.learner_date_of_birth}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={
                        touched.learner_date_of_birth &&
                        !errors.learner_date_of_birth
                      }
                      isInvalid={
                        touched.learner_date_of_birth &&
                        !!errors.learner_date_of_birth
                      }
                      required={true}
                    />
                    {touched.learner_date_of_birth && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_date_of_birth}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="new-school.confirm-password"
                    label="Learner Class"
                  >
                    <FormControl
                      type="text"
                      placeholder="Learner Class"
                      autoComplete="new-learner_class"
                      name="learner_class"
                      value={values.learner_class}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.learner_class && !errors.learner_class}
                      isInvalid={
                        touched.learner_class && !!errors.learner_class
                      }
                      required={true}
                    />
                    {touched.learner_class && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_class}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Col>

                <Row className="g-2 mb-2">
                  <Col md>
                    <FloatingLabel
                      controlId="new-school.confirm-password"
                      label="Learner Division"
                    >
                      <FormControl
                        type="text"
                        placeholder="Learner Division"
                        autoComplete="new-learner_division"
                        name="learner_division"
                        value={values.learner_division}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isValid={
                          touched.learner_division && !errors.learner_division
                        }
                        isInvalid={
                          touched.learner_division && !!errors.learner_division
                        }
                        required={true}
                      />
                      {touched.learner_division && (
                        <FormControl.Feedback type="invalid">
                          {errors.learner_division}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                </Row>
                <ModalFooter>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => setModal((prev) => !prev)}
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    className="text-white"
                  >
                    Add
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
      <Modal
        show={feedbackModal}
        onHide={(e) => setFeedbackModal((prev) => !prev)}
      >
        <ModalHeader closeButton={true}>
          <ModalTitle>Please Share your feedback</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{
              program_finding: "",
              program_experience: "",
              other_feedback: "",
            }}
            // validationSchema={NewAddLearnerSchema}
            onSubmit={(values) => {
              let school = JSON.parse(Cookies.get("ut_school"));
              school_api
                .post(`/school/feedback/${school.id}`, values)
                .then((res) => {
                  school_api
                    .get(`school/feedback/${school.id}`)
                    .then((res) => {
                      console.log(res.data);
                      setFeedback((prev) => ({
                        isDone: res.data.is_link,
                        path: res.data.link,
                      }));
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                  setFeedbackModal((prev) => false);
                  setFeedBackToast(true);
                })
                .catch((err) => {});
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              touched,
              errors,
              values,
            }) => (
              <Form noValidate={true} onSubmit={handleSubmit}>
                <Col md>
                  <FloatingLabel
                    controlId="new-school.parent.name"
                    label="How did you find the Puberty Adventure Program?"
                    style={{ fontSize: "15px" }}
                  >
                    <FormControl
                      type="text"
                      required={true}
                      className="mb-2"
                      placeholder="How did you find the Puberty Adventure Program?"
                      name="program_finding"
                      value={values.program_finding}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={
                        touched.program_finding && !errors.program_finding
                      }
                      isInvalid={
                        touched.program_finding && !!errors.program_finding
                      }
                    />
                    {touched.program_finding && (
                      <FormControl.Feedback type="invalid">
                        {errors.program_finding}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="new-school.parent.name"
                    label="How did you find the entire experience with UnTaboo?"
                    style={{ fontSize: "15px" }}
                  >
                    <FormControl
                      type="text"
                      required={true}
                      className="mb-2"
                      name="program_experience"
                      value={values.program_experience}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      placeholder="How did you find the entire experience with UnTaboo?"
                      isValid={
                        touched.program_experience && !errors.program_experience
                      }
                      isInvalid={
                        touched.program_experience &&
                        !!errors.program_experience
                      }
                    />
                    {touched.program_experience && (
                      <FormControl.Feedback type="invalid">
                        {errors.program_experience}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
                <Col md>
                  <FloatingLabel
                    controlId="new-school.parent.name"
                    label="Any other feedback?"
                    style={{ fontSize: "15px" }}
                  >
                    <FormControl
                      type="text"
                      required={true}
                      className="mb-2"
                      placeholder="Any other feedback?"
                      name="other_feedback"
                      value={values.other_feedback}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.other_feedback && !errors.other_feedback}
                      isInvalid={
                        touched.other_feedback && !!errors.other_feedback
                      }
                    />
                    {touched.other_feedback && (
                      <FormControl.Feedback type="invalid">
                        {errors.other_feedback}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Col>

                <ModalFooter>
                  <Button
                    type="button"
                    variant="secondary"
                    onClick={() => setFeedbackModal((prev) => false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    variant="primary"
                    className="text-white"
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
      <ToastContainer position="top-end" className="pt-2 pe-1 pe-md-2">
        <Toast
          show={toast}
          bg="success"
          onClose={() => setToast(false)}
          delay={2000}
          autohide={true}
        >
          <ToastBody className="text-white">Added student</ToastBody>
        </Toast>
        <Toast
          show={feedBackToast}
          bg="success"
          onClose={() => setFeedBackToast(false)}
          delay={2000}
          autohide={true}
        >
          <ToastBody className="text-white">
            Thank you for your feedback
          </ToastBody>
        </Toast>
      </ToastContainer>
    </div>
  );
};
export default SchoolDashboard;
