import React from "react";
import ReactDOM from "react-dom";
// import Blog_Img from "../../assets/images/blog_Image.png";
import Anjukish from "../../assets/images/Anjukish.png";
import Slider from "react-slick";
import pubertygirls2 from "../../assets/blogs/puberty girls/pubertygirls2.png";
import pubertygirls1 from "../../assets/blogs/puberty girls/pubertygirls1.png";


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// blog_Image
// import { Container } from "react-bootstrap";

// Define a functional component
export const ThirdBlog = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 200,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="poppins">
      <div
        style={{ marginLeft: "10%", marginRight: "10%" }}
        className="my-4 font-weight-bold"
      >
        {/* <div style={{ height: "40%" }}> */}
          
        {/* </div> */}
        <h6 className="text-primary poppins" style={{ fontSize: "15px" }}>
          Published 13 Jan 2022
        </h6>
        <h1 className="title-header poppins">FIRST PUBERTY TALK WITH YOUR DAUGHTER</h1>
        {/* <p className="text-black-50">First Puberty Talk With Your Daughter</p> */}
        <img
            src={pubertygirls2}
            alt="pubertygirls2"
            class="img-fluid responsive-img"
            // height={{ height: "20rem" }}
            // style={{height: "100vh"}}
          />
      {/* </div> */}
      

      {/* <div className="carousel" data-aos="fade-up">
        <Slider {...settings}>
          <div className="">
            <div className="">
              <div className="w-full flex justify-center md:items-center p-2">
                <img
                  src={Anjukish}
                  alt=""
                  className="rounded-full "
                  width={280}
                />
              </div>
            </div>
          </div>
          <div className="p-2 bg-[#E4A795] w-full text-black rounded-xl gap-4">
            <div className="flex flex-col xl:flex-row  text-center">
              <div className="w-full h-screen flex justify-center md:items-center p-2">
                <img src={Anjukish} alt="" className="rounded" width={280} />
              </div>
            </div>
          </div>
          <div className="p-2 bg-[#E4A795] w-full text-black rounded-xl">
            <div className="flex flex-col xl:flex-row text-center">
              <div className="w-full flex justify-center md:items-center p-2">
                <img src={Anjukish} alt="" className="rounded" width={280} />
              </div>
            </div>
          </div>
          <div className="p-2 bg-[#E4A795] w-full text-black rounded-xl">
            <div className="flex flex-col xl:flex-row text-center">
              <div className="w-full flex justify-center md:items-center p-2">
                <img src={Anjukish} alt="" className="rounded" width={280} />
              </div>
            </div>
          </div>
          <div className="p-2 bg-[#E4A795] w-full text-black rounded-xl">
            <div className="flex flex-col xl:flex-row text-center">
              <div className="w-full flex justify-center md:items-center p-2">
                <img src={Anjukish} alt="" className="rounded" width={280} />
              </div>
            </div>
          </div>
        </Slider>
      </div> */}

      {/* <div style={{ marginLeft: "20%", marginRight: "20%" }}> */}
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          <b className="text-pink">H</b>ave you suddenly felt that change in
          your little girl that makes you feel that she is no longer little? The
          mood’s different, so are new found friendships, a definite care for
          appearance and growth spurts. Welcome to that time of your life when
          you need to break the pre-teen ice and have the first ‘puberty talk’
          with your little one.
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          <b className="text-pink">
            Puberty brings in emotional and social changes besides physical
            changes in young girls.
          </b>{" "}
          The age for puberty varies and so do the changes, every body is
          different after all. Initiating the conversation may seem like a big
          deal because it would be the first time you would be breaking the ice
          on topics never discussed before. All you need is patience and all
          your little girl needs is the confidence that she can turn to you for
          any queries.
        </p>

        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          <b className="text-pink">Right Age</b>
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          The right age to talk to your daughter about puberty is any time
          between ages 8-10. Young girls are increasingly attaining puberty
          sooner than before and in the current scenario, this seems the right
          age to break the ice.
        </p>

        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          <b className="text-pink">Starting Trouble</b>
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          She may show defiance because that’s what kids approaching puberty do,
          handle it with care and love as you would. You can also fish for books
          which may form a good bedtime reading for her and involves
          conversations around puberty, fit for her age.
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          <b className="text-pink">THINGS TO TALK ABOUT</b>
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          Young girls go through many changes as puberty sets in. You should
          make sure that you touch upon, each of these. Thanks to the gamut of
          information available to children, they are sure to land on something
          even before you strike a conversation. Be gentle and discuss all of
          the topics below:
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          <b className="text-pink">Breast development</b>
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          Breast develop in various stages, right from the appearance of a bud
          to them becoming fuller and firmer. The process can be sore and
          painful at times. The size of the breasts depends on genes and other
          factors, hence it’s important to let your child know that all sizes
          are perfect. This is also the time to start talking about the usage of
          right bras.
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          <b className="text-pink">Body Hair</b>
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          Shaving and waxing will find their way into the talk. Sudden hair
          growth in the pubic area and armpits besides some facial and bodily
          hair will be visible. It’s important to get them to understand the dos
          and don’ts of body hair removal and how having body hair is nothing to
          be ashamed of.
        </p>
        <img
            src={pubertygirls1}
            alt="pubertygirls1"
            class="img-fluid responsive-img"
            // height={{ height: "20rem" }}
            style={{height: "100vh"}}
          />
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          <b className="text-pink">Oily Skin and Acne</b>
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          This can be embarrassing and worrisome for some, acne will be popped
          and some may leave teeny tiny scars. What’s important is to let her
          know that this happens to some teens and it’s okay. It also is the
          time to get her to wash her face more and provide face washes, fit for
          the same.
        </p>

        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          <b className="text-pink">Smelly Sweat</b>
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          Hormones will start their course and the sweat shall smell. It may be
          the right time to touch upon hygiene lessons and deodorant. It would
          also be a good idea to explain genital hygiene here.
        </p>

        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          <b className="text-pink">Change in genitals</b>
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          The genitals will grow and become more pronounced in appearance.
          Though the uterus may not be visible, but it will grow too. It’s good
          to talk about this and ease in the organ functions into the dialogue,
          setting the ground for future talks on Periods & Reproduction.
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          A close up of a logo Description generated with very high confidence{" "}
          <b>Vaginal discharge</b>
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          She may find it strange to see discharge from her vagina. Explain to
          her that it’s normal and is also cleansing her vagina in a natural
          way. It may feel uncomfortable
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          for some, but is not a cause for concern usually.
        </p>

        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          <b className="text-pink">Menstruation</b>
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          Once the vaginal discharge begins, it’s the perfect time to speak
          about menstruation. Explain the process and make sure that she is
          prepared and positive about them. Do talk about menstrual myths,
          ensuring that she knows that periods are nothing to be ashamed of.
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          A drawing of a face Description generated with high confidence
        </p>

        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          <b className="text-pink">Mood Swings </b>
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          New hormones trying to settle in might make her feel very unsettled.
          Mood swings, confusion, tears are common. Make sure she knows that you
          are always there for her and she can come to you to share, discuss and
          question, anything.
        </p>

        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          <b className="text-pink">Attention to looks</b>
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          It’s evident that friendships will develop with the opposite/same sex
          and she will take extra care of her appearance. Make her feel
          confident as this is a very sensitive phase. This is the time for
          lessons in respect, for oneself and others too.
        </p>

        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          <b className="text-pink">
            Get Her <span style={{ color: "black" }}>Puberty</span> Ready!
          </b>
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          It’s necessary that your little girl is ready and knows what to
          expect. If she gets her first period, she should know what to do and
          how to deal with it. Hence, it’s vital to have a pragmatic approach
          well before she attains puberty. All it needs is for you to instill
          the confidence in her that it’s a natural process of change and you
          shall be there by her side (Albeit, without adding any stress or
          pressure) 🙂
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          <b>
            In my upcoming articles, I shall touch open each of these topics in
            detail and also cover the ‘how to’ of the conversations to help you
            ease into the talk.
          </b>
        </p>

        {/* <img src={Blog_Img} alt="blog_img" class="img-fluid responsive-img" />
        <p
          className="text-gray-500"
          style={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "26px",
            Paragraph: "24px",
          }}
        >
          Image courtesy of Mathilde Langevin via Unsplash
        </p> */}

        <div className="text-gray-500 poppins">
          <hr />
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
            {/* <img src={Anjukish} alt="Anjukish" height={"75px"} style={{borderRadius:"100%"}}/> */}
            <div className="d-flex flex-row">
              <div class="image-container">
                <img
                  src={Anjukish}
                  class="circle-image"
                  alt="Description of the image"
                />
              </div>
              <div style={{ marginLeft: "20px" }}>
                <p
                  className="m-0 d-flex justify-center ml-5 poppins"
                  style={{
                    marginTop: "4%",
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: "600",
                    lineHeight: "37px",
                    Paragraph: "24px",
                  }}
                >
                  Anju Kish
                </p>
                <p
                  className="m-0 text-gray-500 poppins"
                  style={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: "400",
                    lineHeight: "32px",
                    Paragraph: "24px",
                  }}
                >
                  Content Writer
                </p>
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row  gap-2 align-items-center justify-content-top text-center ">
              <p
                className="p-0 px-4 m-0 bg-light  rounded-more poppins"
                style={{
                  backgroundColor: "#F2F4F7",
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "26px",
                  Paragraph: "18px",
                }}
              >
                Sexual Understanding
              </p>
              <p
                className="p-0  px-4 m-0 bg-light rounded-more poppins"
                style={{
                  backgroundColor: "#F2F4F7",
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "26px",
                  Paragraph: "18px",
                }}
              >
                Education
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          // paddingLeft: "16%",
          // paddingRight: "16%",
          padding: "6% 16% 6% 16%",
          marginTop: "4%",
          fontFamily: "Inter",
          fontSize: "20px",
          fontWeight: "400",
          lineHeight: "37px",
          Paragraph: "24px",
          backgroundColor: "#B7C4D1",
        }}
      >
        <p
          className="text-uppercase poppins"
          style={{
            fontFamily: "Inter",
            fontSize: "27px",
            fontWeight: "600",
            lineHeight: "37px",
            textAlign: "center",
          }}
        >
          Come join our movement to UnTaboo safety & sex education and create a
          safer society.
        </p>

        <p
          className="poppins"
          style={{
            // paddingTop: "3%",
            // paddingLeft:"10%",
            padding: "3% 13% 3% 13%",
            fontFamily: "Inter",
            fontSize: "15px",
            fontWeight: "600",
            lineHeight: "30px",
            textAlign: "center",
          }}
        >
          Volunteer with us I Sponsor our program I Be an affiliate partner I Be
          our brand ambassador I Talk about us I Share your feedback &
          suggestions.
        </p>
        <div className="d-flex align-items-center justify-content-center ">
          <p
            className="p-0 px-4 m-0 bg-light rounded-more poppins"
            style={{
              backgroundColor: "#FFFFFF",
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "26px",
              Paragraph: "18px",
            }}
          >
            We would love to hear from you!
          </p>
        </div>
      </div>
    </div>
  );
};
