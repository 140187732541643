import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";

import { useNavigate } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import { Link } from "react-router-dom";
import { app_api } from "../../atoms/api";
import Cookies from "js-cookie";
import { IconContext } from "react-icons";
import { FaRegPlayCircle } from "react-icons/fa";
import { ControlledCarousel } from "../../organisms/carousel";
import { Faq } from "../../organisms/programsFaq";
import icon_1 from "../../assets/images/age appropriate.png";
import icon_2 from "../../assets/images/designed by experts.png";
import icon_3 from "../../assets/images/assessed learning.png";
import icon_4 from "../../assets/images/full parental control.png";
import icon_5 from "../../assets/images/programs_tick.PNG";
import icon_6 from "../../assets/images/programs_icon6.png";
import icon_7 from "../../assets/images/programs_icon7.png";
import icon_8 from "../../assets/images/programs_icon8.png";
import icon_9 from "../../assets/images/programs_icon9.png";
import img_1 from "../../assets/images/neel_kiara.jpg";
import img_2 from "../../assets/images/Author-Dr-Anjali-Chhabria.jpg";
import img_3 from "../../assets/images/program_watch.jpg";
import img_4 from "../../assets/images/workshop_1.jpg";
import { Accordion } from "react-bootstrap";

const InfoSpan = styled.span`
  font-size: 12px;
  color: gray;
`;
const UntabooButton = styled.button`
  background: #6bcacf;
  border-radius: 30px;
  border: none;
  color: #ffffff;
  transition: all 0.4s ease-in-out;
  padding: 10px 30px;
  &:hover {
    background-color: #0bb7af;
    border: none;
    box-shadow: 0px 10px 30px 0px rgb(166 223 226 / 20%);
    background: linear-gradient(120deg, #6bcacf 0%, #b1e3e5 100%);
    color: #fff !important;
  }
  ${(props) =>
    props.secondary
      ? css`
          color: #0c2e60;
          border: 1px solid #0c2e60;
          background-color: white;
          transition: all 0.4s ease-in-out;
        `
      : ""}
  &:hover {
    color: #fff;
  }
`;
export const ProgramLandingTemplate = (props) => {
  const navigate = useNavigate();
  let user;
  const isLoggedIn = !!Cookies.get("ut_user");
  if (isLoggedIn) {
    user = JSON.parse(Cookies.get("ut_user"));
  }
  const [program, setProgram] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [hasGift, setHasGift] = useState(false);
  const [giftCodeTouched, setGiftCodeTouched] = useState(false);
  const [giftCode, setGiftCode] = useState(null);

  useEffect(() => {
    app_api
      .get("programs/public/" + props.slug)
      .then((res) => {
        setLoading(false);
        setProgram(res.data);
        document.title = `UnTaboo | ${res.data?.name || "Program"}`;
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Error");
      });
  }, [props.slug]);

  const enrollClick = () => {
    if (isLoggedIn) {
      navigate(`/programs?program_id=${program?.id}`);
    } else {
      navigate("/login?go_back=true");
    }
  };

  const hasGiftCoupon = () => {
    setHasGift(true);
  };

  const doesNotHaveGiftCoupon = () => {
    setHasGift(false);
    setGiftCodeTouched(false);
    setGiftCode(null);
  };

  const giftCodeChange = (e) => {
    setGiftCode(e.target.value);
  };

  const redeemGift = () => {
    navigate(`/gift/redeem/code/${giftCode}`);
  };

  const giftClick = () => {
    navigate("gift");
  };

  return (
    <section className="poppins">
      <div
        style={{
          backgroundImage:
            "linear-gradient(to right top, #2193b0, #37a3bf, #4ab3ce, #5cc4de, #6dd5ed)",
          color: "white",
        }}
      >
        <Container>
          <div className="px-3 px-lg-0">
            <div className="pt-10 col-lg-6 ">
              <h1 className="fs-1">
                <b className="Yanone_Kaffeesatz-700">{program?.name}</b>
              </h1>
              <p className="fs-6 mt-4">
                Puberty will not seem like a dreaded phase when every change is
                taught in a fun manner. This program turns Puberty into an
                exciting adventure for a child. The end result - a positively
                informed child, ready to face puberty head on!
              </p>
            </div>
            <div className="col-lg-4 d-flex ">
              <IconContext.Provider value={{ color: "white", size: "30px" }}>
                <div className="fs-5 d-flex align-items-center">
                  <FaRegPlayCircle className="me-2" />{" "}
                  {program?.level_sequence?.length} Videos
                </div>
                <div className="fs-5 ps-lg-5 ps-0 d-flex align-items-center">
                  <FaRegPlayCircle className="me-2" /> {program?.learning_hours}{" "}
                  Hours
                </div>
              </IconContext.Provider>
            </div>
            <div className="col-lg-4 d-flex py-4">
              <button
                type="button"
                className="btn btn-light px-4"
                style={{ backgroundColor: "white" }}
                onClick={enrollClick}
              >
                Buy Now
              </button>
              <button
                type="button"
                className="btn btn-outline-light px-4 ms-3"
                style={{ border: "1px solid white", color: "black" }}
                onClick={giftClick}
              >
                Gift this program
              </button>
            </div>
            <div className="pb-5">
              <p className="fs-5">See a demo</p>
              <i>
                {hasGift ? (
                  <Row className="g-2">
                    <Col sm="4">
                      <FormControl
                        value={giftCode}
                        onChange={giftCodeChange}
                        onBlur={() => setGiftCodeTouched(true)}
                        placeholder="Enter code"
                        isValid={giftCodeTouched && !!giftCode}
                        isInvalid={giftCodeTouched && !giftCode}
                      />
                    </Col>
                    <Col>
                      <Button
                        variant="primary"
                        className="me-2"
                        onClick={redeemGift}
                        disabled={!giftCode}
                      >
                        Enter
                      </Button>
                      <Button
                        variant="secondary"
                        onClick={doesNotHaveGiftCoupon}
                      >
                        Cancel
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  <span
                    onClick={hasGiftCoupon}
                    className="fs-6 text-decoration-underline"
                    style={{ color: "black" }}
                  >
                    Have a gift coupon ?
                  </span>
                )}
              </i>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <div className="secondary-blue col-lg-6 my-5 px-3 px-lg-0">
          <h1 className="fs-1 Yanone_Kaffeesatz-700">
            Is Puberty Adventure for my Son?
          </h1>
          <p className="fs-6 py-4 text-justify">
            Puberty can be a difficult and tricky phase of growing up for your
            son.
            <br></br>
            <br></br>
            Hormonal shifts at this stage may cause him to become snappy or rude
            or suddenly turn quiet, and you can see him struggling to deal with
            it. Mood swings could be confusing and physical pubertal changes
            like voice cracking, erections and wet dreams could be embarrassing.
            <br></br>
            <br></br>
            As a parent, you can help him navigate this period with ease by
            getting him ready for puberty before it hits!
            <br></br>
            <br></br>
            This program on puberty education for boys is fun and factual,
            normalising changes and sowing the seeds of confidence, body
            positivity and gender sensitivity.
            <br></br>
            <br></br>
            It helps deepen the parent-child partnership to make puberty a
            stress free, enjoyable experience for your son and a celebration for
            both of you.
          </p>
        </div>

        <div className="enrollmentCard col-lg-5 col-12 mb-10 ">
          <div className="col-12 col-lg-8 position-relative">
            <img className="col-12" src={img_1} />
            <div
              className="rounded-circle position-absolute top-50   d-flex justify-content-center align-items-center"
              style={{
                backgroundColor: "#6BCACF",
                width: "16%",
                height: "15%",
                left: "43%",
              }}
            >
              <div
                className=""
                style={{
                  width: "0",
                  height: " 0",
                  borderTop: "12px solid transparent",
                  borderBottom: "12px solid transparent",
                  borderLeft: "12px solid white",
                }}
              ></div>
            </div>
          </div>
          <div
            className="col-12 col-lg-8 px-3 py-3"
            style={{ border: "1px solid #edeff2" }}
          >
            <div className="px-3 pt-3 d-flex flex-wrap col-12">
              <div className="w-50 text-start">No. of Videos</div>
              <div className=" w-50 text-end">
                {program?.level_sequence?.length}
              </div>
            </div>
            <hr />
            <div className="px-3 d-flex flex-wrap col-12">
              <div className="w-50 text-start">Total learning Hours</div>
              <div className="w-50 text-end">{program?.learning_hours} hrs</div>
            </div>
            <hr />
            <div className="px-3 d-flex flex-wrap col-12">
              <div className="w-50 text-start">Program creator</div>
              <div className="w-50 text-end">UnTaboo</div>
            </div>
            <hr />
            <div className="px-3 d-flex flex-wrap col-12">
              <div className="w-50 text-start fs-6">Age group</div>
              <div className="w-50 text-end fs-6">9-12 Years(Boys)</div>
            </div>
            <hr />
            <div className="px-3 d-flex flex-wrap col-12">
              <div className="w-50 text-start fw-bold">
                <h4 className="fs-6 fw-bold">Program Fee</h4>
              </div>
              <div className="w-50 text-end fw-bold">
                <h4 className="fs-6 fw-bold">Rs.{program?.cost}</h4>
              </div>
            </div>
            <button
              type="button "
              className="btn btn-danger fw-bold col-12 mt-4 mb-3 p-2"
              style={{ backgroundColor: "#F64E60", color: "white" }}
              onClick={enrollClick}
            >
              Enroll For Program
            </button>
            <button
              type="button "
              className="btn btn-danger fw-bold col-12 mb-4 p-2"
              style={{
                backgroundColor: "#FFE2E5",
                color: "#F64E60",
                border: "none",
              }}
              onClick={giftClick}
            >
              Gift This Program
            </button>
          </div>
        </div>

        <div className="col-lg-6  d-lg-flex flex-lg-wrap  justify-content-between px-3 px-lg-0">
          <div className="col-lg-5 col-12 mb-lg-5">
            <img className="w-20" src={icon_1} />
            <h3 className="programs-blue fs-5 fw-bold mt-3">Age Appropriate</h3>
            <p style={{ fontSize: "13px", color: "#656565" }}>
              The information is relevant to the age group and doesn’t include
              any sexual material
            </p>
          </div>
          <div className=" col-lg-5 col-12">
            <img className="w-20" src={icon_2} />
            <h3 className="programs-blue fs-5 fw-bold  mt-3">
              Designed By Expert
            </h3>
            <p style={{ fontSize: "13px", color: "#656565" }}>
              Crafted by leaders in the field of child pubertal health and
              well-being.
            </p>
          </div>
          <div className=" col-lg-5 col-12">
            <img className="w-20" src={icon_3} />
            <h3 className="programs-blue fs-5 fw-bold mt-3">
              Measured Learning
            </h3>
            <p style={{ fontSize: "13px", color: "#656565" }}>
              Each level includes pre & post-assessments to track progress
            </p>
          </div>
          <div className="col-lg-5 col-12">
            <img className="w-20" src={icon_4} />
            <h3 className="programs-blue fs-5 fw-bold mt-3">
              Full Parental Control
            </h3>
            <p style={{ fontSize: "13px", color: "#656565" }}>
              The parent can monitor every step of the journey
            </p>
          </div>
        </div>
      </Container>
      <div
        className="col-12 mt-10 pt-10 pb-10"
        style={{
          backgroundImage: `url(${img_4})`,
          backgroundRepeat: "no-repeat",
        }}
      >
        <h1
          className="fs-1 Yanone_Kaffeesatz-700 text-center"
          style={{ color: "white", fontSize: "52px" }}
        >
          HEARTWARMING FEEDBACK
        </h1>

        <ControlledCarousel />
      </div>
      <div className="bg-img-programs text-center pt-10 mt-5 px-3 px-lg-0">
        <Container>
          <h1
            className="programs-blue Yanone_Kaffeesatz-700"
            style={{ fontSize: "55px" }}
          >
            Let the Adventure begin!
          </h1>
          <p
            className="col-lg-10 mx-auto fs-6 lh-md pt-5"
            style={{ color: "#5C6C7E" }}
          >
            Experts have created this Puberty education program in an adventure
            format to ensure learning is enjoyable and effective. Every change
            experienced during puberty has been created like a ‘level’ on this
            exciting journey to the Puberty Peak. The next level unlocks on
            completing the current level, just like in a game.
          </p>
          <h3 className="mt-5 fs-6 fw-bold" style={{ color: "#5C6C7E" }}>
            There are 10 levels in this program. Let the adventure begin!
          </h3>
          <div className="d-flex flex-lg-row flex-column justify-content-between pt-5">
            <div className="col-lg-6 col-12">
              <Accordion className=" mb-3">
                {/* <h3 className="programs-blue">
                  <b className="fs-4">Levels</b>
                </h3> */}
                <Accordion.Item eventKey="0" className="accordion-item mt-4">
                  <Accordion.Header style={{ color: "#1e4363" }}>
                    Level 1: Puberty Point
                  </Accordion.Header>
                  <Accordion.Body style={{ textAlign: "left" }}>
                    Decoding Puberty and what, when and how of it while giving
                    the child the confidence to not compare their growth and
                    development with others.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="1">
                  <Accordion.Header style={{ color: "#1e4363" }}>
                    Level 2: Body Camp
                  </Accordion.Header>
                  <Accordion.Body style={{ textAlign: "left" }}>
                    Introduction to the bodies of girls and boys, correct names
                    of male and female genitals and the key differences between
                    the genders.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header style={{ color: "#1e4363" }}>
                    Level 3: Hygiene Heights
                  </Accordion.Header>
                  <Accordion.Body style={{ textAlign: "left" }}>
                    Tools to practice general hygiene in the light of changing
                    bodies, increased sweating and foul odour, plus a complete
                    tutorial on special care for genitals.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header style={{ color: "#1e4363" }}>
                    Level 4: Hairy Forest
                  </Accordion.Header>
                  <Accordion.Body style={{ textAlign: "left" }}>
                    Hair growth in different parts of the body, the role of
                    genes in it, its importance and hair removal methods.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="4">
                  <Accordion.Header style={{ color: "#1e4363" }}>
                    Level 5: Echo Point
                  </Accordion.Header>
                  <Accordion.Body style={{ textAlign: "left" }}>
                    Clarity on what voice cracking is, why it happens and
                    learning to cope positively with this embarrassing change.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="5">
                  <Accordion.Header style={{ color: "#1e4363" }}>
                    Level 6: Erection Peak
                  </Accordion.Header>
                  <Accordion.Body style={{ textAlign: "left" }}>
                    Understanding the developmental stages of penis and scrotum,
                    erections and why they happen. Instilling body positivity by
                    accepting shape and size of penis.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="6">
                  <Accordion.Header style={{ color: "#1e4363" }}>
                    Level 7: Wet Dreams Pond
                  </Accordion.Header>
                  <Accordion.Body style={{ textAlign: "left" }}>
                    A non-sexual guide of what wet dreams are, why they happen
                    and how to handle them.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="7">
                  <Accordion.Header style={{ color: "#1e4363" }}>
                    Level 8: Girls Valley
                  </Accordion.Header>
                  <Accordion.Body style={{ textAlign: "left" }}>
                    Gender sensitization through understanding the primary
                    purpose of breasts, decoding periods and busting myths
                    surrounding it.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="8">
                  <Accordion.Header style={{ color: "#1e4363" }}>
                    Level 9: Ropeway of Emotions
                  </Accordion.Header>
                  <Accordion.Body style={{ textAlign: "left" }}>
                    Coping with mood swings, the distraction of crushes,
                    handling rejection and body consciousness.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="9">
                  <Accordion.Header style={{ color: "#1e4363" }}>
                    Level 10: Relationship Bridge
                  </Accordion.Header>
                  <Accordion.Body style={{ textAlign: "left" }}>
                    Navigating changing relationships with parents, peer
                    pressure and risk taking behaviour. Learning to build
                    bridges with parents and sailing through puberty positively.
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </div>
            <div
              className="col-lg-4 card px-3 pb-3"
              style={{
                backgroundColor: "#C9F7F5",
                border: "none",
                borderRadius: "20px",
                objectFit: "contain",
                backgroundRepeat: "no-repeat",
              }}
            >
              <div className="p-4 text-start">
                <h3 className="programs-blue ">
                  <b className="fs-4">Key Learnings</b>
                </h3>
                <div className="mt-3 d-flex flex-wrap pt-3">
                  <img className="py-1 pe-2" src={icon_5} />
                  <p className="primary-blue mb-0 fs-6">
                    Handling changes positively
                  </p>
                </div>
                <div className=" d-flex flex-wrap ">
                  <img className="py-1 pe-2" src={icon_5} />
                  <p className="primary-blue mb-0 fs-6">
                    Managing Peer Pressure
                  </p>
                </div>
                <div className=" d-flex flex-wrap ">
                  <img className="py-1 pe-2" src={icon_5} />
                  <p className="primary-blue mb-0 fs-6">Gender sensitisation</p>
                </div>
                <div className=" d-flex flex-wrap ">
                  <img className="py-1 pe-2" src={icon_5} />
                  <p className="primary-blue mb-0 fs-6">Emotional awareness</p>
                </div>
                <div className=" d-flex flex-wrap ">
                  <img className="py-1 pe-2" src={icon_5} />
                  <p className="primary-blue mb-0 fs-6">Body confidence</p>
                </div>
                <div className=" d-flex flex-wrap ">
                  <img className="py-1 pe-2" src={icon_5} />
                  <p className="primary-blue mb-0 fs-6">Bonding with parents</p>
                </div>
              </div>
              <div
                className="mt-3 py-3 px-2 row"
                style={{ backgroundColor: "white" }}
              >
                <div className="col-4">
                  <img className="col-12" src={img_2} />
                </div>
                <div className="col-8 text-start">
                  <div
                    className="text-center fst-italic"
                    style={{ backgroundColor: "#F64E60", color: "white" }}
                  >
                    <h3 className="fs-6 p-1">Expert recommendation:</h3>
                  </div>
                  <h3 className="fs-6 secondary-blue fw-bold">
                    Dr. Anjali Chhabria
                  </h3>
                  <h4 style={{ fontSize: "13px" }}>
                    Psychiatrist & Psychotherapist
                  </h4>
                </div>
                <div className=" text-start mt-3">
                  <p style={{ fontSize: "13px" }}>
                    "It is crucial to prepare our children for the plethora of
                    changes that puberty brings with and it is important to do
                    it before these changes start. They need to be told what to
                    expect and how they can navigate the physical, emotional,
                    behavioural changes of puberty and the shift in
                    interpersonal relationships. It has been proven that this
                    leads to positive behaviours and stronger relationships with
                    parents. I feel it's important for parents to partner with
                    organisations like UnTaboo and help their child cope with
                    what may be a difficult time."
                  </p>
                  <div className="col-12 text-center">
                    <Link to="">
                      <UntabooButton>Sign me Up</UntabooButton>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <hr className="w-100 my-10"></hr>

        <div className="bg-img-pfb">
          <Container>
            <div className="d-lg-flex flex-lg-row ">
              <div className="col-lg-6 text-start ">
                <h1
                  className=" mb-4 secondary-blue fw-bold Yanone_Kaffeesatz-700"
                  style={{ fontSize: "52px" }}
                >
                  THE PROCESS
                </h1>
                <h4>Engaging Format</h4>
                <p>
                  Our child-friendly Puberty program is age appropriate &
                  designed to cater to the developmental needs of a child at
                  this age. It has been designed in an engaging manner by
                  experts using a unique ‘Watch ~ Learn~ Discuss ~ Play & Bond’
                  method of learning. It can be consumed independently by the
                  child from the comfort and safety of their home, leaving them
                  with takeaways that are easy to absorb and practical to follow
                  - all helping the child grow up healthy, happy, and safe with
                  responsible attitudes.
                </p>
                <div
                  className="mt-5 col-lg-10 p-3 align-center d-flex flex-row rounded-3"
                  style={{ backgroundColor: "#e4f4fb" }}
                >
                  <div className="col-3 my-auto">
                    <img className="col-8" src={icon_6} />
                  </div>
                  <div className=" col-9">
                    <h3 className="fs-5 fw-bold">
                      Watch & Learn - anytime, anywhere
                    </h3>
                    <p className="fs-6" style={{ color: "#6c757d" }}>
                      Lessons are taught through videos to engage & then
                      reiterated through text to maximise retention.
                    </p>
                  </div>
                </div>

                <div className="mt-3 col-lg-10 p-3 align-center d-flex flex-row">
                  <div className="col-3 my-auto">
                    <img className="col-8" src={icon_7} />
                  </div>
                  <div className=" col-9">
                    <h3 className="fs-5 fw-bold">
                      Discuss - anytime, anywhere{" "}
                    </h3>
                    <p className="fs-6" style={{ color: "#6c757d" }}>
                      Any doubts arising post the lesson are clarified through
                      an FAQ, normalising changes & differences.
                    </p>
                  </div>
                </div>

                <div className="mt-3 col-lg-10 p-3 align-center d-flex flex-row">
                  <div className="col-3 my-auto">
                    <img className="col-8" src={icon_8} />
                  </div>
                  <div className=" col-9">
                    <h3 className="fs-5 fw-bold">Play - anytime, anywhere </h3>
                    <p className="fs-6" style={{ color: "#6c757d" }}>
                      Gamified evaluation to check learnings & reward the child
                      while generating a progress report for the parent.
                    </p>
                  </div>
                </div>

                <div className="mt-3 col-lg-10 p-3 align-center d-flex flex-row">
                  <div className="col-3 my-auto">
                    <img className="col-8" src={icon_9} />
                  </div>
                  <div className=" col-9">
                    <h3 className="fs-5 fw-bold">Bond - anytime, anywhere</h3>
                    <p className="fs-6" style={{ color: "#6c757d" }}>
                      A fun Parent-child activity at the end of program to break
                      the ice & help parents to take this conversation forward.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 my-auto">
                <div
                  className="rounded-3 shadow-sm p-3 mb-5 bg-body rounded"
                  style={{}}
                >
                  <img className="col-12" src={img_3} />
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div
          className=" pb-10 text-center"
          style={{ backgroundColor: "#F69494 " }}
        >
          <h2 className="pt-10 px-2 text-white">FREQUENTLY ASKED QUESTIONS</h2>
          <p className="mb-5 text-white">Got doubts? We got it covered!</p>
          <div className="col-11 col-lg-10 mx-auto d-lg-flex justify-content-around">
            <div className="w-75 sm-w-100">
              <Faq></Faq>
            </div>
          </div>
        </div>
        <div className="pb-10 pt-10 " style={{ backgroundColor: "#B7c4D1" }}>
          <Container>
            <div className="col-lg-12 text-center col-12 px-2 px-lg-0">
              <h3 className="secondary-blue text-center  fs-1 fw-bold">
                Hey UnTabooer
              </h3>
              <p
                className="col-12 text-center col-lg-7 mx-auto fs-6 mt-4 poppins"
                style={{ color: "#5C6C7E", lineHeight: "1.7" }}
              >
                Ready to join thousands of parents across India, to partner with
                your kid in turning puberty education into an adventure?
              </p>
              <div className="mt-5  text-center col-12  ">
                <Link to="/program/puberty-for-girls">
                  <UntabooButton
                    type="button"
                    variant="success"
                    className="btn btn-green poppins mb-lg-0 mb-3 rounded-3"
                    style={{ backgroundColor: "#6BCACF", color: "#fff" }}
                  >
                    Get Started Now
                  </UntabooButton>
                </Link>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Container></Container>
    </section>
  );
};
