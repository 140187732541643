import React from "react";
import styled from "styled-components";
import pt from "prop-types";

const SImg = styled.img``;

export const UtImg = ({ src, ...props }) => {
  if (props.imported) {
    return <SImg src={src} {...props} />
  } else {
    return <SImg
      {...props}
      src={
        !!src
          ? `${process.env.REACT_APP_BACKEND_URL}${src}`
          : "https://via.placeholder.com/100x100?text=UnTaboo"
      }
    />
  }
};

UtImg.propTypes = {
  src: pt.any.isRequired,
};

// export const Banner = (props) => {
//   <SImg {...props} src={}
// }
