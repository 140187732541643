import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Cookie from "js-cookie";
import pt from "prop-types";
import { registerSchema } from "../../atoms/schema";
import {
  ChangeScreenMessage,
  ContainerDiv,
  InputBox,
  SelectBox,
  PrimaryButton,
  SecondaryButton,
  SubmitButtonFormGroup,
} from "../../pages/login";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Alert from "react-bootstrap/Alert";
import FormCheck from "react-bootstrap/FormCheck";
import FormGroup from "react-bootstrap/FormGroup";
import { app_api } from "../../atoms/api";
import { Formik } from "formik";
import { Links } from "../sign-in";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import {
  useGAEventAlreadyAMemberLogin,
  useGAEventSignUp,
} from "../../hoc/hooks/useGAEventTracker";

const TermsAndConditions = styled(Links)`
  color: inherit;
`;

export const RegisterUser = (props) => {
  const GAEventSignUp = useGAEventSignUp();

  const [registerLoading, setRegisterLoading] = useState(false);
  const [registerError, setRegisterError] = useState(null);
  let navigate = useNavigate();
  const SignUp = () => {
    props.switchSection("signin");
    GAEventSignUp();
  };

  return (
    <ContainerDiv
      className="flex-column justify-content-start position-absolute col-lg-11 col-12 col-xl-10"
      {...props}
      style={{ zIndex: "5" }}
    >
      <ChangeScreenMessage>
        Already&nbsp;have&nbsp;an&nbsp;account?{" "}
        <span onClick={() => props.switchSection("signin")}>Sign&nbsp;in</span>{" "}
        instead
      </ChangeScreenMessage>
      {/* //To-Do Remove GAEvent */}
      <Formik
        validationSchema={registerSchema}
        onSubmit={(values) => {
          if (!values.date_of_birth) delete values.date_of_birth;
          setRegisterLoading(true);
          app_api
            .post("user", values)
            .then((res) => {
              app_api
                .post("auth/login", {
                  email_id: values.email_id,
                  password: values.password,
                })
                .then((res) => {
                  Cookie.set("ut_user", JSON.stringify(res["data"]));
                  navigate(
                    props.go_back
                      ? -1
                      : props.next_to
                      ? `/programs?program_id=${props.next_to}`
                      : "/dashboard/parent"
                  );
                })
                .catch((err) => {
                  setRegisterLoading(false);
                  setRegisterError(err["response"]["data"]["message"]);
                });
            })
            .catch((err) => {
              setRegisterLoading(false);
              setRegisterError(err["response"]["data"]["message"]);
            });
        }}
        initialValues={{
          first_name: "",
          last_name: "",
          date_of_birth: "",
          gender: "",
          location: "Maharashtra",
          email_id: "",
          password: "",
          cPassword: "",
          terms: false,
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          values,
          touched,
          isValid,
          errors,
        }) => (
          <Form
            className="mt-5 pb-5 pb-lg-0 mb-4 mb-lg-0"
            noValidate
            onSubmit={handleSubmit}
          >
            {registerError !== null && (
              <Alert
                variant="danger"
                dismissible={true}
                onClose={() => setRegisterError(null)}
              >
                {registerError}
              </Alert>
            )}
            <Row className="g-2 mb-2">
              <Col md>
                <FloatingLabel
                  controlId="register.first_name"
                  label="First name"
                >
                  {/* {console.log(errors)} */}
                  <InputBox
                    placeholder="First name"
                    name="first_name"
                    value={values.first_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                    type="text"
                    isValid={touched.first_name && !errors.first_name}
                    isInvalid={touched.first_name && !!errors.first_name}
                  />
                  {touched.first_name && (
                    <FormControl.Feedback
                      type="invalid"
                      controlId="register.first_name"
                    >
                      {errors.first_name}
                    </FormControl.Feedback>
                  )}
                </FloatingLabel>
              </Col>
              <Col md>
                <FloatingLabel controlId="register.last_name" label="Last name">
                  <InputBox
                    placeholder="Doe"
                    name="last_name"
                    value={values.last_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                    type="text"
                    isValid={touched.last_name && !errors.last_name}
                    isInvalid={touched.last_name && !!errors.last_name}
                  />
                  {touched.last_name && (
                    <FormControl.Feedback
                      type="invalid"
                      controlId="register.last_name"
                    >
                      {errors.last_name}
                    </FormControl.Feedback>
                  )}
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="g-2 mb-2 d-flex align-items-center">
              {/* <Col md>
                <FloatingLabel
                  controlId="register.date_of_birth"
                  label="Date of birth"
                >
                  <InputBox
                    type="date"
                    placeholder="D.O.B."
                    name="date_of_birth"
                    value={values.date_of_birth}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.date_of_birth && !errors.date_of_birth}
                    isInvalid={touched.date_of_birth && !!errors.date_of_birth}
                  />
                  {touched.date_of_birth && (
                    <FormControl.Feedback
                      type="invalid"
                      controlId="register.date_of_birth"
                    >
                      {errors.date_of_birth}
                    </FormControl.Feedback>
                  )}
                </FloatingLabel>
              </Col> */}
              <Col md>
                <FormCheck
                  className="my-auto"
                  id="M"
                  type="radio"
                  label="Male"
                  value="M"
                  name="gender"
                  inline={true}
                  required={true}
                  checked={values.gender === "M"}
                  onChange={handleChange}
                  isInvalid={!!errors.gender}
                />
                <FormCheck
                  type="radio"
                  label="Female"
                  id="F"
                  value="F"
                  name="gender"
                  inline={true}
                  required={true}
                  checked={values.gender === "F"}
                  onChange={handleChange}
                  isInvalid={!!errors.gender}
                />
                <FormCheck
                  type="radio"
                  label="Other"
                  id="Other"
                  value="O"
                  name="gender"
                  inline={true}
                  required={true}
                  checked={values.gender === "O"}
                  onChange={handleChange}
                  isInvalid={!!errors.gender}
                />
              </Col>
              {errors.gender && (
                <span
                  className="invalid-feedback"
                  style={{
                    display: "block",
                  }}
                >
                  {errors.gender}
                </span>
              )}
            </Row>
            <Row className="g-2 mb-2">
              <FloatingLabel controlId="register.email_id" label="Email">
                <InputBox
                  type="email"
                  placeholder="Email"
                  autoComplete="email"
                  name="email_id"
                  value={values.email_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required={true}
                  isValid={touched.email_id && !errors.email_id}
                  isInvalid={touched.email_id && !!errors.email_id}
                />
                {touched.email_id && (
                  <FormControl.Feedback type="invalid">
                    {errors.email_id}
                  </FormControl.Feedback>
                )}
              </FloatingLabel>
            </Row>
            <Row className="g-2 mb-2">
              <Col md>
                <FloatingLabel
                  controlId="register.password"
                  label="Set password"
                >
                  <InputBox
                    type="password"
                    placeholder="Set password"
                    autoComplete="new-password"
                    name="password"
                    value={values.password}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                    isValid={touched.password && !errors.password}
                    isInvalid={touched.password && !!errors.password}
                  />
                  {touched.password && (
                    <FormControl.Feedback type="invalid">
                      {errors.password}
                    </FormControl.Feedback>
                  )}
                </FloatingLabel>
              </Col>
              <Col md>
                <FloatingLabel
                  controlId="register.confirm-password"
                  label="Confirm password"
                >
                  <InputBox
                    type="password"
                    placeholder="Confirm password"
                    autoComplete="new-password"
                    name="cPassword"
                    value={values.cPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.cPassword && !errors.cPassword}
                    isInvalid={touched.cPassword && !!errors.cPassword}
                    required={true}
                  />
                  {touched.cPassword && (
                    <FormControl.Feedback type="invalid">
                      {errors.cPassword}
                    </FormControl.Feedback>
                  )}
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="g-2 mb-2">
              <Col md="6">
                <FloatingLabel controlId="register.location" label="Location">
                  <SelectBox
                    type="text"
                    name="location"
                    placeholder="Location"
                    value={values.location}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.location && !errors.location}
                    isInvalid={touched.location && !!errors.location}
                  >
                    <option value="Outside India">Outside India</option>
                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                    <option value="Andaman and Nicobar Islands">
                      Andaman and Nicobar Islands
                    </option>
                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option value="Assam">Assam</option>
                    <option value="Bihar">Bihar</option>
                    <option value="Chandigarh">Chandigarh</option>
                    <option value="Chhattisgarh">Chhattisgarh</option>
                    <option value="Dadar and Nagar Haveli">
                      Dadar and Nagar Haveli
                    </option>
                    <option value="Daman and Diu">Daman and Diu</option>
                    <option value="Delhi">Delhi</option>
                    <option value="Lakshadweep">Lakshadweep</option>
                    <option value="Puducherry">Puducherry</option>
                    <option value="Goa">Goa</option>
                    <option value="Gujarat">Gujarat</option>
                    <option value="Haryana">Haryana</option>
                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                    <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                    <option value="Jharkhand">Jharkhand</option>
                    <option value="Karnataka">Karnataka</option>
                    <option value="Kerala">Kerala</option>
                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                    <option value="Maharashtra">Maharashtra</option>
                    <option value="Manipur">Manipur</option>
                    <option value="Meghalaya">Meghalaya</option>
                    <option value="Mizoram">Mizoram</option>
                    <option value="Nagaland">Nagaland</option>
                    <option value="Odisha">Odisha</option>
                    <option value="Punjab">Punjab</option>
                    <option value="Rajasthan">Rajasthan</option>
                    <option value="Sikkim">Sikkim</option>
                    <option value="Tamil Nadu">Tamil Nadu</option>
                    <option value="Telangana">Telangana</option>
                    <option value="Tripura">Tripura</option>
                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                    <option value="Uttarakhand">Uttarakhand</option>
                    <option value="West Bengal">West Bengal</option>
                  </SelectBox>
                  {touched.location && (
                    <FormControl.Feedback type="invalid">
                      {errors.location}
                    </FormControl.Feedback>
                  )}
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="g-2 mb-2">
              <FormCheck type="checkbox">
                <FormCheck.Input
                  type="checkbox"
                  name="terms"
                  className="me-2"
                  value={values.terms}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.terms && !errors.terms}
                  isInvalid={touched.terms && !!errors.terms}
                  required={true}
                ></FormCheck.Input>
                <FormCheck.Label>
                  By signing in you agree to our{" "}
                  <TermsAndConditions
                    href="/terms-and-conditions"
                    target="_blank"
                  >
                    terms and conditions
                  </TermsAndConditions>{" "}
                  and{" "}
                  <TermsAndConditions href="/privacy-policy" target="_blank">
                    privacy policy
                  </TermsAndConditions>
                  .
                </FormCheck.Label>
                {touched.terms && (
                  <FormControl.Feedback type="invalid">
                    {errors.terms}
                  </FormControl.Feedback>
                )}
              </FormCheck>
            </Row>
            <SubmitButtonFormGroup className="d-flex flex-column align-items-lg-start flex-sm-row mt-2">
              <PrimaryButton
                variant="primary"
                type="submit"
                className="text-dark fw-semibold"
                disabled={registerLoading}
              >
                {registerLoading
                  ? [
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />,
                      " Submitting...",
                    ]
                  : "Submit"}
              </PrimaryButton>
              <SecondaryButton variant="secondary" onClick={SignUp}>
                Cancel
              </SecondaryButton>
            </SubmitButtonFormGroup>
          </Form>
        )}
      </Formik>
    </ContainerDiv>
  );
};

RegisterUser.propTypes = {
  switchSection: pt.func.isRequired,
  show: pt.bool.isRequired,
  go_back: pt.bool,
  next_to: pt.string,
};
