import React from "react";
import pt from "prop-types";
import { UtImg } from ".";

import HappyBadge from "../../assets/images/happyBadge.png";
import SmartBadge from "../../assets/images/smartBadge.png";
import AmazingBadge from "../../assets/images/amazingBadge.png";
import ExpertBadge from "../../assets/images/expertBadge.png";
import ChampionBadge from "../../assets/images/championBadge.png";

export const Badge = (props) => {
  let Image = HappyBadge;
  if (props.score > 80) Image = ChampionBadge;
  else if (props.score > 60) Image = ExpertBadge;
  else if (props.score > 40) Image = AmazingBadge;
  else if (props.score > 20) Image = SmartBadge;
  return (
    <UtImg
      imported
      key={props.score}
      src={Image}
      alt={`badge_score_${props.score}`}
      width={props.notResponse ? "100%" : "300px"}
      height={props.notResponse ? "" : "300px"}
    />
  );
};

// Badge.proptypes = {
//   score: pt.number.isRequired,
// };
