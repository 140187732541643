import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  faChartBar,
  faHome,
  faUser,
  faListUl,
  faCheck,
  faLock,
  faUnlock,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import avatar from "../../assets/images/avatar_cat.png";
import email_icon from "../../assets/images/myProfile_icon.png";
import { Badge } from "../../atoms/image/badge";
import OffCanvas from "react-bootstrap/Offcanvas";
import OffCanvasBody from "react-bootstrap/OffcanvasBody";
import OffCanvasHeader from "react-bootstrap/OffcanvasHeader";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { app_api } from "../../atoms/api";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import Cookie from "js-cookie";
export const LeftNavButton = styled(Button)`
  padding: 10px 18px;
  color: #274156;
  background-color: transparent;
  border-radius: 50px;
  border: none;

  :hover {
    background-color: #fff;
    color: #6bcad0;
  }

  :focus {
    border-color: none;
    box-shadow: none;
  }
`;

export const LeftNavContainer = styled(Container)`
  background-color: var(--bs-primary);
  height: 100vh;
  top: 1px;
`;

export const LevelListSingle = styled.div`
  cursor: pointer;
  :hover {
    background-color: lightgray;
  }
`;

export const ProgramSideNav = (props) => {
  const [showProfile, setShowProfile] = useState(false);
  const [showLevels, setShowLevels] = useState(false);
  const [showActivities, setShowActivities] = useState(false);
  const [actLoading, setActLoading] = useState(true);
  const [actError, setActError] = useState(null);
  const [activities, setActivities] = useState([]);

  const [isCompleted, setIsCompleted] = useState(false);
  let user = {};
  if (!!Cookie?.get("ut_user")) {
    user = JSON?.parse(Cookie?.get("ut_user"));
  }
  const navigate = useNavigate();

  const handleProfile = () => setShowProfile(!showProfile);
  const handleLevels = () => setShowLevels(!showLevels);
  const handleActivities = () => setShowActivities(!showActivities);

  useEffect(() => {
    app_api
      .get(`user-activity/enrollment/${props.enrollmentId}`)
      .then((res) => {
        setActLoading(false);
        setActivities(res.data);
      })
      .catch((err) => {
        setActLoading(false);
        setActError(
          err?.response?.data?.message || "Error fetching activities"
        );
      });
  }, [props.enrollmentId]);

  useEffect(() => {
    setIsCompleted(props.enrollment?.current_level_name === "Completed");
  }, [props.enrollment?.current_level_name]);

  const LeftNavItems = () => {
    const isTeacher = user.role === "teacher";
    return [
      !isTeacher && (
        <LeftNavButton
          key="user"
          onClick={handleProfile}
          className="mb-md-4 d-flex flex-column align-items-center"
        >
          <FontAwesomeIcon
            icon={faUser}
            style={{ fontSize: "22px" }}
            className="poppins"
          />
          <span className="poppins ps-2" style={{ fontSize: "14px" }}>
            My Profile
          </span>
        </LeftNavButton>
      ),
      // !isTeacher && (
      //   <LeftNavButton
      //     key="levels"
      //     onClick={handleLevels}
      //     className="mb-md-4 d-flex align-items-center"
      //   >
      //     <FontAwesomeIcon
      //       icon={faListUl}
      //       size="2x"
      //       className="poppins"
      //       style={{ fontSize: "22px" }}
      //     />
      //     <span className="poppins ps-2" style={{ fontSize: "14px" }}>
      //       Levels
      //     </span>
      //   </LeftNavButton>
      // ),
      !isTeacher && (
        <LeftNavButton
          key="activities"
          onClick={handleActivities}
          className="mb-md-auto d-flex flex-column align-items-center"
        >
          <FontAwesomeIcon
            icon={faChartBar}
            size="2x"
            className="poppins"
            style={{ fontSize: "22px" }}
          />
          <span className="poppins ps-2" style={{ fontSize: "14px" }}>
            Scoreboard
          </span>
        </LeftNavButton>
      ),

      <LeftNavButton
        key="home"
        className="d-flex flex-column align-items-center"
        onClick={() =>
          navigate(
            user.role === "teacher"
              ? "/dashboard/teacher"
              : "/dashboard/learner"
          )
        }
      >
        <FontAwesomeIcon
          icon={faHome}
          size="2x"
          className="poppins"
          style={{ fontSize: "22px" }}
        />{" "}
        Home
      </LeftNavButton>,
    ];
  };

  const selectLevel = (levelid, currentIndex, index) => {
    console.log(levelid);
    if (user.role === "teacher") {
      navigate(`/dashboard/program/${props.enrollmentId}/previous/${levelid}`);
    } else {
      if (index < currentIndex) {
        navigate(
          `/dashboard/program/${props.enrollmentId}/previous/${levelid}`
        );
      } else if (index === currentIndex) {
        navigate(`/dashboard/program/${props.enrollmentId}`);
      }
    }
  };

  const goToCompletionLevel = (stage) => {
    if (user.role === "teacher") {
      navigate(`/dashboard/program/${props.enrollmentId}/previous/${stage}`);
    } else {
      if (isCompleted) {
        navigate(`/dashboard/program/${props.enrollmentId}/success/${stage}`);
        handleLevels();
      }
    }
  };

  return (
    <>
      {props.navbar ? (
        <Navbar bg="light" expand={false} className="d-md-none p-0 mb-3 ">
          <Container fluid={true}>{LeftNavItems()}</Container>
        </Navbar>
      ) : (
        <Col xs="1" className="d-none d-md-flex p-0 w-20">
          <LeftNavContainer
            fluid={true}
            className="overflow-auto rounded-end position-sticky px-0"
          >
            <div
              className="pt-3 position-sticky d-flex flex-wrap align-items-start justify-content-evenly "
              style={{
                backgroundColor: "#6bcad0",
                top: "0",
                boxShadow: "rgb(119 119 119) 0px 6px 8px -6px",
              }}
            >
              {LeftNavItems()}
            </div>
            <div className="w-100 ">
              {props.loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : !!props.error ? (
                <label className="text-danger text-capitalize">
                  {props.error}
                </label>
              ) : (
                props.program?.program?.levels
                  .sort(
                    (a, b) =>
                      props.program?.program?.level_sequence.indexOf(a.id) -
                      props.program?.program?.level_sequence.indexOf(b.id)
                  )
                  .filter(
                    (l) => user.role === "teacher" && l.type !== "checkpoint"
                  )
                  .concat(
                    props.program?.program?.levels.filter(
                      (l) => user.role !== "teacher"
                    )
                  )
                  .map((l, index) => (
                    <LevelListSingle
                      className="d-flex flex-row px-4 py-3 align-items-center rounded"
                      key={index}
                      onClick={() =>
                        selectLevel(
                          l.id,
                          props.enrollment?.is_school &&
                            props.enrollment?.school_type === "hybrid" &&
                            props.enrollment?.current_level >
                              props.enrollment?.lockedAt
                            ? props.enrollment?.lockedAt
                            : props.enrollment?.current_level,
                          index
                        )
                      }
                    >
                      <div
                        className="rounded-circle"
                        style={{
                          backgroundColor: "#F3F6F9",
                          padding: "10px 17px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={
                            user.role === "teacher"
                              ? faUnlock
                              : props.enrollment?.is_school &&
                                props.enrollment?.school_type === "hybrid"
                              ? index < props.enrollment?.current_level
                                ? faUnlock
                                : index > props.enrollment?.current_level
                                ? faLock
                                : props.enrollment?.current_level >
                                  props.enrollment?.lockedAt
                                ? faLock
                                : faUnlock
                              : index === props.enrollment?.current_level // "#F64E60" // lock  "#FFA800" //unlock    // "#376568" // check
                              ? faCheck
                              : index > props.enrollment?.current_level
                              ? faLock
                              : faUnlock
                          }
                          style={{
                            color:
                              user.role === "teacher"
                                ? "#FFA800"
                                : props.enrollment?.is_school &&
                                  props.enrollment?.school_type === "hybrid"
                                ? index < props.enrollment?.current_level
                                  ? "#FFA800"
                                  : index > props.enrollment?.current_level
                                  ? "#F64E60"
                                  : props.enrollment?.current_level >
                                    props.enrollment?.lockedAt
                                  ? "#F64E60"
                                  : "#FFA800"
                                : index === props.enrollment?.current_level // "#F64E60" // lock  "#FFA800" //unlock    // #376568 // check
                                ? "#376568"
                                : index > props.enrollment?.current_level
                                ? "#F64E60"
                                : "#FFA800",
                          }}
                        ></FontAwesomeIcon>
                      </div>
                      <p className="ms-3 mb-0 text-capitalize">{l.title}</p>
                    </LevelListSingle>
                  ))
                  .concat(
                    user.role !== "teacher" && [
                      <LevelListSingle
                        className="d-flex flex-row px-4 py-3 align-items-center rounded"
                        key="coupon"
                        onClick={() => goToCompletionLevel("coupon")}
                      >
                        <div
                          className="rounded-circle"
                          style={{
                            backgroundColor: "#F3F6F9",
                            padding: "10px 17px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={isCompleted ? faUnlock : faLock}
                            style={{
                              color: isCompleted ? "#FFA800" : "#F64E60",
                            }}
                          ></FontAwesomeIcon>
                        </div>
                        <p className="ms-3 mb-0 text-capitalize">
                          Coupons and Child certificate
                        </p>
                      </LevelListSingle>,
                      <LevelListSingle
                        className="d-flex flex-row px-4 py-3 align-items-center rounded"
                        key="survey"
                        onClick={() => goToCompletionLevel("survey")}
                      >
                        <div
                          className="rounded-circle"
                          style={{
                            backgroundColor: "#F3F6F9",
                            padding: "10px 17px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={isCompleted ? faUnlock : faLock}
                            style={{
                              color: isCompleted ? "#FFA800" : "#F64E60",
                            }}
                          ></FontAwesomeIcon>
                        </div>
                        <p className="ms-3 mb-0 text-capitalize">
                          Survey and Parent certificate
                        </p>
                      </LevelListSingle>,
                    ]
                  )
              )}
            </div>
          </LeftNavContainer>
        </Col>
      )}
      <OffCanvas show={showProfile} onHide={handleProfile}>
        <OffCanvasHeader className=" mt-4 mx-4 " closeButton={true}>
          <h3 className="fs-5 mb-0">My Profile</h3>
        </OffCanvasHeader>
        <OffCanvasBody>
          <div className="px-5 py-3 d-flex flex-column my-3">
            <div className="col-6 d-flex flex-row align-items-center">
              <img className="w-60 rounded me-3" src={avatar} alt="avatar" />
              <div className="col-8">
                <span>{props.enrollment?.learner?.username}</span>
                <Link to="/logout">
                  <button
                    type="button "
                    className="btn btn-danger rounded fw-bold col-12 p-2"
                    style={{
                      backgroundColor: "#FFE2E5",
                      color: "#F64E60",
                      border: "none",
                    }}
                  >
                    Log Out
                  </button>
                </Link>
              </div>
            </div>
            <hr />
            <div className="col-12 my-3">
              <div className="d-flex flex-row align-items-center">
                <img
                  className="w-15 rounded me-3"
                  src={email_icon}
                  alt="email"
                />
                <div className="d-flex flex-column justify-content-start">
                  <p className="fs-7 mb-0" style={{ fontSize: "13px" }}>
                    Guardian Email
                  </p>
                  <span className="text-gray">
                    {props.enrollment?.learner?.parent?.email_id}
                  </span>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </OffCanvasBody>
      </OffCanvas>
      <OffCanvas show={showActivities} onHide={handleActivities}>
        <OffCanvasHeader className="mt-4 mx-4" closeButton={true}>
          <h3 className="fs-5">
            Activites{" "}
            <span style={{ fontSize: "12px", color: "#B5B5C3" }}>
              Action &amp; Reports
            </span>
          </h3>
        </OffCanvasHeader>
        <OffCanvasBody>
          {actLoading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : !!actError ? (
            <span className="text-danger">{actError}</span>
          ) : (
            activities.map((a, index) => (
              <div
                key={index}
                className=" d-flex flex-row mx-4 my-3 p-3 align-items-center rounded"
                style={{ backgroundColor: "#FFF4DE " }}
              >
                <div className="col-2">
                  <Badge score={a?.score} notResponse />
                </div>
                <div className="col-8">
                  <h4 className="fs-6 ">{a?.level?.title} Quiz</h4>
                  <p
                    className="mb-0 "
                    style={{ fontSize: "13px", color: "#B5B5C3" }}
                  >
                    Badge Earned -{" "}
                    {a?.score > 80
                      ? "Champion"
                      : a?.score > 60
                      ? "Expert"
                      : a?.score > 40
                      ? "Advanced"
                      : "Smart"}{" "}
                    Badge
                  </p>
                </div>
                <div>
                  <h4 style={{ color: "#FFA800", fontSize: "20px" }}>
                    {a?.is_faceoff
                      ? a?.score >= 0
                        ? "You won"
                        : "Your parent won"
                      : `${a?.score} %`}
                  </h4>
                </div>
              </div>
            ))
          )}
        </OffCanvasBody>
      </OffCanvas>
    </>
  );
};
