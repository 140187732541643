import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import CardImg from "react-bootstrap/CardImg";
import Button from "react-bootstrap/Button";
import pdf_img from "../../assets/images/pdf1.png";
import pdf_img3 from "../../assets/images/thumbnail.png";

export const Resources = (props) => {
  const [pdfLink, setPdfLink] = useState("");

  const handleClose = () => setPdfLink("");

  document.oncontextmenu = function () {
    return false;
  };
  return (
    <Container className="py-3">
      <Modal
        show={!!pdfLink}
        animation={true}
        onHide={handleClose}
        size="lg"
        fullscreen={true}
        centered={true}
      >
        <ModalHeader closeButton={true}></ModalHeader>
        <ModalBody>
          <iframe
            title="free-resource-pdf"
            src={pdfLink + "#toolbar=0"}
            width="100%"
            height="100%"
          />
        </ModalBody>
      </Modal>
      <Row className="g-3">
        <Col md="4">
          <Card>
            <CardImg variant="top p-1" src={pdf_img} />
            <hr className="my-1"></hr>
            <Card.Body className="align-self-center">
              <Button
                className="rounded-3 text-white"
                onClick={() =>
                  setPdfLink(
                    "https://test.api.untaboo.online/uploads/pdf/ppts_pdf/Top_5_embarrasing_questions_kids_ask.pdf"
                  )
                }
                variant="primary"
              >
                Read Online
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <CardImg variant="top p-1" src={pdf_img3} />
            <hr className="my-1"></hr>
            <Card.Body className="align-self-center">
              <Button
                className="rounded-3 text-white"
                onClick={() =>
                  setPdfLink(
                    "https://test.api.untaboo.online/uploads/pdf/ppts_pdf/Info_on_pads.pdf"
                  )
                }
                variant="primary"
              >
                Read Online
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
