import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { SignIn } from "../../templates/sign-in";
import { RegisterUser } from "../../templates/register";
import { LearnerLogin } from "../../templates/learnerLogin";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import FormSelect from "react-bootstrap/FormSelect";
import FormGroup from "react-bootstrap/FormGroup";
import Cookies from "js-cookie";
import { Link, Navigate } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import signUp from "../../assets/images/sign_up.png";
import { SchoolSignIn } from "../../templates/schoolSignIn";
import { TeacherSignIn } from "../../templates/teachersignin";
import { app_api } from "../../atoms/api";

const LoginLandingDiv = styled.div`
  background-color: #f69494;
`;

const LoginLandingMessage = styled.h3`
  color: #0f3d67;
  font-weight: 600;
`;

export const ChangeScreenMessage = styled.span`
  color: #000;
  font-weight: 500;

  > span {
    color: #0f3d67;
    cursor: pointer;
    text-decoration: underline;
    font-weight: 600;

    :hover {
      color: #6bcacf;
      text-decoration: none;
    }
  }
`;

export const ContainerDiv = styled.div`
  opacity: 0;
  height: 0px;
  transition: 1s ease;
  top: -100vh;

  > * {
    visibility: hidden;
  }

  ${(props) =>
    props.show &&
    css`
      opacity: 1;
      top: 0;
      height: unset;

      > * {
        visibility: visible;
      }
    `}
`;

export const SubmitButtonFormGroup = styled(FormGroup)`
  row-gap: 1rem;
  column-gap: 1rem;
`;

export const InputBox = styled(FormControl)`
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #464e5f;
  padding: 1.2rem 1rem;
  border-radius: 0.85rem !important;

  ::placeholder {
    color: #b5b5c3;
  }
`;

export const SelectBox = styled(FormSelect)`
  background-color: #f3f6f9;
  border-color: #f3f6f9;
  color: #464e5f;
  padding: 1.2rem 1rem;
  border-radius: 0.85rem !important;

  ::placeholder {
    color: #b5b5c3;
  }
`;

export const PrimaryButton = styled(Button)`
  color: #000;
  font-weight: 500;
  box-shadow: none;

  :hover {
    color: #fff;
    background-color: #65b3b8;
    border-color: #65b3b8;
  }
`;

export const SecondaryButton = styled(Button)`
  color: #000;
  font-weight: 500;
  border-color: transparent;

  :hover {
    color: #ffffff;
    background-color: #6bcacf;
    border-color: transparent;
  }
`;

export const Login = (props) => {
  let url = new URL(document.URL);
  let auth_section = url.searchParams.get("auth");
  const [auth, setAuthFunc] = useState(auth_section || "register");
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [schoolLoading, setSchoolLoading] = useState(true);
  const [schools, setSchools] = useState([]);
  const [schoolError, setSchoolError] = useState(null);

  let go_back = url.searchParams.get("go_back") === "true";
  let next_to = url.searchParams.get("next_to");

  const setAuth = (section) => {
    // url.searchParams.set("auth", section);
    // window.location = url;
    setAuthFunc(section);
  };

  useEffect(() => {
    try {
      setIsLoggedIn(!!Cookies.get("ut_user"));
      app_api
        .get("school/public/all")
        .then((res) => res.data)
        .then((res) => {
          setSchools(res);
          setSchoolLoading(false);
        })
        .catch((err) => {
          setSchoolError(
            err?.response?.data?.message ||
              "Error getting schools. Please refresh"
          );
          setSchoolLoading(false);
        });
    } catch {
      setIsLoggedIn(false);
    }
  }, []);

  if (isLoggedIn) {
    return <Navigate to={go_back ? -1 : "/dashboard/parent"} />;
  } else {
    return (
      <div className="min-vh-100 d-lg-flex flex-row text-center text-lg-start poppins">
        <LoginLandingDiv className="d-none d-md-none d-lg-flex flex-column justify-content-center align-items-center col-4">
          <img className="w-30 text-center" src={logo} />
          <LoginLandingMessage className="text-center ">
            You are one click away
            <br />
            from unlocking a<br />
            lifetime of open conversations
            <br />
            with your child
          </LoginLandingMessage>
          <img className="w-70 text-center" src={signUp} />
        </LoginLandingDiv>

        <div className="col-lg-8 col-10 mx-auto pt-5 d-flex flex-column  align-items-center position-relative">
          <div className="col-12 col-md-8 mx-auto ps-md-5  pb-5 pb-lg-0">
            <h3 className="secondary-blue f-35" style={{ fontWeight: "600" }}>
              Welcome to UnTaboo
            </h3>
            <div className="position-relative">
              <RegisterUser
                switchSection={setAuth}
                show={auth === "register"}
                go_back={go_back}
                next_to={next_to}
              />

              <SignIn
                className="text-center text-lg-start"
                switchSection={setAuth}
                show={auth === "signin"}
                go_back={go_back}
                next_to={next_to}
              />
              <LearnerLogin show={auth === "learner"} switchSection={setAuth} />
              <SchoolSignIn
                switchSection={setAuth}
                show={auth === "school"}
                go_back={go_back}
              />
              <TeacherSignIn
                switchSection={setAuth}
                show={auth === "teacher"}
                go_back={go_back}
                loading={schoolLoading}
                error={schoolError}
                schools={schools}
              />
            </div>
          </div>
          <div
            className="w-100 d-none d-lg-flex  mx-auto d-flex flex-row justify-content-center mb-3"
            style={{ position: "fixed", bottom: "0", zIndex: "10" }}
          >
            <Link to="/">
              <h3 className="fs-5 secondary-blue pe-4">Home</h3>
            </Link>
            <Link to="/schools">
              <h3 className="fs-5 secondary-blue pe-4">UnTaboo For Schools</h3>
            </Link>
            <Link to="/contact">
              <h3 className="fs-5 secondary-blue pe-4">Contact</h3>
            </Link>
          </div>
        </div>
        <div
          className="w-100 d-flex d-lg-none  mx-auto d-flex flex-row justify-content-center r"
          style={{
            position: "fixed",
            bottom: "0",
            padding: "10px 0",
            backgroundColor: "rgb(246, 148, 148)",
            zIndex: "10",
          }}
        >
          <Link to="/" style={{ textDecoration: "none" }}>
            {" "}
            <h3 className="fs-5 secondary-blue pe-4">Home</h3>
          </Link>
          <Link to="/schools" style={{ textDecoration: "none" }}>
            {" "}
            <h3 className="fs-5 secondary-blue pe-4">UnTaboo For Schools</h3>
          </Link>
          <Link to="/contact" style={{ textDecoration: "none" }}>
            <h3 className="fs-5 secondary-blue ">Contact</h3>
          </Link>
        </div>
      </div>
    );
  }
};

Login.propTypes = {};
