import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { InfoCard } from "../../molecules/infoCard";
import {
  faChartLine,
  faChild,
  faFileDownload,
  faLaptop,
  faPlayCircle,
} from "@fortawesome/free-solid-svg-icons";
import { PurchasedPrograms } from "../../organisms/purchasedPrograms";
import { ReportAndActivity } from "../../organisms/report-and-activity";
import { Webinar } from "../../organisms/webinar";
import { ParentUpdate } from "../../organisms/parent-update";
import { Resources } from "../../organisms/resources";
import Cookies from "js-cookie";
import { Navigate, useNavigate } from "react-router-dom";
import { app_api } from "../../atoms/api";
import { LearnerSchema, NewLearnerSchema } from "../../atoms/schema";
import { Formik } from "formik";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import FormCheck from "react-bootstrap/FormCheck";
import InputGroup from "react-bootstrap/InputGroup";
import FormSelect from "react-bootstrap/FormSelect";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import styled from "styled-components";
import Button from "react-bootstrap/Button";

const InfoSpan = styled.span`
  font-size: 12px;
  color: gray;
`;

export const ParentDashboard = (props) => {
  const [info, setInfo] = useState("program");
  let user;
  const isLoggedIn = !!Cookies.get("ut_user");
  if (isLoggedIn) {
    user = JSON.parse(Cookies.get("ut_user"));
  }

  const [programLoading, setProgramLoading] = useState(true);
  const [programError, setProgramError] = useState(null);
  const [programs, setPrograms] = useState([]);
  const [unassignedProgramLoading, setUnassignedProgramLoading] =
    useState(true);
  const [unassignedProgramError, setUnassignedProgramError] = useState(null);
  const [unassignedPrograms, setUnassignedProgram] = useState([]);
  const [actLoading, setActLoading] = useState(true);
  const [actError, setActError] = useState(null);
  const [activities, setActivities] = useState([]);
  const [lLoading, setlLoading] = useState(false);
  const [lError, setlError] = useState(null);
  const [learnerLoading, setLearnerLoading] = useState(true);
  const [learnerError, setLearnerError] = useState(null);
  const [learners, setLearners] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    app_api
      .get(`enrollment/parent/${user?.id}`)
      .then((res) => {
        setProgramLoading(false);
        setPrograms(res.data);
      })
      .catch((err) => {
        setProgramLoading(false);
        setProgramError(
          err?.response?.data?.message || "Error fetching your enrollments"
        );
      });
    app_api
      .get(`enrollment-temp/parent/${user?.id}`)
      .then((res) => {
        setUnassignedProgramLoading(false);
        setUnassignedProgram(res.data);
      })
      .catch((err) => {
        setUnassignedProgramLoading(false);
        setUnassignedProgramError(
          err?.response?.data?.message ||
            "Error fetching your unassigned programs"
        );
      });
    app_api
      .get(`learner/parent/${user?.id}`)
      .then((res) => {
        setLearnerLoading(false);
        setLearners(res.data);
      })
      .catch((err) => {
        setLearnerLoading(false);
        setLearnerError(
          err?.response?.data?.message || "Error fetching learner data"
        );
      });
    app_api
      .get(`user-activity/parent/${user?.id}`)
      .then((res) => {
        setActLoading(false);
        setActivities(res.data);
      })
      .catch((err) => {
        {
          setActLoading(false);
          setActError(
            err?.response?.data?.message || "Error fetching activities data"
          );
        }
      });
  }, []);

  const goToLearner = (enrollmentId) => {
    let e = programs.find((p) => p.id === enrollmentId);
    app_api
      .post("learner/login", {
        email_id: user?.email,
        username: e.learner.username,
      })
      .then((res) => {
        Cookies.set("ut_user", JSON.stringify(res.data));
        navigate("/dashboard/learner");
      })
      .catch((err) => {});
  };

  const [showLearner, setShowLearner] = useState(false);
  const [tempEnrolmentId, setTempEnrolmentId] = useState(null);
  const [enrolling, setEnrolling] = useState(false);
  const [eError, seteError] = useState(null);

  const dismissEnrollError = () => seteError(null);
  const dismissLearnerError = () => setlError(null);

  const assignLearner = (temp_enrolmentId) => {
    setShowLearner(true);
    setTempEnrolmentId(temp_enrolmentId);
  };

  const closeLearner = () => {
    setShowLearner(false);
    setTempEnrolmentId(null);
    dismissEnrollError();
    dismissLearnerError();
  };

  if (user.role === "parent") {
    return (
      <Container className="poppins">
        <div className="d-none d-lg-flex justify-content-md-center flex-wrap  flex-md-nowrap poppins ">
          {programs.length === 0 ? (
            <>
              <InfoCard
                icon={faLaptop}
                info="program purchased"
                selected={info === "program"}
                onClick={() => setInfo("program")}
              />
              <InfoCard
                icon={faChartLine}
                info={`activity & reports`}
                selected={info === "activity"}
                onClick={() => setInfo("activity")}
              />
              <InfoCard
                icon={faChild}
                info="parent update"
                selected={info === "parent"}
                onClick={() => setInfo("parent")}
              />
            </>
          ) : (
            <>
              <InfoCard
                icon={faLaptop}
                info="program purchased"
                selected={info === "program"}
                onClick={() => setInfo("program")}
              />
              <InfoCard
                icon={faChartLine}
                info={`activity & reports`}
                selected={info === "activity"}
                onClick={() => setInfo("activity")}
              />
              <InfoCard
                icon={faChild}
                info="parent update"
                selected={info === "parent"}
                onClick={() => setInfo("parent")}
              />

              <InfoCard
                icon={faPlayCircle}
                info="live webinars"
                selected={info === "webinar"}
                onClick={() => setInfo("webinar")}
              />
              <InfoCard
                icon={faFileDownload}
                info="free resources"
                selected={info === "resources"}
                onClick={() => setInfo("resources")}
              />
            </>
          )}
        </div>
        <div className="d-flex d-md-flex d-lg-none justify-content-md-center flex-row  flex-md-nowrap poppins overflow-auto ">
          {programs.length === 0 && unassignedPrograms.length === 0 ? (
            <>
              <InfoCard
                icon={faLaptop}
                info="program purchased"
                selected={info === "program"}
                onClick={() => setInfo("program")}
              />
              <InfoCard
                icon={faChartLine}
                info={`activity & reports`}
                selected={info === "activity"}
                onClick={() => setInfo("activity")}
              />

              <InfoCard
                icon={faChild}
                info="parent update"
                selected={info === "parent"}
                onClick={() => setInfo("parent")}
              />
            </>
          ) : (
            <>
              <InfoCard
                icon={faLaptop}
                info="program purchased"
                selected={info === "program"}
                onClick={() => setInfo("program")}
              />
              <InfoCard
                icon={faChartLine}
                info={`activity & reports`}
                selected={info === "activity"}
                onClick={() => setInfo("activity")}
              />
              <InfoCard
                icon={faChild}
                info="parent update"
                selected={info === "parent"}
                onClick={() => setInfo("parent")}
              />
              <InfoCard
                icon={faPlayCircle}
                info="live webinars"
                selected={info === "webinar"}
                onClick={() => setInfo("webinar")}
              />
              <InfoCard
                icon={faFileDownload}
                info="free resources"
                selected={info === "resources"}
                onClick={() => setInfo("resources")}
              />
            </>
          )}
        </div>
        {info === "program" && (
          <PurchasedPrograms
            user={user}
            loading={programLoading}
            error={programError}
            programs={programs}
            uLoading={unassignedProgramLoading}
            uError={unassignedProgramError}
            unassignedPrograms={unassignedPrograms}
            goToLearner={goToLearner}
            assignLearner={assignLearner}
          />
        )}
        {info === "activity" && (
          <ReportAndActivity
            loading={actLoading}
            error={actError}
            activities={activities}
          />
        )}
        {info === "webinar" && <Webinar />}
        {info === "resources" && <Resources />}
        {info === "parent" && (
          <ParentUpdate
            loading={learnerLoading}
            error={learnerError}
            learners={learners}
          />
        )}
        <Modal show={showLearner} className="enrollpfb" onHide={closeLearner}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Create a learner</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {eError !== null && (
              <Alert
                variant="danger"
                dismissible={true}
                onClose={dismissEnrollError}
              >
                {eError}
              </Alert>
            )}
            {lError !== null && (
              <Alert
                variant="danger"
                dismissible={true}
                onClose={dismissLearnerError}
              >
                {lError}
              </Alert>
            )}
            <Formik
              initialValues={{
                first_name: "",
                last_name: user?.last_name || "",
                gender: "",
                date_of_birth: "",
                parent_id: user?.id,
                username: "",
                password: "",
              }}
              validationSchema={NewLearnerSchema}
              onSubmit={(values) => {
                setlLoading(true);
                app_api
                  .post("learner", values)
                  .then((res) => {
                    setlLoading(false);
                    setEnrolling(true);
                    let learner = learners;
                    learner.push(res.data);
                    setLearners(learner);
                    app_api
                      .post("enrollment-temp/assign", {
                        temp_enrolment_id: tempEnrolmentId,
                        learner_id: parseInt(res.data.id),
                      })
                      .then((res) => {
                        setEnrolling(false);
                        let p = programs;
                        let up = unassignedPrograms;
                        p.push(res.data);
                        up = up.filter((p) => p.id !== tempEnrolmentId);
                        setPrograms(p);
                        setUnassignedProgram(up);
                      })
                      .catch((err) => {
                        setEnrolling(false);
                        seteError(
                          `Enrollment error: ${err?.response?.data?.message}` ||
                            "Enrollment error: Error"
                        );
                      });
                  })
                  .catch((err) => {
                    setlLoading(false);
                    setlError(
                      `Adding learner error: ${err?.response?.data?.message}` ||
                        "Adding learner error: Error"
                    );
                  });
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setValues,
                touched,
                values,
                errors,
                isValid,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit} className="">
                  <Row className="mb-2 g-2">
                    <Col md>
                      <FloatingLabel
                        controlId="add-learner.first_name"
                        label="First name"
                      >
                        <FormControl
                          type="text"
                          name="first_name"
                          placeholder="First name"
                          required={true}
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.first_name && !errors.first_name}
                          isInvalid={touched.first_name && !!errors.first_name}
                        />
                        {touched.first_name && (
                          <FormControl.Feedback type="invalid">
                            {errors.first_name}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel
                        controlId="add-learner.last_name"
                        label="Last name"
                      >
                        <FormControl
                          type="text"
                          name="last_name"
                          placeholder="Last name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.last_name && !errors.last_name}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="mb-2 g-2">
                    <Col md>
                      <FloatingLabel
                        controlId="add-learner.date_of_birth"
                        label="Date of birth"
                      >
                        <FormControl
                          type="date"
                          name="date_of_birth"
                          placeholder="Date of birth"
                          required={true}
                          value={values.date_of_birth}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={
                            touched.date_of_birth && !errors.date_of_birth
                          }
                          isInvalid={
                            touched.date_of_birth && !!errors.date_of_birth
                          }
                        />
                        {touched.date_of_birth && (
                          <FormControl.Feedback type="invalid">
                            {errors.date_of_birth}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FormGroup>
                        <FormCheck
                          type="radio"
                          name="gender"
                          label="Female"
                          value="F"
                          inline={true}
                          checked={values.gender === "F"}
                          required={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.gender && !errors.gender}
                          isInvalid={touched.gender && !!errors.gender}
                        />
                        <FormCheck
                          type="radio"
                          name="gender"
                          label="Male"
                          value="M"
                          inline={true}
                          checked={values.gender === "M"}
                          required={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.gender && !errors.gender}
                          isInvalid={touched.gender && !!errors.gender}
                        />
                        <FormCheck
                          type="radio"
                          name="gender"
                          label="Other"
                          value="O"
                          inline={true}
                          checked={values.gender === "O"}
                          required={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.gender && !errors.gender}
                          isInvalid={touched.gender && !!errors.gender}
                        />
                        {touched.gender && (
                          <FormControl.Feedback type="invalid">
                            {errors.gender}
                          </FormControl.Feedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mb-2 g-2">
                    <Col md>
                      <FloatingLabel
                        controlId="add-learner.username"
                        label="Set username"
                      >
                        <FormControl
                          type="text"
                          name="username"
                          placeholder="Set username"
                          required={true}
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.username && !errors.username}
                          isInvalid={touched.username && !!errors.username}
                        />
                        {touched.username && (
                          <FormControl.Feedback type="invalid">
                            {errors.username}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel
                        controlId="add-learner.password"
                        label="Set password"
                      >
                        <FormControl
                          type="password"
                          name="password"
                          placeholder="Set password"
                          autoComplete="new-password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.password && !errors.password}
                          isInvalid={touched.password && !!errors.password}
                        />
                        {touched.password && (
                          <FormControl.Feedback type="invalid">
                            {errors.password}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Button
                      type="submit"
                      variant="primary"
                      className="text-black"
                      disabled={!isValid || enrolling || lLoading}
                    >
                      {enrolling || lLoading
                        ? [
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />,
                            enrolling ? " Enrolling..." : " Adding...",
                          ]
                        : "Add learner"}
                    </Button>
                  </Row>
                </Form>
              )}
            </Formik>
            <br></br>
            <span>
              <b>or, choose a learner</b>
            </span>
            <Formik
              initialValues={{
                learner_id: "",
                temp_enrolment_id: tempEnrolmentId,
              }}
              onSubmit={(values) => {
                setEnrolling(true);
                app_api
                  .post("enrollment-temp/assign", {
                    ...values,
                    learner_id: parseInt(values.learner_id),
                  })
                  .then((res) => {
                    setEnrolling(false);
                    let p = programs;
                    let up = unassignedPrograms;
                    p.push(res.data);
                    up = up.filter((p) => p.id !== tempEnrolmentId);
                    setPrograms(p);
                    setUnassignedProgram(up);
                  })
                  .catch((err) => {
                    setEnrolling(false);
                    seteError(
                      `Enrollment error: ${err?.response?.data?.message}` ||
                        "Enrollment error: Error"
                    );
                  });
              }}
              validationSchema={LearnerSchema}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                touched,
                errors,
                values,
                isValid,
              }) => (
                <Form
                  noValidate={true}
                  onSubmit={handleSubmit}
                  className="mb-4 d-flex flex-column justify-content-end"
                >
                  <InputGroup className="mb-2">
                    <InputGroup.Text>Learner</InputGroup.Text>
                    <FormSelect
                      name="learner_id"
                      value={values.learner_id}
                      required={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.learner_id && !errors.learner_id}
                      isInvalid={touched.learner_id && !!errors.learner_id}
                    >
                      <option value="">Select a learner</option>
                      {learners.map((l) => (
                        <option value={l?.id}>
                          {l?.first_name} {l?.last_name}
                        </option>
                      ))}
                    </FormSelect>
                    {touched.learner_id && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_id}
                      </FormControl.Feedback>
                    )}
                  </InputGroup>
                  <Button
                    type="submit"
                    variant="primary"
                    className="text-black"
                    disabled={!isValid || enrolling}
                  >
                    {enrolling
                      ? [
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />,
                          " Enrolling...",
                        ]
                      : "Select learner"}
                  </Button>
                </Form>
              )}
            </Formik>
            <ModalFooter>
              <Button type="button" variant="secondary" onClick={closeLearner}>
                Close
              </Button>
            </ModalFooter>
          </ModalBody>
        </Modal>
      </Container>
    );
  } else {
    return <Navigate to="/logout" />;
  }
};

ParentDashboard.propTypes = {};
