import React from "react";
import { useParams } from "react-router-dom";
import { ProgramLandingTemplate } from "../../templates/program-landing";
import { PFBLandingPage } from "../../templates/program-landing/puberty-for-boys";
import { PFGLandingPage } from "../../templates/program-landing/puberty-for-girls";
import { PFBAGLandingPage } from "../../templates/program-landing/puberty-for-boys-and-girls";

export const LandingPage = (props) => {
  document.title = "UnTaboo | Program";
  const param = useParams();
  if (param.slug === "puberty-for-boys")
    return <PFBLandingPage slug={param.slug} />;
  else if (param.slug === "puberty-for-girls")
    return <PFGLandingPage slug={param.slug} />;
  else if (param.slug === "puberty-adventure-for-boys-and-girls")
    return <PFBAGLandingPage slug={param.slug} />;
  else return <ProgramLandingTemplate slug={param.slug} />;
};
