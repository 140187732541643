import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Spinner from "react-bootstrap/Spinner";
import { faLock, faUnlock, faHome } from "@fortawesome/free-solid-svg-icons";
import { useParams, useNavigate } from "react-router-dom";
import { app_api } from "../../atoms/api";
import { Sections } from "./sections";
import {
  LeftNavButton,
  LeftNavContainer,
  LevelListSingle,
} from "../../templates/programSideNav";
import BuyProgram from "../../assets/images/buyProgram.png";
import Button from "react-bootstrap/Button";
import styled from "styled-components";

const BuyContainer = styled.div`
  padding: 30px;
  .text {
    font-size: 20px;
    font-weight: 500;
  }
  @media (max-width: 768px) {
    padding: 15px;
    .text {
      font-size: 16px;
    }
  }
`;

export const Demo = (props) => {
  const [currentSection, setCurrentSection] = useState("watch");
  const [nextSection, setNextSection] = useState("learn");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [qLoading, setqLoading] = useState(false);
  const [qError, setqError] = useState(null);
  const [program, setProgram] = useState({});
  const [question, setQuestion] = useState({});
  const [levLoad, setLevLoad] = useState(true);
  const [levError, setLevError] = useState(null);
  const [levels, setLevels] = useState([]);
  const [hasLearn, setHasLearn] = useState(false);
  const [hasDiscuss, setHasDiscuss] = useState(false);
  const [buyToProceed, setBuyToProceed] = useState(false);

  const params = useParams();

  useEffect(() => {
    app_api
      .get(`programs/public/slug/${params.slug}/wld`)
      .then((res) => {
        setProgram(res.data);
        setLoading(false);
        if (res.data?.levels[0]?.watch?.length > 0) setCurrentSection("watch");
        else {
          if (res.data?.levels[0]?.learn?.length > 0)
            setCurrentSection("learn");
          else {
            if (res.data?.levels[0]?.discuss?.length > 0)
              setCurrentSection("discuss");
            else setCurrentSection("play");
          }
        }
        if (res.data?.levels[0]?.learn?.length > 0) setHasLearn(true);
        if (res.data?.levels[0]?.discuss?.length > 0) setHasDiscuss(true);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Error getting program ID");
      });
    app_api
      .get(`programs/public/slug/${params.slug}/play`)
      .then((res) => {
        setqLoading(false);
        setQuestion(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setqError(err?.response?.data?.message || "Error getting questions");
      });
    app_api
      .get(`programs/public/${params.slug}`)
      .then((res) => {
        setLevLoad(false);
        setLevels(res.data?.levels);
      })
      .catch((err) => {
        setLevLoad(false);
        setLevError(err?.response?.data?.message || "Error");
      });
  }, [params.slug]);

  const navigate = useNavigate();

  const onNextSectionClick = () => {
    if (currentSection === "watch") {
      if (hasLearn) {
        setCurrentSection("learn");
        setNextSection("discuss");
      } else if (hasDiscuss) {
        setCurrentSection("discuss");
        setNextSection("play");
      } else {
        setCurrentSection("play");
      }
    } else if (currentSection === "learn") {
      if (hasDiscuss) {
        setCurrentSection("discuss");
        setNextSection("play");
      } else {
        setCurrentSection("play");
      }
    } else if (currentSection === "discuss") {
      setCurrentSection("play");
    } else if (currentSection === "discuss") {
      setCurrentSection("play");
    }
  };

  const LeftNavItems = () => {
    return (
      <LeftNavButton key="home" className="" onClick={() => navigate(-1)}>
        <FontAwesomeIcon
          icon={faHome}
          size="2x"
          className="poppins"
          style={{ fontSize: "22px" }}
        />
        Home
      </LeftNavButton>
    );
  };

  return (
    <div>
      <Navbar bg="light" expand={false} className="d-md-none p-0 mb-3 ">
        <Container fluid={true}>{LeftNavItems()}</Container>
      </Navbar>
      <Row>
        <Col xs="1" className="d-none d-md-flex p-0 w-20">
          <LeftNavContainer
            fluid={true}
            className="overflow-auto rounded-end position-sticky px-0"
          >
            <div
              className=" w-100 py-3  position-sticky d-flex flex-wrap align-items-start justify-content-evenly "
              style={{
                backgroundColor: "#6bcad0",
                top: "0",
                boxShadow: "rgb(119 119 119) 0px 6px 8px -6px",
              }}
            >
              {LeftNavItems()}
            </div>
            <div>
              {levLoad ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : !!levError ? (
                <label className="text-danger text-capitalize">
                  {levError}
                </label>
              ) : (
                levels
                  .sort(
                    (a, b) =>
                      program?.level_sequence?.indexOf(a.id) -
                      program?.level_sequence?.indexOf(b.id)
                  )
                  .map((l, index) => (
                    <LevelListSingle
                      className="d-flex flex-row px-4 py-3 align-items-center rounded"
                      key={index}
                      onClick={
                        index === 1
                          ? () => setBuyToProceed(false)
                          : () => setBuyToProceed(true)
                      }
                    >
                      <div
                        className="rounded-circle"
                        style={{
                          backgroundColor: "#F3F6F9",
                          padding: "10px 17px",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={index === 1 ? faUnlock : faLock}
                          style={{
                            color: index === 1 ? "#FFA800" : "#F64E60",
                          }}
                        ></FontAwesomeIcon>
                      </div>
                      <p className="ms-3 mb-0 text-capitalize">{l.title}</p>
                    </LevelListSingle>
                  ))
              )}
            </div>
          </LeftNavContainer>
        </Col>
        <Col xs="12" className="p-0 mx-auto w-80">
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : !!error ? (
            <label className="text-danger text-capitalize">{error}</label>
          ) : buyToProceed ? (
            <BuyContainer>
              <h5>Not Included In Demo</h5>
              <center>
                <p className="text mt-5">
                  Purchase the full program to unlock the remaining Levels. Help
                  us make the world a little safer for our children. :){" "}
                </p>
                <Button
                  variant="primary"
                  className="text-white"
                  onClick={() => navigate(-1)}
                >
                  View Program Details
                </Button>
                <div>
                  <img src={BuyProgram} style={{ width: "100%" }} />
                </div>
                <p className="text">Buy full program to unlock this section.</p>
              </center>
            </BuyContainer>
          ) : (
            <Sections
              isDemo
              buyToProceed={setBuyToProceed}
              program={program?.levels?.[0]}
              enrollment={{ program: { directed_at: program?.directed_at } }}
              questions={question?.levels?.[0]}
              currentSection={currentSection}
              setCurrentSection={setCurrentSection}
              questionLoading={qLoading}
              questionError={qError}
              processClick={onNextSectionClick}
              nextSection={nextSection}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
