import React, { useEffect } from "react";
import styled, { css } from "styled-components";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamation } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

const IconContainer = styled.div`
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 200px;
  width: 200px;
  height: 200px;
  background: ${(props) => props.background};
  margin: 0 auto;
`;

export const Success = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/dashboard/parent");
    }, 3000);
  }, []);
  return (
    <div className="text-center px-5 h-100 poppins">
      <div className="card justify-content-center" style={{ height: "100vh" }}>
        <IconContainer background="#f8faf5">
          <FontAwesomeIcon
            icon={faCheck}
            size="7x"
            style={{
              color: "#9ABC66",
            }}
          ></FontAwesomeIcon>
        </IconContainer>
        <h1 className="secondary-blue mt-5 mb-4 ">Payment Successful</h1>
        <p className="fs-5 mb-4">
          We received your purchase request.
          <br />
          Go back to dashboard
        </p>
        <Link to="/dashboard/parent" redirect>
          <Button className="mt-4">Dashboard</Button>
        </Link>
      </div>
    </div>
  );
};

export const Failed = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="text-center px-5 h-100 poppins">
      <div className="card justify-content-center" style={{ height: "100vh" }}>
        <IconContainer background="rgb(250 245 247)">
          <FontAwesomeIcon
            icon={faExclamation}
            size="7x"
            style={{
              color: "rgb(188 102 102)",
            }}
          ></FontAwesomeIcon>
        </IconContainer>
        <h1 className="secondary-blue mt-5 mb-4 ">Payment Failed</h1>
        <p className="fs-5 mb-4">
          We were unable to process your payment
          <br />
          Please retry
        </p>
        <div
          className="d-flex justify-content-center mt-4"
          style={{ gap: "10px" }}
        >
          <Button onClick={goBack}>Go back</Button>
          <Link to="/dashboard/parent" redirect>
            <Button>Dashboard</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};
