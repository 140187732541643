import React from "react";
import { Formik } from "formik";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Spinner from "react-bootstrap/Spinner";
import { useParams, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect } from "react";
import { useState } from "react";
import { app_api } from "../../atoms/api";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GiftSchema } from "../../atoms/schema";

export const Gift = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  // let user
  // try {
  //   user = JSON.parse(Cookies.get('ut_user'));
  // } catch {
  //   // do nothing
  // }

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [program, setProgram] = useState({});
  const [user, setUser] = useState({});

  const [ctempLoading, setctempLoading] = useState(false);
  const [ctempError, setctempError] = useState(null);
  const [transLoading, setTransLoading] = useState(false);
  const [transError, setTransError] = useState(null);

  useEffect(() => {
    app_api
      .get("programs/public/" + params.slug)
      .then((res) => {
        setLoading(false);
        setProgram(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Error");
      });
  }, [params.slug]);

  useEffect(() => {
    try {
      let user = JSON.parse(Cookies.get("ut_user"));
      setUser(user);
    } catch {
      // do nothing
    }
  }, [user?.id]);

  const onLoginClick = () => {
    navigate("/login?go_back=true");
  };

  return (
    <Container className="my-10 poppins">
      <div className="px-4 px-lg-0">
        <h3 className="secondary-blue f-40">
          <b>Welcome to the gift page</b>
        </h3>
        {loading && (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        )}
        {error !== null && <span className="text-danger">{error}</span>}
        <h5 className="mt-4 mb-3 f-35 secondary-blue">
          Gifting program: {program?.name || ""}
        </h5>
        <Formik
          initialValues={{
            email_id: "",
            name: "",
            program_id: program?.id,
            gifted_by_user_id: user?.id || null,
            gifted_by_email_id: user?.email || "",
            gifted_by_name:
              (user?.first_name || "") +
              (user?.last_name ? " " + user?.last_name : ""),
          }}
          enableReinitialize={true}
          validationSchema={GiftSchema}
          onSubmit={(values) => {
            setctempLoading(true);
            app_api
              .post("gift-temp", values)
              .then((gt) => {
                setctempLoading(false);
                setTransLoading(true);
                let gift_temp = gt.data;
                app_api
                  .post("transaction", {
                    first_name: values.gifted_by_name,
                    email_id: values.gifted_by_email_id,
                    temp_gift_id: gift_temp.id,
                    trans_type: "gift",
                    amount: program?.cost,
                  })
                  .then((tr) => {
                    setTransLoading(false);
                    navigate(`/gift/checkout/${tr.data.id}`);
                  })
                  .catch((err) => {
                    setTransLoading(false);
                    setTransError(
                      `Transaction error: ${err?.response?.data?.message}` ||
                        "Transaction error: Error"
                    );
                  });
              })
              .catch((err) => {
                setctempLoading(false);
                setctempError(
                  err?.response?.data?.message || "Error creating gift"
                );
              });
          }}
        >
          {({
            handleBlur,
            handleSubmit,
            handleChange,
            touched,
            values,
            errors,
            isValid,
          }) => (
            <Form noValidate={false} onSubmit={handleSubmit}>
              <Row className="pb-4">
                <h5>
                  Your details
                  {user?.id ? null : (
                    <Button
                      onClick={onLoginClick}
                      variant="primary"
                      className="ms-2"
                    >
                      Login
                    </Button>
                  )}
                </h5>
                <hr />
                <Col className="mb-2" md="6">
                  <FloatingLabel
                    controlId="gift.gifted_by_email_id"
                    label="Your email ID"
                  >
                    <FormControl
                      type="email"
                      name="gifted_by_email_id"
                      placeholder="Your email ID"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!!user?.email}
                      value={values.gifted_by_email_id}
                      isValid={
                        touched.gifted_by_email_id && !errors.gifted_by_email_id
                      }
                      isInvalid={
                        touched.gifted_by_email_id &&
                        !!errors.gifted_by_email_id
                      }
                    />
                    {touched.gifted_by_email_id && (
                      <FormControl.Feedback type="invalid">
                        {errors.gifted_by_email_id}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
                <Col md="6" className="mb-2">
                  <FloatingLabel
                    controlId="gift.gifted-by-name"
                    label="Your name"
                  >
                    <FormControl
                      type="text"
                      name="gifted_by_name"
                      placeholder="Your name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      disabled={!!user?.email}
                      value={values.gifted_by_name}
                      isValid={touched.gifted_by_name && !errors.gifted_by_name}
                      isInvalid={
                        touched.gifted_by_name && !!errors.gifted_by_name
                      }
                    />
                    {touched.gifted_by_name && (
                      <FormControl.Feedback type="invalid">
                        {errors.gifted_by_name}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
              </Row>
              <Row className="mb-2">
                <h5>Their details</h5>
                <hr />
                <Col md="6" className="mb-2">
                  <FloatingLabel controlId="gift.email_id" label="Email ID">
                    <FormControl
                      type="email"
                      name="email_id"
                      placeholder="Email ID"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email_id}
                      isValid={touched.email_id && !errors.email_id}
                      isInvalid={touched.email_id && !!errors.email_id}
                    />
                    {touched.email_id && (
                      <FormControl.Feedback type="invalid">
                        {errors.email_id}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
                <Col md="6" className="mb-2">
                  <FloatingLabel controlId="gift.name" label="Name">
                    <FormControl
                      type="text"
                      name="name"
                      placeholder="Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.name}
                      isValid={touched.name && !errors.name}
                      isInvalid={touched.name && !!errors.name}
                    />
                    {touched.name && (
                      <FormControl.Feedback type="invalid">
                        {errors.name}
                      </FormControl.Feedback>
                    )}
                  </FloatingLabel>
                </Col>
              </Row>
              <Button
                type="submit"
                variant="primary"
                className="text-white px-4 py-2 mt-4"
                disabled={!isValid || ctempLoading || transLoading}
              >
                {ctempLoading
                  ? "Creating gift..."
                  : transLoading
                  ? "Starting transaction..."
                  : "Checkout"}
              </Button>
              {ctempError !== null && (
                <span className="text-danger">{ctempError}</span>
              )}
              {transError !== null && (
                <span className="text-danger">{transLoading}</span>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </Container>
  );
};
