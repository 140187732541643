import React from "react";
import ReactDOM from "react-dom";
// import Blog_Img from "../../assets/images/blog_Image.png";
import Anjukish from "../../assets/images/Anjukish.png";
// import from 'src/custom.scss';
import fathertalksperiods1 from "../../assets/blogs/father talks periods/fathertalksperiods1.png";
// import fathertalksperiods2 from "../../blogs/father talks periods/fathertalksperiods2.png";
// import fathertalksperiods3 from "../../blogs/father talks periods/fathertalksperiods3.png";
import fathertalksperiods4 from "../../assets/blogs/father talks periods/fathertalksperiods4.png";

// src\blogs\father talks periods\fathertalksperiods1.png
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// blog_Image
// import { Container } from "react-bootstrap";

// Define a functional component
export const FirstBlog = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 200,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="poppins">
      <div
        style={{ marginLeft: "10%", marginRight: "10%" }}
        className="my-4 font-weight-bold poppins"
      >
        <img
          src={fathertalksperiods1}
          alt="fathertalksperiods1"
          class="img-fluid responsive-img"
        />
        <h6 className="text-primary poppins" style={{ fontSize: "15px" }}>
          Published 13 Jan 2022
        </h6>
        <h1 className="title-header">
          HOW TO HAVE THE FIRST PERIOD TALK WITH YOUR LITTLE GIRL
        </h1>
        <p className="text-black-50">
          Parents 1st Period talk with their Daughters can be scary: what to
          say, how to say? Here’s a post to help ease your way and make this
          conversation simple and special.
        </p>
        <p className="text-black-50">
          <span className="text-pink">
            <b>P-E-R-I-O-D</b>
          </span>
          - A six letter word which brings with it so much of shame and
          embarrassment, yes even today. Things are changing – movies are being
          made on the topic, girls are attempting to talk openly about it, but
          the change is slow.
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          <b>
            As a parent, having that first period talk with your daughter is
            always a difficult stage.
          </b>{" "}
          When do I tell her? How do I tell her? What do I tell her? Most of us
          have grown up with hesitations and silence around this word, so its
          but natural that a talk on this does not come easy. Moreover, most of
          us still do not know the correct reason or process about how periods
          happen.
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          <b>
            So today, let me hold your hand and take you on a step by step guide
            to initiate the ‘Periods’ conversation with your daughters.
          </b>
        </p>
      {/* </div> */}

      {/* <div style={{ marginLeft: "20%", marginRight: "20%" }}> */}
      {/* <div> */}
        <h1
          className="text-pink poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "30px",
            Weight: "600",
            lineHeight: "40px",
            Paragraph: "40px",
          }}
        >
          What’s the right age to initiate a conversation on periods?
        </h1>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          Girls attain puberty anytime between the age of 9 and 13, some earlier
          than that and some later.{" "}
          <b>
            The ideal situation would be to prepare the girl for this change in
            her life before she actually experiences it.
          </b>
          Hence, we suggest that your first conversation starts{" "}
          <b>at the age of 9-10.</b>
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          Of course, there are other times when a younger child may land upon
          Mama’s tampons or sanitary pads and raise a question about the same.
          As a parent, one needs to be ready to talk at that age too, it’s just
          your vocabulary to explain the process, that will need to change.
        </p>

        <h1
          className="text-pink poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "30px",
            Weight: "600",
            lineHeight: "40px",
            Paragraph: "40px",
          }}
        >
          Who is the right person to talk to the child about periods? Should it
          be the mother, the father or both together?
        </h1>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          We suggest that the conversation happen with both the parents in
          attendance.{" "}
          <b>
            It’s important to get the message across that periods is not a
            gender based topic.{" "}
          </b>{" "}
          It’s a natural biological process and there is no shame or
          embarrassment in it. It’s as natural as growing taller or getting
          facial hair during puberty. Having the dad present during this
          conversation takes the shame away from the process and passes the
          message to the child that its normal and natural.
        </p>
        {/* <img src={Blog_Img} alt="blog_img" class="img-fluid responsive-img" /> */}
        <p
          className="text-gray-500 poppins"
          style={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "26px",
            Paragraph: "24px",
          }}
        >
          Image courtesy of Mathilde Langevin via Unsplash
        </p>

        <div className="text-gray-500 poppins">
          <h1
            className="text-pink"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "30px",
              Weight: "600",
              lineHeight: "40px",
              Paragraph: "40px",
            }}
          >
            How do we initiate the conversation about Periods?
          </h1>
          <p
            className="text-gray-500"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            Opportunities present themselves every single day; you need to just
            recognise those conversation opener opportunities.
          </p>
          <p
            className="text-gray-500 poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            A sanitary napkin ad in a newspaper is an opportunity: “Hey, do you
            know what these napkins are used for?” An ad on TV is a great
            opportunity too: “Hey, do you know that the blue liquid they put on
            pads is incorrect information?” You, getting your periods and having
            cramps is another conversation opportunity. So, grab these
            opportunities.
          </p>

          <h1
            className="text-pink"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "30px",
              Weight: "600",
              lineHeight: "40px",
              Paragraph: "40px",
            }}
          >
            How should we behave while having this conversation with our child?
          </h1>
          <p
            className="text-gray-500 poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            How you say something is more important at times than what you say.
            Her attitude about periods will be a direct reflection of your
            behaviour while you explain it to her. If you are shy and
            embarrassed, the child will treat it as what it is today- a taboo.
            She will be shy and embarrassed about it and may never speak to you
            about it and that is not our purpose, is it? Your confidence and
            assertiveness of the fact that it’s natural and normal will instill
            confidence in her.
          </p>
          <p
            className="text-gray-500 poppins"
            style={{
              fontFamily: "Inter",
              fontSize: "16px",
              fontWeight: "400",
              lineHeight: "26px",
              Paragraph: "24px",
            }}
          >
            Image courtesy of Mathilde Langevin via Unsplash
          </p>

          <h1
            className="text-pink poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "30px",
              Weight: "600",
              lineHeight: "40px",
              Paragraph: "40px",
            }}
          >
            How do we explain the process of periods sensitively to a child?
          </h1>
          <p
            className="text-gray-500"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            Chances are, that before you even speak to your daughter about
            periods, she already has an inkling about it. Ads on television, a
            chance encounter to info on the internet, whispered conversations
            among school friends…{" "}
            <b>
              the source of information can be many and not necessarily the
              right information. This could have created fear in her mind{" "}
            </b>{" "}
            and thus, this makes it more important that as parents, we work
            harder to make the conversation about Periods easy, comfortable and
            accurate for our girls.
          </p>

          <h1
            className="text-pink poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "30px",
              Weight: "600",
              lineHeight: "40px",
              Paragraph: "40px",
            }}
          >
            Let’s make it easy by picturing a conversation between a Father and
            his Daughter.
          </h1>

          <p
            className="text-blue poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            <b style={{ color: "black" }}>F :</b> Dad wants to talk to you about
            periods today. I want you to know that it’s absolutely natural and
            normal.
          </p>
          <p
            className="text-brown poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            <b style={{ color: "black" }}>D :</b> Dad! This is awkward!
          </p>
          <p
            className="text-blue poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            <b style={{ color: "black" }}>F :</b> Exactly why we are discussing
            this today. To make you understand that there is absolutely nothing
            ‘awkward’ about periods. It’s natural and girls start getting one
            once they attain puberty.
          </p>
          <p
            className="text-brown poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            <b style={{ color: "black" }}>D :</b> Why can’t mom talk to me about
            this…this is so embarrassing!
          </p>
          <p
            className="text-blue poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            <b style={{ color: "black" }}>F :</b> That’s exactly the point I am
            trying to make – that its natural, so should not be embarrassing
            whether I speak to you or mom does. I want you to be comfortable
            with them.
          </p>
          <p
            className="text-brown poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            <b style={{ color: "black" }}>D :</b> Aahhh…ummm…Dad, some girls in
            my class already have had their periods. I haven’t. Is that normal?
          </p>
          <p
            className="text-blue poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            <b style={{ color: "black" }}>F :</b> hat’s perfectly normal. The
            puberty age varies from 9-13 across the world. Some may start having
            their periods at 8 years of age and some at fourteen. Everyone’s
            body is unique and so is yours.
          </p>
          <p
            className="text-brown poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            <b style={{ color: "black" }}>D :</b> I have heard some real painful
            accounts of periods. Can one bleed to death?
          </p>
          <p
            className="text-blue poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            <b style={{ color: "black" }}>F :</b> No, you won’t. It’s normal to
            have varied amounts of blood flow on different days of your period.
            The number of days range from three to five. Yet, if you feel that
            the bleeding is abnormal, that’s when you should reach out to your
            parent so that we can help you.
          </p>
          <p
            className="text-brown poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            <b style={{ color: "black" }}>D :</b> Tampons and pads, they look
            complicated.
          </p>
          <p
            className="text-blue poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            <b style={{ color: "black" }}>F :</b> In the beginning they may look
            so but you will get used to them once you find a brand or type you
            are comfortable with.
          </p>
          <p
            className="text-brown poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            <b style={{ color: "black" }}>D :</b> It’s all so weird.
          </p>
          <p
            className="text-blue poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            <b style={{ color: "black" }}>F :</b> It may sound so and it’s
            alright to feel so too. There will be changes in your body and you
            may feel a little angry towards everyone around, right before you
            get your periods. This is called Pre-Menstrual Syndrome, it’s normal
            too and let no one tell you that it’s not.
          </p>
          <p
            className="text-brown poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            <b style={{ color: "black" }}>D :</b> This is crazy, why do I have
            to bleed every month?
          </p>
          <p
            className="text-blue poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            <b style={{ color: "black" }}>F :</b> Anatomy. A girl has a baby bag
            inside her which is called the ‘Uterus’. This is the place where a
            baby develops. This uterus creates a thick lining of blood and
            tissues every month, in preparation for the time when the girl has a
            baby. But till then, this bag goes through a monthly cleaning. So
            every month, this thick lining of blood and tissues sheds and comes
            out from the girls body through her vagina. This shedding of the
            lining is known as a girls period. A new lining is again created and
            shed next month. Its as simple. (Parents can add on to this
            information as the girl grows older by talking about ova, sperms,
            and fertilisation not happening, thus lining shedding.)
          </p>
          <p
            className="text-brown poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            <b style={{ color: "black" }}>D :</b> It’s not simple…it’s all so
            complicated. I have so many questions.
          </p>
          <p
            className="text-blue poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            <b style={{ color: "black" }}>F :</b> And that’s why I am here, to
            answer all of them. To make it all simple.
          </p>

          <h1
            className="text-pink poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "30px",
              Weight: "600",
              lineHeight: "40px",
              Paragraph: "40px",
            }}
          >
            How do you get her Period ready?
          </h1>
          <p
            className="text-gray-500 poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            Now that she understands the process, there are other things she
            needs to get ready for.{" "}
            <b>
              You need to talk to her about vaginas, tampons, sanitary pads,
              menstrual cramps, PMS, irregular periods, accidental staining etc
              in detail.
            </b>{" "}
            Each of these can be broken down into simpler terms while being
            communicated to a child of 9 or 10. Always be ready for questions,
            children have a tonne load of them.{" "}
            <span className="text-blue">
              (Look out for our blogs on all of these topics – coming soon!)
            </span>
          </p>

          <h1
            className="text-pink poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "30px",
              Weight: "600",
              lineHeight: "40px",
              Paragraph: "40px",
            }}
          >
            Do you need to celebrate when she gets her first Period?
          </h1>
          <p
            className="text-gray-500 poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            What she wants should be of utmost importance! The child’s body is
            undergoing major changes in puberty and more than celebration, she
            needs comfort and understanding. We know of the turmoil in our
            bodies and minds while we face periods, every month. Imagine it for
            the very first time. As much as she needs to be happy and
            comfortable with the changes in her body, she also needs time to
            accept them and make it a way of life.
          </p>
          <div className="d-flex flex-sm-row flex-column poppins">
            <div className="md:w-40 w-100">
              <img
                src={fathertalksperiods4}
                alt="fathertalksperiods4"
                class="img-fluid responsive-img"
              />
            </div>
            <p
              className="text-gray-500 md:w-60 w-100 poppins"
              style={{
                marginTop: "4%",
                fontFamily: "Inter",
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "37px",
                Paragraph: "24px",
              }}
            >
              If she wants to have a special sleepover or party with her close
              bunch of friends to celebrate her becoming a little lady, then by
              all means give her that celebration. But if she is not ready to
              publicly share this news as yet, then respect that. You can make
              it a special occasion for her anyways by cutting a cake and giving
              her a special gift and lots of cuddles!
            </p>
          </div>

          <hr />
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-start">
            {/* <img src={Anjukish} alt="Anjukish" height={"75px"} style={{borderRadius:"100%"}}/> */}
            <div className="d-flex flex-row">
              <div class="image-container">
                <img
                  src={Anjukish}
                  class="circle-image"
                  alt="Description of the image"
                />
              </div>
              <div style={{ marginLeft: "20px" }}>
                <p
                  className="m-0 d-flex justify-center ml-5"
                  style={{
                    marginTop: "4%",
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: "600",
                    lineHeight: "37px",
                    Paragraph: "24px",
                  }}
                >
                  Anju Kish
                </p>
                <p
                  className="m-0 text-gray-500"
                  style={{
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: "400",
                    lineHeight: "32px",
                    Paragraph: "24px",
                  }}
                >
                  Content Writer
                </p>
              </div>
            </div>
            <div className="d-flex flex-column flex-md-row  gap-2 align-items-center justify-content-top text-center ">
              <p
                className="p-0 px-4 m-0 bg-light  rounded-more"
                style={{
                  backgroundColor: "#F2F4F7",
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "26px",
                  Paragraph: "18px",
                }}
              >
                Sexual Understanding
              </p>
              <p
                className="p-0  px-4 m-0 bg-light rounded-more"
                style={{
                  backgroundColor: "#F2F4F7",
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "26px",
                  Paragraph: "18px",
                }}
              >
                Education
              </p>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          // paddingLeft: "16%",
          // paddingRight: "16%",
          padding: "6% 16% 6% 16%",
          marginTop: "4%",
          fontFamily: "Inter",
          fontSize: "20px",
          fontWeight: "400",
          lineHeight: "37px",
          Paragraph: "24px",
          backgroundColor: "#B7C4D1",
        }}
      >
        <p
          className="text-uppercase"
          style={{
            fontFamily: "Inter",
            fontSize: "27px",
            fontWeight: "600",
            lineHeight: "37px",
            textAlign: "center",
          }}
        >
          Come join our movement to UnTaboo safety & sex education and create a
          safer society.
        </p>

        <p
          className=""
          style={{
            // paddingTop: "3%",
            // paddingLeft:"10%",
            padding: "3% 13% 3% 13%",
            fontFamily: "Inter",
            fontSize: "15px",
            fontWeight: "600",
            lineHeight: "30px",
            textAlign: "center",
          }}
        >
          Volunteer with us I Sponsor our program I Be an affiliate partner I Be
          our brand ambassador I Talk about us I Share your feedback &
          suggestions.
        </p>
        <div className="d-flex align-items-center justify-content-center ">
          <p
            className="p-0 px-4 m-0 bg-light rounded-more"
            style={{
              backgroundColor: "#FFFFFF",
              fontFamily: "Inter",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "26px",
              Paragraph: "18px",
            }}
          >
            We would love to hear from you!
          </p>
        </div>
      </div>
    </div>
  );
};
