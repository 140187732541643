import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export const TermsCondition = () => {
  return (
    <Container>
      <div className="poppins py-3 ">
        <h2 className="primary mt-5 fw-bold fs-1 primary-pink">
          TERMS & CONDITIONS
        </h2>
        <h1 className="secondary-blue pt-3 pb-5  fs-5">
          These Terms & Conditions (“Terms”) of (a) use of our Platform
          www.untaboo.online or any services in connection with the Platform
          (“Services”) or (b) any modes of registrations or usage of Services
          are between UnTaboo Education Pvt. Ltd. (“Company/We/Us/Our”) and its
          users (“User/You/Your”).
        </h1>
        <p
          className="secondary-b col-11 pt-3 pb-3 mb-5"
          style={{ fontSize: "17px" }}
        >
          These Terms constitute an electronic record in accordance with the
          provisions of the Information Technology Act, 2000 and the Information
          Technology (Intermediaries guidelines) Rules, 2011 thereunder, as
          amended from time to time.
          <br></br>
          <br></br>
          Please read the Terms and the privacy policy of the Company (“Privacy
          Policy”) with respect to registration with us, the use of the Platform
          and Services carefully before using the Platform or Services. In the
          event of any discrepancy between the Terms and any other policies with
          respect to the Platform or Services, the provisions of the Terms shall
          prevail.
          <br></br>
          <br></br>
          Your use/access/browsing of the Platform or the Services or
          registration (with or without payment/with or without subscription)
          through any means shall signify Your acceptance of the Terms and Your
          agreement to be legally bound by the same.
          <br></br>
          <br></br>
          If You do not agree with the Terms or the Privacy Policy, please do
          not use the Platform or avail the Services. Any access to our
          Platform/Services through registrations/subscription is
          non-transferable.
        </p>
        <p
          className="secondary-b col-11 pt-3 pb-3 mb-5"
          style={{ fontSize: "17px" }}
        >
          <b>1.</b> &nbsp;&nbsp;Except as mentioned below, all information, content, material,
          trademarks, services marks, trade names, and trade secrets including
          but not limited to the software, text, images, graphics, video, script
          and audio, contained in the Platform and Services are proprietary
          property of the Company (“Proprietary Information”). No Proprietary
          Information may be copied, downloaded, reproduced, modified,
          republished, uploaded, posted, transmitted or distributed in any way
          without obtaining prior written permission from the Company and
          nothing on this Platform or Services shall be deemed to confer a
          license of or any other right, interest or title to or in any of the
          intellectual property rights belonging to the Company, to the User.
          You may own the medium on which the information, content or materials
          resides, but the Company shall at all times retain full and complete
          title to the information, content or materials and all intellectual
          property rights inserted by the Company on such medium. Certain
          contents on the Platform may belong to third parties. Such contents
          have been reproduced after taking prior consent from said party and
          all rights relating to such content will remain with such third party.
          Further, you recognize and acknowledge that the ownership of all
          trademarks, copyright, logos, service marks and other intellectual
          property owned by any third party shall continue to vest with such
          party and You are not permitted to use the same without the consent of
          the respective third party.
          <br></br><br></br>
          <b>2.</b> 	Your use of our Platform and Services is solely for Your personal and non-commercial use. Any use of the Platform and Services or their contents other than for personal purposes is prohibited. Your personal and non-commercial use of Platform and/ or Services shall be subjected to the following restrictions:
i. You may not decompile, reverse engineer, or disassemble the contents of the Platform and/or Services or modify, copy, distribute, transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell any information or software obtained from the Platform and/or Services, or remove any copyright, trademark registration, or other proprietary notices from the contents of the Platform and/or Services.<br></br><br></br>
ii. You will not
<br></br>(a)   use this Platform and/or Services for commercial purposes of any kind, or
<br></br>(b)   advertise or sell the Platform or any products, Services or domain names or otherwise (whether or not for profit), or solicit others (including, without limitation, solicitations for contributions or donations) or use any public forum for commercial purposes of any kind, or
<br></br>(c)   use the Platform and Services in any way that is unlawful, or harms the Company or any other person or entity as determined by the Company.
<br></br><br></br>iii. No User shall be permitted to perform any of the following prohibited activities while availing our Services:
<br></br>(a)   Making available any content that is misleading, unlawful, harmful, threatening, abusive, tortious, defamatory, libelous, vulgar, obscene, child-pornographic, lewd, lascivious, profane, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable;
<br></br>(b)   Stalking, intimidating and/or harassing another and/or inciting other to commit violence;
<br></br>(c)   Transmitting material that encourages anyone to commit a criminal offence, that results in civil liability or otherwise breaches any relevant laws, regulations or code of practice;
<br></br>(d)   Interfering with any other person's use or enjoyment of the Platform/Services;
<br></br>(e)   Making, transmitting or storing electronic copies of materials protected by copyright without the permission of the owner, committing any act that amounts to the infringement of intellectual property or making available any material that infringes any intellectual property rights or other proprietary rights of anyone else;
<br></br>(f)    Make available any content or material that You do not have a right to make available under any law or contractual or fiduciary relationship, unless You own or control the rights thereto or have received all necessary consents for such use of the content;
<br></br>(g)   Impersonate any person or entity, or falsely state or otherwise misrepresent Your affiliation with a person or entity;
<br></br>(h)   Post, transmit or make available any material that contains viruses, trojan horses, worms, spyware, time bombs, cancelbots, or other computer programming routines, code, files or such other programs that may harm the Platform/Services, interests or rights of other users or limit the functionality of any computer software, hardware or telecommunications, or that may harvest or collect any data or personal information about other Users without their consent;
<br></br>(i)    Access or use the Platform/Services in any manner that could damage, disable, overburden or impair any of the Platform’s servers or the networks connected to any of the servers on which the Platform is hosted;
<br></br>(j)    Intentionally or unintentionally interfere with or disrupt the services or violate any applicable laws related to the access to or use of the Platform/Services, violate any requirements, procedures, policies or regulations of networks connected to the Platform/Services, or engage in any activity prohibited by these Terms;
<br></br>(k)   Disrupt or interfere with the security of, or otherwise cause harm to, the Platform/Services, materials, systems resources, or gain unauthorised access to user accounts, passwords, servers or networks connected to or accessible through the Platform/Services or any affiliated or linked sites;
<br></br>(l)    Interfere with, or inhibit any user from using and enjoying access to the Platform/Services, or other affiliated sites, or engage in disruptive attacks such as denial of service attack on the Platform/Services;
<br></br>(m) Use deep-links, page-scrape, robot, spider or other automatic device, program, algorithm or methodology, or any similar or equivalent manual process, to increase traffic to the Platform/Services, to access, acquire, copy or monitor any portion of the Platform/Services, or in any way reproduce or circumvent the navigational structure or presentation of the Application, or any content, to obtain or attempt to obtain any content, documents or information through any means not specifically made available through the Platform/Services;
<br></br>(n)   Alter or modify any part of the Services;
<br></br>(o)   Use the Services for purposes that are not permitted by: (i) these Terms; and (ii) any applicable law, regulation or generally accepted practices or guidelines in the relevant jurisdiction; or
<br></br>(p)   Violate any of the terms specified under the Terms for the use of the Platform/Services.
<b>3.</b> 	By submitting content on or through the Services (your “Material”), you grant us a worldwide, non-exclusive, royalty-free licence (with the right to sublicense) to use, copy, reproduce, process, adapt, modify, publish, transmit, display and distribute such Material in any and all media or distribution methods (now known or later developed) and to associate your Material with you, except as described below. You agree that others may use Your Material in the same way as any other content available through the Services. Other users of the Services may fork, tweak and repurpose your Material in accordance with these Terms. If you delete your user account, your Material and name may remain available through the Services
<br></br><br></br><b>4.</b> 	In the preparation of the Platform/Services and contents therein, every effort has been made to offer the most current, correct, and clearly expressed information possible. Nevertheless, inadvertent errors may occur. In particular, but without limiting anything here, the Company disclaims any responsibility for any errors and accuracy of the information that may be contained in the Application. Any feedback from User is most welcome to make the Application and contents thereof error free and user friendly. Company also reserves the right and discretion to make any changes/corrections or withdraw/add contents at any time without notice. Neither the Company nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on Platform/Services for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.
<br></br><br></br><b>5.</b> 	Our Platform provides Users with access to compiled educational information and related sources. Such information is provided on an As Is basis and We assume no liability for the accuracy or completeness or use or non-obsolescence of such information. We shall not be liable to update or ensure continuity of such information contained on the Website. We would not be responsible for any errors, which might appear in such information, which is compiled from third party sources or for any unavailability of such information. From time to time the Platform may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s). You may not create a link to the Platform from another website or document without the Company's prior written consent.
<br></br><br></br><b>6.</b> 	The Company may, based on any form of access to the Platform (including free download/trials) or Services or registrations through any source whatsoever, contact the User through sms, email and call, to give information about its products as well as notifications on various important updates and/or to seek permission for demonstration of its products. The User expressly grants such permission to contact him/her through telephone, SMS, e-mail and holds the Company indemnified against any liabilities including financial penalties, damages, expenses in case the User's mobile number is registered with Do not Call (DNC) database. By registering yourself, you agree to make your contact details available to Our employees, associates and partners so that you may be contacted for education information and promotions through telephone, SMS, email etc.
<br></br><br></br><b>7.</b> 	Upon registration through any means whatsoever, the Company may contact You through the registered mobile number or e-mail or any other mobile number or contact number or email provided by You to enable effective provision of Services. The User expressly permits the Company to contact him/her and the student utilising the Services, through the above mentioned means at any time post registration. Further, the Company shall have the right to monitor the download and usage of the Platform/Services and the contents thereof by the User/student, to analyse such usage and discuss the same with the User/student to enable effective and efficient usage of the Services. The User expressly permits the Company to clear the doubts of the student using the Platform/Services by answering the questions placed before it, providing study plans, informing of the progress, providing feedback, communicating with the student and mentoring the student through telephone or e-mail on express consent of the legal guardian/parent of the User or through any other forum..
<br></br><br></br><b>8.</b> 	Access to certain elements of the Services including mentoring services, webinars etc. may be subject to separate terms, conditions and fair usage policy. The Company reserves the right to determine the criteria for provision of various elements of Services to the different categories of Users based on its policies. Hence, subscription to the Platform or registrations do not automatically entitle the User to any and all elements of Services provided by the Company and the Company shall be entitled to exercise its discretion while providing access to and determining continuity of certain elements of Services. We reserve the right to extend, cancel, discontinue, prematurely withdraw or modify any of Our Services at Our discretion.
<br></br><br></br><b>9.</b> 	In order to access the Services and to avail the use of the Platform, You shall be required to register yourself with the Platform, and maintain an account with the Platform. You will be required to furnish certain information and details, including Your name, mobile number, e-mail address, residential address, grade/class of the student, school name, payment information (credit/debit card details) if required, and any other information deemed necessary by the Platform. With respect to the provision of information, the following may be noted:
<br></br>•	It is Your sole responsibility to ensure that the account information provided by You is accurate, complete, and latest.
<br></br>•	You shall be responsible for maintaining the confidentiality of the account information and for all activities that occur under Your account. You agree to (a) ensure that You successfully log out from Your account at the end of each session; and (b) immediately notify the Company of any unauthorised use of Your account.  If there is reason to believe that there is likely to be a breach of security or misuse of Your account, we may request You to change the password or we may suspend Your account without any liability to the Company, for such period of time as we deem appropriate in the circumstances. We shall not be liable for any loss or damage arising from Your failure to comply with this provision.
<br></br>•	You acknowledge that Your ability to use Your account is dependent upon external factors such as internet service providers and internet network availability and the Company cannot guarantee accessibility to the Application at all times. In addition to the disclaimers set forth in the Terms, the Company shall not be liable to You for any damages arising from Your inability to log into Your account and access the services of the Application at any time.
<br></br><br></br><b>10.</b>  Persons who are "competent/capable" of contracting within the meaning of the Indian Contract Act, 1872 shall be eligible to register for the Platform and all Our Services. Persons who are minors, un-discharged insolvents etc. are not eligible to register for Our Services. As a minor if You wish to use Our Services, such use shall be made available to You by Your legal guardian or parents, who have agreed to these Terms. In the event a minor utilises the Platform/Services, it is assumed that he/she has obtained the consent of the legal guardian or parents and such use is made available by the legal guardian or parents. The Company will not be responsible for any consequence that arises as a result of misuse of any kind of Our Platform or any of Our Services that may occur by virtue of any person including a minor registering for the Services provided. By using the Services You warrant that all the data provided by You is accurate and complete and that the student using the Platform has obtained the consent of parent/legal guardian (in case of minors). The Company reserves the right to terminate Your subscription and / or refuse to provide You with access to the Services if it is discovered that You are under the age of 18 (eighteen) years and the consent to use the Services is not made by Your parent/legal guardian or any information provided by You is inaccurate. You acknowledge that the Company does not have the responsibility to ensure that You conform to the aforesaid eligibility criteria. It shall be Your sole responsibility to ensure that You meet the required qualification. Any persons under the age of 18 (eighteen) should seek the consent of their parents/legal guardians before providing any Information about themselves or their parents and other family members on the Platform.
<br></br><br></br><b>11.</b>  You agree to defend, indemnify and hold harmless the Company, its officers, directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from: 
(i) Your use of and access of the Platform/Services; 
(ii) Your violation of any term of these Terms or any other policy of the Company; 
(iii) Your violation of any third party right, including without limitation, any copyright, property, or privacy right; or 
(iv) any claim that Your use of the Platform/Services has caused damage to a third party. This defence and indemnification obligation will survive these Terms.
<br></br><br></br><b>12.</b>  In no event shall the Company, its officers, directors, employees, partners or agents be liable to You or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits or any other claim arising out, of or in connection with, Your use of, or access to, the Platform.
<br></br><br></br><b>13.</b>  In the event of Your breach of these Terms, You agree that the Company will be irreparably harmed and may not have an adequate remedy in money or damages. The Company, therefore, shall be entitled in such event to obtain an injunction against such a breach from any court of competent jurisdiction. The Company's right to obtain such relief shall not limit its right to obtain other remedies.
<br></br><br></br><b>14.</b>  Any violation by You of the terms of this Clause may result in immediate suspension or termination of Your Accounts apart from any legal remedy that the Company can avail. In such instances, the Company may also disclose Your Account Information if required by any Governmental or legal authority. You understand that the violation of these Terms could also result in civil or criminal liability under applicable laws.
<br></br><br></br><b>15.</b>  The Terms shall be governed by and construed in accordance with the laws of India, without regard to conflict of law principles. Further, the Terms shall be subject to the exclusive jurisdiction of the competent courts located in Mumbai and You hereby accede to and accept the jurisdiction of such courts.
<br></br><br></br><b>16.</b>  The Company has the right to change, modify, suspend, or discontinue and/or eliminate any aspect(s), features or functionality of the Platform or the Services as it deems fit at any time without notice. Further, the Company has the right to amend these Terms from time to time without prior notice to you. The Company makes no commitment, express or implied, to maintain or continue any aspect of the Platform. You agree that the Company shall not be liable to You or any third party for any modification, suspension or discontinuance of the Platform/Services. All prices are subject to change without notice. 
<br></br><br></br><h4>17. REFUND & CANCELLATION</h4> 
<br></br>It is important that you read and accept the terms and conditions when you make payment for the UnTaboo online Programs. By Proceeding to Pay for UnTaboo online Program, you are confirming that you have read and understood the Refund & Cancellation Policy and agree to it.

<br></br>•	UnTaboo Education Pvt ltd takes great care to provide all salient information about its programs & services on its website pages & emails. We also provide the option of access to a demo section for the buyer to see before purchase. There is also an option to reach out to us via our contact us pages or whatsapp chat feature for any doubt clarification. We do our best to give all possible information and expect the buyer to exercise due diligence and run through the content before making a purchase. 
<br></br>•	No Refund Policy: Kindly be informed that we have a "NO-REFUND" policy. All SALES ARE FINAL AND NON-REFUNDABLE.
<br></br>•	Payment Gateway or Technical Errors:  If you are charged twice for the same purchase because of a payment gateway error, a bank technical error, or a website mechanism error, a refund process will be initiated. This happens on occasion in all e-commerce situations. The money is returned within 3 to 7 working days of the error being reported. You can report the error at team.untaboo@gmail.com 
<br></br>•	Updating These Terms
<br></br>We may update these Terms from time to time to clarify our practices or to reflect new or different practices (for example, when we add new features), and UnTaboo Education Pvt Ltd. reserves the right, in its sole discretion, to modify and/or change these Terms at any time. If we make a material change, we will notify you in a prominent manner, such as by email to the email address specified in your account or by posting a notice on our Services. Unless otherwise specified, changes will take effect on the day they are posted.

<br></br>Your continued use of our Services after changes become effective shall mean that you accept those changes. Any revised Terms shall supersede all previous Terms.
<br></br><br></br><b>18.  General Provisions:</b>
<br></br>·       <b>Notice</b>: All notices served by the Company shall be provided via email to Your account or as a general notification on the Application. Any notice to be provided to the Company should be sent to team.untaboo@gmail.com.
<br></br>·       <b>Entire Agreement</b>: The Terms, along with the Privacy Policy, Refund & Cancellation Policy and any other guidelines made applicable to the Application from time to time, constitute the entire agreement between the Company and You with respect to Your access to or use of the Application, Website and the Services thereof.
<br></br>·       <b>Assignment</b>: You cannot assign or otherwise transfer Your obligations under the Terms, or any right granted hereunder to any third party. The Company's rights under the Terms are freely transferable by the Company to any third parties without the requirement of seeking Your consent.
<br></br>·       <b>Severability</b>: If, for any reason, a court of competent jurisdiction finds any provision of the Terms, or portion thereof, to be unenforceable, that provision shall be enforced to the maximum extent permissible so as to give effect to the intent of the parties as reflected by that provision, and the remainder of the Terms shall continue in full force and effect.
<br></br>·     <b>Waiver</b>: Any failure by the Company to enforce or exercise any provision of the Terms, or any related right, shall not constitute a waiver by the Company of that provision or right.
<br></br>·    <b> Relationship</b>: You acknowledge that Your participation on the Platform, does not make You an employee or agency or partnership or joint venture or franchise of the Company.
The Company provides these Terms so that You are aware of the terms that apply to your use of the Platform and Services. You acknowledge that the Company has given You a reasonable opportunity to review these Terms and that You have agreed to them.
<br></br><b>19.  Feedback</b>:                 	
·       We welcome your suggestions, ideas, comments, and other feedback regarding the Services (‘Feedback’). By submitting any Feedback, you grant us the right to use the Feedback without any restriction or any compensation to you.
·       Under no circumstances shall the Company be held responsible in any manner for any content provided by other users even if such content is offensive, hurtful or offensive. Please exercise caution while accessing the Platform.
<br></br>20. <b> DISCLAIMER</b>: THIS PLATFORM AND THE SERVICES ARE PROVIDED ON AN "AS IS" BASIS WITH ALL FAULTS AND WITHOUT ANY WARRANTY OF ANY KIND. THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES AND CONDITIONS WITH REGARD TO THE PLATFORM AND THE SERVICES, INCLUDING WITHOUT LIMITATION, ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, ACCURACY, TIMELINESS. PERFORMANCE, COMPLETENESS, SUITABILITY AND NON-INFRINGEMENT. ADDITIONALLY, THE COMPANY SHALL NOT BE LIABLE FOR ANY DAMAGES ARISING OUT OF OR IN CONNECTION WITH THE USE OR PERFORMANCE OF THIS SITE, OR THE APPLICATION OR THE SERVICES. YOUR USE OF ANY INFORMATION OR MATERIALS ON THIS PLATFORM/SERVICES IS ENTIRELY AT YOUR OWN RISK, FOR WHICH WE SHALL NOT BE LIABLE. IT SHALL BE YOUR OWN RESPONSIBILITY TO ENSURE THAT SERVICES PROVIDED BY US MEET YOUR SPECIFIC REQUIREMENTS.

<br></br>21. <b>Customer Care</b>:
<br></br>We make all best endeavours to provide You with a pleasant experience. In the unlikely event that You face any issues, please contact us at team.untaboo@gmail.com

          </p>
      </div>
    </Container>
  );
};
