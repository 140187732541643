import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import { app_api } from "../../atoms/api";
import Button from "../../atoms/button";
import Spinner from "react-bootstrap/Spinner";

export const CheckAffiliate = () => {
  const [affiliatedata, setAffiliateData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [emailcheck, setEmailCheck] = useState("");

  const CheckAffiliateData = () => {
    setLoading(true);
    app_api
      .post("/affiliate/codes", {
        email: emailcheck,
      })
      .then((res) => {
        setLoading(false);
        setError(null);
        setAffiliateData(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Error getting data");
        setAffiliateData([]);
      });
  };

  return (
    <div>
      <Container className="mt-10">
        <div className="my-10">
          <label className="fs-4 mb-3">Enter Your Email Address</label>
          <br></br>
          <input
            type="email"
            placeholder="Enter Email"
            value={emailcheck}
            onInput={(e) => setEmailCheck(e.target.value)}
            style={{ height: "50px", width: "300px" }}
          />
          <Button
            className="mx-3"
            onClick={CheckAffiliateData}
            disabled={loading}
          >
            {loading ? "Checking..." : "Check"}
          </Button>
        </div>

        {loading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : error !== null ? (
          <span className="text-danger">{error}</span>
        ) : (
          <Table striped>
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Program Name</th>
                <th>Code</th>
                <th>Amount</th>
                <></>
              </tr>
            </thead>
            <tbody>
              {affiliatedata.length === 0 ? (
                <tr>
                  <td colSpan="6">
                    No purchase has been made using your codes.
                  </td>
                </tr>
              ) : (
                affiliatedata.map((a, index) => (
                  <tr key={index}>
                    <td>{a?.first_name}</td>
                    <td>{a?.last_name}</td>
                    <td>
                      {!!a?.temp_enrollment_id
                        ? a?.temp_enrollment?.program?.name
                        : a?.temp_gift?.program?.name}
                    </td>
                    <td>{a?.affiliate_code}</td>
                    <td>{(0.9 * a?.program_cost).toFixed(2)}</td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        )}
      </Container>
    </div>
  );
};
