import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";

export const RefundPolicy = () => {
  return (
    <Container>
      <div className="poppins py-3 ">
        <h2 className=" primary mt-5 fw-bold fs-1 primary-pink">
          REFUND & CANCELLATION POLICY
        </h2>
        <h1 className="secondary-blue pt-3 pb-5  fs-5">
          It is important that you read and accept the terms and conditions when
          you make payment for the UnTaboo online Programs. By Proceeding to Pay
          for UnTaboo online Program, you are confirming that you have read and
          understood the Refund & Cancellation Policy and agree to it.
        </h1>
        <p
          className="secondary-b col-11 pt-3 pb-3 mb-5"
          style={{ fontSize: "17px" }}
        >
        <h4 className="fw-bold">Refund & Cancellation Policy</h4>
        
        UnTaboo Education Pvt ltd takes great care to provide all salient information about its programs & services on its website pages & emails. We also provide the option of access to a demo section for the buyer to see before purchase. There is also an option to reach out to us via our contact us pages or whatsapp chat feature for any doubt clarification. We do our best to give all possible information and expect the buyer to exercise due diligence and run through the content before making a purchase. 

<br></br><br></br><b>No Refund Policy:</b> <br></br>Kindly be informed that we have a "NO-REFUND" policy. All SALES ARE FINAL AND NON-REFUNDABLE.
<br></br><br></br><b>Payment Gateway or Technical Errors:</b> <br></br> If you are charged twice for the same purchase because of a payment gateway error, a bank technical error, or a website mechanism error, a refund process will be initiated. This happens on occasion in all e-commerce situations. The money is returned within 3 to 7 working days of the error being reported. You can report the error at team.untaboo@gmail.com 
<br></br><br></br><b>Updating These Terms</b><br></br>
We may update these Terms from time to time to clarify our practices or to reflect new or different practices (for example, when we add new features), and UnTaboo Education Pvt Ltd. reserves the right, in its sole discretion, to modify and/or change these Terms at any time. If we make a material change, we will notify you in a prominent manner, such as by email to the email address specified in your account or by posting a notice on our Services. Unless otherwise specified, changes will take effect on the day they are posted.

Your continued use of our Services after changes become effective shall mean that you accept those changes. Any revised Terms shall supersede all previous Terms.

          </p>
      </div>
    </Container>
  );
};
