import styled, { css } from "styled-components";

const Button = styled.button`
  background: #6bcacf;
  border-radius: 30px;
  font-weight: 600;
  border: none;
  color: #000;
  transition: all 0.3s ease-in-out;
  padding: 10px 30px;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  &:hover {
    background-color: #0bb7af;
    border: none;
    box-shadow: 0px 10px 30px 0px rgb(166 223 226 / 20%);
    background: linear-gradient(120deg, #6bcacf 0%, #b1e3e5 100%);
    color: #000 !important;
  }
  ${(props) =>
    props.secondary
      ? css`
          color: #000;
          border: 1px solid #0c2e60;
          background-color: white;
          transition: all 0.2s ease-in-out;
        `
      : ""}
  &:hover {
    color: #000;
    border: none;
  }
`;
export default Button;
