import { Formik } from "formik";
import React, { Component } from "react";
import { Navigate, useParams } from "react-router-dom";
import "../../cstyle.scss";
import { app_api } from "../../atoms/api";
import { Banner } from "../../pages/program";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import FormCheck from "react-bootstrap/FormCheck";
import FormControl from "react-bootstrap/FormControl";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import BootStrapModal from "react-bootstrap/Modal";
import BootstrapModalBody from "react-bootstrap/ModalBody";
import BootstrapModalHeader from "react-bootstrap/ModalHeader";
import BootstrapModalFooter from "react-bootstrap/ModalFooter";
import BootstrapModalTitle from "react-bootstrap/ModalTitle";
import { UtImg } from "../../atoms/image";
import playBanner from "../../assets/images/Fitness test boys.png";
import playBannerGirl from "../../assets/images/fitness test girls.png";
import playBannerOther from "../../assets/images/fitnessTestOther.png";
import { Badge } from "../../atoms/image/badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

// const SingleSchema = yup.object().shape({
//   selectedAnswer: yup.string().required("Please select an answer"),
// });

const Modal = styled(BootStrapModal)`
  border: "none";
`;

const ModalHeader = styled(BootstrapModalHeader)`
  background-color: rgb(246, 148, 148);
  color: rgb(30, 67, 99);
  font-weight: 600;
`;

const ModalTitle = styled(BootstrapModalTitle)`
  font-weight: 600;
`;

const ModalBody = styled(BootstrapModalBody)`
  background-color: #6bcad0;
  color: white;
`;

const ModalFooter = styled(BootstrapModalFooter)`
  background-color: #6bcad0;
`;

export class Play extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  constructor(props) {
    super(props);
    this.state = {
      showQuestion: 0,
      loading: false,
      error: null,
      answeredQuestions: {},
      responseStatus: null,
      responseText: "",
      responseGif: "",
      showbadge: false,
      score: 0,
      learnerScore: 0,
      parentScore: 0,
      completed: false,
    };
  }

  loading = () => this.setState({ loading: true });
  loaded = () => this.setState({ loading: false });
  errored = (err) => this.setState({ error: err });
  dismissError = () => this.setState({ error: null });

  nextQuestion = () => {
    let showQuestion = this.state.showQuestion;
    this.setState({ showQuestion: ++showQuestion });
  };

  submitReponse = (question_id, is_correct) => {
    let question = this.props.content.find((q) => q.id === question_id);
    let response = question.responses.find((r) => r.is_correct === is_correct);
    let isParent = Array.isArray(question.question.match(/^parent/gi));
    if (this.props.isFaceoff) {
      if (typeof is_correct === "boolean") {
        this.setState({
          responseStatus: is_correct,
          responseText: response.response,
          responseGif: response.gif?.filePath,
          answeredQuestions: {
            ...this.state.answeredQuestions,
            [question_id]: {
              answer: is_correct,
              isParent,
            },
          },
        });
      } else {
        this.setState({
          responseStatus: true,
          responseText: "Thanks for sharing.",
          responseGif: "...",
          answeredQuestions: {
            ...this.state.answeredQuestions,
            [question_id]: {
              answer: is_correct,
              isParent,
            },
          },
        });
      }
    } else {
      if (typeof is_correct === "boolean" && this.props.type !== "checkpoint") {
        this.setState({
          responseStatus: is_correct,
          responseText: response.response,
          responseGif: response.gif?.filePath,
          answeredQuestions: {
            ...this.state.answeredQuestions,
            [question_id]: is_correct,
          },
        });
      } else {
        this.setState({
          responseStatus: true,
          responseText: "Thanks for sharing.",
          responseGif: "...",
          answeredQuestions: {
            ...this.state.answeredQuestions,
            [question_id]: is_correct,
          },
        });
      }
    }
  };

  closeResponse = () => {
    this.setState({
      responseStatus: null,
      responseText: "",
      responseGif: "",
      showbadge:
        this.props.sequence.length ===
        Object.keys(this.state.answeredQuestions).length,
    });
  };

  closeScoreModal = () => {
    if (!this.state.error) {
      if (this.props.isDemo) this.props.buyToProceed(true);
      else document.location.reload();
    } else {
      this.setState({ showbadge: false });
    }
  };

  closeFaceoffScoreModal = () => {
    if (!this.state.error) {
      this.setState({ completed: true });
    } else {
      this.setState({ showbadge: false });
    }
  };

  submitQuiz = () => {
    let totalquestion = this.props?.sequence.length;
    let score;
    if (this.props.isFaceoff) {
      let parentCorrectAnswer = Object.values(
        this.state.answeredQuestions
      ).filter((ans) => ans.isParent && ans.answer).length;
      let learnerCorrectAnswer = Object.values(
        this.state.answeredQuestions
      ).filter((ans) => !ans.isParent && ans.answer).length;
      score = learnerCorrectAnswer - parentCorrectAnswer;
      this.setState({
        learnerScore: learnerCorrectAnswer,
        parentScore: parentCorrectAnswer,
        score,
      });
    } else {
      let correctanswer = Object.values(this.state.answeredQuestions).filter(
        (correctanswer) => correctanswer
      ).length;
      score = Math.ceil((correctanswer / totalquestion) * 100);
      this.setState({ score });
    }
    if (!this.props.isDemo) {
      this.loading();
      app_api
        .post(
          this.props.isFaceoff
            ? "user-response/bulk/faceoff"
            : "user-response/bulk",
          {
            enrollment_id: parseInt(this.props.enrollmentId),
            answeredQuestions: this.state.answeredQuestions,
            score,
            isPost: this.props.isPost,
          }
        )
        .then((res) => {
          this.loaded();
          if (this.props.type === "checkpoint" && !this.props.isFaceoff) {
            this.setState({
              showbadge:
                this.props.sequence.length ===
                Object.keys(this.state.answeredQuestions).length,
            });
          }
        })
        .catch((err) => {
          this.loaded();
          this.errored(err?.response?.data?.message || "Error");
        });
    }
  };

  submitQuestionButton = () => (
    <Button
      className="mt-5"
      type="submit"
      variant="primary"
      disabled={this.state.loading}
    >
      {this.state.loading
        ? [
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />,
            " Submitting...",
          ]
        : "Submit"}
    </Button>
  );

  renderAnswers = (question_id, type, answers, currentIndex, totalLength) => {
    switch (type) {
      case "single":
        return (
          <Formik
            initialValues={{ selectedAnswer: null }}
            onSubmit={(values) => {
              let answer = answers.find((a) => a.id == values.selectedAnswer);
              this.submitReponse(question_id, answer.is_correct);
              if (currentIndex === totalLength - 1) {
                this.submitQuiz();
              } else {
                this.nextQuestion();
              }
            }}
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate={true} onSubmit={handleSubmit}>
                {answers.map((a, index) => {
                  return (
                    <Form.Group controlId={a.id} key={index}>
                      <FormCheck
                        name="selectedAnswer"
                        value={a.id}
                        label={a.answer}
                        type="radio"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        checked={values.selectedAnswer == a.id}
                        required={true}
                        isInvalid={
                          touched.selectedAnswer && !!errors.selectedAnswer
                        }
                      />
                    </Form.Group>
                  );
                })}
                {this.submitQuestionButton()}
              </Form>
            )}
          </Formik>
        );
      case "multiple":
        return (
          <Formik
            initialValues={{ selectedAnswers: [] }}
            onSubmit={(values) => {
              let is_correct = false;
              if (values.selectedAnswers.length > 0) {
                if (
                  values.selectedAnswers.length ===
                  answers.filter((a) => a.is_correct).length
                ) {
                  is_correct = values.selectedAnswers
                    .map((a) => JSON.parse(a))
                    .map((a) => a.is_correct)
                    .reduce((acc, next) => acc && next, true);
                }
                this.submitReponse(question_id, is_correct);
                if (currentIndex === totalLength - 1) {
                  this.submitQuiz();
                } else {
                  this.nextQuestion();
                }
              }
            }}
          >
            {({ handleSubmit, handleChange, values, touched }) => (
              <Form noValidate={true} onSubmit={handleSubmit}>
                <FontAwesomeIcon icon={faInfoCircle} />
                &nbsp;Select multiple answers
                {answers.map((a, index) => {
                  return (
                    <Form.Group controlId={a.id} key={index}>
                      <FormCheck
                        name="selectedAnswers"
                        value={JSON.stringify(a)}
                        label={a.answer}
                        type="checkbox"
                        onChange={handleChange}
                        checked={
                          values.selectedAnswers.length > 0 &&
                          values.selectedAnswers
                            .map((a) => JSON.parse(a).id)
                            .includes(a.id)
                        }
                        required={true}
                        isInvalid={
                          touched.selectedAnswers &&
                          values.selectedAnswers.length === 0
                        }
                      />
                    </Form.Group>
                  );
                })}
                {this.submitQuestionButton()}
              </Form>
            )}
          </Formik>
        );
      case "text":
        return (
          <Formik
            initialValues={{ value: "" }}
            onSubmit={(values) => {
              let answer = answers[0],
                isCorrect = false;
              if (values.value.toLowerCase() === answer?.answer.toLowerCase()) {
                isCorrect = true;
              }
              this.submitReponse(question_id, isCorrect);
              if (currentIndex === totalLength - 1) {
                this.submitQuiz();
              } else {
                this.nextQuestion();
              }
            }}
          >
            {({
              handleSubmit,
              handleBlur,
              handleChange,
              touched,
              errors,
              values,
            }) => (
              <Form noValidate={true} onSubmit={handleSubmit}>
                <FormControl
                  name="value"
                  value={values.value}
                  required={true}
                  type="text"
                  placeholder="Type your answer"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.value && !errors.value}
                  isInvalid={touched.value && !!errors.value}
                />
                {this.submitQuestionButton()}
              </Form>
            )}
          </Formik>
        );
      case "opinion":
        return (
          <Formik
            initialValues={{ value: "" }}
            onSubmit={(values) => {
              this.submitReponse(question_id, values.value);
              if (currentIndex === totalLength - 1) {
                this.submitQuiz();
              } else {
                this.nextQuestion();
              }
            }}
          >
            {({
              handleSubmit,
              handleBlur,
              handleChange,
              touched,
              errors,
              values,
            }) => (
              <Form noValidate={true} onSubmit={handleSubmit}>
                <FormControl
                  name="value"
                  value={values.value}
                  required={true}
                  type="text"
                  placeholder="Type your answer"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isValid={touched.value && !errors.value}
                  isInvalid={touched.value && !!errors.value}
                />
                {this.submitQuestionButton()}
              </Form>
            )}
          </Formik>
        );
      default:
        return null;
    }
  };

  render() {
    if (this.state.completed) {
      return (
        <Navigate
          to={`/dashboard/program/${this.props.enrollmentId}/success/coupon`}
        />
      );
    } else {
      return (
        <Container
          fluid={true}
          className={`play mb-5 flex-column align-items-center ${
            this.props.show ? "d-flex" : "d-none"
          }`}
        >
          {/* <h1 className="f-55 poppins mb-5">{this.props?.title}</h1> */}
          <Banner
            imported
            key="play"
            alt="Play banner"
            src={
              this.props.isPost
                ? "https://test.api.untaboo.online/uploads/images/1695320188205-puberty_challenge_header_image.jpeg"
                : this.props.directed_at === "girls"
                ? playBannerGirl
                : this.props.directed_at === "boys"
                ? playBanner
                : this.props.isFaceoff &&
                  document.location.pathname.split("/")[
                    document.location.pathname.split("/").length - 1
                  ] !== this.props.level_id.toString()
                ? "https://test.api.untaboo.online/" + this.props.bannerImg
                : playBannerOther
            }
          />
          {this.props?.heading && (
            <p
              className="col-11 fw-bold fst-italic text-center my-5 poppins"
              style={{ color: "#663259" }}
            >
              {this.props?.heading}
            </p>
          )}
          {this.props.loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : this.props.error !== null ? (
            <label className="text-danger text-capitalize">
              {this.props.error}
            </label>
          ) : (
            <Container fluid={true} className="mt-5 position-relative">
              {Array.isArray(this.props.sequence) &&
              Array.isArray(this.props.content) &&
              this.props?.sequence.length > 0 &&
              this.props.content.length >= this.props.sequence.length ? (
                this.props.content
                  .sort(
                    (a, b) =>
                      this.props.sequence.indexOf(a.id) -
                      this.props.sequence.indexOf(b.id)
                  )
                  .map((q, index) => (
                    <div
                      className={`${
                        this.state.showQuestion === index ? "d-flex" : "d-none"
                      } flex-column `}
                      key={index}
                    >
                      {this.state.error && (
                        <Alert
                          variant="danger"
                          dismissible={true}
                          onClose={this.dismissError}
                        >
                          {this.state.error}
                        </Alert>
                      )}
                      <div className="showQuestion">
                        <h4
                          className="position-absolute poppins"
                          style={{
                            left: "19%",
                            top: "10%",
                            color: "lightgrey",
                            fontSize: "12px",
                          }}
                        >
                          <FontAwesomeIcon
                            className="me-2"
                            icon={faInfoCircle}
                          />
                          Hover over image to make it larger
                        </h4>
                        <div className="col-12 mt-lg-0 mt-4 col-md-6 text-center">
                          <UtImg
                            className="w-30 scale_img"
                            src={q?.image?.filePath}
                          />
                          <h3 className="mt-lg-5 mt-3 poppins f-25 px-lg-0 px-2">
                            {q?.question}
                          </h3>
                        </div>
                        <div className="text-center col-12 col-md-6 poppins">
                          {Array.isArray(q?.answers) ? (
                            this.renderAnswers(
                              q?.id,
                              q?.type,
                              q?.answers,
                              index,
                              this.props.sequence.length
                            )
                          ) : (
                            <p>No choices available</p>
                          )}
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <div className="col-8 mx-auto col-md-12 text-center">
                  <span className="text-muted">
                    You have attempted this once and now it is locked. But go on
                    and see the other sections.
                  </span>
                </div>
              )}
              <Modal
                className="poppins"
                show={
                  this.state.responseStatus !== null &&
                  this.state.responseGif !== "..."
                }
                onHide={this.closeResponse}
                centered={true}
                size="lg"
              >
                <ModalHeader closeButton={true}>
                  <ModalTitle className="ms-auto">
                    {this.state.responseStatus
                      ? "That's correct"
                      : "That's incorrect"}
                  </ModalTitle>
                </ModalHeader>
                <ModalBody className="d-flex flex-column flex-md-row">
                  <span
                    className="align-self-center col-12 col-md-8 f-5"
                    style={{ fontSize: "18px", fontWeight: "600" }}
                  >
                    {this.state.responseText}
                  </span>
                  <UtImg
                    className="col-9 mx-auto col-md-4 mt-lg-0 mt-4"
                    src={this.state.responseGif}
                  />
                </ModalBody>
                <ModalFooter className="text-center">
                  <Button
                    className="mx-auto"
                    style={{
                      backgroundColor: "rgb(246, 148, 148)",
                      color: "rgb(30, 67, 99)",
                      fontWeight: "600",
                    }}
                    type="button"
                    variant="secondary"
                    onClick={this.closeResponse}
                  >
                    Okay
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal
                className="poppins"
                show={this.state.showbadge && !this.props.isFaceoff}
                onHide={this.closeScoreModal}
                centered={true}
              >
                <ModalHeader closeButton={true}>
                  <ModalTitle className="ms-auto px-4 text-center">
                    {this.state.score < 20
                      ? `You have scored ${this.state.score}%`
                      : `Congratulations you've scored ${this.state.score}%`}
                  </ModalTitle>
                </ModalHeader>
                <ModalBody className="d-flex flex-column align-items-center justify-content-center">
                  <h5 style={{ fontSize: "18px", fontWeight: "600" }}>
                    You've earned the following badge
                  </h5>
                  <Badge score={this.state.score} />
                  <p style={{ fontWeight: "600" }} className="text-center">
                    {this.state.score < 20 ? (
                      `Thank you for trying your best to trek through ${this.props?.title}. And now it's time for the next climb.`
                    ) : (
                      <>{this.props?.footer}</>
                    )}
                  </p>
                  <UtImg
                    className="w-100"
                    src={this.props.completion_image?.filePath}
                  />
                </ModalBody>
                <ModalFooter className="text-center">
                  <Button
                    type="button"
                    style={{
                      backgroundColor: "rgb(246, 148, 148)",
                      color: "rgb(30, 67, 99)",
                      fontWeight: "600",
                    }}
                    variant="secondary"
                    onClick={this.closeScoreModal}
                  >
                    Okay
                  </Button>
                </ModalFooter>
              </Modal>

              <Modal
                className="poppins"
                show={this.state.showbadge && this.props.isFaceoff}
                onHide={this.closeFaceoffScoreModal}
                centered={true}
              >
                <ModalHeader closeButton={true}>
                  <ModalTitle lassName="ms-auto px-4 text-center">
                    Congratulations on finishing the face off!
                  </ModalTitle>
                </ModalHeader>
                <ModalBody className="d-flex flex-column align-items-center justify-content-center">
                  <p style={{ fontWeight: "600" }} className="text-center">
                    And the winner is...
                    <br />
                    {this.state.learnerScore - this.state.parentScore > 0
                      ? "Learner"
                      : "Parent"}
                  </p>
                  {/* <p>{this.props?.footer}</p> */}
                </ModalBody>
                <ModalFooter>
                  <Button
                    type="button"
                    style={{
                      backgroundColor: "rgb(246, 148, 148)",
                      color: "rgb(30, 67, 99)",
                      fontWeight: "600",
                    }}
                    variant="secondary"
                    onClick={this.closeFaceoffScoreModal}
                  >
                    Okay
                  </Button>
                </ModalFooter>
              </Modal>
            </Container>
          )}
        </Container>
      );
    }
  }
}
