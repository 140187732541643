import React from "react";
import styled from "styled-components";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import FormCheck from "react-bootstrap/FormCheck";
import FormCheckInput from "react-bootstrap/FormCheckInput";
import FormCheckLabel from "react-bootstrap/FormCheckLabel";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { SurveySchema } from "../../atoms/schema";
import { app_api } from "../../atoms/api";

const SurveyContainer = styled(Container)`
  height: 80vh;
  overflow-y: auto;
  background-color: aquablue;
  border: 1px solid gray;
  border-radius: 10px;
`;

const FormLabell = styled(FormLabel)`
  margin: 30px 0 6px 0;
  font-weight: 500;
  font-size: 20px;
`;

export const SurveyForm = (props) => {
  return (
    <SurveyContainer fluid={true} className="px-3 px-md-5">
      <h4
        className="secondary-blue mt-5 mb-4"
        style={{ fontWeight: "600", fontSize: "35px" }}
      >
        Disclaimer
      </h4>
      <p className="fs-5">
        The form has been divided into two halves, one for the parent and one
        for the child. <br />
        Each part should be filled by the concerned person independently. We
        request that you answer the questions as honestly as you can. <br />
        By filling in this form, you consent to UnTaboo using this information
        for research and product development purposes. However, we assure you
        that your information will not be shared with any outside agencies.
      </p>
      <Formik
        initialValues={{
          name:
            props.enrollment?.learner?.parent?.first_name +
            " " +
            props.enrollment?.learner?.parent?.last_name,
          learner_name:
            props.enrollment?.learner?.first_name +
            " " +
            props.enrollment?.learner?.last_name,
          relationship: "",
          other_relationship: "",
          learner_age: "",
          other_learner_age: 13,
          learner_sex: "",
          other_learner_sex: "",
          learner_school: "",
          learner_school_board: "",
          other_learner_school_board: "",
          ease_of_navigation: "",
          hand_holding: "",
          time_taken: "",
          impact: "",
          yes_impact: [],
          program_value: "",
          suggestions: "",
          recommendation_probability: "",
          was_uncomfortable: "",
          yes_was_uncomfortable: "",
          program_likeliness: "",
          difficulty: "",
          ease_of_understanding: "",
          rating: {
            watch: "",
            learn: "",
            discuss: "",
            play: "",
            faceoff: "",
            scoreboard: "",
            moodboard: "",
            coupons: "",
          },
          ease_of_discussion: "",
          change: "",
          character: "",
        }}
        validationSchema={SurveySchema}
        enableReinitialize={true}
        onSubmit={(values) => {
          props.setLoading(true);
          app_api
            .post("/survey", { enrollment_id: props.enrollmentId, ...values })
            .then((res) => {
              props.setLoading(false);
              props.setSurveyedOn(new Date());
            })
            .catch((err) => {
              props.setLoading(false);
              props.setError(
                err?.response?.data?.message || "Error updating enrollment"
              );
            });
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          touched,
          errors,
          isValid,
        }) => {
          return (
            <Form noValidate={true} onSubmit={handleSubmit}>
              <div>
                <section
                  style={{
                    top: "0",
                    zIndex: "10",
                    backgroundColor: "#fef7e1",
                    borderBottom: "1px solid #000",
                  }}
                  className="py-3 position-sticky"
                >
                  <h3
                    className="secondary-blue m-0"
                    style={{ fontWeight: "600", fontSize: "25px" }}
                  >
                    Parent Section
                  </h3>
                </section>
                <FormGroup controlId="survey.name">
                  <FormLabell>Your name</FormLabell>
                  <FormControl
                    type="text"
                    name="name"
                    placeholder="Your name"
                    required={true}
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.name && !errors.name}
                    isInvalid={touched.name && !!errors.name}
                  />
                  {touched.name && (
                    <FormControl.Feedback type="invalid">
                      {errors.name}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.learner_name">
                  <FormLabell>Hey {values.name}, your child's name</FormLabell>
                  <FormControl
                    type="text"
                    name="learner_name"
                    placeholder="Learner's name"
                    required={true}
                    value={values.learner_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.learner_name && !errors.learner_name}
                    isInvalid={touched.learner_name && !!errors.learner_name}
                  />
                  {touched.learner_name && (
                    <FormControl.Feedback type="invalid">
                      {errors.learner_name}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.relationship">
                  <FormLabell>
                    Your relationship with {values.learner_name}
                  </FormLabell>
                  <Form.Check type="radio" id="relationship.Mother">
                    <FormCheckInput
                      type="radio"
                      value="Mother"
                      name="relationship"
                      required={true}
                      checked={values.relationship === "Mother"}
                      onChange={handleChange}
                      isValid={touched.relationship && !errors.relationship}
                      isInvalid={touched.relationship && !!errors.relationship}
                    />
                    <FormCheckLabel className="ms-2">Mother</FormCheckLabel>
                    {touched.relationship && (
                      <FormControl.Feedback type="invalid">
                        {errors.relationship}
                      </FormControl.Feedback>
                    )}
                  </Form.Check>
                  <Form.Check type="radio" id="relationship.Father">
                    <FormCheckInput
                      type="radio"
                      value="Father"
                      name="relationship"
                      required={true}
                      checked={values.relationship === "Father"}
                      onChange={handleChange}
                      isValid={touched.relationship && !errors.relationship}
                      isInvalid={touched.relationship && !!errors.relationship}
                    />
                    <FormCheckLabel className="ms-2">Father</FormCheckLabel>
                    {touched.relationship && (
                      <FormControl.Feedback type="invalid">
                        {errors.relationship}
                      </FormControl.Feedback>
                    )}
                  </Form.Check>
                  <Form.Check type="radio" id="relationship.Other">
                    <FormCheckInput
                      type="radio"
                      value="Other"
                      name="relationship"
                      required={true}
                      checked={values.relationship === "Other"}
                      onChange={handleChange}
                      isValid={touched.relationship && !errors.relationship}
                      isInvalid={touched.relationship && !!errors.relationship}
                    />
                    <FormCheckLabel className="ms-2">Other</FormCheckLabel>
                    {touched.relationship && (
                      <FormControl.Feedback type="invalid">
                        {errors.relationship}
                      </FormControl.Feedback>
                    )}
                  </Form.Check>
                  <FormControl
                    className="ms-2 w-75 w-md-50"
                    type="text"
                    name="other_relationship"
                    placeholder="Specify"
                    disabled={values.relationship !== "Other"}
                    value={values.other_relationship}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.other_relationship && !errors.other_relationship
                    }
                    isInvalid={
                      touched.other_relationship && !!errors.other_relationship
                    }
                  />
                  {touched.other_relationship && (
                    <FormControl.Feedback type="invalid">
                      {errors.other_relationship}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.learner_age">
                  <FormLabell>How old is {values.learner_name}</FormLabell>
                  <Form.Check type="radio" id="learner_age.9">
                    <FormCheckInput
                      type="radio"
                      value="9"
                      name="learner_age"
                      required={true}
                      checked={values.learner_age === "9"}
                      onChange={handleChange}
                      isValid={touched.learner_age && !errors.learner_age}
                      isInvalid={touched.learner_age && !!errors.learner_age}
                    />
                    <FormCheckLabel className="ms-2">9</FormCheckLabel>
                    {touched.learner_age && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_age}
                      </FormControl.Feedback>
                    )}
                  </Form.Check>
                  <Form.Check type="radio" id="learner_age.10">
                    <FormCheckInput
                      type="radio"
                      value="10"
                      name="learner_age"
                      required={true}
                      checked={values.learner_age === "10"}
                      onChange={handleChange}
                      isValid={touched.learner_age && !errors.learner_age}
                      isInvalid={touched.learner_age && !!errors.learner_age}
                    />
                    <FormCheckLabel className="ms-2">10</FormCheckLabel>
                    {touched.learner_age && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_age}
                      </FormControl.Feedback>
                    )}
                  </Form.Check>
                  <Form.Check type="radio" id="learner_age.11">
                    <FormCheckInput
                      type="radio"
                      value="11"
                      name="learner_age"
                      required={true}
                      checked={values.learner_age === "11"}
                      onChange={handleChange}
                      isValid={touched.learner_age && !errors.learner_age}
                      isInvalid={touched.learner_age && !!errors.learner_age}
                    />
                    <FormCheckLabel className="ms-2">11</FormCheckLabel>
                    {touched.learner_age && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_age}
                      </FormControl.Feedback>
                    )}
                  </Form.Check>
                  <Form.Check type="radio" id="learner_age.12">
                    <FormCheckInput
                      type="radio"
                      value="12"
                      name="learner_age"
                      required={true}
                      checked={values.learner_age === "12"}
                      onChange={handleChange}
                      isValid={touched.learner_age && !errors.learner_age}
                      isInvalid={touched.learner_age && !!errors.learner_age}
                    />
                    <FormCheckLabel className="ms-2">12</FormCheckLabel>
                    {touched.learner_age && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_age}
                      </FormControl.Feedback>
                    )}
                  </Form.Check>
                  <Form.Check type="radio" id="learner_age.other">
                    <FormCheckInput
                      type="radio"
                      value="Other"
                      name="learner_age"
                      required={true}
                      checked={values.learner_age === "Other"}
                      onChange={handleChange}
                      isValid={touched.learner_age && !errors.learner_age}
                      isInvalid={touched.learner_age && !!errors.learner_age}
                    />
                    <FormCheckLabel className="ms-2">Other</FormCheckLabel>
                    {touched.learner_age && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_age}
                      </FormControl.Feedback>
                    )}
                  </Form.Check>
                  <FormControl
                    className="ms-2 w-75 w-md-50"
                    min="1"
                    max="100"
                    type="number"
                    name="other_learner_age"
                    placeholder="Specify"
                    disabled={values.learner_age !== "Other"}
                    value={values.other_learner_age}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.other_learner_age && !errors.other_learner_age
                    }
                    isInvalid={
                      touched.other_learner_age && !!errors.other_learner_age
                    }
                  />
                  {touched.other_learner_age && (
                    <FormControl.Feedback type="invalid">
                      {errors.other_learner_age}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.learner_sex">
                  <FormLabell>
                    What is the sex of {values.learner_name}
                  </FormLabell>
                  <Form.Check type="radio" id="learner_sex.F">
                    <FormCheckInput
                      type="radio"
                      value="F"
                      name="learner_sex"
                      required={true}
                      checked={values.learner_sex === "F"}
                      onChange={handleChange}
                      isValid={touched.learner_sex && !errors.learner_sex}
                      isInvalid={touched.learner_sex && !!errors.learner_sex}
                    />
                    <FormCheckLabel className="ms-2">Female</FormCheckLabel>
                    {touched.learner_sex && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_sex}
                      </FormControl.Feedback>
                    )}
                  </Form.Check>
                  <Form.Check type="radio" id="learner_sex.M">
                    <FormCheckInput
                      type="radio"
                      value="M"
                      name="learner_sex"
                      required={true}
                      checked={values.learner_sex === "M"}
                      onChange={handleChange}
                      isValid={touched.learner_sex && !errors.learner_sex}
                      isInvalid={touched.learner_sex && !!errors.learner_sex}
                    />
                    <FormCheckLabel className="ms-2">Male</FormCheckLabel>
                    {touched.learner_sex && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_sex}
                      </FormControl.Feedback>
                    )}
                  </Form.Check>
                  <Form.Check type="radio" id="learner_sex.O">
                    <FormCheckInput
                      type="radio"
                      value="Other"
                      name="learner_sex"
                      required={true}
                      checked={values.learner_sex === "O"}
                      onChange={handleChange}
                      isValid={touched.learner_sex && !errors.learner_sex}
                      isInvalid={touched.learner_sex && !!errors.learner_sex}
                    />
                    <FormCheckLabel className="ms-2">Other</FormCheckLabel>
                    {touched.learner_sex && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_sex}
                      </FormControl.Feedback>
                    )}
                  </Form.Check>
                  <FormControl
                    className="ms-2 w-75 w-md-50"
                    type="text"
                    name="other_learner_sex"
                    placeholder="Specify"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.other_learner_sex}
                    disabled={values.learner_sex !== "Other"}
                    isValid={
                      touched.other_learner_sex && !errors.other_learner_sex
                    }
                    isInvalid={
                      touched.other_learner_sex && !!errors.other_learner_sex
                    }
                  />
                  {touched.other_learner_sex && (
                    <FormControl.Feedback type="invalid">
                      {errors.other_learner_sex}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.learner_school">
                  <FormLabell>
                    Which school does {values.learner_name} go to?
                  </FormLabell>
                  <FormControl
                    type="text"
                    placeholder="Name of school"
                    name="learner_school"
                    required={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.learner_school && !errors.learner_school}
                    isInvalid={
                      touched.learner_school && !!errors.learner_school
                    }
                  />
                  {touched.learner_school && (
                    <FormControl.Feedback type="invalid">
                      {errors.learner_school}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.learner_school_board">
                  <FormLabell>
                    Which board is the school, {values.learner_school}, affiliated to?
                  </FormLabell>
                  <Form.Check type="radio" id="learner_school_board.icse">
                    <FormCheckInput
                      type="radio"
                      value="ICSE"
                      name="learner_school_board"
                      required={true}
                      checked={values.learner_school_board === "ICSE"}
                      onChange={handleChange}
                      isValid={
                        touched.learner_school_board &&
                        !errors.learner_school_board
                      }
                      isInvalid={
                        touched.learner_school_board &&
                        !!errors.learner_school_board
                      }
                    />
                    <FormCheckLabel className="ms-2">ICSE</FormCheckLabel>
                    {touched.learner_school_board && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_school_board}
                      </FormControl.Feedback>
                    )}
                  </Form.Check>
                  <Form.Check type="radio" id="learner_school_board.igcse">
                    <FormCheckInput
                      type="radio"
                      value="IGCSE"
                      name="learner_school_board"
                      required={true}
                      checked={values.learner_school_board === "IGCSE"}
                      onChange={handleChange}
                      isValid={
                        touched.learner_school_board &&
                        !errors.learner_school_board
                      }
                      isInvalid={
                        touched.learner_school_board &&
                        !!errors.learner_school_board
                      }
                    />
                    <FormCheckLabel className="ms-2">IGCSE</FormCheckLabel>
                    {touched.learner_school_board && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_school_board}
                      </FormControl.Feedback>
                    )}
                  </Form.Check>
                  <Form.Check type="radio" id="learner_school_board.ib">
                    <FormCheckInput
                      type="radio"
                      value="IB"
                      name="learner_school_board"
                      required={true}
                      checked={values.learner_school_board === "IB"}
                      onChange={handleChange}
                      isValid={
                        touched.learner_school_board &&
                        !errors.learner_school_board
                      }
                      isInvalid={
                        touched.learner_school_board &&
                        !!errors.learner_school_board
                      }
                    />
                    <FormCheckLabel className="ms-2">IB</FormCheckLabel>
                    {touched.learner_school_board && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_school_board}
                      </FormControl.Feedback>
                    )}
                  </Form.Check>
                  <Form.Check type="radio" id="learner_school_board.cbse">
                    <FormCheckInput
                      type="radio"
                      value="CBSE"
                      name="learner_school_board"
                      required={true}
                      checked={values.learner_school_board === "CBSE"}
                      onChange={handleChange}
                      isValid={
                        touched.learner_school_board &&
                        !errors.learner_school_board
                      }
                      isInvalid={
                        touched.learner_school_board &&
                        !!errors.learner_school_board
                      }
                    />
                    <FormCheckLabel className="ms-2">CBSE</FormCheckLabel>
                    {touched.learner_school_board && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_school_board}
                      </FormControl.Feedback>
                    )}
                  </Form.Check>
                  <Form.Check type="radio" id="learner_school_board.ssc">
                    <FormCheckInput
                      type="radio"
                      value="SSC"
                      name="learner_school_board"
                      required={true}
                      checked={values.learner_school_board === "SSC"}
                      onChange={handleChange}
                      isValid={
                        touched.learner_school_board &&
                        !errors.learner_school_board
                      }
                      isInvalid={
                        touched.learner_school_board &&
                        !!errors.learner_school_board
                      }
                    />
                    <FormCheckLabel className="ms-2">SSC</FormCheckLabel>
                    {touched.learner_school_board && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_school_board}
                      </FormControl.Feedback>
                    )}
                  </Form.Check>
                  <Form.Check type="radio" id="learner_school_board.other">
                    <FormCheckInput
                      type="radio"
                      value="Other"
                      name="learner_school_board"
                      required={true}
                      checked={values.learner_school_board === "Other"}
                      onChange={handleChange}
                      isValid={
                        touched.learner_school_board &&
                        !errors.learner_school_board
                      }
                      isInvalid={
                        touched.learner_school_board &&
                        !!errors.learner_school_board
                      }
                    />
                    <FormCheckLabel className="ms-2">Other</FormCheckLabel>
                    {touched.learner_school_board && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_school_board}
                      </FormControl.Feedback>
                    )}
                  </Form.Check>
                  <FormControl
                    type="text"
                    className="ms-2 w-75 w-md-50"
                    name="other_learner_school_board"
                    placeholder="Specify"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.other_learner_school_board}
                    disabled={values.learner_school_board !== "Other"}
                    isValid={
                      touched.other_learner_school_board &&
                      !errors.other_learner_school_board
                    }
                    isInvalid={
                      touched.other_learner_school_board &&
                      !!errors.other_learner_school_board
                    }
                  />
                  {touched.learner_school_board && (
                    <FormControl.Feedback type="invalid">
                      {errors.learner_school_board}
                    </FormControl.Feedback>
                  )}
                  {touched.other_learner_school_board && (
                    <FormControl.Feedback type="invalid">
                      {errors.other_learner_school_board}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.ease_of_navigation">
                  <FormLabell>
                    How was your experience of navigating through our website?
                  </FormLabell>
                  <FormCheck
                    id="ease_of_navigation.very_difficult"
                    type="radio"
                    required={true}
                    name="ease_of_navigation"
                    value="very_difficult"
                    label="Very difficult"
                    checked={values.ease_of_navigation === "very_difficult"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.ease_of_navigation && !errors.ease_of_navigation
                    }
                    isInvalid={
                      touched.ease_of_navigation && !!errors.ease_of_navigation
                    }
                  />
                  <FormCheck
                    id="ease_of_navigation.difficult"
                    type="radio"
                    required={true}
                    name="ease_of_navigation"
                    value="difficult"
                    label="Difficult"
                    checked={values.ease_of_navigation === "difficult"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.ease_of_navigation && !errors.ease_of_navigation
                    }
                    isInvalid={
                      touched.ease_of_navigation && !!errors.ease_of_navigation
                    }
                  />
                  <FormCheck
                    id="ease_of_navigation.it_was_okay"
                    type="radio"
                    required={true}
                    name="ease_of_navigation"
                    value="it_was_okay"
                    label="Okay"
                    checked={values.ease_of_navigation === "it_was_okay"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.ease_of_navigation && !errors.ease_of_navigation
                    }
                    isInvalid={
                      touched.ease_of_navigation && !!errors.ease_of_navigation
                    }
                  />
                  <FormCheck
                    id="ease_of_navigation.easy"
                    type="radio"
                    required={true}
                    name="ease_of_navigation"
                    value="easy"
                    label="Easy"
                    checked={values.ease_of_navigation === "easy"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.ease_of_navigation && !errors.ease_of_navigation
                    }
                    isInvalid={
                      touched.ease_of_navigation && !!errors.ease_of_navigation
                    }
                  />
                  <FormCheck
                    id="ease_of_navigation.very_easy"
                    type="radio"
                    required={true}
                    name="ease_of_navigation"
                    value="very_easy"
                    label="Very easy"
                    checked={values.ease_of_navigation === "very_easy"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.ease_of_navigation && !errors.ease_of_navigation
                    }
                    isInvalid={
                      touched.ease_of_navigation && !!errors.ease_of_navigation
                    }
                  />
                </FormGroup>
                <FormGroup controlId="survey.hand_holding">
                  <FormLabell>
                    Did the child require hand-holding while doing the program?
                  </FormLabell>
                  <FormCheck
                    type="radio"
                    name="hand_holding"
                    id="hand_holding.yes"
                    label="Yes"
                    value="Yes"
                    checked={values.hand_holding === "Yes"}
                    required={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.hand_holding && !errors.hand_holding}
                    isInvalid={touched.hand_holding && !!errors.hand_holding}
                  />
                  <FormCheck
                    type="radio"
                    name="hand_holding"
                    id="hand_holding.sometimes"
                    label="Sometimes"
                    value="Sometimes"
                    checked={values.hand_holding === "Sometimes"}
                    required={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.hand_holding && !errors.hand_holding}
                    isInvalid={touched.hand_holding && !!errors.hand_holding}
                  />
                  <FormCheck
                    type="radio"
                    name="hand_holding"
                    id="hand_holding.chose"
                    label="I chose to be a part of it"
                    value="Chose"
                    checked={values.hand_holding === "Chose"}
                    required={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.hand_holding && !errors.hand_holding}
                    isInvalid={touched.hand_holding && !!errors.hand_holding}
                  />
                  <FormCheck
                    type="radio"
                    name="hand_holding"
                    id="hand_holding.wanted"
                    label="They wanted to do it independently"
                    value="Wanted"
                    checked={values.hand_holding === "Wanted"}
                    required={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.hand_holding && !errors.hand_holding}
                    isInvalid={touched.hand_holding && !!errors.hand_holding}
                  />
                  <FormCheck
                    type="radio"
                    name="hand_holding"
                    id="hand_holding.no"
                    label="No"
                    value="No"
                    checked={values.hand_holding === "No"}
                    required={true}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.hand_holding && !errors.hand_holding}
                    isInvalid={touched.hand_holding && !!errors.hand_holding}
                  />
                  {touched.hand_holding && (
                    <FormControl.Feedback type="invalid">
                      {errors.hand_holding}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.time_taken">
                  <FormLabell>
                    How long did it take {values.learner_name} to complete the
                    program?
                  </FormLabell>
                  <FormCheck
                    type="radio"
                    required={true}
                    name="time_taken"
                    id="time_taken.1"
                    label="1 day"
                    value="1"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.time_taken === "1"}
                    isValid={touched.time_taken && !errors.time_taken}
                    isInvalid={touched.time_taken && !!errors.time_taken}
                  />
                  <FormCheck
                    type="radio"
                    required={true}
                    name="time_taken"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="time_taken.2_5"
                    label="2 to 5 days"
                    value="2_5"
                    checked={values.time_taken === "2_5"}
                    isValid={touched.time_taken && !errors.time_taken}
                    isInvalid={touched.time_taken && !!errors.time_taken}
                  />
                  <FormCheck
                    type="radio"
                    required={true}
                    name="time_taken"
                    id="time_taken.6_14"
                    label="6 to 14 days"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="6_14"
                    checked={values.time_taken === "6_14"}
                    isValid={touched.time_taken && !errors.time_taken}
                    isInvalid={touched.time_taken && !!errors.time_taken}
                  />
                  <FormCheck
                    type="radio"
                    required={true}
                    name="time_taken"
                    id="time_taken.15_30"
                    label="15 to 30 days"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="15_30"
                    checked={values.time_taken === "15_30"}
                    isValid={touched.time_taken && !errors.time_taken}
                    isInvalid={touched.time_taken && !!errors.time_taken}
                  />
                  <FormCheck
                    type="radio"
                    required={true}
                    name="time_taken"
                    id="time_taken.gt30"
                    label="more than 30 days"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="gt30"
                    checked={values.time_taken === "gt30"}
                    isValid={touched.time_taken && !errors.time_taken}
                    isInvalid={touched.time_taken && !!errors.time_taken}
                  />
                  {touched.time_taken && (
                    <FormControl.Feedback type="invalid">
                      {errors.time_taken}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.impact">
                  <FormLabell>
                    Did this Program have any impact on {values.learner_name}?
                  </FormLabell>
                  <FormCheck
                    type="radio"
                    name="impact"
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue("yes_impact", []);
                    }}
                    onBlur={handleBlur}
                    id="impact.false"
                    required={true}
                    label="No"
                    value="false"
                    checked={values.impact === "false"}
                    isValid={touched.impact && !errors.impact}
                    isInvalid={touched.impact && !!errors.impact}
                  />
                  <FormCheck
                    type="radio"
                    name="impact"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="impact.true"
                    required={true}
                    label="Yes"
                    value="true"
                    checked={values.impact === "true"}
                    isValid={touched.impact && !errors.impact}
                    isInvalid={touched.impact && !!errors.impact}
                  />
                  <FormGroup
                    controlId="survey.yes_impact"
                    className={`${
                      values.impact === "true" ? "d-block" : "d-none"
                    } ms-2`}
                  >
                    <FormCheck
                      type="checkbox"
                      name="yes_impact"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="yes_impact.open"
                      label="Initiated open conversations"
                      value="open"
                      checked={values.yes_impact.includes("open")}
                      isValid={touched.yes_impact && !errors.yes_impact}
                      isInvalid={touched.yes_impact && !!errors.yes_impact}
                    />
                    <FormCheck
                      type="checkbox"
                      name="yes_impact"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="yes_impact.concept"
                      label="Clarified concepts"
                      value="concept"
                      checked={values.yes_impact.includes("concept")}
                      isValid={touched.yes_impact && !errors.yes_impact}
                      isInvalid={touched.yes_impact && !!errors.yes_impact}
                    />
                    <FormCheck
                      type="checkbox"
                      name="yes_impact"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="yes_impact.bond"
                      label="Created a stronger bond with parents"
                      value="bond"
                      checked={values.yes_impact.includes("bond")}
                      isValid={touched.yes_impact && !errors.yes_impact}
                      isInvalid={touched.yes_impact && !!errors.yes_impact}
                    />
                    <FormCheck
                      type="checkbox"
                      name="yes_impact"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="yes_impact.academic"
                      label="Improved academic focus"
                      value="academic"
                      checked={values.yes_impact.includes("academic")}
                      isValid={touched.yes_impact && !errors.yes_impact}
                      isInvalid={touched.yes_impact && !!errors.yes_impact}
                    />
                    <FormCheck
                      type="checkbox"
                      name="yes_impact"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="yes_impact.curiosity"
                      label="Increased Curiosity"
                      value="curiosity"
                      checked={values.yes_impact.includes("curiosity")}
                      isValid={touched.yes_impact && !errors.yes_impact}
                      isInvalid={touched.yes_impact && !!errors.yes_impact}
                    />
                    <FormCheck
                      type="checkbox"
                      name="yes_impact"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      id="yes_impact.distraction"
                      label="Led to distraction"
                      value="distraction"
                      checked={values.yes_impact.includes("distraction")}
                      isValid={touched.yes_impact && !errors.yes_impact}
                      isInvalid={touched.yes_impact && !!errors.yes_impact}
                    />
                    {touched.yes_impact && (
                      <FormControl.Feedback type="invalid">
                        {errors.yes_impact}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  {touched.impact && (
                    <FormControl.Feedback type="invalid">
                      {errors.impact}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.program_value">
                  <FormLabell>
                    What do you think the value of this program should be?
                  </FormLabell>
                  <FormCheck
                    type="radio"
                    required={true}
                    name="program_value"
                    id="program_value.-1500"
                    label="INR under 1,500"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="-1500"
                    checked={values.program_value === "-1500"}
                    isValid={touched.program_value && !errors.program_value}
                    isInvalid={touched.program_value && !!errors.program_value}
                  />{" "}
                  <FormCheck
                    type="radio"
                    required={true}
                    name="program_value"
                    id="program_value.1500-2000"
                    label="INR 1,500 - 2,000"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="1500-2000"
                    checked={values.program_value === "1500-2000"}
                    isValid={touched.program_value && !errors.program_value}
                    isInvalid={touched.program_value && !!errors.program_value}
                  />
                  <FormCheck
                    type="radio"
                    required={true}
                    name="program_value"
                    id="program_value.2000-2500"
                    label="INR 2,000 - 2,500"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value="2000-2500"
                    checked={values.program_value === "2000-2500"}
                    isValid={touched.program_value && !errors.program_value}
                    isInvalid={touched.program_value && !!errors.program_value}
                  />
                  <FormCheck
                    type="radio"
                    required={true}
                    name="program_value"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="program_value.2500-3000"
                    label="INR 2,500 - 3,000"
                    value="2500-3000"
                    checked={values.program_value === "2500-3000"}
                    isValid={touched.program_value && !errors.program_value}
                    isInvalid={touched.program_value && !!errors.program_value}
                  />
                  <FormCheck
                    type="radio"
                    required={true}
                    name="program_value"
                    id="program_value.3000"
                    label="More than INR 3,000"
                    value="3000+"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.program_value === "3000+"}
                    isValid={touched.program_value && !errors.program_value}
                    isInvalid={touched.program_value && !!errors.program_value}
                  />
                  {touched.program_value && (
                    <FormControl.Feedback type="invalid">
                      {errors.program_value}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.suggestions">
                  <FormLabell>
                    Do you have any suggestions for us to better the program or
                    any topics that you would like to be included?
                  </FormLabell>
                  <FormControl
                    type="text"
                    name="suggestions"
                    placeholder="Suggestions"
                    value={values.suggestions}
                    maxLength="1000"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.suggestions && !errors.suggestions}
                    isInvalid={touched.suggestions && !!errors.suggestions}
                  />
                  {touched.suggestions && (
                    <FormControl.Feedback type="invalid">
                      {errors.suggestions}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.recommendation_probability">
                  <FormLabell>
                    How likely would you recommend this program to someone?
                  </FormLabell>
                  <FormCheck
                    type="radio"
                    required={true}
                    name="recommendation_probability"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="recommendation_probability.1"
                    label="1 (least likely)"
                    value="1"
                    checked={values.recommendation_probability === "1"}
                    isValid={
                      touched.recommendation_probability &&
                      !errors.recommendation_probability
                    }
                    isInvalid={
                      touched.recommendation_probability &&
                      !!errors.recommendation_probability
                    }
                  />
                  <FormCheck
                    type="radio"
                    required={true}
                    name="recommendation_probability"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="recommendation_probability.2"
                    label="2"
                    value="2"
                    checked={values.recommendation_probability === "2"}
                    isValid={
                      touched.recommendation_probability &&
                      !errors.recommendation_probability
                    }
                    isInvalid={
                      touched.recommendation_probability &&
                      !!errors.recommendation_probability
                    }
                  />
                  <FormCheck
                    type="radio"
                    required={true}
                    name="recommendation_probability"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="recommendation_probability.3"
                    label="3"
                    value="3"
                    checked={values.recommendation_probability === "3"}
                    isValid={
                      touched.recommendation_probability &&
                      !errors.recommendation_probability
                    }
                    isInvalid={
                      touched.recommendation_probability &&
                      !!errors.recommendation_probability
                    }
                  />
                  <FormCheck
                    type="radio"
                    required={true}
                    name="recommendation_probability"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="recommendation_probability.4"
                    label="4"
                    value="4"
                    checked={values.recommendation_probability === "4"}
                    isValid={
                      touched.recommendation_probability &&
                      !errors.recommendation_probability
                    }
                    isInvalid={
                      touched.recommendation_probability &&
                      !!errors.recommendation_probability
                    }
                  />
                  <FormCheck
                    type="radio"
                    required={true}
                    name="recommendation_probability"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="recommendation_probability.5"
                    label="5 (most likely)"
                    value="5"
                    checked={values.recommendation_probability === "5"}
                    isValid={
                      touched.recommendation_probability &&
                      !errors.recommendation_probability
                    }
                    isInvalid={
                      touched.recommendation_probability &&
                      !!errors.recommendation_probability
                    }
                  />
                  {touched.recommendation_probability && (
                    <FormControl.Feedback type="invalid">
                      {errors.recommendation_probability}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.was_uncomfortable">
                  <FormLabell>
                    Did you feel uncomfortable with any of the content shared or
                    shown to your child?
                  </FormLabell>
                  <FormCheck
                    type="radio"
                    required={true}
                    name="was_uncomfortable"
                    id="was_uncomfortable.No"
                    label="No"
                    value="false"
                    onChange={(e) => {
                      handleChange(e);
                      setFieldValue("yes_was_uncomfortable", "");
                    }}
                    onBlur={handleBlur}
                    checked={values.was_uncomfortable === "false"}
                    isValid={
                      touched.was_uncomfortable && !errors.was_uncomfortable
                    }
                    isInvalid={
                      touched.was_uncomfortable && !!errors.was_uncomfortable
                    }
                  />
                  <FormCheck
                    type="radio"
                    required={true}
                    name="was_uncomfortable"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    id="was_uncomfortable.Yes"
                    label="Yes"
                    value="true"
                    checked={values.was_uncomfortable === "true"}
                    isValid={
                      touched.was_uncomfortable && !errors.was_uncomfortable
                    }
                    isInvalid={
                      touched.was_uncomfortable && !!errors.was_uncomfortable
                    }
                  />
                  <FormGroup
                    controlId="survey.yes_was_uncomfortable"
                    className={`${
                      values.was_uncomfortable === "true" ? "d-block" : "d-none"
                    } ms-2`}
                  >
                    <FormControl
                      type="text"
                      name="yes_was_uncomfortable"
                      placeholder="Why did you feel unconfortable?"
                      max="500"
                      required={true}
                      value={values.yes_was_uncomfortable}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      maxLength="500"
                      isValid={
                        touched.yes_was_uncomfortable &&
                        !errors.yes_was_uncomfortable
                      }
                      isInvalid={
                        touched.yes_was_uncomfortable &&
                        !!errors.yes_was_uncomfortable
                      }
                    />
                    {touched.yes_was_uncomfortable && (
                      <FormControl.Feedback type="invalid">
                        {errors.yes_was_uncomfortable}
                      </FormControl.Feedback>
                    )}
                  </FormGroup>
                  {touched.was_uncomfortable && (
                    <FormControl.Feedback type="invalid">
                      {errors.was_uncomfortable}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
              </div>
              <div>
                <section
                  style={{
                    top: "0",
                    zIndex: "10",
                    backgroundColor: "#fef7e1",
                    borderBottom: "1px solid #000",
                  }}
                  className="py-3 position-sticky"
                >
                  <h3
                    className="secondary-blue m-0"
                    style={{ fontWeight: "600", fontSize: "25px" }}
                  >
                    Children Section
                  </h3>
                </section>
                <FormGroup controlId="survey.program_likeliness">
                  <FormLabell>How did you find the program?</FormLabell>
                  <FormCheck
                    id="program_likeliness.hated"
                    type="radio"
                    name="program_likeliness"
                    label="Hated"
                    value="hated"
                    required={true}
                    checked={values.program_likeliness === "hated"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.program_likeliness && !errors.program_likeliness
                    }
                    isInvalid={
                      touched.program_likeliness && !!errors.program_likeliness
                    }
                  />
                  <FormCheck
                    id="program_likeliness.did_not_like_it"
                    type="radio"
                    name="program_likeliness"
                    label="Did not like it"
                    value="did_not_like_it"
                    required={true}
                    checked={values.program_likeliness === "did_not_like_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.program_likeliness && !errors.program_likeliness
                    }
                    isInvalid={
                      touched.program_likeliness && !!errors.program_likeliness
                    }
                  />
                  <FormCheck
                    id="program_likeliness.it_was_okay"
                    type="radio"
                    name="program_likeliness"
                    label="It was okay"
                    value="it_was_okay"
                    required={true}
                    checked={values.program_likeliness === "it_was_okay"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.program_likeliness && !errors.program_likeliness
                    }
                    isInvalid={
                      touched.program_likeliness && !!errors.program_likeliness
                    }
                  />
                  <FormCheck
                    id="program_likeliness.liked_it"
                    type="radio"
                    name="program_likeliness"
                    label="Liked It"
                    value="liked_it"
                    required={true}
                    checked={values.program_likeliness === "liked_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.program_likeliness && !errors.program_likeliness
                    }
                    isInvalid={
                      touched.program_likeliness && !!errors.program_likeliness
                    }
                  />
                  <FormCheck
                    id="program_likeliness.loved_it"
                    type="radio"
                    name="program_likeliness"
                    label="Loved It"
                    value="loved_it"
                    checked={values.program_likeliness === "loved_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.program_likeliness && !errors.program_likeliness
                    }
                    isInvalid={
                      touched.program_likeliness && !!errors.program_likeliness
                    }
                  />
                  {touched.program_likeliness && (
                    <FormControl.Feedback type="invalid">
                      {errors.program_likeliness}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.difficulty">
                  <FormLabell>
                    How easy was it to understand the topics taught in the
                    program?
                  </FormLabell>
                  <FormCheck
                    id="difficulty.very_difficult"
                    type="radio"
                    name="difficulty"
                    label="Very Difficult"
                    value="very_difficult"
                    checked={values.difficulty === "very_difficult"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.difficulty && !errors.difficulty}
                    isInvalid={touched.difficulty && !!errors.difficulty}
                  />
                  <FormCheck
                    id="difficulty.difficult"
                    type="radio"
                    name="difficulty"
                    label="Difficult"
                    value="difficult"
                    checked={values.difficulty === "difficult"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.difficulty && !errors.difficulty}
                    isInvalid={touched.difficulty && !!errors.difficulty}
                  />
                  <FormCheck
                    id="difficulty.it_was_okay"
                    type="radio"
                    name="difficulty"
                    label="It was okay"
                    value="it_was_okay"
                    checked={values.difficulty === "it_was_okay"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.difficulty && !errors.difficulty}
                    isInvalid={touched.difficulty && !!errors.difficulty}
                  />
                  <FormCheck
                    id="difficulty.easy"
                    type="radio"
                    name="difficulty"
                    label="Easy"
                    value="easy"
                    checked={values.difficulty === "easy"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.difficulty && !errors.difficulty}
                    isInvalid={touched.difficulty && !!errors.difficulty}
                  />
                  <FormCheck
                    id="difficulty.very_easy"
                    type="radio"
                    name="difficulty"
                    label="Very Easy"
                    value="very_easy"
                    checked={values.difficulty === "very_easy"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.difficulty && !errors.difficulty}
                    isInvalid={touched.difficulty && !!errors.difficulty}
                  />
                  {touched.difficulty && (
                    <FormControl.Feedback type="invalid">
                      {errors.difficulty}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.ease_of_understanding">
                  <FormLabell>Did you find the website easy to use?</FormLabell>
                  <FormCheck
                    id="ease_of_understanding.very_difficult"
                    type="radio"
                    name="ease_of_understanding"
                    required={true}
                    label="Very Difficult"
                    value="very_difficult"
                    checked={values.ease_of_understanding === "very_difficult"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.ease_of_understanding &&
                      !errors.ease_of_understanding
                    }
                    isInvalid={
                      touched.ease_of_understanding &&
                      !!errors.ease_of_understanding
                    }
                  />
                  <FormCheck
                    id="ease_of_understanding.difficult"
                    type="radio"
                    name="ease_of_understanding"
                    label="Difficult"
                    value="difficult"
                    required={true}
                    checked={values.ease_of_understanding === "difficult"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.ease_of_understanding &&
                      !errors.ease_of_understanding
                    }
                    isInvalid={
                      touched.ease_of_understanding &&
                      !!errors.ease_of_understanding
                    }
                  />
                  <FormCheck
                    id="ease_of_understanding.it_was_okay"
                    type="radio"
                    name="ease_of_understanding"
                    label="It was okay"
                    required={true}
                    value="it_was_okay"
                    checked={values.ease_of_understanding === "it_was_okay"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.ease_of_understanding &&
                      !errors.ease_of_understanding
                    }
                    isInvalid={
                      touched.ease_of_understanding &&
                      !!errors.ease_of_understanding
                    }
                  />
                  <FormCheck
                    id="ease_of_understanding.easy"
                    type="radio"
                    name="ease_of_understanding"
                    label="Easy"
                    required={true}
                    value="easy"
                    checked={values.ease_of_understanding === "easy"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.ease_of_understanding &&
                      !errors.ease_of_understanding
                    }
                    isInvalid={
                      touched.ease_of_understanding &&
                      !!errors.ease_of_understanding
                    }
                  />
                  <FormCheck
                    id="ease_of_understanding.very_easy"
                    type="radio"
                    name="ease_of_understanding"
                    label="Very Easy"
                    value="very_easy"
                    required={true}
                    checked={values.ease_of_understanding === "very_easy"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.ease_of_understanding &&
                      !errors.ease_of_understanding
                    }
                    isInvalid={
                      touched.ease_of_understanding &&
                      !!errors.ease_of_understanding
                    }
                  />
                  {touched.ease_of_understanding && (
                    <FormControl.Feedback type="invalid">
                      {errors.ease_of_understanding}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <h6 className="mt-5 f-25">
                  Rate the following program sections
                </h6>
                <FormGroup controlId="survey.rating.watch">
                  <FormLabell>1. Videos in watch section</FormLabell>
                  <FormCheck
                    id="watch.loved_it"
                    type="radio"
                    name="rating.watch"
                    label="Love"
                    value="loved_it"
                    required={true}
                    checked={values.rating.watch === "loved_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.watch && !errors.rating?.watch}
                    isInvalid={touched.rating?.watch && !!errors.rating?.watch}
                  />
                  <FormCheck
                    id="watch.liked_it"
                    type="radio"
                    name="rating.watch"
                    label="Like"
                    value="liked_it"
                    required={true}
                    checked={values.rating.watch === "liked_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.watch && !errors.rating?.watch}
                    isInvalid={touched.rating?.watch && !!errors.rating?.watch}
                  />

                  <FormCheck
                    id="watch.it_was_okay"
                    type="radio"
                    name="rating.watch"
                    label="Was okay"
                    value="it_was_okay"
                    required={true}
                    checked={values.rating.watch === "it_was_okay"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.watch && !errors.rating?.watch}
                    isInvalid={touched.rating?.watch && !!errors.rating?.watch}
                  />
                  <FormCheck
                    id="watch.did_not_like_it"
                    type="radio"
                    name="rating.watch"
                    label="Did not like"
                    value="did_not_like_it"
                    required={true}
                    checked={values.rating.watch === "did_not_like_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.watch && !errors.rating?.watch}
                    isInvalid={touched.rating?.watch && !!errors.rating?.watch}
                  />
                  <FormCheck
                    id="character.hated"
                    type="radio"
                    name="rating.watch"
                    label="Hated"
                    value="hated"
                    required={true}
                    checked={values.rating.watch === "hated"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.watch && !errors.rating?.watch}
                    isInvalid={touched.rating?.watch && !!errors.rating?.watch}
                  />
                  {touched.rating?.watch && (
                    <FormControl.Feedback type="invalid">
                      {errors.rating?.watch}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.rating.learn">
                  <FormLabell>2. Text in Learn section</FormLabell>
                  <FormCheck
                    id="learn.loved_it"
                    type="radio"
                    name="rating.learn"
                    label="Love"
                    value="loved_it"
                    required={true}
                    checked={values.rating.learn === "loved_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.learn && !errors.rating?.learn}
                    isInvalid={touched.rating?.learn && !!errors.rating?.learn}
                  />
                  <FormCheck
                    id="learn.liked_it"
                    type="radio"
                    name="rating.learn"
                    label="Like"
                    value="liked_it"
                    required={true}
                    checked={values.rating.learn === "liked_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.learn && !errors.rating?.learn}
                    isInvalid={touched.rating?.learn && !!errors.rating?.learn}
                  />

                  <FormCheck
                    id="learn.it_was_okay"
                    type="radio"
                    name="rating.learn"
                    label="Was okay"
                    value="it_was_okay"
                    required={true}
                    checked={values.rating.learn === "it_was_okay"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.learn && !errors.rating?.learn}
                    isInvalid={touched.rating?.learn && !!errors.rating?.learn}
                  />
                  <FormCheck
                    id="learn.did_not_like_it"
                    type="radio"
                    name="rating.learn"
                    label="Did not like"
                    value="did_not_like_it"
                    required={true}
                    checked={values.rating.learn === "did_not_like_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.learn && !errors.rating?.learn}
                    isInvalid={touched.rating?.learn && !!errors.rating?.learn}
                  />
                  <FormCheck
                    id="learn.hated"
                    type="radio"
                    name="rating.learn"
                    label="Hated"
                    value="hated"
                    required={true}
                    checked={values.rating.learn === "hated"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.learn && !errors.rating?.learn}
                    isInvalid={touched.rating?.learn && !!errors.rating?.learn}
                  />
                  {touched.rating?.learn && (
                    <FormControl.Feedback type="invalid">
                      {errors.rating?.learn}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.rating.discuss">
                  <FormLabell>3. Question in discuss section</FormLabell>
                  <FormCheck
                    id="discuss.loved_it"
                    type="radio"
                    name="rating.discuss"
                    label="Love"
                    value="loved_it"
                    required={true}
                    checked={values.rating.discuss === "loved_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.discuss && !errors.rating?.discuss}
                    isInvalid={
                      touched.rating?.discuss && !!errors.rating?.discuss
                    }
                  />
                  <FormCheck
                    id="discuss.liked_it"
                    type="radio"
                    name="rating.discuss"
                    label="Like"
                    value="liked_it"
                    required={true}
                    checked={values.rating.discuss === "liked_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.discuss && !errors.rating?.discuss}
                    isInvalid={
                      touched.rating?.discuss && !!errors.rating?.discuss
                    }
                  />

                  <FormCheck
                    id="discuss.it_was_okay"
                    type="radio"
                    name="rating.discuss"
                    label="Was okay"
                    value="it_was_okay"
                    required={true}
                    checked={values.rating.discuss === "it_was_okay"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.discuss && !errors.rating?.discuss}
                    isInvalid={
                      touched.rating?.discuss && !!errors.rating?.discuss
                    }
                  />
                  <FormCheck
                    id="discuss.did_not_like_it"
                    type="radio"
                    name="rating.discuss"
                    label="Did not like"
                    value="did_not_like_it"
                    required={true}
                    checked={values.rating.discuss === "did_not_like_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.discuss && !errors.rating?.discuss}
                    isInvalid={
                      touched.rating?.discuss && !!errors.rating?.discuss
                    }
                  />
                  <FormCheck
                    id="discuss.hated"
                    type="radio"
                    name="rating.discuss"
                    label="Hated"
                    value="hated"
                    required={true}
                    checked={values.rating.discuss === "hated"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.discuss && !errors.rating?.discuss}
                    isInvalid={
                      touched.rating?.discuss && !!errors.rating?.discuss
                    }
                  />
                  {touched.rating?.discuss && (
                    <FormControl.Feedback type="invalid">
                      {errors.rating?.discuss}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.rating.play">
                  <FormLabell>4. Quiz in play section</FormLabell>
                  <FormCheck
                    id="play.loved_it"
                    type="radio"
                    name="rating.play"
                    label="Love"
                    value="loved_it"
                    required={true}
                    checked={values.rating.play === "loved_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.play && !errors.rating?.play}
                    isInvalid={touched.rating?.play && !!errors.rating?.play}
                  />
                  <FormCheck
                    id="play.liked_it"
                    type="radio"
                    name="rating.play"
                    label="Like"
                    value="liked_it"
                    required={true}
                    checked={values.rating.play === "liked_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.play && !errors.rating?.play}
                    isInvalid={touched.rating?.play && !!errors.rating?.play}
                  />
                  <FormCheck
                    id="play.it_was_okay"
                    type="radio"
                    name="rating.play"
                    label="Was okay"
                    value="it_was_okay"
                    required={true}
                    checked={values.rating.play === "it_was_okay"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.play && !errors.rating?.play}
                    isInvalid={touched.rating?.play && !!errors.rating?.play}
                  />
                  <FormCheck
                    id="play.did_not_like_it"
                    type="radio"
                    name="rating.play"
                    label="Did not like"
                    value="did_not_like_it"
                    required={true}
                    checked={values.rating.play === "did_not_like_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.play && !errors.rating?.play}
                    isInvalid={touched.rating?.play && !!errors.rating?.play}
                  />
                  <FormCheck
                    id="play.hated"
                    type="radio"
                    name="rating.play"
                    label="Hated"
                    value="hated"
                    required={true}
                    checked={values.rating.play === "hated"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.play && !errors.rating?.play}
                    isInvalid={touched.rating?.play && !!errors.rating?.play}
                  />
                  {touched.rating?.play && (
                    <FormControl.Feedback type="invalid">
                      {errors.rating?.play}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.rating.faceoff">
                  <FormLabell>5. Faceoff with parents</FormLabell>
                  <FormCheck
                    id="faceoff.loved_it"
                    type="radio"
                    name="rating.faceoff"
                    label="Love"
                    value="loved_it"
                    required={true}
                    checked={values.rating.faceoff === "loved_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.faceoff && !errors.rating?.faceoff}
                    isInvalid={
                      touched.rating?.faceoff && !!errors.rating?.faceoff
                    }
                  />
                  <FormCheck
                    id="faceoff.liked_it"
                    type="radio"
                    name="rating.faceoff"
                    label="Like"
                    value="liked_it"
                    required={true}
                    checked={values.rating.faceoff === "liked_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.faceoff && !errors.rating?.faceoff}
                    isInvalid={
                      touched.rating?.faceoff && !!errors.rating?.faceoff
                    }
                  />

                  <FormCheck
                    id="faceoff.it_was_okay"
                    type="radio"
                    name="rating.faceoff"
                    label="Was okay"
                    value="it_was_okay"
                    required={true}
                    checked={values.rating.faceoff === "it_was_okay"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.faceoff && !errors.rating?.faceoff}
                    isInvalid={
                      touched.rating?.faceoff && !!errors.rating?.faceoff
                    }
                  />
                  <FormCheck
                    id="faceoff.did_not_like_it"
                    type="radio"
                    name="rating.faceoff"
                    label="Did not like"
                    value="did_not_like_it"
                    required={true}
                    checked={values.rating.faceoff === "did_not_like_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.faceoff && !errors.rating?.faceoff}
                    isInvalid={
                      touched.rating?.faceoff && !!errors.rating?.faceoff
                    }
                  />
                  <FormCheck
                    id="faceoff.hated"
                    type="radio"
                    name="rating.faceoff"
                    label="Hated"
                    value="hated"
                    required={true}
                    checked={values.rating.faceoff === "hated"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.faceoff && !errors.rating?.faceoff}
                    isInvalid={
                      touched.rating?.faceoff && !!errors.rating?.faceoff
                    }
                  />
                  {touched.rating?.faceoff && (
                    <FormControl.Feedback type="invalid">
                      {errors.rating?.faceoff}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.rating.scoreboard">
                  <FormLabell>6. The scoreboard in your dashboard</FormLabell>
                  <FormCheck
                    id="scoreboard.loved_it"
                    type="radio"
                    name="rating.scoreboard"
                    label="Love"
                    value="loved_it"
                    required={true}
                    checked={values.rating.scoreboard === "loved_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.rating?.scoreboard && !errors.rating?.scoreboard
                    }
                    isInvalid={
                      touched.rating?.scoreboard && !!errors.rating?.scoreboard
                    }
                  />
                  <FormCheck
                    id="scoreboard.liked_it"
                    type="radio"
                    name="rating.scoreboard"
                    label="Like"
                    value="liked_it"
                    required={true}
                    checked={values.rating.scoreboard === "liked_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.rating?.scoreboard && !errors.rating?.scoreboard
                    }
                    isInvalid={
                      touched.rating?.scoreboard && !!errors.rating?.scoreboard
                    }
                  />
                  <FormCheck
                    id="scoreboard.it_was_okay"
                    type="radio"
                    name="rating.scoreboard"
                    label="Was okay"
                    value="it_was_okay"
                    required={true}
                    checked={values.rating.scoreboard === "it_was_okay"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.rating?.scoreboard && !errors.rating?.scoreboard
                    }
                    isInvalid={
                      touched.rating?.scoreboard && !!errors.rating?.scoreboard
                    }
                  />
                  <FormCheck
                    id="scoreboard.did_not_like_it"
                    type="radio"
                    name="rating.scoreboard"
                    label="Did not like"
                    value="did_not_like_it"
                    required={true}
                    checked={values.rating.scoreboard === "did_not_like_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.rating?.scoreboard && !errors.rating?.scoreboard
                    }
                    isInvalid={
                      touched.rating?.scoreboard && !!errors.rating?.scoreboard
                    }
                  />
                  <FormCheck
                    id="scoreboard.hated"
                    type="radio"
                    name="rating.scoreboard"
                    label="Hated"
                    value="hated"
                    required={true}
                    checked={values.rating.scoreboard === "hated"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.rating?.scoreboard && !errors.rating?.scoreboard
                    }
                    isInvalid={
                      touched.rating?.scoreboard && !!errors.rating?.scoreboard
                    }
                  />
                  {touched.rating?.scoreboard && (
                    <FormControl.Feedback type="invalid">
                      {errors.rating?.scoreboard}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.rating.moodboard">
                  <FormLabell>7. Moodboard Download</FormLabell>
                  <FormCheck
                    id="moodboard.loved_it"
                    type="radio"
                    name="rating.moodboard"
                    label="Love"
                    value="loved_it"
                    required={true}
                    checked={values.rating.moodboard === "loved_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.rating?.moodboard && !errors.rating?.moodboard
                    }
                    isInvalid={
                      touched.rating?.moodboard && !!errors.rating?.moodboard
                    }
                  />
                  <FormCheck
                    id="moodboard.liked_it"
                    type="radio"
                    name="rating.moodboard"
                    label="Like"
                    value="liked_it"
                    required={true}
                    checked={values.rating.moodboard === "liked_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.rating?.moodboard && !errors.rating?.moodboard
                    }
                    isInvalid={
                      touched.rating?.moodboard && !!errors.rating?.moodboard
                    }
                  />
                  <FormCheck
                    id="moodboard.it_was_okay"
                    type="radio"
                    name="rating.moodboard"
                    label="Was okay"
                    value="it_was_okay"
                    required={true}
                    checked={values.rating.moodboard === "it_was_okay"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.rating?.moodboard && !errors.rating?.moodboard
                    }
                    isInvalid={
                      touched.rating?.moodboard && !!errors.rating?.moodboard
                    }
                  />
                  <FormCheck
                    id="moodboard.did_not_like_it"
                    type="radio"
                    name="rating.moodboard"
                    label="Did not like"
                    value="did_not_like_it"
                    required={true}
                    checked={values.rating.moodboard === "did_not_like_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.rating?.moodboard && !errors.rating?.moodboard
                    }
                    isInvalid={
                      touched.rating?.moodboard && !!errors.rating?.moodboard
                    }
                  />
                  <FormCheck
                    id="moodboard.hated"
                    type="radio"
                    name="rating.moodboard"
                    label="Did not download"
                    value="hated"
                    required={true}
                    checked={values.rating.moodboard === "hated"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.rating?.moodboard && !errors.rating?.moodboard
                    }
                    isInvalid={
                      touched.rating?.moodboard && !!errors.rating?.moodboard
                    }
                  />
                  {touched.rating?.moodboard && (
                    <FormControl.Feedback type="invalid">
                      {errors.rating?.moodboard}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.rating.coupons">
                  <FormLabell>8. Gift coupons</FormLabell>
                  <FormCheck
                    id="coupons.loved_it"
                    type="radio"
                    name="rating.coupons"
                    label="Love"
                    value="loved_it"
                    required={true}
                    checked={values.rating.coupons === "loved_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.coupons && !errors.rating?.coupons}
                    isInvalid={
                      touched.rating?.coupons && !!errors.rating?.coupons
                    }
                  />
                  <FormCheck
                    id="coupons.liked_it"
                    type="radio"
                    name="rating.coupons"
                    label="Like"
                    value="liked_it"
                    required={true}
                    checked={values.rating.coupons === "liked_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.coupons && !errors.rating?.coupons}
                    isInvalid={
                      touched.rating?.coupons && !!errors.rating?.coupons
                    }
                  />
                  <FormCheck
                    id="coupons.it_was_okay"
                    type="radio"
                    name="rating.coupons"
                    label="Was okay"
                    value="it_was_okay"
                    required={true}
                    checked={values.rating.coupons === "it_was_okay"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.coupons && !errors.rating?.coupons}
                    isInvalid={
                      touched.rating?.coupons && !!errors.rating?.coupons
                    }
                  />
                  <FormCheck
                    id="coupons.did_not_like_it"
                    type="radio"
                    name="rating.coupons"
                    label="Did not like"
                    value="did_not_like_it"
                    required={true}
                    checked={values.rating.coupons === "did_not_like_it"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.coupons && !errors.rating?.coupons}
                    isInvalid={
                      touched.rating?.coupons && !!errors.rating?.coupons
                    }
                  />
                  <FormCheck
                    id="coupons.hated"
                    type="radio"
                    name="rating.coupons"
                    label="Hated"
                    value="hated"
                    required={true}
                    checked={values.rating.coupons === "hated"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.rating?.coupons && !errors.rating?.coupons}
                    isInvalid={
                      touched.rating?.coupons && !!errors.rating?.coupons
                    }
                  />
                  {touched.rating?.coupons && (
                    <FormControl.Feedback type="invalid">
                      {errors.rating?.coupons}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.ease_of_discussion">
                  <FormLabell>
                    After doing the program, do you feel that it has become easy
                    for you to discuss these topics with your parents?
                  </FormLabell>
                  <FormCheck
                    id="ease_of_discussion.yes"
                    type="radio"
                    name="ease_of_discussion"
                    label="Yes"
                    value="yes"
                    checked={values.ease_of_discussion === "yes"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.ease_of_discussion && !errors.ease_of_discussion
                    }
                    isInvalid={
                      touched.ease_of_discussion && !!errors.ease_of_discussion
                    }
                  />
                  <FormCheck
                    id="ease_of_discussion.a_little"
                    type="radio"
                    name="ease_of_discussion"
                    label="A Little"
                    value="a_little"
                    checked={values.ease_of_discussion === "a_little"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.ease_of_discussion && !errors.ease_of_discussion
                    }
                    isInvalid={
                      touched.ease_of_discussion && !!errors.ease_of_discussion
                    }
                  />
                  <FormCheck
                    id="ease_of_discussion.unsure"
                    type="radio"
                    name="ease_of_discussion"
                    label="Unsure"
                    value="unsure"
                    checked={values.ease_of_discussion === "unsure"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.ease_of_discussion && !errors.ease_of_discussion
                    }
                    isInvalid={
                      touched.ease_of_discussion && !!errors.ease_of_discussion
                    }
                  />
                  <FormCheck
                    id="ease_of_discussion.not_much"
                    type="radio"
                    name="ease_of_discussion"
                    label="Not Much"
                    value="not_much"
                    checked={values.ease_of_discussion === "not_much"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.ease_of_discussion && !errors.ease_of_discussion
                    }
                    isInvalid={
                      touched.ease_of_discussion && !!errors.ease_of_discussion
                    }
                  />
                  <FormCheck
                    id="ease_of_discussion.no"
                    type="radio"
                    name="ease_of_discussion"
                    label="No"
                    value="no"
                    checked={values.ease_of_discussion === "no"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={
                      touched.ease_of_discussion && !errors.ease_of_discussion
                    }
                    isInvalid={
                      touched.ease_of_discussion && !!errors.ease_of_discussion
                    }
                  />
                  {touched.ease_of_discussion && (
                    <FormControl.Feedback type="invalid">
                      {errors.ease_of_discussion}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.change">
                  <FormLabell>
                    If you could add or change anything to this program what
                    would it be?
                  </FormLabell>
                  <FormControl
                    type="text"
                    name="change"
                    placeholder="Suggestions"
                    required={true}
                    value={values.change}
                    maxLength="1000"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.change && !errors.change}
                    isInvalid={touched.change && !!errors.change}
                  />
                  {touched.change && (
                    <FormControl.Feedback type="invalid">
                      {errors.change}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
                <FormGroup controlId="survey.character">
                  <FormLabell>
                    Which character did you like the most?
                  </FormLabell>
                  <FormCheck
                    id="character.neal"
                    type="radio"
                    name="character"
                    label="Neal"
                    value="neal"
                    checked={values.character === "neal"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                    isValid={touched.character && !errors.character}
                    isInvalid={touched.character && !!errors.character}
                  />
                  <FormCheck
                    id="character.kiara"
                    type="radio"
                    name="character"
                    label="Kiara"
                    value="kiara"
                    checked={values.character === "kiara"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                    isValid={touched.character && !errors.character}
                    isInvalid={touched.character && !!errors.character}
                  />
                  <FormCheck
                    id="character.pepper_the_cat"
                    type="radio"
                    name="character"
                    label="Pepper the cat"
                    value="pepper_the_cat"
                    checked={values.character === "pepper_the_cat"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                    isValid={touched.character && !errors.character}
                    isInvalid={touched.character && !!errors.character}
                  />
                  <FormCheck
                    id="character.none"
                    type="radio"
                    name="character"
                    label="None"
                    value="none"
                    checked={values.character === "none"}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required={true}
                    isValid={touched.character && !errors.character}
                    isInvalid={touched.character && !!errors.character}
                  />
                  {touched.change && (
                    <FormControl.Feedback type="invalid">
                      {errors.change}
                    </FormControl.Feedback>
                  )}
                </FormGroup>
              </div>
              <Button
                variant="primary my-4 py-2 px-5"
                type="submit"
                disabled={props.loading || !isValid}
              >
                Submit
              </Button>
              {props.error && (
                <Alert
                  variant="danger"
                  dismissible={true}
                  onClose={props.dismissError}
                >
                  {props.error}
                </Alert>
              )}
              {!isValid && (
                <Alert variant="danger">
                  Some field(s) is/are still required. Check for anything red.
                </Alert>
              )}
            </Form>
          );
        }}
      </Formik>
    </SurveyContainer>
  );
};
