import React, { useLayoutEffect } from "react";
import { Link } from "react-router-dom";
import contact_img1 from "../../assets/images/contactus_img.png";
import contact_img2 from "../../assets/images/video-bg-shape.png";
import Button from "../../atoms/button";
import emailjs from "emailjs-com";

// import "../../css/style.css";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";
import axios from "axios";
import { useState } from "react";
import { app_api } from "../../atoms/api";

const ContactCheck = () => {
  var Check = document.getElementById("expand");
  var more = document.getElementById("moreoptions");
  if (Check.checked == true) {
    more.style.display = "block";
  } else {
    more.style.display = "none";
  }
};

export const Contact = (props) => {
  //   useLayoutEffect(() => {
  //     window.scrollTo(0, 0)
  // });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  function sendEmail(e) {
    setLoading(true);
    e.preventDefault();
    const formData = new FormData(e.target);
    let values = {};
    formData.forEach((value, key) => {
      values = {
        ...values,
        [key]: !!key.match(/Interest/)
          ? Array.from(values[key] || []).concat(value)
          : value || "",
      };
    });
    // console.log(values);
    if (
      // values.other !== "on" &&
      !values.primaryInterest &&
      !values.secondaryInterest
    ) {
      setError("Please select atleast one option");
      setLoading(false);
      return;
    }
    app_api
      .post("/user/contact/us", values)
      .then((res) => {
        setLoading(false);
        setSuccess(true);
        setError(null);
        setTimeout(() => {
          setSuccess(false);
        }, 2000);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Error");
      });
  }

  document.title = "contacts";
  return (
    <div className="Yanone_Kaffeesatz">
      <Container className="mt-10  container">
        <div className="col-12 px-4 px-lg-0 position-relative  secondary-blue  ">
          <div className="col-lg-8 col-12 ">
            <h3 className="Yanone_Kaffeesatz-700 f-55 f-sm-38">
              HEY, THANK YOU SO MUCH FOR WANTING TO REACH OUT TO US. WE WOULD
              ABSOLUTELY LOVE TO HEAR FROM YOU.
            </h3>
          </div>
          <div className="d-flex  flex-lg-row flex-column justify-content-lg-between">
            <div className="mt-5 col-lg-4 col-12">
              <h3 className="secondary-blue Yanone_Kaffeesatz-700 f-40">
                FILL &amp; CONNECT
              </h3>
              <p
                className="mt-2 fs-5 poppins"
                style={{ color: "#656565", lineHeight: "1.7" }}
              >
                Fill out the form below &amp; we will connect with you at the
                soonest.
              </p>
              <p
                className="mt-lg-5 mt-2 fs-5 poppins"
                style={{ color: "#656565", lineHeight: "1.7" }}
              >
                If the program you are interested in is not active yet, filling
                out the form below will ensure that you would be among the first
                to be informed once the program goes live.
              </p>
            </div>
            <div className="text-center col-lg-6 col-12">
              <img className="w-80" src={contact_img1} alt="aboutus_asset1" />
            </div>
          </div>
          <img
            className="col-md-6 d-none d-lg-block position-absolute top-0 end-0"
            src={contact_img2}
            alt="aboutus_asset2"
          />
        </div>

        <div
          className="col-lg-6 col-12 px-4 px-lg-0 mt-5 "
          style={{ color: "#282828" }}
        >
          <form onSubmit={sendEmail} className="poppins">
            <input
              style={{ color: "#282828" }}
              className="form-control mt-4"
              name="name"
              placeholder="Full Name"
              type="text"
              required
            />
            <input
              style={{ color: "#282828" }}
              className="form-control mt-4"
              name="user_email"
              placeholder="Email"
              type="email"
              required
            />
            <input
              style={{ color: "#282828" }}
              className="form-control mt-4"
              name="phone"
              min="10000000"
              placeholder="Phone"
              type="number"
              required
            />
            <input
              style={{ color: "#282828" }}
              className="form-control mt-4"
              name="city"
              placeholder="City &amp; State"
              type="text"
              required
            />
            <p className="mt-5 fs-5  poppins" style={{ color: "#282828" }}>
              I am interested in:
            </p>
            <ul
              style={{ listStyle: "none", color: "#282828" }}
              className="form-check  fs-5 ms-3 poppins"
            >
              <li className="mb-3">
                <input
                  style={{ color: "#282828" }}
                  type="checkbox"
                  name="primaryInterest"
                  className="form-check-input -3"
                  id="expand"
                  value="Gamified Self Learning Programs for my child"
                  onClick={ContactCheck}
                />
                <label htmlFor="expand">
                  Gamified Self Learning Programs for my child
                </label>
              </li>
              <ul id="moreoptions" style={{ display: "none" }}>
                <li>
                  <input
                    style={{ color: "#282828" }}
                    type="checkbox"
                    name="secondaryInterest"
                    className="form-check-input -3"
                    id="exampleCheck1_a"
                    value="Puberty Adventure for Boys (9-12 year-old boys)"
                  />
                  <label htmlFor="exampleCheck1_a">
                    Puberty Adventure for Boys (9-12 year-old boys)
                  </label>
                </li>

                <li>
                  <input
                    style={{ color: "#282828" }}
                    type="checkbox"
                    name="secondaryInterest"
                    className="form-check-input -3"
                    id="exampleCheck1_b"
                    value=" Puberty Adventure for Girls (9-12 year-old girls)"
                  />
                  <label htmlFor="exampleCheck1_b">
                    Puberty Adventure for Girls (9-12 year-old girls)
                  </label>
                </li>

                <li>
                  <input
                    style={{ color: "#282828" }}
                    type="checkbox"
                    name="secondaryInterest"
                    className="form-check-input -3"
                    id="exampleCheck1_c"
                    value=" Sexual Literacy (13-15-year-old girls and boys)"
                  />
                  <label htmlFor="exampleCheck1_c">
                    Sexual Literacy (13-15-year-old girls and boys)
                  </label>
                </li>

                <li>
                  <input
                    style={{ color: "#282828" }}
                    type="checkbox"
                    name="secondaryInterest"
                    className="form-check-input -3"
                    id="exampleCheck1_d"
                    value=" Safety: Personal &amp; Online (13- 18-year-old girls and boys)"
                  />
                  <label htmlFor="exampleCheck1_d">
                    Safety: Personal &amp; Online (13- 18-year-old girls and
                    boys)
                  </label>
                </li>

                <li>
                  <input
                    style={{ color: "#282828" }}
                    type="checkbox"
                    name="secondaryInterest"
                    className="form-check-input -3"
                    id="exampleCheck1_e"
                    value="Sex | Sexuality | Safety Awareness (16 -18-year-old girls and
                  boys)"
                  />
                  <label htmlFor="exampleCheck1_e">
                    Sex | Sexuality | Safety Awareness (16 -18-year-old girls
                    and boys)
                  </label>
                </li>

                <li>
                  <input
                    style={{ color: "#282828" }}
                    type="checkbox"
                    name="secondaryInterest"
                    className="form-check-input -3"
                    id="exampleCheck1_f"
                    value=" Smoking | Alcohol | Drugs: Resistance &amp; Responsibility (13
                  -18-year-old girls and boys)"
                  />
                  <label htmlFor="exampleCheck1_f">
                    Smoking | Alcohol | Drugs: Resistance &amp; Responsibility
                    (13 -18-year-old girls and boys)
                  </label>
                </li>
              </ul>

              <li className="mb-3 mt-3">
                <input
                  style={{ color: "#282828" }}
                  type="checkbox"
                  name="primaryInterest"
                  className="form-check-input -3"
                  id="exampleCheck2"
                  value="Online School Workshops"
                />
                <label htmlFor="exampleCheck2">
                  Online Zoom School Workshops
                </label>
              </li>
              <li className="mb-3">
                {" "}
                <input
                  style={{ color: "#282828" }}
                  type="checkbox"
                  name="primaryInterest"
                  className="form-check-input -3"
                  id="exampleCheck3"
                  value="In-person School Workshop"
                />
                <label htmlFor="exampleCheck3">
                  In-person School Workshops
                </label>
              </li>
              <li className="mb-3">
                {" "}
                <input
                  style={{ color: "#282828" }}
                  type="checkbox"
                  name="primaryInterest"
                  className="form-check-input -3"
                  id="exampleCheck4"
                  value="Mind Unblocking &amp; Empowering - for Parents"
                />
                <label htmlFor="exampleCheck4">Parent Awareness Talks</label>
              </li>
              <li className="mb-3">
                <input
                  style={{ color: "#282828" }}
                  type="checkbox"
                  name="primaryInterest"
                  className="form-check-input -3"
                  id="exampleCheck5"
                  value="Mind Unblocking &amp; Empowering - for Educators"
                />
                <label htmlFor="exampleCheck5">Educators Awareness Talks</label>
              </li>
              <li className="mb-3">
                <input
                  style={{ color: "#282828" }}
                  type="checkbox"
                  name="primaryInterest"
                  className="form-check-input -3"
                  id="exampleCheck6"
                  value="Join Team UnTaboo"
                />
                <label htmlFor="exampleCheck6">Train The Teacher</label>
              </li>
              <li className="mb-3">
                <input
                  style={{ color: "#282828" }}
                  type="checkbox"
                  name="primaryInterest"
                  className="form-check-input -3"
                  id="exampleCheck7"
                  value="Join Team UnTaboo"
                />
                <label htmlFor="exampleCheck7">Join Team UnTaboo</label>
              </li>

              <li className="mb-3">
                <input
                  style={{ color: "#282828" }}
                  type="checkbox"
                  name="primaryInterest"
                  className="form-check-input -3"
                  id="exampleCheck8"
                  value="Partnerships &amp; Collaborations"
                />
                <label htmlFor="exampleCheck8">
                  Partnerships &amp; Collaborations
                </label>
              </li>
              {/* <li className="mb-3">
                {" "}
                <input
                  style={{ color: "#282828" }}
                  type="checkbox"
                  name="other"
                  className="form-check-input -3"
                  id="expandInput"
                  // onClick={ContactInput}
                />
                <label htmlFor="expandInput">Other</label>
              </li>

              <ul id="inputbox" style={{ display: "none" }}>
                <li>
                  <textarea
                    className="form-control mt-4"
                    name="other_value"
                    placeholder="Type here..."
                    type="textarea"
                    style={{ maxHeight: "80px" }}
                  />
                  <br></br>
                </li>
              </ul> */}
            </ul>

            <Button
              className="mt-4 me-2 poppins"
              type="submit"
              disabled={loading}
            >
              {loading ? "Submitting" : "Submit"}
            </Button>
            {error && <span className="text-danger">{error}</span>}
            {success && (
              <span className="text-success">
                We have received your request
              </span>
            )}
          </form>
        </div>
        <div className="col-lg-6 px-4 px-lg-0 col-12 my-10 text-center text-lg-start ">
          <h3 className="secondary-blue fs-1  Yanone_Kaffeesatz-700">
            Other ways to reach us
          </h3>
          <a
            href="mailto:team.untaboo@gamil.com"
            className="fs-5 mb-2 mt-4  poppins"
          >
            <b>Email:</b> team.untaboo@gmail.com
          </a>
          <p className="fs-5 mb-2  poppins">
            <b>Work Hours:</b> Monday - Saturday, 11am - 6:00pm
          </p>
          <div className="  col-8 pt-3 mx-lg-0 mx-auto">
            <div className="col-lg-6 col-9  mx-auto mx-lg-0 d-flex  justify-content-between">
              <div className="footer-icons d-flex align-items-center  justify-content-center text-center">
                <a
                  target="_blank"
                  href="https://www.facebook.com/untabooers"
                  className=" d-flex align-items-center  justify-content-center text-center"
                >
                  <FaFacebook style={{ fontSize: "20px" }} />
                </a>
              </div>
              <div className="footer-icons d-flex align-items-center justify-content-center text-center">
                <a
                  target="_blank"
                  href="https://www.instagram.com/untaboo"
                  className=" d-flex align-items-center  justify-content-center text-center"
                >
                  <FaInstagram style={{ fontSize: "20px" }} />
                </a>
              </div>
              <div className="footer-icons d-flex align-items-center justify-content-center text-center">
                <a
                  target="_blank"
                  href="https://twitter.com/untabooers?lang=en"
                  className=" d-flex align-items-center  justify-content-center text-center"
                >
                  <FaTwitter style={{ fontSize: "20px" }} />
                </a>
              </div>
              <div className="footer-icons d-flex align-items-center justify-content-center text-center">
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/untaboo-a-sex-education-company/?original_referer=https%3A%2F%2Funtaboo.online%2F "
                  className=" d-flex align-items-center  justify-content-center text-center"
                >
                  <FaLinkedinIn style={{ fontSize: "20px" }} />
                </a>
              </div>
            </div>
          </div>
          <Link to="/schools">
            <Button className="mt-5 poppins">
              Click here for School/College/Organization partnerships
            </Button>
          </Link>
        </div>
      </Container>

      <div
        className="mt-5 pt-10 pb-10 poppins px-4 px-lg-0"
        style={{ backgroundColor: "#F3F3F3" }}
      >
        <Container className="text-center ">
          <h3 className="f-40">
            <b>
              <u>Be a part of the change - Be an Untabooer!</u>
            </b>
          </h3>
          <h3 className="mt-5 primary-color">
            <b>Because when we talk – we educate</b>
          </h3>
          <h3 style={{ color: "#379FBF" }}>
            <b>When we educate – we create responsibility</b>
          </h3>
          <h3 style={{ color: "#1D7AB3" }}>
            <b>When we create responsibility – change happens</b>
          </h3>

          <h3 className="fs-2 secondary-blue">
            <b>
              Let's partner to raise a positively aware &amp; responsible
              generation
            </b>
          </h3>
        </Container>
      </div>

      <div
        className="pb-10 pt-10 poppins"
        style={{ backgroundColor: "#B7c4D1" }}
      >
        <Container>
          <div className="col-lg-12 text-center col-12 px-4 px-lg-0">
            <h3 className="secondary-blue text-center  fs-1 fw-bold">
              READY TO START?
            </h3>
            <p
              className="col-12 text-center col-lg-7 secondary-blue mx-auto mt-4 poppins f-25"
              style={{ fontWeight: "600", lineHeight: "1.7" }}
            >
              Join thousands of parents and teachers from across India,
              consciously empowering their children and teens with Safety and
              Sex education.
            </p>
            <div className="mt-5  text-center col-12  ">
              <a href="/#programDetails">
                <Button
                  type="button"
                  className="btn btn-light poppins mb-lg-0 mb-3"
                  style={{ backgroundColor: "white", color: "#415094" }}
                >
                  Show me how
                </Button>
              </a>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
