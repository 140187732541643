import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import ProgressBar from "react-bootstrap/ProgressBar";
import Assest from "../../assets/images/neel_kiara.jpg";
import badge from "../../assets/images/badge.png";
import Spinner from "react-bootstrap/Spinner";
import { Badge } from "../../atoms/image/badge";
import { app_api } from "../../atoms/api";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";

export const SchoolDetails = (props) => {
  const [school, setSchool] = useState({});
  const [program, setProgram] = useState({});
  const [levelCount, setLevelCount] = useState(1);
  const [ploading, setploading] = useState(true);
  const [perror, setperror] = useState(null);
  const [enrollments, setEnrollments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const params = useParams();

  useEffect(() => {
    try {
      let school = JSON.parse(Cookies.get("ut_school"));
      let programid = params.program_id;
      setSchool(school);
      app_api
        .get(`programs/public/id/${programid}`)
        .then((res) => {
          setploading(false);
          setProgram(res.data);
          setLevelCount(res.data.level_sequence?.length || 1);
        })
        .catch((err) => {
          setploading(false);
          setperror(
            err?.response?.data?.message || "Error getting program details"
          );
        });
      app_api
        .get(`enrollment/school/${school.id}/program/${programid}`)
        .then((res) => {
          setLoading(false);
          setEnrollments(res.data);
        })
        .catch((err) => {
          setLoading(false);
          setError(err?.response?.data?.message || "Error getting enrollments");
        });
    } catch {
      // do nothing
    }
  }, [school.id]);

  return (
    <Container className="my-3 bg-white rounded-3 poppins mt-10 ">
      <h1
        className=" secondary-blue f-55 f-sm-38 mb-5"
        style={{ color: "#1e4363" }}
      >
        <b>{program?.name || "Program name"}</b>
      </h1>
      <div
        className="p-3"
        style={{ boxShadow: "rgb(0 0 0 / 20%) 0px 3px 10px" }}
      >
        <h2 className="p-3 dashboard-blue" style={{ fontSize: "35px" }}>
          Learner details
        </h2>
        <Table responsive className="p-3">
          <thead>
            <tr>
              <th className="dashboard-blue fs-5">Learner</th>
              <th className="dashboard-blue fs-5">Progress</th>
              <th></th>
              <th className="dashboard-blue fs-5">Class</th>
              <th className="dashboard-blue fs-5">Div</th>
              <th className="dashboard-blue fs-5">Comparision Report</th>
            </tr>
          </thead>
          <tbody>
            {enrollments.map((e, index) => (
              <tr key={index}>
                <td className="w-20 align-middle ">
                  <div className="d-flex align-items-center">
                    <img className="w-20 rounded me-2" src={Assest} />
                    {e?.learner?.first_name + " " + e?.learner?.last_name}
                  </div>
                </td>
                <td className=" align-middle">
                  <div className="d-flex align-items-center justify-content-">
                    <div
                      className="col-8 rounded-2"
                      style={{ backgroundColor: "#F3F6F9", height: "5px" }}
                    >
                      <div
                        style={{
                          backgroundColor: "#6ECBD0",
                          width: `${(e?.current_level / levelCount) * 100}%`,
                          height: "5px",
                        }}
                      ></div>
                    </div>
                  </div>
                </td>
                <td className="align-middle">
                  {((e?.current_level / levelCount) * 100).toFixed(0)}%
                </td>
                <td className="align-middle">{e?.learner?.class}</td>
                <td className="align-middle">{e?.learner?.division}</td>
                <td className="align-middle">
                  {e?.learner_comparision_report ? (
                    <a
                      href={
                        process.env.REACT_APP_BACKEND_URL +
                        e?.learner_comparision_report
                      }
                    >
                      View
                    </a>
                  ) : (
                    "N/A"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </Container>
  );
};
export default SchoolDetails;
