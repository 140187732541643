import React from "react";
import { Accordion } from "react-bootstrap";

export const Faq = () => {
  return (
    <Accordion
      className="mb-3 rounded-3 px-4 px-lg-0"
      style={{ fontSize: "17px" }}
    >
      <Accordion.Item eventKey="0" className="accordion-item mt-4">
        <Accordion.Header
          style={{ color: "#1e4363", fontSize: "20px!important" }}
        >
          How will UnTaboo’s Safety & Sex Ed programs help our students?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Adolescence is considered one of the most stressful phases in a
          person's life. Our programs help children & teens navigate these
          tricky years easily. Moreover research worldwide has shown that
          age-appropriate Safety & Sex Education helps children be positively
          aware,be better prepared for bodily and emotional changes, resist peer
          pressure, delay experimentation, make better decisions and keep
          themselves safe.<br></br>It provides them with the skills to grow up
          healthy and safe with the right attitudes. It also strengthens their
          interpersonal relationships and makes them more focussed academically.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Is one session enough or do students need this every year?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Safety & Sex Education is not a one-time talk but an ongoing
          conversation - from childhood to adulthood and sometimes even beyond.
          Our grade wise Programs are designed by experts to cater to the
          developmental needs of a child at every stage. From explaining
          concepts simply to helping shape their thinking, from helping them
          develop coping skills to teaching them to be safe….we are with you
          from start to finish, to help nurture your students into well-informed
          and responsible young adults.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Is doing an online program as impactful as an offline in-person
          program?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Our online programs have been designed using the best learnings from
          our highly successful offline Workshops, which have been perfected by
          engaging with lakhs of children, parents, and educators across India.
          <br></br>
          Our unique pedagogy enables children to take these online programs
          independently and retain the learnings with ease.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Most children today have low attention spans – will they be able to do
          this Online program?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Our programs are designed keeping in mind the low attention span of
          children. We combine education with entertainment using videos &
          games, both in the online and in-person format.<br></br> We also
          ensure that various learning Styles are catered to in the program
          design to keep them engaged and make things relatable, retainable, and
          enjoyable.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header style={{ color: "#1e4363" }}>
          What is the flow of the Online Programs once we sign up?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          We have a quick & structured on-boarding process for schools. Here is
          what happens once you sign up with us:
          <ol>
            <li>Grade-wise login IDs for students are created & shared</li>
            <li>Students login using those and start their program</li>
            <li>They have access for 8 weeks</li>
            <li>
              A dashboard is provided to the school to monitor student progress
            </li>
            <li>
              At the end of 8 weeks, schools can choose to sign up students for
              a live Q&A webinar with UnTaboo experts.
            </li>
          </ol>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Will we be able to see the program before giving it to our students?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          You get to see all content topics and key learnings of the programs we
          offer. For online programs, you will also be able to view a complete
          demo level before purchase. And post purchase, we will be happy to
          give access to the school to view the entire program. You will also be
          able to track student progress through the School Dashboard.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header style={{ color: "#1e4363" }}>
          What equipment do the students need for the program?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          You just need a device with internet connection - Laptop, Tablet or
          Mobile Phone. However, we do recommend a laptop or tablet, so it is
          easier for the child to read but even a mobile phone with a large
          enough screen works well.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Will we be able to download the program?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          The program is not downloadable. The child has to go online to access
          it. However, we do give the child and parent some downloadable
          resources as part of the learning.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="9">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Once we purchase, is it ours to keep forever?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          This program takes on average 5 hours to complete. Even if a child
          spares just one hour a week to do the program, it will take 5 weeks to
          finish it. But you will have access to the program for 8 weeks i.e. 2
          months and during this time period, you can access the content as
          often as you like.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="10">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Are these programs available in other countries?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Our Online programs are developed by experts and universally relevant,
          as the changes and concerns while growing up are more or less the same
          for children across the globe. They can be purchased and accessed from
          any corner of the world. We also do zoom online sessions for students
          from other countries.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="11">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Is it possible to do these programs in Offline mode?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          We offer all options and are happy to customise it as per your need.
          We recommend going for a hybrid online-offline combination for maximum
          impact, but are happy to support you in the direction you want to
          head. Please contact us for options to suit your requirement.
        </Accordion.Body>
      </Accordion.Item>

      <Accordion.Item eventKey="12">
        <Accordion.Header style={{ color: "#1e4363" }}>
          How do we get our parents to understand the value of these Safety &
          Sex Ed programs?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          UnTaboo has this covered. We offer a very impactful and interactive
          Mind-Unblocking & Awareness talk for Parents & Teachers to get them
          onboard. From there on, we march together as one in this journey to
          nurture a positively informed, happier & responsible generation,
          leading to a more safer society.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
