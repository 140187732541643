import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import Madhavan from "../../assets/images/Madhavan.jpg";
import Growing_up from "../../assets/images/Growing_up.jpg";
import manita_mallik from "../../assets/images/manita-mallik.jpg";
import workshoplp from "../../assets/images/workshoplp.PNG";
import sejal from "../../assets/images/sejal.png";
import book from "../../assets/images/sejalbook.jpg";
import Surendra_Kulkarni from "../../assets/images/Surendra_Kulkarni _headshot.jpg";
import Skbook from "../../assets/images/Surendra_Kulkarni_img.jpg";
import dheer from "../../assets/images/dheer.png";

import behroz from "../../assets/images/behroz.png";

import aparna from "../../assets/images/aparna.jpg";

import vrishti from "../../assets/images/vrishti.jpg";

import rajesh from "../../assets/images/rajesh.png";

import dishita from "../../assets/images/dishita.jpg";
import shruti from "../../assets/images/shruti.jpg";

export const TestimonialCarousel = (props) => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div>
      <div className="d-none d-lg-flex">
    <Carousel
      className="text-center"
      activeIndex={index}
      onSelect={handleSelect}
      interval={50000}
    >
      <Carousel.Item
        className="px-4 px-lg-0" 
        style={{
          backgroundColor: "#26547C",
          objectFit: "contain",
        }}
      >
        <div className="col-10 mb-4 mx-auto d-lg-flex justify-content-around  align-items-center ">
          <div className="w-45 ">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="w-30 rounded-3 mb-5"
                src={Madhavan}
                alt={"R_madhvan"}
              />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                I just love the play Growing Up! It is so informative, and the
                best part is that all the information is conveyed in such an
                entertaining way that kids actually want to learn about puberty
                & safety without it feeling awkward or weird. Growing up, I had
                to learn about these topics from my friends, which as we all
                know, is not the best source for information on these sensitive
                topics. After watching the play, I am happy that growing up will
                be so much easier for the new generation!
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">- R. Madhavan</h4>
              <h6 className="text-start secondary-b">Actor&Parent</h6>
            </div>
          </div>

          <div className="w-45 w-sm-100">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3  p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="w-30 rounded-3 mb-5"
                src={manita_mallik}
                alt={"manita_mallik"}
              />
              <p
                className="text-start p-17 secondary-b mb-5 "
                style={{   lineHeight: "1.7" }}
              >
                UnTaboo is a safe and non-judgmental space. Their sessions are
                engaging, fun and highly interactive. Before my boys attended
                these sessions, I did not fully understand the importance of
                giving them this knowledge and awareness. But after seeing the
                impact of UnTaboo’s Workshops, I strongly believe that giving
                our children the right information about these topics, at the
                right age and in the right manner is the duty of every parent.
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">- Ms Manita Malik</h4>
              <h6 className="text-start secondary-b"> Parent from Mumbai</h6>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item
        className="px-4 px-lg-0" 
        style={{
          backgroundColor: "#26547C",
          objectFit: "contain",
        }}
      >
        <div className="col-10 mb-4 mx-auto d-lg-flex justify-content-around align-items-center">
          <div className="w-45">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img className="w-30 rounded-3 mb-5" src={sejal} alt={"sejal"} />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                How I Got My Belly Button’ is absolutely brilliant and so simply
                written! It's a must read for all parents and adolescents.
                Despite being a Gynaecologist, I could not put the book down and
                neither could my 12 year-old. Not only is the content scientific
                and covers all aspects, but it also has an underlying set of
                morals and values (got an Enid Blyton feel). Have read many
                books on this topic, but this one is THE BEST.
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">- Dr. Sejal Ajmera</h4>
              <h6 className="text-start secondary-b">Gynaecologist & Parent</h6>
            </div>
          </div>
          <div className="w-45">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="w-30 rounded-3 mb-5"
                src={Surendra_Kulkarni}
                alt={"Surendra_Kulkarni"}
              />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                We appreciate the initiative and hard work done by UnTaboo
                Group. Based on the response received from the staff and
                students, I can say that the workshop was very informative and
                knowledgeable. And it has educated them in a positive manner and
                will help them in dealing with the physical and emotional age
                appropriate changes. I would like to thank you for the time and
                effort put in by the team. It is highly commendable.
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">
                - Lt. Gen (Retd.) Kulkarni{" "}
              </h4>
              <h6 className="text-start secondary-b">
                {" "}
                Director, Mayo College Ajmer
              </h6>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item
        className="px-4 px-lg-0" 
        style={{
          backgroundColor: "#26547C",
          objectFit: "contain",
        }}
      >
        <div className="col-10 mb-4 mx-auto d-lg-flex justify-content-around align-items-center">
          <div className="w-45">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="rounded-3 mb-5"
                src={aparna}
                alt={"aparna"}
                style={{ width: "39%" }}
              />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                Thanks again for the great workshop. As mothers, we can tell
                them everything, but it comes off as blah 😬 ... the way you've
                handled a sensitive topic like puberty is commendable. Just
                saying words like periods, vulva, penis is so casually
                normalised. My daughter's cringe rating on puberty has certainly
                gone down a notch 😂 ...the real effects of the session will
                roll out slowly in appropriate situations. I know next time I
                talk to her about sex, she'll be more receptive to hear me, I
                know next time a friend tells her some muddled non-facts, she
                will recall what she learnt at UnTaboo and correct her friend.
                So thanks again and keep it up! 👍🏽
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">- Aparna </h4>
              <h6 className="text-start secondary-b"> Parent, Mumbai</h6>
            </div>
          </div>
          <div className="w-45">
            <div
              className=" bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className=" rounded-3 mb-5"
                src={shruti}
                alt={"shruti"}
                style={{ width: "39%" }}
              />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                I was a single mom for 5 years. However COOL a mom I was,
                talking about puberty and sex was a little bit too much. Then
                came UnTaboo to my rescue. I first saw their play that broke the
                ice for us. Then I got to know about their workshop, which was
                such a need of the hour. After that workshop, my son knows
                everything the right way and our communication is much better
                and open! I totally encourage and urge each one of you to make
                your kids attend this workshop. Even the coolest parents and of
                course the orthodox ones, need this information to be shared
                with their kids the right way. Thanks, UnTaboo! 😊
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">- Shruti</h4>
              <h6 className="text-start secondary-b"> Parent, Mumbai</h6>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item
        className="px-4 px-lg-0" 
        style={{
          backgroundColor: "#26547C",
          objectFit: "contain",
        }}
      >
        <div className="col-10 mb-4 mx-auto d-lg-flex justify-content-around align-items-center">
          <div className="w-45">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="w-30 rounded-3 mb-5"
                src={dishita}
                alt={"dishita Gopalani"}
              />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                Thank you UnTaboo for being my guiding star. I always take pride
                in being equally close to my parents in sharing my day- to-day
                experiences. I thought I knew it all, but am glad that I became
                a part of your sessions which taught me so many useful things
                for the future- like how to handle periods and bodily changes
                that are already taking place with me. I'm so happy and glad to
                have taken up this workshop and would strongly recommend all my
                friends to do the same. Thank you once again!
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">
                - Dishita Gopalani
              </h4>
              <h6 className="text-start secondary-b">
                {" "}
                Age 11, Grade 6, Nagpur
              </h6>
            </div>
          </div>
          <div className="w-45">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="w-30 rounded-3 mb-5"
                src={dheer}
                alt={"dheer hirani"}
              />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                I was not very keen to attend the session, but my mom forced me
                to and I absolutely loved it! They made everything so
                interesting with exciting quizzes, fun images and videos. They
                listened to what I and all the other kids had to say. Without
                laughing at us, they cleared our doubts and questions by
                explaining things so simply. I could open up and ask them
                anything that I had a doubt about, without hesitating and they
                answered all the questions. It has made me feel so much more
                confident and calm. Thank you UnTaboo for giving me such
                important lessons and clearing my doubts about Puberty.
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">- Dheeer Hirani</h4>
              <h6 className="text-start secondary-b">
                {" "}
                Age 11, Grade 6, Nagpur
              </h6>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item
        className="px-4 px-lg-0" 
        style={{
          backgroundColor: "#26547C",
          objectFit: "contain",
        }}
      >
        <div className="col-10 mb-4 mx-auto d-lg-flex justify-content-around align-items-center">
          <div className="w-45">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="w-30 rounded-3 mb-5"
                src={behroz}
                alt={"behroz menon"}
              />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                A big Thank U to Anju and her team for the awesome workshop on
                Puberty for Boys! The content is very well articulated and so
                child friendly. My son has just not stopped talking about it! He
                is dishing out quizzes to us and his friends! What I also really
                liked was the parent involvement in the session - I learnt a lot
                too and it’s helping me and my son have much more open
                conversations.
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">- Behroz Menon</h4>
              <h6 className="text-start secondary-b"> Parent, Mumbai</h6>
            </div>
          </div>

          <div className="w-45">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="w-40 rounded-3 mb-5"
                src={vrishti}
                alt={"vrishti"}
              />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                I am a mother of twins who are 12 years old. Both of them
                attended the boy’s puberty and the girl’s puberty session
                respectively. The kids found the sessions informative. As
                parents we are happy that an important topic that usually goes
                unaddressed was presented to them in a manner in which they felt
                comfortable to participate in. They are now comfortable to
                discuss puberty related issues that they are facing, with us
                without feeling shy about it.
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">- Vrishti </h4>
              <h6 className="text-start secondary-b"> Parent, Hyderabad</h6>
            </div>
          </div>
        </div>
      </Carousel.Item>

      <Carousel.Item
        className="px-4 px-lg-0" 
        style={{
          backgroundColor: "#26547C",
          objectFit: "contain",
        }}
      >
        {" "}
        <div className="col-10 mb-4 mx-auto d-lg-flex justify-content-around align-items-center">
          <div className="w-60 w-sm-100">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="w-30 rounded-3 mb-5"
                src={rajesh}
                alt={"rajesh mapuskar"}
              />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                I sat there stunned during the parent session as I watched the
                wonderful sight of kids talking so openly, in a day’s training.
                I remember as a child, I spent many traumatic years not knowing
                whether what I was experiencing was right or wrong. Information
                from friends led to loads of misconceptions and I lived with
                guilt and fear for many years. Then someone like Anju and her
                team comes into your life and turns the whole thing around and
                decodes it for you, taking away the guilt and lifting the weight
                off your shoulder. My kids are blessed they will never go
                through that because of the effort Anju and her team are putting
                to educate them so early in their life. Can’t thank you enough
                Anju!
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">- Rajesh Mapuskar</h4>
              <h6 className="text-start secondary-b">
                {" "}
                Film Director & Parent, Mumbai
              </h6>
            </div>
          </div>
        </div>
      </Carousel.Item>
    </Carousel>
    </div>

    <div className="d-flex d-lg-none">
    <Carousel
      className="text-center"
      activeIndex={index}
      onSelect={handleSelect}
      interval={50000}
    >
      <Carousel.Item
        className="px-4 px-lg-0" 
        style={{
          backgroundColor: "#26547C",
          objectFit: "contain",
        }}
      >
       
          <div className="w-45 ">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="w-sm-45 rounded-3 mb-5"
                src={Madhavan}
                alt={"R_madhvan"}
              />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                I just love the play Growing Up! It is so informative, and the
                best part is that all the information is conveyed in such an
                entertaining way that kids actually want to learn about puberty
                & safety without it feeling awkward or weird. Growing up, I had
                to learn about these topics from my friends, which as we all
                know, is not the best source for information on these sensitive
                topics. After watching the play, I am happy that growing up will
                be so much easier for the new generation!
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">- R. Madhavan</h4>
              <h6 className="text-start secondary-b">Actor&Parent</h6>
            </div>
          </div>

          </Carousel.Item>

<Carousel.Item
  className="px-4 px-lg-0" 
  style={{
    backgroundColor: "#26547C",
    objectFit: "contain",
  }}
>
  
    <div className="w-45">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3  p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="w-sm-45 rounded-3 mb-5"
                src={manita_mallik}
                alt={"manita_mallik"}
              />
              <p
                className="text-start secondary-b mb-5 "
                style={{   lineHeight: "1.7" }}
              >
                UnTaboo is a safe and non-judgmental space. Their sessions are
                engaging, fun and highly interactive. Before my boys attended
                these sessions, I did not fully understand the importance of
                giving them this knowledge and awareness. But after seeing the
                impact of UnTaboo’s Workshops, I strongly believe that giving
                our children the right information about these topics, at the
                right age and in the right manner is the duty of every parent.
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">- Ms Manita Malik</h4>
              <h6 className="text-start secondary-b"> Parent from Mumbai</h6>
            </div>
          </div>
      
      </Carousel.Item>

      <Carousel.Item
        className="px-4 px-lg-0" 
        style={{
          backgroundColor: "#26547C",
          objectFit: "contain",
        }}
      >
      
          <div className="w-45">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img className="w-sm-45 rounded-3 mb-5" src={sejal} alt={"sejal"} />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                How I Got My Belly Button’ is absolutely brilliant and so simply
                written! It's a must read for all parents and adolescents.
                Despite being a Gynaecologist, I could not put the book down and
                neither could my 12 year-old. Not only is the content scientific
                and covers all aspects, but it also has an underlying set of
                morals and values (got an Enid Blyton feel). Have read many
                books on this topic, but this one is THE BEST.
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">- Dr. Sejal Ajmera</h4>
              <h6 className="text-start secondary-b">Gynaecologist & Parent</h6>
            </div>
          </div>
          </Carousel.Item>

<Carousel.Item
  className="px-4 px-lg-0" 
  style={{
    backgroundColor: "#26547C",
    objectFit: "contain",
  }}
>
  
    <div className="w-45">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="w-sm-45 rounded-3 mb-5"
                src={Surendra_Kulkarni}
                alt={"Surendra_Kulkarni"}
              />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                We appreciate the initiative and hard work done by UnTaboo
                Group. Based on the response received from the staff and
                students, I can say that the workshop was very informative and
                knowledgeable. And it has educated them in a positive manner and
                will help them in dealing with the physical and emotional age
                appropriate changes. I would like to thank you for the time and
                effort put in by the team. It is highly commendable.
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">
                - Lt. Gen (Retd.) Kulkarni{" "}
              </h4>
              <h6 className="text-start secondary-b">
                {" "}
                Director, Mayo College Ajmer
              </h6>
            </div>
          </div>
   
      </Carousel.Item>

      <Carousel.Item
        className="px-4 px-lg-0" 
        style={{
          backgroundColor: "#26547C",
          objectFit: "contain",
        }}
      >
      
          <div className="w-45">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="w-sm-45 rounded-3 mb-5"
                src={aparna}
                alt={"aparna"}
              
              />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                Thanks again for the great workshop. As mothers, we can tell
                them everything, but it comes off as blah 😬 ... the way you've
                handled a sensitive topic like puberty is commendable. Just
                saying words like periods, vulva, penis is so casually
                normalised. My daughter's cringe rating on puberty has certainly
                gone down a notch 😂 ...the real effects of the session will
                roll out slowly in appropriate situations. I know next time I
                talk to her about sex, she'll be more receptive to hear me, I
                know next time a friend tells her some muddled non-facts, she
                will recall what she learnt at UnTaboo and correct her friend.
                So thanks again and keep it up! 👍🏽
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">- Aparna </h4>
              <h6 className="text-start secondary-b"> Parent, Mumbai</h6>
            </div>
          </div>
          </Carousel.Item>

<Carousel.Item
  className="px-4 px-lg-0" 
  style={{
    backgroundColor: "#26547C",
    objectFit: "contain",
  }}
>
  
    <div className="w-45">
            <div
              className=" bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="w-sm-45 rounded-3 mb-5"
                src={shruti}
                alt={"shruti"}
                
              />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                I was a single mom for 5 years. However COOL a mom I was,
                talking about puberty and sex was a little bit too much. Then
                came UnTaboo to my rescue. I first saw their play that broke the
                ice for us. Then I got to know about their workshop, which was
                such a need of the hour. After that workshop, my son knows
                everything the right way and our communication is much better
                and open! I totally encourage and urge each one of you to make
                your kids attend this workshop. Even the coolest parents and of
                course the orthodox ones, need this information to be shared
                with their kids the right way. Thanks, UnTaboo! 😊
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">- Shruti</h4>
              <h6 className="text-start secondary-b"> Parent, Mumbai</h6>
            </div>
          </div>
      
      </Carousel.Item>

      <Carousel.Item
        className="px-4 px-lg-0" 
        style={{
          backgroundColor: "#26547C",
          objectFit: "contain",
        }}
      >
        
          <div className="w-45">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="w-sm-45 rounded-3 mb-5"
                src={dishita}
                alt={"dishita Gopalani"}
              />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                Thank you UnTaboo for being my guiding star. I always take pride
                in being equally close to my parents in sharing my day- to-day
                experiences. I thought I knew it all, but am glad that I became
                a part of your sessions which taught me so many useful things
                for the future- like how to handle periods and bodily changes
                that are already taking place with me. I'm so happy and glad to
                have taken up this workshop and would strongly recommend all my
                friends to do the same. Thank you once again!
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">
                - Dishita Gopalani
              </h4>
              <h6 className="text-start secondary-b">
                {" "}
                Age 11, Grade 6, Nagpur
              </h6>
            </div>
          </div>
          </Carousel.Item>

<Carousel.Item
  className="px-4 px-lg-0" 
  style={{
    backgroundColor: "#26547C",
    objectFit: "contain",
  }}
>
  
    <div className="w-45">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="w-sm-45 rounded-3 mb-5"
                src={dheer}
                alt={"dheer hirani"}
              />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                I was not very keen to attend the session, but my mom forced me
                to and I absolutely loved it! They made everything so
                interesting with exciting quizzes, fun images and videos. They
                listened to what I and all the other kids had to say. Without
                laughing at us, they cleared our doubts and questions by
                explaining things so simply. I could open up and ask them
                anything that I had a doubt about, without hesitating and they
                answered all the questions. It has made me feel so much more
                confident and calm. Thank you UnTaboo for giving me such
                important lessons and clearing my doubts about Puberty.
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">- Dheeer Hirani</h4>
              <h6 className="text-start secondary-b">
                {" "}
                Age 11, Grade 6, Nagpur
              </h6>
            </div>
          </div>
    
      </Carousel.Item>

      <Carousel.Item
        className="px-4 px-lg-0" 
        style={{
          backgroundColor: "#26547C",
          objectFit: "contain",
        }}
      >
       
          <div className="w-45">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="w-sm-45 rounded-3 mb-5"
                src={behroz}
                alt={"behroz menon"}
              />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                A big Thank U to Anju and her team for the awesome workshop on
                Puberty for Boys! The content is very well articulated and so
                child friendly. My son has just not stopped talking about it! He
                is dishing out quizzes to us and his friends! What I also really
                liked was the parent involvement in the session - I learnt a lot
                too and it’s helping me and my son have much more open
                conversations.
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">- Behroz Menon</h4>
              <h6 className="text-start secondary-b"> Parent, Mumbai</h6>
            </div>
          </div>
</Carousel.Item>

<Carousel.Item
  className="px-4 px-lg-0" 
  style={{
    backgroundColor: "#26547C",
    objectFit: "contain",
  }}
>
          <div className="w-45">
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="w-sm-45 rounded-3 mb-5"
                src={vrishti}
                alt={"vrishti"}
              />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                I am a mother of twins who are 12 years old. Both of them
                attended the boy’s puberty and the girl’s puberty session
                respectively. The kids found the sessions informative. As
                parents we are happy that an important topic that usually goes
                unaddressed was presented to them in a manner in which they felt
                comfortable to participate in. They are now comfortable to
                discuss puberty related issues that they are facing, with us
                without feeling shy about it.
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">- Vrishti, Parent </h4>
              <h6 className="text-start secondary-b"> Parent, Hyderabad</h6>
            </div>
          </div>
      
      </Carousel.Item>

      <Carousel.Item
        className="px-4 px-lg-0" 
        style={{
          backgroundColor: "#26547C",
          objectFit: "contain",
        }}
      >
        {" "}
       
            <div
              className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
              style={{ backgroundColor: "#fff", height: "854px" }}
            >
              <img
                className="w-sm-45 rounded-3 mb-5"
                src={rajesh}
                alt={"rajesh mapuskar"}
              />
               <p
                className="text-start p-17 secondary-b mb-5"
                style={{   lineHeight: "1.7" }}
              >
                I sat there stunned during the parent session as I watched the
                wonderful sight of kids talking so openly, in a day’s training.
                I remember as a child, I spent many traumatic years not knowing
                whether what I was experiencing was right or wrong. Information
                from friends led to loads of misconceptions and I lived with
                guilt and fear for many years. Then someone like Anju and her
                team comes into your life and turns the whole thing around and
                decodes it for you, taking away the guilt and lifting the weight
                off your shoulder. My kids are blessed they will never go
                through that because of the effort Anju and her team are putting
                to educate them so early in their life. Can’t thank you enough
                Anju!
              </p>
              <h4 className="secondary-blue fs-5 fw-bold">- Rajesh Mapuskar</h4>
              <h6 className="text-start secondary-b">
                {" "}
                Film Director & Parent, Mumbai
              </h6>
            </div>
         
       
      </Carousel.Item>
    </Carousel>
    </div>

    </div>
  );
};
