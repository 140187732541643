import React, { useState, useLayoutEffect } from "react";
import Button from "../../atoms/button";
import { Link } from "react-router-dom";
import untaboocloud from "../../assets/images/Untaboocloud.png";
// import "../../../css/style.css";
import { Container } from "react-bootstrap";
import course1 from "../../assets/images/course1.png";
import myBodyawarness from "../../assets/images/myBodyawarness.png";
import course2 from "../../assets/images/school/1.png";
import course3 from "../../assets/images/school/2.png";
import course4 from "../../assets/images/school/7.png";
import course5 from "../../assets/images/school/3.png";
import course6 from "../../assets/images/school/4.png";
import course7 from "../../assets/images/school/5.png";
import course8 from "../../assets/images/course6.png";
import about_img3 from "../../assets/images/Asset2.png";
import about_img4 from "../../assets/images/partnership.png";
import about_img1 from "../../assets/images/about_asset1.png";
import { TestimonialCarousel } from "../../organisms/Testimonial/schooltestimonial";
import { Faq } from "../../organisms/faq/school";
import icon_6 from "../../assets/images/programs_icon6.png";
import icon_7 from "../../assets/images/programs_icon7.png";
import icon_8 from "../../assets/images/programs_icon8.png";
import icon_9 from "../../assets/images/programs_icon9.png";
import sexual_literacy from "../../assets/images/sexualliteracy11_12.png";
import prog5 from "../../assets/images/5 (2).png";
import prog4 from "../../assets/images/4.jpeg";
import prog3 from "../../assets/images/3.png";
import schoolPedagogy from "../../assets/images/schoolPedagogy.jpeg";
import partnerUntaboo from "../../assets/images/partnerwithUntaboo.jpeg";

const ContactInput = () => {
  var Check1 = document.getElementById("expandInput");
  var more1 = document.getElementById("inputbox");
  if (Check1.checked == true) {
    more1.style.display = "block";
  } else {
    more1.style.display = "none";
  }
};

const ContactCheck = () => {
  var Check = document.getElementById("expand");
  var more = document.getElementById("moreoptions");
  if (Check.checked == true) {
    more.style.display = "block";
  } else {
    more.style.display = "none";
  }
};
export const Schools = (props) => {
  const [isOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  // useLayoutEffect(() => {
  //   window.scrollTo(0, 0);
  // });
  document.title = "Schools";
  return (
    <div className="bg-img-about poppins">
      <Container className="d-flex flex-row text-center text-lg-start">
        <div className="col-lg-6 pt-lg-10 ">
          <h1
            className=" secondary-blue f-55 f-sm-38"
            style={{ color: "#1e4363" }}
          >
            <b>
              UnTaboo <br></br>
              For Schools
            </b>
          </h1>
          <p
            className="mt-4 px-4 px-lg-0 fs-5"
            style={{ color: "#656565", lineHeight: "1.7" }}
          >
            At UnTaboo, a Safety & Sex Education company, we pride ourselves on
            creating learning solutions tailored to the unique needs of schools.
            If you are a progressive school looking for positively empowered
            students and a healthy school environment, you have landed at the
            right place!
          </p>
          <a href="mailto:team.untaboo@gmail.com">
            <Button
              style={{ backgroundColor: "#063A67", color: "#fff" }}
              className="fs-7 mt-5"
            >
              Write to us at: team.untaboo@gmail.com
            </Button>
          </a>

          {/* <Button  style={{backgroundColor:"white",border:"1px solid #063A67" ,color:"#063A67"}} className="fs-7 mt-5 ms-3">
                                Download Brochhure
                             </Button> */}
        </div>
      </Container>

      <Container>
        <div className="mt-10">
          <div className="px-4 px-lg-0 d-flex flex-lg-row flex-column align-items-center justify-content-between">
            <div className="col-lg-5 px-4 text-center mb-5 position-relative px-lg-0  pt-lg-5">
              {/* <div className="col-lg-5 " > */}
              <img
                className="w-100 "
                src={untaboocloud}
                alt={"Untaboo Cloud"}
                style={{
                  borderRadius: "20px",
                }}
              />
            </div>
            <div className="pt-5 col-lg-6 fs-5">
              {/* <h3 className="">END THE TABOOS - START THE CONVERSATION</h3> */}
              <h5 className="mt-lg-5 mt-3 primary-blue f-40">
                <b style={{ color: "#005995" }}>
                  UnTaboo as your Safety & Sex-Ed Partner
                </b>
              </h5>
              <p style={{ lineHeight: "1.7" }}>
                {/* Children need progressive Sex Education in order to grow up as
                responsible adults, especially in today’s digitally-aware world.
                UNESCO, WHO, UNAIDS, UNFPA - all list Comprehensive Sexuality
                Education as one of the basic fundamental rights of every child.
                <br></br>
                <br></br> */}
                With UnTaboo’s unique age-wise, grade-wise holistic learning
                model, you can adopt a proactive approach to handle teenage
                issues vs a reactive, incident-based approach. We can even help
                you build an ecosystem of change via our change driven Mind
                Unblocking & Awareness talks for parents, teachers and grassroot
                level staff.<br></br>
                <br></br>
                <span className="fw-bold">
                  So are you ready to give your school the competitive edge of
                  progressive learning?
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="px-4 px-lg-0 col-12 text-center mx-auto mt-lg-5 mt-3 ">
          <Link to="/contact">
            <Button className="mx-auto">
              Connect with us on team.untaboo@gmail.com
            </Button>
          </Link>
        </div>
        {/* </div> */}
      </Container>

      {/* <div
        className="pb-10 pt-10 mt-180"
        style={{ backgroundColor: "#B7c4D1" }}
      >
        <Container>
          <div className="col-lg-12 text-center col-12 px-4  px-lg-0">
            <h3 className="secondary-blue text-center  fs-1 fw-bold">
              UnTaboo’s Pedagogy
            </h3>
            <p
              className="col-12 f-25 secondary-blue text-center mx-auto  mt-4 poppins"
              style={{ fontWeight: "500", lineHeight: "1.7" }}
            >
              Our child friendly programs are carefully designed & curated by
              experts using audio- video and experiential format, which immerses
              the child into a learning journey with takeaways that are easy to
              absorb and practical to follow. Whether you're looking for Online
              Self-Learning programs or In-person workshops or a Hybrid model
              that is specific to your school, we can customise the right
              solution for you.
            </p>
          </div>
        </Container>
      </div> */}
      <div className="container">
      <div className=" sm: mt-10 pt-5 pt-lg-0 mb-10">
        <Container className="  d-lg-flex justify-content-between align-items-center">
          <div className="col-12 col-lg-12 mb-4  position-relative text-start">
            <h3
              className="secondary-blue my-5  f-40 px-4 px-lg-0"
              style={{ fontWeight: "600" }}
            >
              UnTaboo’s Pedagogy
            </h3>
            {/* fs-5 lh-md pt-lg-5 pt-3 px-4 px-lg-0 */}
            <p className="mt-5 col-lg-10 fs-5 lh-md pt-lg-5 pt-3 px-4 px-lg-0">
              Our child friendly programs are carefully designed & curated by
              experts using audio- video and experiential format, which immerses
              the child into a learning journey with takeaways that are easy to
              absorb and practical to follow.
            </p>
            <img
              className="w-100 rounded-3-top col-lg-10 mx-auto fs-5 lh-md pt-lg-5 pt-3 px-4 px-lg-0"
              src={schoolPedagogy}
              alt={"course_img"}
              style={{ borderRadius: "10px 10px 0 0 " }}
            />
          </div>
        </Container>
      </div>
      </div>

      <div>
        <Container>
          <h5 className="pt-10 mt-5 text-center primary-blue f-40 my-5 px-4 px-lg-0">
            <b style={{ color: "#005995" }}>
              UnTaboo’s Grade Wise Student Programs
            </b>
          </h5>
          <p
            className="col-lg-10 mx-auto fs-5 lh-md pt-lg-5 pt-3 px-4 px-lg-0"
            style={{ color: "#5C6C7E" }}
          >
            Year-on-year layered learning is required for real change to happen.
            Our Learning tracks tackle the developmental needs of children at
            every stage of their lives, to help empower them into being
            well-informed responsible young adults.
          </p>
          <div className="programsfilter px-2 px-lg-0 d-lg-flex flex-wrap justify-content-around mt-5 pt-3">
            {/* My Body is Mine */}
            <div
              className="boysgirls mx-auto  mb-5 card filtercard program_cards w-sm-100 rounded-3"
              style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
            >
              <img
                className="w-100 rounded-3-top"
                src={myBodyawarness}
                alt={"course_img"}
                style={{ borderRadius: "10px 10px 0 0 " }}
              />
              <div className="boysgirls  mx-auto col-10 py-4">
                <div className="d-flex">
                  <div className="w-100 text-start">
                    <h3
                      className="secondary-blue fw-bold"
                      style={{ fontSize: "20px", fontWeight: "700" }}
                    >
                      Currently only offered: In-person & Zoom live
                    </h3>
                  </div>
                </div>
                <p
                  className="pb-4 text-start secondary-b p-17"
                  style={{ lineHeight: "1.7", height: "540px" }}
                >
                  A talk on Safe - Unsafe touch & behaviour is a must to
                  safeguard children. Our workshop handles this topic very
                  sensitively and in an extremely child friendly manner,
                  empowering them with tools which can be easily understood,
                  retained and practised. It empowers them to navigate the world
                  in a safe manner & grow up with a healthy body image.
                </p>
                <div className="position-absolute text-center bottom-0 col-10 mx-auto  pb-4">
                  <hr></hr>
                  <a href="#contactform">
                    <Button style={{ borderRadius: "25px" }}>
                      I Am Interested
                    </Button>
                  </a>
                </div>
              </div>
            </div>

            {/* Puberty for Girls */}
            <div
              className="boysgirls mx-auto  mb-5 card filtercard  program_cards w-sm-100 rounded-3"
              style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
            >
              <img
                className="w-100 rounded-3-top"
                src={course2}
                alt={"course_img"}
                style={{ borderRadius: "10px 10px 0 0 " }}
              />

              <div className="mx-auto col-10 py-4">
                <div className="d-flex">
                  <div className="w-100 text-start">
                    <h3
                      className="secondary-blue fw-bold"
                      style={{ fontSize: "20px", fontWeight: "700" }}
                    >
                      Offered: In-person, Zoom live, Online animated
                    </h3>
                  </div>
                </div>
                <p
                  className="pb-4 text-start secondary-b p-17"
                  style={{ lineHeight: "1.7", height: "540px" }}
                >
                  Puberty is happening very early on for girls today. It can be
                  a confusing & difficult phase, but learning about it before it
                  happens can make puberty a smooth ride for her. We prepare the
                  girls, so they feel comfortable with their changing bodies &
                  emotions, empowering them with skills to cope with it
                  positively & happily.
                </p>
                <div className="position-absolute text-center bottom-0 col-10 mx-auto  pb-4">
                  <hr></hr>
                  <a href="#contactform">
                    <Button style={{ borderRadius: "25px" }}>
                      I Am Interested
                    </Button>
                  </a>
                </div>
              </div>
            </div>

            {/* Puberty for Boys */}
            <div
              className="awareness mx-auto  mb-5 card filtercard  program_cards w-sm-100 rounded-3"
              style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
            >
              <img
                className="w-100 rounded-3-top"
                src={course3}
                alt={"course_img"}
                style={{ borderRadius: "10px 10px 0 0 " }}
              />

              <div className="mx-auto col-10 py-4">
                <div className="d-flex">
                  <div className=" text-start">
                    <h3
                      className="secondary-blue fw-bold"
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      Offered: In-person, Zoom live, Online animated
                    </h3>
                  </div>
                </div>
                <div>
                  <p
                    className="pb-4 text-start secondary-b p-17"
                    style={{ lineHeight: "1.7", height: "540px" }}
                  >
                    Puberty can be a confusing & stressful time for boys as most
                    of them grow up with silence around the subject. We open
                    conversations on their changing bodies, emotions and also
                    the changes girls go through, thus sowing the seeds for a
                    lifetime of body positivity & gender sensitivity.
                  </p>
                </div>
                <div className="position-absolute text-center bottom-0 col-10 mx-auto  pb-4">
                  <hr></hr>
                  <a href="#contactform">
                    <Button style={{ borderRadius: "25px" }}>
                      I Am Interested
                    </Button>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="awareness mx-auto mb-5 card filtercard  program_cards w-sm-100 rounded-3"
              style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
            >
              <img
                className="w-100 rounded-3-top"
                src={course4}
                alt={"course_img"}
                style={{ borderRadius: "10px 10px 0 0 " }}
              />

              <div className="mx-auto col-10 py-4">
                <div className="d-flex">
                  <div className=" text-start">
                    <h3
                      className="secondary-blue fw-bold"
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      Offered: In-person, Zoom live, Online animated
                    </h3>
                  </div>
                </div>
                <p
                  className="pb-4 text-start secondary-b p-17"
                  style={{ lineHeight: "1.7", height: "540px" }}
                >
                  Puberty can be a confusing & difficult phase for young boys &
                  girls, but knowing that both boys and girls go through equally
                  challenging changes - physical, emotional, social and
                  behavioural, helps normalise them and sows the seeds for a
                  lifetime of body positivity & gender sensitivity.
                </p>

                <div className="position-absolute text-center bottom-0 col-10 mx-auto  pb-4 ">
                  <hr></hr>
                  <a href="#contactform">
                    <Button style={{ borderRadius: "25px" }}>
                      I Am Interested
                    </Button>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="awareness mx-auto safety mb-5 card filtercard  program_cards w-sm-100 rounded-3"
              style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
            >
              <img
                className="w-100 rounded-3-top"
                src={course5}
                alt={"course_img"}
                style={{ borderRadius: "10px 10px 0 0 " }}
              />

              <div className="mx-auto col-10 py-4">
                <div className="d-flex">
                  <div className="text-start">
                    <h3
                      className="secondary-blue fw-bold"
                      style={{ fontSize: "20px", fontWeight: "700" }}
                    >
                      Currently only offered: In-person & Zoom live
                    </h3>
                  </div>
                </div>
                <div>
                  <p
                    className="pb-4 text-start secondary-b p-17"
                    style={{ lineHeight: "1.7", height: "540px" }}
                  >
                    Teenage is a time of curiosity and teens are growing up with
                    age-inappropriate information from the internet. We gently
                    introduce them to the concepts of sex, reproduction, sexual
                    changes, sexuality, & developing the right attitudes, so
                    that they get accurate information and grow up safely.
                  </p>
                </div>
                <div className="position-absolute text-center bottom-0 col-10 mx-auto  pb-4">
                  <hr></hr>
                  <a href="#contactform">
                    <Button style={{ borderRadius: "25px" }}>
                      I Am Interested
                    </Button>
                  </a>
                </div>
              </div>
            </div>

            <div
              className="safety mx-auto mb-5 card filtercard  program_cards w-sm-100 rounded-3"
              style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
            >
              <img
                className="w-100 rounded-3-top"
                src={sexual_literacy}
                alt={"course_img"}
                style={{ borderRadius: "10px 10px 0 0 " }}
              />
              <div className="mx-auto col-10 py-4">
                <div className="d-flex">
                  <div className=" text-start">
                    <h3
                      className="secondary-blue fw-bold"
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                      }}
                    >
                      Currently only offered: In-person & Zoom live
                    </h3>
                  </div>
                </div>
                <p
                  className="pb-4 text-start secondary-b p-17"
                  style={{ lineHeight: "1.7", height: "540px" }}
                >
                  We provide teenagers edging towards young adulthood with
                  accurate information on sex, sexuality, safety so that they
                  are able to make well-informed, responsible decisions. We help
                  them cultivate gender sensitivity & inclusivity, enabling them
                  to develop healthy relationships; and take care of themselves
                  - both physically and emotionally.
                </p>
                <div className="position-absolute text-center bottom-0 col-10 mx-auto  pb-4">
                  <hr></hr>
                  <a href="#contactform">
                    <Button style={{ borderRadius: "25px" }}>
                      I Am Interested
                    </Button>
                  </a>
                </div>
              </div>
            </div>

            {/* <div
              className="safety mb-5 card filtercard  program_cards w-sm-100 rounded-3"
              style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
            >
              <img
                className="w-100 rounded-3-top"
                src={course6}
                alt={"course_img"}
                style={{ borderRadius: "10px 10px 0 0 " }}
              />
              <div className="mx-auto col-10 py-4">
                <div className="d-flex">
                  <div className=" text-start">
                    <h3
                      className="secondary-blue fw-bold"
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        height: "75px",
                      }}
                    >
                      Safety: Personal and Online: Grade 8 to 12 (Only offered
                      in Online animated version)
                    </h3>
                  </div>
                </div>
                <p
                  className="pb-4 text-start secondary-b p-17"
                  style={{ lineHeight: "1.7", height: "540px" }}
                >
                  We provide teenagers edging towards young adulthood with
                  accurate information on sex, sexuality, safety so that they
                  are able to make well-informed, responsible decisions. We help
                  them cultivate gender sensitivity & inclusivity and enable
                  them to develop healthy relationships; and take care of
                  themselves - both physically and emotionally.
                </p>
                <div className="position-absolute text-center bottom-0 col-10 mx-auto  pb-4">
                  <hr></hr>
                  <a href="#contactform">
                    <Button style={{ borderRadius: "25px" }}>
                      I Am Interested
                    </Button>
                  </a>
                </div>
              </div>
            </div> */}

            {/* <div
              className="safety mb-5 card filtercard  program_cards w-sm-100 rounded-3"
              style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
            >
              <img
                className="w-100 rounded-3-top"
                src={course7}
                alt={"course_img"}
                style={{ borderRadius: "10px 10px 0 0 " }}
              />

              <div className="mx-auto col-10 py-4">
                <div className="d-flex">
                  <div className=" text-start">
                    <h3
                      className="secondary-blue fw-bold"
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        height: "75px",
                      }}
                    >
                      Smoking-Alcohol-Drugs: Resistance & Responsibility: Grade
                      8 to 12 (Only offered in Online animated version)
                    </h3>
                  </div>
                </div>
                <p
                  className="pb-4 pt-4 text-start secondary-b p-17"
                  style={{ lineHeight: "1.7", height: "540px" }}
                >
                  Teen experimentation with substances is on the rise. We raise
                  awareness of its short & long term impact on health &
                  behaviour, while also empowering them with tools to combat
                  peer pressure & need to belong. We focus on helping them
                  develop resilience & skills to make positive choices.
                </p>
                <div className="position-absolute text-center bottom-0 col-10 mx-auto  pb-4">
                  <hr></hr>
                  <a href="#contactform">
                    <Button style={{ borderRadius: "25px" }}>
                      I Am Interested
                    </Button>
                  </a>
                </div>
              </div>
            </div> */}

            {/* <div
              className="safety mb-5 card filtercard  program_cards w-sm-100 rounded-3"
              style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
            >
              <img
                className="w-100 rounded-3-top"
                src={course8}
                alt={"course_img"}
                style={{ borderRadius: "10px 10px 0 0 " }}
              />
              <div className="mx-auto col-10 py-4">
                <div className="d-flex">
                  <div className=" text-start">
                    <h3
                      className="secondary-blue fw-bold"
                      style={{
                        fontSize: "20px",
                        fontWeight: "700",
                        height: "75px",
                      }}
                    >
                      Adult Awareness Talks (Offered: In-person & Zoom live)
                    </h3>
                  </div>
                </div>
                <p
                  className="pb-4 text-start secondary-b p-17"
                  style={{ lineHeight: "1.7", height: "540px" }}
                >
                  While we focus on educating the child, we also believe that
                  their entire ecosystem should be empowered, for real change to
                  happen. Towards that goal, we run Adult Awareness &
                  Empowerment Programs for Parents, Teachers & Grassroot level
                  staff at school. These talks aim to clear mind blocks about
                  sex education and enable the adults to establish a supportive
                  ecosystem, so that the children can openly express, feel
                  heard, accepted, and understood, making their growing up
                  journey easier & happier.
                </p>
                <div className="position-absolute text-center bottom-0 col-10 mx-auto  pb-4">
                  <hr></hr>
                  <a href="#contactform">
                    <Button style={{ borderRadius: "25px" }}>
                      I Am Interested
                    </Button>
                  </a>
                </div>
              </div>
            </div> */}
          </div>
        </Container>
      </div>
      <br></br>

      <div className="mt-10 mb-10">
        <Container>
          <h5 className="pt-5 mt-5 text-center primary-blue f-40">
            <b style={{ color: "#005995" }}>
              UnTaboo's Awareness & Empowerment Talks
            </b>
          </h5>
          <div className="d-flex flex-lg-row flex-column-reverse justify-content-center align-items-center mt-5">
            <div className="col-lg-6 text-start mb-2 px-4 px-lg-0">
              <img
                className="mt-6 col-lg-9 col-12 h-100"
                src={course8}
                alt="aboutus_asset1"
              />
            </div>
            <div
              className="px-4 px-lg-0 col-lg-6 col-sm-12 fs-5"
              style={{ color: "#656565" }}
            >
              <p
                className="fs-5"
                style={{ lineHeight: "1.7", marginBottom: "2.75rem" }}
              >
                The aim of this talk is to tackle individual mind blocks about
                sex education and establish the correct mindset and supportive
                structure at home, to further create a holistic ecosystem for
                the child. We enable parents to break the ice, establish an
                atmosphere of open conversations and truly create a bond with
                the children. The focus is also on equipping them to deal with
                the questions raised by children with ease, paving the way for
                them to become askable parents.
              </p>
              <a href="#contactform">
                <Button style={{ borderRadius: "25px" }}>
                  I Am Interested
                </Button>
              </a>
            </div>
          </div>
        </Container>
      </div>

      {/* <div className="mt-10 mb-10">
        <Container>
          <div className="d-flex flex-lg-row flex-column-reverse justify-content-center align-items-center">
            <div className="col-lg-6 text-start mb-2 px-4 px-lg-0">
              <img
                className="col-lg-9 col-12 h-100"
                src={about_img1}
                alt="aboutus_asset1"
              />
            </div>
            <div
              className="px-4 px-lg-0 col-lg-6 col-sm-12 fs-5"
              style={{ color: "#656565" }}
            >
              <h5 className="mt-lg-5 mt-4 primary-blue f-40">
                <b style={{ color: "#005995" }}>
                  Parent Mind Unblocking & Awareness talk
                </b>
              </h5>
              <p className="fs-5 mb-3 mb-lg-0" style={{ lineHeight: "1.7" }}>
                The aim of this talk is to tackle individual mind blocks about
                sex education and establish the correct mindset and supportive
                structure at home, to further create a holistic ecosystem for
                the child. We enable parents to break the ice, establish an
                atmosphere of open conversations and truly create a bond with
                the children. The focus is also on equipping them to deal with
                the questions raised by children with ease, paving the way for
                them to become askable parents.
              </p>
              <h5 className="mt-lg-5 mt-3 f-40 primary-blue">
                <b style={{ color: "#005995" }}>
                  Teacher Mind Unblocking & Awareness talk
                </b>
              </h5>
              <p className="fs-5 mb-3 mb-lg-0" style={{ lineHeight: "1.7" }}>
                Teachers and educators are also individuals at the end of the
                day, with personal mind blocks and biases. This talk aims to
                clear those blocks about sex education, while enabling them to
                not let their personal biases impact their interaction with
                students. It makes a case for not being judgemental and being
                askable teachers, so that the students can openly express, feel
                heard, accepted, and understood, leading to a positive and
                nurturing ecosystem in the school.
              </p>
              <h5 className="mt-lg-5 mt-3 primary-blue f-40">
                <b style={{ color: "#005995" }}>
                  Grassroot Staff Mind Unblocking & Awareness talk
                </b>
              </h5>
              <p
                className="fs-5 mb-3 mb-lg-0"
                style={{ lineHeight: "1.7" }}
                id={"contactform"}
              >
                To create a truly inclusive and progressive ecosystem, we need
                to ensure that every individual in the school is enabled and
                empowered to support the students as they go through their
                growing up journey. As such we provide mind-unblocking and
                awareness talks for the grassroot level employees in English and
                Hindi language.
              </p>
            </div>
          </div>
        </Container>
      </div> */}

      <div className=" mt-10 pt-10 pt-lg-0">
        <Container className="  d-lg-flex justify-content-between  align-items-center">
          <div className="col-12 col-lg-7 mb-4  position-relative text-start secondary-blue">
            <h3 id="contactform" className="Yanone_Kaffeesatz-700 my-5 px-4 px-lg-0 f-55 f-sm-38">
              Partner with UnTaboo
            </h3>
            <p
              className="mt-2 fs-5 col-lg-10  lh-md px-4 px-lg-0 poppins"
              style={{ color: "#656565", lineHeight: "1.7" }}
            >
              Reach out to us for a custom learning solution that works best for{" "}
              <br />
              your unique needs and fits within your school budget.
            </p>
            <div
              className="col-lg-6 col-12 px-4 px-lg-0 mt-5 "
              style={{ color: "#282828" }}
            >
              <form className="poppins">
                <p className="mt-5 fs-5  poppins" style={{ color: "#282828" }}>
                  I am interested and would like to be contacted with further
                  details about:
                </p>
                <ul
                  style={{ listStyle: "none", color: "#282828" }}
                  className="form-check  fs-5 ms-3 poppins"
                >
                  <li className="mb-3">
                    <input
                      style={{ color: "#282828" }}
                      type="checkbox"
                      name="primaryInterest"
                      className="form-check-input -3"
                      id="expand"
                      value="In-Person Workshops"
                      onClick={ContactCheck}
                    />
                    In-Person Workshops<br></br>
                  </li>

                  <li className="mb-3 mt-3">
                    <input
                      style={{ color: "#282828" }}
                      type="checkbox"
                      name="primaryInterest"
                      className="form-check-input -3"
                      id="exampleCheck1"
                      value="Online animated Programs"
                    />
                    Online animated Programs<br></br>
                  </li>
                  <li className="mb-3">
                    {" "}
                    <input
                      style={{ color: "#282828" }}
                      type="checkbox"
                      name="primaryInterest"
                      className="form-check-input -3"
                      id="exampleCheck1"
                      value="Parent Awareness talks"
                    />
                    Parent Awareness talks<br></br>
                  </li>
                  <li className="mb-3">
                    {" "}
                    <input
                      style={{ color: "#282828" }}
                      type="checkbox"
                      name="primaryInterest"
                      className="form-check-input -3"
                      id="exampleCheck1"
                      value="Teacher Awareness talks"
                    />
                    Teacher Awareness talks<br></br>
                  </li>
                  <li className="mb-3">
                    <input
                      style={{ color: "#282828" }}
                      type="checkbox"
                      name="primaryInterest"
                      className="form-check-input -3"
                      id="exampleCheck1"
                      value="Grassroot Staff Awareness talks"
                    />
                    Grassroot Staff Awareness talks<br></br>
                  </li>

                  <li className="mb-3">
                    {" "}
                    <input
                      style={{ color: "#282828" }}
                      type="checkbox"
                      name="other"
                      className="form-check-input -3"
                      id="expandInput"
                      onClick={ContactInput}
                    />
                    Other<br></br>
                  </li>

                  <ul id="inputbox" style={{ display: "none" }}>
                    <li>
                      <textarea
                        className="form-control mt-4"
                        name="other_value"
                        placeholder="Type here..."
                        type="textarea"
                        style={{ maxHeight: "80px" }}
                      />
                      <br></br>
                    </li>
                  </ul>
                </ul>
                <input
                  style={{ color: "#282828" }}
                  className="form-control mt-4"
                  name="name"
                  placeholder="Organisation/School name"
                  type="text"
                  required
                />
                <input
                  style={{ color: "#282828" }}
                  className="form-control mt-4"
                  name="city"
                  placeholder="Contact Person"
                  type="text"
                />
                <input
                  style={{ color: "#282828" }}
                  className="form-control mt-4"
                  name="user_email"
                  placeholder="Email ID"
                  type="email"
                  required
                />
                <input
                  style={{ color: "#282828" }}
                  className="form-control mt-4"
                  name="phone"
                  placeholder="Contact Number"
                  type="number"
                  min={"0"}
                  required
                />
                <Button
                  className="mt-4 me-2 poppins"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? "Submitting" : "Submit"}
                </Button>
                {error && <span className="text-danger">{error}</span>}
                {success && (
                  <span className="text-success">
                    We have received your request
                  </span>
                )}
              </form>
            </div>
          </div>
          <div className="col-lg-5 text-start process_button px-4 px-lg-0">
            <img
              className="col-lg-9 col-12 h-70 w-100 mt-5 pt-5 "
              src={partnerUntaboo}
              alt="aboutus_asset1"
            />
          </div>
        </Container>
      </div>

      <div
        className=" text-center pb-10"
        style={{ backgroundColor: "#26547C" }}
      >
        <Container>
          <h2 className="text-white fw-bold pt-10 mb-5 f-40">They said it!</h2>

          <TestimonialCarousel></TestimonialCarousel>
        </Container>
      </div>

      <div
        className=" pb-10 text-center"
        style={{ backgroundColor: "#F69494 " }}
      >
        <Container>
          <h2
            className="pt-10 px-2 text-white f-40"
            style={{ fontWeight: "600" }}
          >
            FREQUENTLY ASKED QUESTIONS
          </h2>
          <p className="mb-5 fs-4 text-white">Got doubts? We got it covered!</p>

          <div className="w-100 ">
            <Faq></Faq>
          </div>
          <div className="px-4 px-lg-0 col-12 text-center mx-auto mt-lg-5 mt-3 ">
            <a href="#contactform">
              <Button className="mx-auto">
                I want to partner with UnTaboo
              </Button>
            </a>
          </div>
        </Container>
      </div>
    </div>
  );
};
