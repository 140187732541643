import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import CardImg from "react-bootstrap/CardImg";
import Button from "react-bootstrap/Button";
import pdf_img from "../../assets/images/pdf1.png";
import pdf_img3 from "../../assets/images/thumbnail.png";
export const PptViewer = (props) => {
  const [pdfLink, setPdfLink] = useState("");

  const handleClose = () => setPdfLink("");

  document.oncontextmenu = function () {
    return false;
  };
  return (
    <Container className="py-3">
      <Modal
        show={!!pdfLink}
        animation={true}
        onHide={handleClose}
        size="lg"
        fullscreen={true}
        centered={true}
      >
        <ModalHeader closeButton={true}></ModalHeader>
        <ModalBody>
          <div>
            <iframe
              width={"100%"}
              height={600}
              src={pdfLink}
              seamless="seamless"
              scrolling="no"
              frameborder="0"
              allowtransparency="true"
              allowfullscreen="true"
            ></iframe>
          </div>
        </ModalBody>
      </Modal>
      <Row className="g-3">
        <Col md="4">
          <Card>
            <CardImg variant="top p-1" src={"/peerpressure.png"} />
            <hr className="my-1"></hr>
            <Card.Body className="align-self-center">
              <Button
                className="rounded-3 text-white"
                onClick={() =>
                  setPdfLink("https://online.fliphtml5.com/zdxhk/jojk/")
                }
                variant="primary"
              >
                Peer pressure
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <CardImg variant="top p-1" src={"/Bodypositivity.png"} />
            <hr className="my-1"></hr>
            <Card.Body className="align-self-center">
              <Button
                className="rounded-3 text-white"
                onClick={() =>
                  setPdfLink("https://online.fliphtml5.com/zdxhk/azqt/")
                }
                variant="primary"
              >
                Body Positivity
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <CardImg variant="top p-1" src={"/Communication.png"} />
            <hr className="my-1"></hr>
            <Card.Body className="align-self-center">
              <Button
                className="rounded-3 text-white"
                onClick={() =>
                  setPdfLink("https://online.fliphtml5.com/zdxhk/dsvo/")
                }
                variant="primary"
              >
                Communications skill
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <CardImg variant="top p-1" src={"/Objectification.png"} />
            <hr className="my-1"></hr>
            <Card.Body className="align-self-center">
              <Button
                className="rounded-3 text-white"
                onClick={() =>
                  setPdfLink("https://online.fliphtml5.com/zdxhk/lczy/")
                }
                variant="primary"
              >
                Objectification and foul language
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <CardImg variant="top p-1" src={"/20230827_001843_0000.png"} />
            <hr className="my-1"></hr>
            <Card.Body className="align-self-center">
              <Button
                className="rounded-3 text-white"
                onClick={() =>
                  setPdfLink("https://online.fliphtml5.com/zdxhk/vedh/#p=1")
                }
                variant="primary"
              >
                Puberty Ally
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
