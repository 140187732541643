import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import mayo from "../../assets/images/st1.png";
import doon from "../../assets/images/st2.png";
import oberoi from "../../assets/images/st3.png";
import jbcn from "../../assets/images/st4.png";

export const TestimonialCarousel = (props) => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div>
      <div className="d-none d-lg-flex">
        <Carousel
          className="text-center"
          activeIndex={index}
          onSelect={handleSelect}
          interval={50000}
        >
          <Carousel.Item
            className="px-4 px-lg-0"
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
            <div className="col-10 mb-4 mx-auto d-lg-flex justify-content-around  align-items-center ">
              <div className="w-45 ">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "854px" }}
                >
                  <img
                    className="w-30 rounded-3 mb-5"
                    src={mayo}
                    alt={"Mayo College"}
                  />
                  <p
                    className="text-start p-17 secondary-b mb-5"
                    style={{ lineHeight: "1.7" }}
                  >
                    The Sex Education workshops conducted by UnTaboo were able
                    to bring clarity in the minds of students and dispel a lot
                    of myths, helping them gain knowledge about matters that
                    they could not have discussed easily with others. It will
                    definitely help them in dealing with themselves and peers in
                    a better manner. The talk for Staff members was an
                    eye-opener of how ignorant we were on certain issues and at
                    the same time a great learning opportunity about how to
                    handle queries on such issues. The team of trainers’ lead by
                    Ms. Anju handled the sessions with ease and great poise.
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">
                    - Garimaa Sethi - School Counsellor, Mayo College, Ajmer
                  </h4>
                  {/* <h6 className="text-start secondary-b">Actor&Parent</h6> */}
                </div>
              </div>

              <div className="w-45 w-sm-100">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3  p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "854px" }}
                >
                  <img
                    className="w-30 rounded-3 mb-5"
                    src={doon}
                    alt={"doonSchool"}
                  />
                  <p
                    className="text-start p-17 secondary-b mb-5 "
                    style={{ lineHeight: "1.7" }}
                  >
                    We were very impressed with UnTaboo’s engaging pedagogy
                    coupled with the matter-of-fact manner in which they
                    addressed these topics and answered queries. The
                    facilitators invested time in breaking the ice with the
                    children and created a space that felt truly safe for them.
                    This meant that even some of our otherwise reticent students
                    were very participative. We received overwhelmingly positive
                    feedback from our students and we look forward to a
                    long-term association with UnTaboo.
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">
                    - Stuti Kuthiala, Deputy Head Pastoral, Doon School
                  </h4>
                  {/* <h6 className="text-start secondary-b"> Parent from Mumbai</h6> */}
                </div>
              </div>
            </div>
          </Carousel.Item>

          


          <Carousel.Item
            className="px-4 px-lg-0"
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
            <div className="col-10 mb-4 mx-auto d-lg-flex justify-content-around  align-items-center ">
              <div className="w-45 ">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "854px" }}
                >
                  <img
                    className="w-30 rounded-3 mb-5"
                    src={oberoi}
                    alt={"oberoi School"}
                  />
                 <p
                    className="text-start p-17 secondary-b mb-5"
                    style={{ lineHeight: "1.7" }}
                  >
                    Tricky topics can often fumble the best of us! The workshops
                    held on "Puberty and Growing Up" for the learners and
                    parents of JBCN International School, Oshiwara, were highly
                    engaging and informative. We are certain that they will go a
                    long way to opening doors, having honest conversations and
                    building bridges. The sessions held for the youngsters were
                    child friendly and engaging. They have helped the children
                    express their thoughts and ask questions freely as they felt
                    they were in a safe space. We thank the team at UnTaboo for
                    showing us a way to navigate through this journey and we
                    look forward to more interactive sessions and workshops in
                    the future as well.
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">
                    - Neha Kakkad, High School Counsellor Oberoi International
                    School{" "}
                  </h4>
                  {/* <h6 className="text-start secondary-b">Actor&Parent</h6> */}
                </div>
              </div>

              <div className="w-45 w-sm-100">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3  p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "854px" }}
                >
                  <img
                    className="w-30 rounded-3 mb-5"
                    src={jbcn}
                    alt={"jcbn School"}
                  />
                  <p
                  className="text-start p-17 secondary-b mb-5"
                  style={{ lineHeight: "1.7" }}
                >
                  Tricky topics can often fumble the best of us! The workshops
                  held on "Puberty and Growing Up" for the learners and parents
                  of JBCN International School, Oshiwara, were highly engaging
                  and informative. We are certain that they will go a long way
                  to opening doors, having honest conversations and building
                  bridges. The sessions held for the youngsters were child
                  friendly and engaging. They have helped the children express
                  their thoughts and ask questions freely as they felt they were
                  in a safe space. We thank the team at UnTaboo for showing us a
                  way to navigate through this journey and we look forward to
                  more interactive sessions and workshops in the future as well.
                </p>
                <h4 className="secondary-blue fs-5 fw-bold">
                  - Mr. K.V. Arjun Rao, Principal, JBCN International School{" "}
                </h4>
                <h6 className="text-start secondary-b"> Oshiwara, Mumbai</h6>
                  {/* <h6 className="text-start secondary-b"> Parent from Mumbai</h6> */}
                </div>
              </div>
            </div>
          </Carousel.Item>



       

        </Carousel>
      </div>








      <div className="d-flex d-lg-none">
        <Carousel
          className="text-center"
          activeIndex={index}
          onSelect={handleSelect}
          interval={50000}
        >
          <Carousel.Item
            className="px-4 px-lg-0"
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
            <div className="col-10 mb-4 mx-auto d-lg-flex justify-content-around  align-items-center ">
              <div className="w-45 w-100 ">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "854px" }}
                >
                  <img
                    className="w-30 rounded-3 mb-5"
                    src={mayo}
                    alt={"Mayo College"}
                  />
                  <p
                    className="text-start p-17 secondary-b mb-5"
                    style={{ lineHeight: "1.7" }}
                  >
                    The Sex Education workshops conducted by UnTaboo were able
                    to bring clarity in the minds of students and dispel a lot
                    of myths, helping them gain knowledge about matters that
                    they could not have discussed easily with others. It will
                    definitely help them in dealing with themselves and peers in
                    a better manner. The talk for Staff members was an
                    eye-opener of how ignorant we were on certain issues and at
                    the same time a great learning opportunity about how to
                    handle queries on such issues. The team of trainers’ lead by
                    Ms. Anju handled the sessions with ease and great poise.
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">
                    - Garimaa Sethi - School Counsellor, Mayo College, Ajmer
                  </h4>
                  {/* <h6 className="text-start secondary-b">Actor&Parent</h6> */}
                </div>
              </div>
              </div>
              </Carousel.Item>
              <Carousel.Item
            className="px-4 px-lg-0"
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
            <div className="col-10 mb-4 mx-auto d-lg-flex justify-content-around  align-items-center ">
              <div className="w-sm-45 w-100">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3  p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "854px" }}
                >
                  <img
                    className="w-30 rounded-3 mb-5"
                    src={doon}
                    alt={"doonSchool"}
                  />
                  <p
                    className="text-start p-17 secondary-b mb-5 "
                    style={{ lineHeight: "1.7" }}
                  >
                    We were very impressed with UnTaboo’s engaging pedagogy
                    coupled with the matter-of-fact manner in which they
                    addressed these topics and answered queries. The
                    facilitators invested time in breaking the ice with the
                    children and created a space that felt truly safe for them.
                    This meant that even some of our otherwise reticent students
                    were very participative. We received overwhelmingly positive
                    feedback from our students and we look forward to a
                    long-term association with UnTaboo.
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">
                    - Stuti Kuthiala, Deputy Head Pastoral, Doon School
                  </h4>
                  {/* <h6 className="text-start secondary-b"> Parent from Mumbai</h6> */}
                </div>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item
            className="px-4 px-lg-0"
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
            <div className="col-10 mb-4 mx-auto d-lg-flex justify-content-around align-items-center">
              <div className="w-sm-45 w-100">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "854px" }}
                >
                  <img
                    className="rounded-3 mb-5"
                    src={oberoi}
                    alt={"oberoi school"}
                    style={{ width: "39%" }}
                  />
                  <p
                    className="text-start p-17 secondary-b mb-5"
                    style={{ lineHeight: "1.7" }}
                  >
                    Tricky topics can often fumble the best of us! The workshops
                    held on "Puberty and Growing Up" for the learners and
                    parents of JBCN International School, Oshiwara, were highly
                    engaging and informative. We are certain that they will go a
                    long way to opening doors, having honest conversations and
                    building bridges. The sessions held for the youngsters were
                    child friendly and engaging. They have helped the children
                    express their thoughts and ask questions freely as they felt
                    they were in a safe space. We thank the team at UnTaboo for
                    showing us a way to navigate through this journey and we
                    look forward to more interactive sessions and workshops in
                    the future as well.
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">
                    - Neha Kakkad, High School Counsellor Oberoi International
                    School{" "}
                  </h4>
                  {/* <h6 className="text-start secondary-b"> Oshiwara, Mumbai</h6> */}
                </div>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item
            className="px-4 px-lg-0"
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
            <div className="col-10 mb-4 mx-auto d-lg-flex justify-content-around align-items-center">
              <div className="w-sm-45 w-100">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "854px" }}
                >
                  <img
                    className="rounded-3 mb-5"
                    src={jbcn}
                    alt={"jbcn school"}
                    style={{ width: "39%" }}
                  />
                  <p
                    className="text-start p-17 secondary-b mb-5"
                    style={{ lineHeight: "1.7" }}
                  >
                    Tricky topics can often fumble the best of us! The workshops
                    held on "Puberty and Growing Up" for the learners and
                    parents of JBCN International School, Oshiwara, were highly
                    engaging and informative. We are certain that they will go a
                    long way to opening doors, having honest conversations and
                    building bridges. The sessions held for the youngsters were
                    child friendly and engaging. They have helped the children
                    express their thoughts and ask questions freely as they felt
                    they were in a safe space. We thank the team at UnTaboo for
                    showing us a way to navigate through this journey and we
                    look forward to more interactive sessions and workshops in
                    the future as well.
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">
                    - Mr. K.V. Arjun Rao, Principal, JBCN International School{" "}
                  </h4>
                  <h6 className="text-start secondary-b"> Oshiwara, Mumbai</h6>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};
