import * as yup from "yup";
import { differenceInYears } from "date-fns";

export const registerSchema = yup.object().shape({
  first_name: yup
    .string()
    .matches(/^[A-Za-z]+$/, "Enter valid first name")
    .max(255, "Cannot exceed 255 characters")
    .required("First name is required"),
  last_name: yup
    .string()
    .matches(/^[A-Za-z]+$/, "Enter valid last name")
    .max(255, "Cannot exceed 255 characters")
    .required("Last name is required"),
  date_of_birth: yup
    .date()
    .max(new Date(), "Date cannot exceed today")
    .optional()
    .nullable(),
  gender: yup.string().oneOf(["M", "F", "O"]).required("Gender is required"),
  location: yup
    .string()
    .max(255, "Cannot exceed 255 characters")
    .required("Location is required"),
  email_id: yup
    .string()
    .email("Invalid email")
    .required("Email ID is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long"),
  cPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm your password"),
  terms: yup.bool().required().oneOf([true], "Terms must be accepted"),
});
export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters long"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm your password"),
});
export const signInUser = yup.object().shape({
  email_id: yup
    .string()
    .email("Invalid email")
    .required("Email ID is required"),
  password: yup
    .string()
    // .min(8, "Password must be at least 8 characters long")
    .required("Password is required"),
});

export const schoolSignIn = yup.object().shape({
  username: yup.string().required("Username is required"),
  password: yup.string().required("Password is required"),
});

export const teacherSignIn = yup.object().shape({
  schoolId: yup.string().required("School is required"),
  emailId: yup.string().required("Email ID is required"),
  password: yup.string().required("Password is required"),
});

export const LearnerSignIn = yup.object().shape({
  email_id: yup
    .string()
    .email("Invalid email")
    .required("Parent's email ID is required"),
  username: yup.string().required("Username is required"),
  password: yup.string().required("Password is required"),
});

export const LearnerSchema = yup.object().shape({
  learner_id: yup.number().required("Please select a learner"),
});

export const NewLearnerSchema = yup.object().shape({
  first_name: yup
    .string()
    .max(255, "Cannot exceed 255 characters")
    .required("Learner first name is required"),
  last_name: yup.string().max(255, "Cannot exceed 255 characters").optional(),
  date_of_birth: yup
    .date()
    .max(new Date(), "Cannot be unborn")
    .required("Date of birth is required"),
  gender: yup
    .string()
    .oneOf(["M", "F", "O"])
    .required("Learner gender is required"),
  username: yup
    .string()
    .max(255, "Cannot exceed 255 characters")
    .required("Learner user name is required."),
  password: yup
    .string()
    .max(255, "Cannot exceed 255 characters")
    .required("Learner password is required"),
});
export const NewAddLearnerSchema = yup.object().shape({
  parent_first_name: yup
    .string()
    .max(255, "Cannot exceed 255 characters")
    .optional(),
  parent_last_name: yup
    .string()
    .max(255, "Cannot exceed 255 characters")
    .optional(),
  email_id: yup
    .string()
    .email("Invalid email")
    .optional("Parent email ID is required"),
  parent_date_of_birth: yup
    .date()
    .max(new Date(), "Cannot be unborn")
    .optional("Parent date of birth is required"),
  parent_gender: yup.string().oneOf(["M", "F", "O"]).optional(),

  learner_first_name: yup

    .string()
    .max(255, "Cannot exceed 255 characters")
    .required("Learner first name is required"),
  learner_last_name: yup
    .string()
    .max(255, "Cannot exceed 255 characters")
    .required("Learner last name is required"),

  learner_date_of_birth: yup
    .date()
    .max(new Date(), "Cannot be unborn")
    .required("Learner date of birth is required"),

  learner_class: yup
    .string()
    .max(255, "Cannot exceed 255 characters")
    .required("Learner class is required"),

  learner_gender: yup
    .string()
    .oneOf(["M", "F", "O"])
    .required("learner Gender is required"),

  learner_division: yup
    .string()
    .max(255, "Cannot exceed 255 characters")
    .required("Learner division is required"),

  program_id: yup
    .string()
    .max(255, "Cannot exceed 255 characters")
    .required("Program is required"),
});

const DifficultySchema = yup
  .string()
  .oneOf(["very_difficult", "difficult", "it_was_okay", "easy", "very_easy"])
  .required("Level of difficulty is required");

const LikenessSchema = yup
  .string()
  .oneOf(["hated", "did_not_like_it", "it_was_okay", "liked_it", "loved_it"])
  .required("This is required");

export const SurveySchema = yup.object().shape({
  name: yup
    .string()
    .max(255, "Cannot exceed 255 characters")
    .required("Your name is required"),
  learner_name: yup
    .string()
    .max(255, "Cannot exceed 255 characters")
    .required("Learner name is required"),
  relationship: yup
    .string()
    .oneOf(["Mother", "Father", "Other"])
    .required("Your relationship to the learner is required"),
  other_relationship: yup.string().when(["relationship"], {
    is: "Other",
    then: yup
      .string()
      .max(255, "Cannot exceed 255 characters")
      .required("Please tell us your relationship"),
    otherwise: yup.string().max(255, "Cannot exceed 255 characters").optional(),
  }),
  learner_age: yup
    .mixed()
    .oneOf(["9", "10", "11", "12", "Other"])
    .required("Learner age is required"),
  other_learner_age: yup
    .number("Learner age should be a number")
    .when(["learner_age"], {
      is: "Other",
      then: yup
        .number("Learner age should be a number")
        .min(1, "Cannot be less than 1yr old")
        .max(100, "Cannot be 100yrs old")
        .required("Please enter the learner age"),
      otherwise: yup.number("Learner age should be a number").optional(),
    }),
  learner_sex: yup
    .string()
    .oneOf(["M", "F", "O"])
    .required("Learner's sex is required"),
  other_learner_sex: yup.string().optional(),
  learner_school: yup
    .string()
    .max(500, "Cannot exceed 500 characters")
    .required("School name is required"),
  learner_school_board: yup
    .string()
    .oneOf(["ICSE", "IGCSE", "IB", "CBSE", "SSC", "Other"])
    .required("Board of school is required"),
  other_learner_school_board: yup.string().when(["learner_school_board"], {
    is: "Other",
    then: yup.string().required("The school board is required"),
    otherwise: yup.string().optional(),
  }),
  ease_of_navigation: yup
    .string()
    .clone(DifficultySchema)
    .required("This is required"),
  hand_holding: yup
    .string()
    .oneOf(["Yes", "Sometimes", "Chose", "Wanted", "No"])
    .required("This is required"),
  time_taken: yup
    .string()
    .oneOf(["1", "2_5", "6_14", "15_30", "gt30"])
    .required("This is required"),
  impact: yup
    .string()
    .oneOf(["true", "false"])
    .required("Impact on learner is required"),
  yes_impact: yup.array().when(["impact"], {
    is: "true",
    then: yup.array().min(1, "Select atleast one impact"),
    otherwise: yup.array().optional(),
  }),
  program_value: yup
    .string()
    .oneOf(["3000+", "2500-3000", "2000-2500", "1500-2000", "-1500"])
    .required("Your opinion matters to us"),
  suggestions: yup
    .string()
    .max(1000, "Cannot exceed 1000 characters")
    .optional(),
  recommendation_probability: yup
    .string()
    .oneOf(["1", "2", "3", "4", "5"])
    .required(),
  was_uncomfortable: yup
    .string()
    .oneOf(["true", "false"])
    .required("This is required"),
  yes_was_uncomfortable: yup.string().when(["was_uncomfortable"], {
    is: "true",
    then: yup
      .string()
      .max(500, "Cannot exceed 500 characters")
      .required("Please tell us what made you feel uncomfortable"),
    otherwise: yup.string().optional(),
  }),
  program_likeliness: yup
    .string()
    .clone(LikenessSchema)
    .required("This is required"),
  difficulty: yup.string().clone(DifficultySchema).required("This is required"),
  ease_of_understanding: yup
    .string()
    .clone(DifficultySchema)
    .required("This is required"),
  rating: yup.object().shape({
    watch: yup
      .string()
      .clone(LikenessSchema)
      .required("Please rate the watch section"),
    learn: yup
      .string()
      .clone(LikenessSchema)
      .required("Please rate the learn section"),
    discuss: yup
      .string()
      .clone(LikenessSchema)
      .required("Please rate the discuss section"),
    play: yup
      .string()
      .clone(LikenessSchema)
      .required("Please rate the play section"),
    faceoff: yup
      .string()
      .clone(LikenessSchema)
      .required("Please rate the face off section"),
    scoreboard: yup
      .string()
      .clone(LikenessSchema)
      .required("Please rate the scoreboard feature"),
    moodboard: yup
      .string()
      .clone(LikenessSchema)
      .required("Please rate the moodboard"),
    coupons: yup
      .string()
      .clone(LikenessSchema)
      .required("Please rate the coupons"),
  }),
  ease_of_discussion: yup
    .string()
    .oneOf(["yes", "a_little", "unsure", "not_much", "no"])
    .required("This is required"),
  change: yup.string().max(1000, "Cannot exceed 1000 characters").optional(),
  character: yup
    .string()
    .oneOf(["neal", "kiara", "pepper_the_cat", "none"])
    .required("This is required"),
});

export const GiftSchema = yup.object().shape({
  email_id: yup
    .string()
    .email("Should be a valid email")
    .max(500, "Cannot exceed 500 characters")
    .required("Email ID is required"),
  name: yup.string().max(255, "Cannot exceed 255 characters").optional(),
  program_id: yup
    .number("Should be a number")
    .required("Program ID is required"),
  gifted_by_user_id: yup.number("Should be a number").nullable(),
  gifted_by_email_id: yup
    .string()
    .email("Should be a valid email")
    .required("Email ID is required"),
  gifted_by_name: yup.string().optional(),
});

export const AssignLearnerSchema = yup.object().shape({
  code: yup.string().required("Learner code is required"),
});

export const affiliateSchema = yup.object().shape({
  firstName: yup
    .string()
    .max(255, "Cannot exceed 255 characters")
    .required("First name is required"),
  lastName: yup
    .string()
    .max(255, "Cannot exceed 255 characters")
    .required("Last name is required"),
  dob: yup
    .date()
    .required("Date of birth is required")
    .test({
      name: "is_eighteen_plus",
      message:
        "Sorry! While we love your commitment - you need to be 18+ to sign up for this. We are here & waiting for you to come back!",
      test: (value) => {
        return differenceInYears(new Date(), new Date(value)) >= 18;
      },
    }),
  contact_no: yup
    .string()
    .max(10, "Cannot exceed 10 characters")
    .required("Contact number is required"),
  city: yup
    .string()
    // .max(10, "Cannot exceed 10 characters")
    .required("City is required"),
  state: yup
    .string()
    .max(255, "Cannot exceed 255 characters")
    .required("Location is required"),
  email: yup.string().email("Invalid email").required("Email ID is required"),
  knowDetails: yup.bool().required().oneOf([true], "Terms must be accepted"),
});
