import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Assest from "../../assets/images/neel_kiara.jpg";
import Spinner from "react-bootstrap/Spinner";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import { Formik } from "formik";
import { AssignLearnerSchema } from "../../atoms/schema";
import { app_api } from "../../atoms/api";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Alert from "react-bootstrap/Alert";

// const Button = styled.button`
//   color: #f64e60;
//   background-color: #ffe2e5;
//   border: none;
//   font-size: 14px;
//   border-radius: 10px;
//   padding: 7px 10px;
//   font-weight: bold;
//   &:hover {
//     color: white;
//     background-color: #f64e60;
//   }
// `;

export const ParentUpdate = (props) => {
  const [assign, setAssign] = useState(false);
  const [assignLoading, setAssignLoading] = useState(false);
  const [assignSucces, setAssignSuccess] = useState(false);
  const [assignError, setAssignError] = useState(null);

  const handleAssignOpen = () => setAssign(true);
  const handleAssignClose = () => setAssign(false);
  const dismissedAssignError = () => setAssignError(null);
  const dismissAssignSuccess = () => setAssignSuccess(false);

  function getAge(dateString) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
  return (
    <Container className="my-3 bg-white rounded-3">
      <div className="d-flex flex-column flex-md-row p-3">
        <span className="text-bold fs-2">Learner Details</span>
        <Button
          variant="secondary"
          className="ms-auto"
          title="Use learner code to add learner"
          onClick={handleAssignOpen}
        >
          Add existing learner
        </Button>
      </div>
      <Table responsive="md p-3">
        <thead>
          <tr>
            <th className="dashboard-blue fs-5"> LEARNER</th>
            <th className="dashboard-blue fs-5">AGE</th>
            <th className="dashboard-blue fs-5">GENDER</th>
            {/* <th className="dashboard-blue">PROGRAMS NAME</th> */}
            {/* <th className="dashboard-blue">ACTION</th> */}
          </tr>
        </thead>
        <tbody>
          {props.loading ? (
            <tr>
              <td>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              </td>
            </tr>
          ) : props.error ? (
            <tr>
              <span className="text-danger">{props.error}</span>
            </tr>
          ) : (
            props.learners.map((l, index) => (
              <tr key={index}>
                <td className="w-20 align-middle">
                  <img className="w-20 rounded me-2" src={Assest} />
                  {l?.first_name + " " + l?.last_name}
                </td>
                <td className="align-middle">
                  {getAge(`${l?.date_of_birth}`)}
                </td>
                <td className="align-middle text-lowercase">
                  <Badge pill={true} bg="secondary">
                    {l?.gender}
                  </Badge>
                </td>
                {/* <td className="align-middle">
                  <Button variant="danger">
                    <FontAwesomeIcon className="text-light" icon={faTrash} />
                  </Button>
                </td> */}
              </tr>
            ))
          )}
        </tbody>
      </Table>
      <Modal show={assign} onHide={handleAssignClose}>
        <ModalHeader closeButton={true}>
          <ModalTitle>Add a learner</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <Formik
            initialValues={{ code: "" }}
            onSubmit={(values) => {
              setAssignLoading(true);
              setAssignError(null);
              app_api
                .patch(`/learner/assign/${values.code.toUpperCase()}`)
                .then((res) => {
                  setAssignLoading(false);
                  setAssignSuccess(true);
                  setAssignError(null);
                })
                .catch((err) => {
                  setAssignLoading(false);
                  setAssignSuccess(false);
                  setAssignError(
                    err?.response?.data?.message || "Error assigning learner"
                  );
                });
            }}
            validationSchema={AssignLearnerSchema}
          >
            {({
              handleBlur,
              handleChange,
              handleSubmit,
              values,
              touched,
              errors,
            }) => (
              <Form noValidate={true} onSubmit={handleSubmit}>
                {assignError && (
                  <Alert
                    variant="danger"
                    dismissible={true}
                    onClose={dismissedAssignError}
                  >
                    {assignError}
                  </Alert>
                )}
                {assignSucces && (
                  <Alert
                    variant="success"
                    dismissible={true}
                    onClose={dismissAssignSuccess}
                  >
                    Learner added successfully. Reload the page to see the
                    learner.
                  </Alert>
                )}
                <FloatingLabel
                  label="Learner code"
                  controlId="assign-learner.code"
                >
                  <FormControl
                    name="code"
                    type="text"
                    maxLength="6"
                    placeholder="Learner code"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isValid={touched.code && !errors.code}
                    isInvalid={touched.code && !!errors.code}
                    value={values.code}
                  />
                  {touched.code && (
                    <FormControl.Feedback type="invalid">
                      {errors.code}
                    </FormControl.Feedback>
                  )}
                </FloatingLabel>
                <ModalFooter>
                  <Button
                    variant="secondary"
                    onClick={handleAssignClose}
                    type="button"
                  >
                    Close
                  </Button>
                  <Button variant="primary" type="submit">
                    {assignLoading ? "Assigning..." : "Submit"}
                  </Button>
                </ModalFooter>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </Modal>
    </Container>
  );
};
