import React, { useEffect } from "react";
import pt from "prop-types";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormControl from "react-bootstrap/FormControl";
import Alert from "react-bootstrap/Alert";
import { app_api } from "../../atoms/api";
import { Navigate, useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { Formik } from "formik";
import { useState } from "react";
import {
  ContainerDiv,
  SubmitButtonFormGroup,
  InputBox,
  PrimaryButton,
  SecondaryButton,
  ChangeScreenMessage,
} from "../../pages/login";
import { LearnerSignIn } from "../../atoms/schema";
import Cookies from "js-cookie";
import {
  useGAEventLearnerLogin,
  useGAEventSchoolLogin,
  useGAEventSignUp,
} from "../../hoc/hooks/useGAEventTracker";

export const LearnerLogin = (props) => {
  const GAEventSignUp = useGAEventSignUp;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const dismissError = () => setError(null);

  const login = (values) => {
    setLoading(true);
    app_api
      .post("auth/learner/login", values)
      .then((res) => {
        setLoading(false);
        Cookies.set("ut_user", JSON.stringify(res.data));
        navigate("/dashboard/learner");
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Error");
      });
  };

  // useEffect(() => {
  //   if (props.show) {
  //     console.log("learner login");
  //     GAEventLearnerLogin();
  //     // console.log(GAEventLearnerLogin);
  //   }
  // }, [props.show]);
  const SignUp = () => {
    props.switchSection("signin");
    GAEventSignUp();
  };
  return (
    <ContainerDiv
      className="flex-column justify-content-start position-absolute col-12 col-lg-11 col-xl-10"
      {...props}
    >
      <ChangeScreenMessage>Learner login</ChangeScreenMessage>
      <Formik
        className="mt-4"
        initialValues={{
          email_id: "",
          username: "",
          password: "",
        }}
        onSubmit={login}
        validationSchema={LearnerSignIn}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          touched,
          values,
          errors,
          isValid,
          resetForm,
        }) => (
          <Form
            noValidate={true}
            onSubmit={handleSubmit}
            className="mt-5 pb-5 pb-lg-0 mb-4 mb-lg-0"
          >
            {error !== null && (
              <Alert variant="danger" dismissible={true} onClose={dismissError}>
                {error}
              </Alert>
            )}
            <FloatingLabel
              controlId="login.email_id"
              label="Parent's Email"
              className="mb-3"
            >
              <InputBox
                type="email"
                placeholder="Parent's Email"
                autoComplete="email"
                name="email_id"
                value={values.email_id}
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                isValid={touched.email_id && !errors.email_id}
                isInvalid={touched.email_id && !!errors.email_id}
              />
              {touched.email_id && (
                <FormControl.Feedback type="invalid">
                  {errors.email_id}
                </FormControl.Feedback>
              )}
            </FloatingLabel>
            <FloatingLabel
              controlId="login.username"
              label="Username"
              className="mb-3"
            >
              <InputBox
                type="text"
                placeholder="Username"
                autoComplete="new-username"
                name="username"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                isValid={touched.username && !errors.username}
                isInvalid={touched.username && !!errors.username}
              />
              {touched.username && (
                <FormControl.Feedback type="invalid">
                  {errors.username}
                </FormControl.Feedback>
              )}
            </FloatingLabel>
            <FloatingLabel
              controlId="login.password"
              label="Password"
              className="mb-3"
            >
              <InputBox
                type="password"
                placeholder="Password"
                autoComplete="new-password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                isValid={touched.password && !errors.password}
                isInvalid={touched.password && !!errors.password}
              />
              {touched.password && (
                <FormControl.Feedback type="invalid">
                  {errors.password}
                </FormControl.Feedback>
              )}
            </FloatingLabel>
            <ChangeScreenMessage
              style={{ marginBottom: "3rem", display: "block" }}
            >
              <span>
                <a href="/forgotpassword?type=learner">Forgot Password</a>
              </span>
            </ChangeScreenMessage>
            <SubmitButtonFormGroup className="d-flex flex-column align-items-start flex-sm-row">
              <PrimaryButton
                variant="primary"
                type="submit"
                disabled={loading || !isValid}
              >
                {loading
                  ? [
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />,
                      " Logging in...",
                    ]
                  : "Login"}
              </PrimaryButton>
              <SecondaryButton
                variant="secondary"
                onClick={() => {
                  SignUp();
                  resetForm();
                }}
              >
                Cancel
              </SecondaryButton>
            </SubmitButtonFormGroup>
          </Form>
        )}
      </Formik>
    </ContainerDiv>
  );
};

LearnerLogin.propTypes = {
  show: pt.bool.isRequired,
};
