import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useParams } from "react-router-dom";
import { app_api } from "../../atoms/api";
import { Sections } from "./sections";
import { ProgramSideNav } from "../../templates/programSideNav";
import Cookies from "js-cookie";

export const OldLevel = (props) => {
  const [currentSection, setCurrentSection] = useState("watch");
  const [nextSection, setNextSection] = useState("learn");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [enrollment, setEnrollment] = useState({});
  const [program, setProgram] = useState({});
  const [hasLearn, setHasLearn] = useState(false);
  const [hasDiscuss, setHasDiscuss] = useState(false);

  const params = useParams();
  let user = {};
  if (!!Cookies.get("ut_user")) {
    user = JSON.parse(Cookies.get("ut_user"));
  }
  useEffect(() => {
    if (!!Cookies.get("ut_user") && user.role === "teacher") {
      let levelId = params.previousLevelId;
      if (levelId) {
        app_api
          .get(`levels/${levelId}/previousLevel`)
          .then((res) => {
            setLoading(false);
            setProgram(res.data);
            if (res.data?.watch?.length > 0) setCurrentSection("watch");
            else {
              if (res.data?.learn?.length > 0) setCurrentSection("learn");
              else {
                if (res.data?.discuss?.length > 0) setCurrentSection("discuss");
                else setCurrentSection("play");
              }
            }
            if (res.data?.learn?.length > 0) setHasLearn(true);
            if (res.data?.discuss?.length > 0) setHasDiscuss(true);
          })
          .catch((err) => {
            setLoading(false);
            setError(err?.response?.data?.message || "Error");
          });
      }
    } else {
      app_api
        .get(`enrollment/${params.enrollmentId}`)
        .then((res) => {
          setEnrollment(res.data);
          let levelId = params.previousLevelId;
          if (levelId) {
            app_api
              .get(`levels/${levelId}/previousLevel`)
              .then((res) => {
                setLoading(false);

                setProgram(res.data);
                if (res.data?.watch?.length > 0) setCurrentSection("watch");
                else {
                  if (res.data?.learn?.length > 0) setCurrentSection("learn");
                  else {
                    if (res.data?.discuss?.length > 0)
                      setCurrentSection("discuss");
                    else setCurrentSection("play");
                  }
                }
                if (res.data?.learn?.length > 0) setHasLearn(true);
                if (res.data?.discuss?.length > 0) setHasDiscuss(true);
              })
              .catch((err) => {
                setLoading(false);
                setError(err?.response?.data?.message || "Error");
              });
          }
        })
        .catch((err) => {
          setLoading(false);
          setError(
            err?.response?.data?.message || "Error getting enrollment ID"
          );
        });
    }
  }, [params.previousLevelId]);

  const onNextSectionClick = () => {
    if (currentSection === "watch") {
      if (hasLearn) {
        setCurrentSection("learn");
        setNextSection("discuss");
      } else if (hasDiscuss) {
        setCurrentSection("discuss");
        setNextSection("play");
      } else {
        setCurrentSection("play");
      }
    } else if (currentSection === "learn") {
      if (hasDiscuss) {
        setCurrentSection("discuss");
        setNextSection("play");
      } else {
        setCurrentSection("play");
      }
    } else if (currentSection === "discuss") {
      setCurrentSection("play");
    }
  };

  return (
    <div>
      <ProgramSideNav
        navbar
        program={program}
        loading={loading}
        error={error}
        enrollmentId={params.enrollmentId}
        enrollment={enrollment}
      />
      <Row>
        <ProgramSideNav
          program={program}
          loading={loading}
          error={error}
          enrollmentId={params.enrollmentId}
          enrollment={enrollment}
        />
        <Col xs="12" className="p-0 mx-auto w-80">
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : !!error ? (
            <label className="text-danger text-capitalize">{error}</label>
          ) : (
            <Sections
              enrollmentId={params.enrollmentId}
              program={program}
              questions={program}
              enrollment={enrollment}
              currentSection={currentSection}
              setCurrentSection={setCurrentSection}
              questionLoading={loading}
              questionError={error}
              processClick={onNextSectionClick}
              nextSection={nextSection}
            />
          )}
        </Col>
      </Row>
    </div>
  );
};
