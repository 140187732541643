import React, { useLayoutEffect } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import img1 from "../../assets/images/Resources page main image.png";
import img2 from "../../assets/images/Final Untaboo blog images-04.png";
import img3 from "../../assets/images/Final Untaboo blog images-01.png";
import img4 from "../../assets/images/Final Untaboo blog images-02.png";
import img5 from "../../assets/images/Final Untaboo blog images-03.png";
import img6 from "../../assets/images/tedxx.png";
import img7 from "../../assets/images/tediitd.png";
import img8 from "../../assets/images/cactus.png";
import img9 from "../../assets/images/dance.png";
import img10 from "../../assets/images/Final Untaboo blog images-05.png";
import img11 from "../../assets/images/child_innocence.png";
import img12 from "../../assets/images/teachable_moment.png";
import img13 from "../../assets/images/parent_child.png";
import img14 from "../../assets/images/chubby_boy.png";
import img15 from "../../assets/images/EV_1.png";
import img16 from "../../assets/images/EV_2.png";
import img17 from "../../assets/images/EV_3.png";
import img18 from "../../assets/images/EV_4.png";
import img19 from "../../assets/images/EV_5.png";
import img20 from "../../assets/images/EV_6.png";
import img21 from "../../assets/images/EV_7.png";
import img22 from "../../assets/images/EV_8.png";
import img23 from "../../assets/images/EV_9.png";

import Button from "../../atoms/button";

export const Resources = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div className="px-4 px-lg-0">
      <Container className="poppins ">
        <div className="my-10 d-lg-flex justify-content-between align-items-center">
          <div className="w-45 w-sm-100">
            <img className="w-100" src={img1}></img>
          </div>
          <div className="w-45 w-sm-100">
            <p className="fs-5">
              UnTaboo Online & the range of programs here are geared towards
              enabling & empowering pre-teens & teens. But conversations are a
              2-way street, right? <br></br>
              <br></br>So here is our Founder, Anju Kish, opening up her locker
              of blogs, videos & write-ups that are bursting with information,
              tips, and anecdotes on various topics.<br></br>
              <br></br> Hope it will help you to open conversations with your
              children and teens.
              <b className="secondary-b"> Happy talking & bonding!</b>
            </p>
          </div>
        </div>
        {/* ----------BLOGS-------------- */}

        <div className="mt-10 pt-5">
          <h1 className="secondary-blue mb-4 fw-bold"> Blogs </h1>
          <p style={{ fontSize: "20px" }}>
            <b>
              <i>
                “Hey, go ahead - explore my Blog space and do nudge me if there
                is a topic you want me to write on, to share your feedback on
                something that worked (or didn’t work!) for you - I’d love to
                hear from you!”
              </i>
            </b>
            - <b>Anju Kish</b>
          </p>

          <div className="d-lg-flex flex-wrap justify-content-between mt-10">
            <Card
              style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
              className="w-45 w-sm-100 text-center mb-5 "
            >
              <Card.Img variant="top" src={img2} />
              <Card.Body className="px-lg-5 px-3">
                <Card.Title
                  className=" mt-5 mb-4 fw-bold fs-4"
                  style={{ height: "60px" }}
                >
                  HOW TO HAVE THE FIRST PERIOD TALK WITH YOUR LITTLE GIRL
                </Card.Title>
                <Card.Text
                  className="mt-5 mb-3"
                  style={{ textAlign: "justify", height: "180px" }}
                >
                  Parents 1st Period talk with their Daughters can be scary:
                  what to say, how to say? Here’s a post to help ease your way
                  and make this conversation simple and special.
                </Card.Text>

                <a
                  target="_blank"
                  href="/how-to-have-the-first-period-talk-with-your-little-girl"
                >
                  <Button className="mt-3 mb-5">CONTINUE READING</Button>
                </a>
              </Card.Body>
            </Card>

            <Card
              style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
              className="w-45 w-sm-100 text-center mb-5"
            >
              <Card.Img variant="top" src={img3} />
              <Card.Body className="px-lg-5 px-3">
                <Card.Title
                  className=" mt-5 mb-4 fw-bold fs-4"
                  style={{ height: "60px" }}
                >
                  A HEART TO HEART WITH BOYS ABOUT PERIODS
                </Card.Title>
                <Card.Text
                  className="mt-5 mb-3"
                  style={{ textAlign: "justify", height: "180px" }}
                >
                  What better way to explain ‘Periods’ to boys than a letter
                  written by Periods introducing herself. A sensitive way to
                  help them understand this important change in a girl's life
                  and develop empathy.
                </Card.Text>

                <a
                  target="_blank"
                  href="/a-heart-to-heart-with-boys-about-period"
                >
                  <Button className="mt-3 mb-5">CONTINUE READING</Button>
                </a>
              </Card.Body>
            </Card>

            <Card
              style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
              className="w-45 w-sm-100 text-center mb-5"
            >
              <Card.Img variant="top" src={img4} />
              <Card.Body className="px-lg-5 px-3">
                <Card.Title
                  className=" mt-5 mb-4 fw-bold fs-4"
                  style={{ height: "60px" }}
                >
                  FIRST PUBERTY TALK WITH YOUR DAUGHTER
                </Card.Title>
                <Card.Text
                  className="mt-5 mb-3"
                  style={{ textAlign: "justify", height: "180px" }}
                >
                  We want to hold on to our kids’ childhood for as long as we
                  can. But Puberty is inevitable and here are some things you
                  need to be talking to your daughter about, to prepare her for
                  this big change in her life
                </Card.Text>

                <a
                  target="_blank"
                  href="/first-puberty-talk-with-your-daughter"
                >
                  <Button className="mt-3 mb-5">CONTINUE READING</Button>
                </a>
              </Card.Body>
            </Card>

            <Card
              style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
              className="w-45 w-sm-100 text-center mb-5"
            >
              <Card.Img variant="top" src={img5} />
              <Card.Body className="px-lg-5 px-3">
                <Card.Title
                  className=" mt-5 mb-4 fw-bold fs-4"
                  style={{ height: "60px" }}
                >
                  FIRST PUBERTY TALK WITH YOUR SON{" "}
                </Card.Title>
                <Card.Text
                  className="mt-5 mb-3"
                  style={{ textAlign: "justify", height: "180px" }}
                >
                  Boys don’t get periods, but that does not mean they don’t go
                  through equally traumatic changes at Puberty. Here are some
                  things you need to be talking to your Son about, to ease his
                  way through this big change in his life
                </Card.Text>

                <a target="_blank" href="/first-puberty-talk-with-your-son">
                  <Button className="mt-3 mb-5">CONTINUE READING</Button>
                </a>
              </Card.Body>
            </Card>

            <Card
              style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
              className="w-45 w-sm-100 text-center mb-5"
            >
              <Card.Img variant="top" src={img10} />
              <Card.Body className="px-lg-5 px-3">
                <Card.Title
                  className=" mt-5 mb-4 fw-bold fs-4"
                  style={{ height: "60px" }}
                >
                  A LETTER FOR RED DAYS
                </Card.Title>
                <Card.Text
                  className="mt-5 mb-3"
                  style={{ textAlign: "justify", height: "180px" }}
                >
                  Periods are a big change in a girl's life and there is so much
                  she needs to know about it. A little girl expresses the
                  confusion she is facing and shares the trauma of coping with
                  Periods.
                </Card.Text>

                <a target="_blank" href="/a-letter-for-red-days">
                  <Button className="mt-3 mb-5">CONTINUE READING</Button>
                </a>
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className="mt-10 pt-5">
          <h1 className="secondary-blue mb-4 fw-bold"> TEDx </h1>

          <div className="d-lg-flex flex-wrap justify-content-between mt-10">
            <Card
              style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
              className="w-45 w-sm-100 text-center mb-5"
            >
              <Card.Img variant="top" src={img6} />
              <Card.Body className="px-lg-5 px-3">
                <Card.Title
                  className=" mt-5 mb-4 fw-bold fs-4"
                  style={{ height: "60px" }}
                >
                  How sex education can make the world a safer place
                </Card.Title>
                <Card.Text className="mt-5 mb-3" style={{ height: "380px" }}>
                  Most people read only the word ‘Sex’ and don’t even notice the
                  most important word ‘Education’ right next to it. This makes
                  them make wrong conjectures of what ‘sex education’ could be
                  and thus reject it outright. Sex education is in fact a
                  beautiful concept which not only positively educates but also
                  teaches responsibility, accountability and sensitivity to a
                  child. It has the power to change attitudes, leading to a more
                  responsible and safer nation and world.
                </Card.Text>

                <a
                  target="_blank"
                  href="https://www.ted.com/talks/anju_kish_how_sex_education_can_make_the_world_a_safer_place "
                >
                  <Button className="mt-3 mb-5">Watch Video</Button>
                </a>
              </Card.Body>
            </Card>
            <Card
              style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
              className="w-45 w-sm-100 text-center mb-5 "
            >
              <Card.Img variant="top" src={img7} />
              <Card.Body className="px-lg-5 px-3">
                <Card.Title
                  className=" mt-5 mb-4 fw-bold fs-4"
                  style={{ height: "60px" }}
                >
                  Don't just do it, talk about it too!
                </Card.Title>
                <Card.Text className="mt-5 mb-3" style={{ height: "380px" }}>
                  Anju Kish went out of the box and spoke about the importance
                  and need for sex education. Giving examples from real-life
                  situations, she bonded with the audience through this
                  unconventional topic and inspired everyone to be more open and
                  comfortable with talking about this natural, yet taboo, topic.
                </Card.Text>

                <a
                  target="_blank"
                  href="https://www.ted.com/talks/anju_kish_don_t_just_do_talk_too_jan_2019"
                >
                  <Button className="mt-3 mb-5">Watch Video</Button>
                </a>
              </Card.Body>
            </Card>
            <Card
              style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
              className="w-45 w-sm-100 text-center mb-5 "
            >
              <Card.Img variant="top" src={img7} />
              <Card.Body className="px-lg-5 px-3">
                <Card.Title
                  className=" mt-5 mb-4 fw-bold fs-4"
                  style={{ height: "60px" }}
                >
                  Sex education is not the villain – lack of it is!
                </Card.Title>
                <Card.Text className="mt-5 mb-3" style={{ height: "380px" }}>
                  Anju Kish, founder of UnTaboo believes that sex education is a
                  not a new concept, but it is a topic which makes people
                  nervous and uncomfortable. The hesitation to impart it stems
                  from one’s inability to tackle the subject, the taboos
                  surrounding it and fears about its impact. Through her talk
                  she aims to break down the hesitations logically to normalise
                  and UnTaboo the subject to make people realise the importance
                  of it and the positive impact it can have in the life of our
                  child...
                </Card.Text>

                <a
                  target="_blank"
                  href="https://www.ted.com/talks/anju_kish_sex_education_is_not_the_villain_lack_of_it_is"
                >
                  <Button className="mt-3 mb-5">Watch Video</Button>
                </a>
              </Card.Body>
            </Card>
          </div>

          <div className="mt-10 pt-5">
            <h1 className="secondary-blue mb-4" style={{ fontWeight: "600" }}>
              EXPERT COLUMNS AT TWEAK INDIA
            </h1>

            <div className="d-lg-flex flex-wrap justify-content-between mt-10">
              <Card
                style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
                className="w-45 w-sm-100 text-center mb-5"
              >
                <Card.Img variant="top" src={img8} />
                <Card.Body className="px-lg-5 px-3">
                  <Card.Title
                    className=" mt-5 mb-4 fw-bold fs-4"
                    style={{ height: "60px" }}
                  >
                    The honest truth about erections — they're not always about
                    sex
                  </Card.Title>

                  <a
                    target="_blank"
                    href="https://tweakindia.com/parenting/expert/erections-young-boys-puberty/"
                  >
                    <Button className="mt-3 mb-5">Continue Reading</Button>
                  </a>
                </Card.Body>
              </Card>

              <Card
                style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
                className="w-45 w-sm-100 text-center mb-5 "
              >
                <Card.Img variant="top" src={img9} />
                <Card.Body className="px-lg-5 px-3">
                  <Card.Title
                    className=" mt-5 mb-4 fw-bold fs-4"
                    style={{ height: "60px" }}
                  >
                    Why you need to talk about breasts<br></br> with your
                    daughter
                  </Card.Title>

                  <a
                    target="_blank"
                    href="https://tweakindia.com/parenting/expert/having-the-breasts-talk-with-your-daughter/"
                  >
                    <Button className="mt-3 mb-5">Continue Reading</Button>
                  </a>
                </Card.Body>
              </Card>

              <Card
                style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
                className="w-45 w-sm-100 text-center mb-5 "
              >
                <Card.Img
                  variant="top"
                  src={img11}
                  style={{ height: "274px" }}
                />
                <Card.Body className="px-lg-5 px-3">
                  <Card.Title
                    className=" mt-5 mb-4 fw-bold fs-4"
                    style={{ height: "60px" }}
                  >
                    Sex education won't take away your child’s innocence
                  </Card.Title>

                  <a
                    target="_blank"
                    href="https://tweakindia.com/parenting/nurture/sex-education-wont-take-away-your-childs-innocence/"
                  >
                    <Button className="mt-3 mb-5">Continue Reading</Button>
                  </a>
                </Card.Body>
              </Card>
              <Card
                style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
                className="w-45 w-sm-100 text-center mb-5 "
              >
                <Card.Img variant="top" src={img12} />
                <Card.Body className="px-lg-5 px-3">
                  <Card.Title
                    className=" mt-5 mb-4 fw-bold fs-4"
                    style={{ height: "60px" }}
                  >
                    Talking about sex: spot the teachable moments
                  </Card.Title>

                  <a
                    target="_blank"
                    href="https://tweakindia.com/parenting/talking-about-sex-spot-the-teachable-moments/"
                  >
                    <Button className="mt-3 mb-5">Continue Reading</Button>
                  </a>
                </Card.Body>
              </Card>
              <Card
                style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
                className="w-45 w-sm-100 text-center mb-5 "
              >
                <Card.Img variant="top" src={img13} />
                <Card.Body className="px-lg-5 px-3">
                  <Card.Title
                    className=" mt-5 mb-4 fw-bold fs-4"
                    style={{ height: "75px" }}
                  >
                    Pee-pees, nunus and the family jewels: the first mistake
                    parents make with sex education
                  </Card.Title>

                  <a
                    target="_blank"
                    href="https://tweakindia.com/parenting/expert/sex-education-teaching-kids-the-right-words-for-their-genitals/"
                  >
                    <Button className="mt-3 mb-5">Continue Reading</Button>
                  </a>
                </Card.Body>
              </Card>

              <Card
                style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
                className="w-45 w-sm-100 text-center mb-5 "
              >
                <Card.Img variant="top" src={img14} />
                <Card.Body className="px-lg-5 px-3">
                  <Card.Title
                    className=" mt-5 mb-4 fw-bold fs-4"
                    style={{ height: "60px" }}
                  >
                    Boys have breasts too
                  </Card.Title>

                  <a
                    target="_blank"
                    href="https://tweakindia.com/parenting/expert/boys-have-breasts-too/"
                  >
                    <Button className="mt-3 mb-5">Continue Reading</Button>
                  </a>
                </Card.Body>
              </Card>
            </div>
          </div>

          <div className="mt-10 pt-5">
            <h1 className="secondary-blue mb-4" style={{ fontWeight: "600" }}>
              EXPERT VIDEOS
            </h1>
            <div className="d-lg-flex flex-wrap justify-content-between mt-10">
              <Card
                style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
                className="w-45 w-sm-100 text-center mb-5"
              >
                <Card.Img
                  variant="top"
                  src={img15}
                  style={{ height: "274px" }}
                />
                <Card.Body className="px-lg-5 px-3">
                  <Card.Title
                    className=" mt-5 mb-5 pb-3 fw-bold fs-4"
                    style={{ height: "60px" }}
                  >
                    What is the right age to teach your kids about privacy?
                    <p className=" my-3 fs-6  fw-normal">
                      Credit: Times of India
                    </p>
                  </Card.Title>

                  <a
                    target="_blank"
                    href="https://timesofindia.indiatimes.com/videos/lifestyle/parenting/what-is-the-right-age-to-teach-your-kids-about-privacy/videoshow/70932301.cms"
                  >
                    <Button className="mt-4 mb-5">Watch Video</Button>
                  </a>
                </Card.Body>
              </Card>

              <Card
                style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
                className="w-45 w-sm-100 text-center mb-5"
              >
                <Card.Img
                  variant="top"
                  src={img16}
                  style={{ height: "274px" }}
                />
                <Card.Body className="px-lg-5 px-3">
                  <Card.Title
                    className=" mt-5 mb-5 pb-3 fw-bold fs-4"
                    style={{ height: "60px" }}
                  >
                    How to talk to your child about sex!
                    <p className=" my-3 fs-6  fw-normal">
                      Credit: Times of India
                    </p>
                  </Card.Title>

                  <a
                    target="_blank"
                    href="https://m.timesofindia.com/videoshow/69026594.cms?frmapp=yes"
                  >
                    <Button className="mt-4 mb-5">Watch Video</Button>
                  </a>
                </Card.Body>
              </Card>

              <Card
                style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
                className="w-45 w-sm-100 text-center mb-5"
              >
                <Card.Img
                  variant="top"
                  src={img17}
                  style={{ height: "274px" }}
                />
                <Card.Body className="px-lg-5 px-3">
                  <Card.Title
                    className=" mt-5 mb-5 pb-3 fw-bold fs-4"
                    style={{ height: "60px" }}
                  >
                    How to talk to your kids about sex
                    <p className=" my-3 fs-6  fw-normal">Credit: Tweak India</p>
                  </Card.Title>

                  <a
                    target="_blank"
                    href="https://tweakindia.com/parenting/expert/how-to-talk-to-your-kids-about-sex/"
                  >
                    <Button className="mt-4 mb-5">Watch Video</Button>
                  </a>
                </Card.Body>
              </Card>

              <Card
                style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
                className="w-45 w-sm-100 text-center mb-5"
              >
                <Card.Img
                  variant="top"
                  src={img18}
                  style={{ height: "274px" }}
                />
                <Card.Body className="px-lg-5 px-3">
                  <Card.Title
                    className=" mt-5 mb-5 pb-3 fw-bold fs-4"
                    style={{ height: "60px" }}
                  >
                    How to talk to kids about sex, sexting and porn
                    <p className=" my-3 fs-6  fw-normal">
                      Credit: Express Parenting{" "}
                    </p>
                  </Card.Title>

                  <a
                    target="_blank"
                    href="https://www.facebook.com/watch/?v=562837784624081"
                  >
                    <Button className="mt-4 mb-5">Watch Video</Button>
                  </a>
                </Card.Body>
              </Card>

              <Card
                style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
                className="w-45 w-sm-100 text-center mb-5"
              >
                <Card.Img
                  variant="top"
                  src={img19}
                  style={{ height: "274px" }}
                />
                <Card.Body className="px-lg-5 px-3">
                  <Card.Title
                    className=" mt-5 mb-5 pb-3 fw-bold fs-4"
                    style={{ height: "60px" }}
                  >
                    How to talk to teenagers about sex
                    <p className=" my-3 fs-6  fw-normal">Credit: Tweak India</p>
                  </Card.Title>

                  <a
                    target="_blank"
                    href="https://www.facebook.com/watch/live/?ref=watch_permalink&v=770256863598340"
                  >
                    <Button className="mt-4 mb-5">Watch Video</Button>
                  </a>
                </Card.Body>
              </Card>

              <Card
                style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
                className="w-45 w-sm-100 text-center mb-5"
              >
                <Card.Img
                  variant="top"
                  src={img20}
                  style={{ height: "274px" }}
                />
                <Card.Body className="px-lg-5 px-3">
                  <Card.Title
                    className=" mt-5 mb-5 pb-3 fw-bold fs-4"
                    style={{ height: "60px" }}
                  >
                    Unlocking the #LockerRoom
                    <p className=" my-3 fs-6  fw-normal">Credit: ScooNews </p>
                  </Card.Title>

                  <a
                    target="_blank"
                    href="https://www.facebook.com/watch/live/?ref=watch_permalink&v=281698929656889"
                  >
                    <Button className="mt-4 mb-5">Watch Video</Button>
                  </a>
                </Card.Body>
              </Card>

              <Card
                style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
                className="w-45 w-sm-100 text-center mb-5"
              >
                <Card.Img
                  variant="top"
                  src={img21}
                  style={{ height: "274px" }}
                />
                <Card.Body className="px-lg-5 px-3">
                  <Card.Title
                    className=" mt-5 mb-5 pb-3 fw-bold fs-4"
                    style={{ height: "60px" }}
                  >
                    PANEL DISCUSSION: DO CHILDREN WANT SEX EDUCATION?
                    <p className=" my-3 fs-6  fw-normal">Jairangam 2020</p>
                  </Card.Title>

                  <a
                    target="_blank"
                    href="https://www.youtube.com/watch?v=PMsnlZbUpFQ"
                  >
                    <Button className="mt-4 mb-5">Watch Video</Button>
                  </a>
                </Card.Body>
              </Card>

              <Card
                style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
                className="w-45 w-sm-100 text-center mb-5"
              >
                <Card.Img
                  variant="top"
                  src={img22}
                  style={{ height: "274px" }}
                />
                <Card.Body className="px-lg-5 px-3">
                  <Card.Title
                    className=" mt-5 mb-5 pb-3 fw-bold fs-4"
                    style={{ height: "60px" }}
                  >
                    How parents can address questions about puberty and
                    sexuality
                    <p className=" my-3 fs-6  fw-normal">Credit: FirstPost </p>
                  </Card.Title>

                  <a
                    target="_blank"
                    href="https://www.facebook.com/watch/?v=332933947331161"
                  >
                    <Button className="mt-4 mb-5">Watch Video</Button>
                  </a>
                </Card.Body>
              </Card>

              <Card
                style={{ boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)" }}
                className="w-45 w-sm-100 text-center mb-5"
              >
                <Card.Img
                  variant="top"
                  src={img23}
                  style={{ height: "274px" }}
                />
                <Card.Body className="px-lg-5 px-3">
                  <Card.Title
                    className=" mt-5 mb-5 pb-3 fw-bold fs-4"
                    style={{ height: "75px" }}
                  >
                    9 Months Season 4|BIG, BAD WORLD: Puberty, Sex Education,
                    Good & Bad Touch
                    <p className=" my-3 fs-6  fw-normal">Credit: FirstPost </p>
                  </Card.Title>

                  <a
                    target="_blank"
                    href="https://www.firstpost.com/shows/9-months-season-4-big-bad-world-puberty-sex-education-good-bad-touch-7432981.html"
                  >
                    <Button className="mt-4 mb-5">Watch Video</Button>
                  </a>
                </Card.Body>
              </Card>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
