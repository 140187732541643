import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Cookies from "js-cookie";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { app_api } from "../../atoms/api";
import {
  InvoicePDF
} from "../../organisms/pdf-viewer";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import styled from "styled-components";
import CardBootstrap from "react-bootstrap/Card";
import ButtonBootstrap from "react-bootstrap/Button";

const KitCard = styled(CardBootstrap)`
  background-color: #fff4de;
  border: none;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);

  & > h2 {
    color: var(--bs-info);
  }

  & > p {
    color: #464e5f;
  }
`;

const NoData = styled(CardBootstrap)`
  background-color: #fcd9cc;
  border: none;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);

  & > h2 {
    color: var(--bs-info);
  }

  & > p {
    color: #464e5f;
  }
`;

const StarterKitButton = styled(ButtonBootstrap)`
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  border-radius: 0.85rem;
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  color: #ffffff;
  background-color: #ffa800;
  margin-bottom: 30px;
  :hover {
    color: #ffffff;
    background-color: #d98f00;
  }
`;

export const InvoiceView = (props) => {
  let user;
  const params = useParams();
  const isLoggedIn = !!Cookies.get("ut_user");
  if (isLoggedIn) {
    user = JSON.parse(Cookies.get("ut_user"));
  }

  const [invoiceData, setInvoiceData] = useState([])
  const [statusCode, setStatusCode] = useState(false)
  const [programs, setPrograms] = useState([]);
  const [unassignedProgramLoading, setUnassignedProgramLoading] =
    useState(true);
  const [unassignedProgramError, setUnassignedProgramError] = useState(null);
  const [unassignedPrograms, setUnassignedProgram] = useState([]);


  const navigate = useNavigate();

  useEffect(() => {
    app_api
      .get(`transaction/invoices/${params.transaction_id}`)
      .then((res) => {
        setInvoiceData(res.data)
        setStatusCode(true)
        setUnassignedProgramLoading(false);
        setUnassignedProgram(res.data);
      })
      .catch((err) => {
        setStatusCode(false)
        setUnassignedProgramLoading(false);
        setUnassignedProgramError(
          err?.response?.data?.message ||
            "Error fetching your unassigned programs"
        );
      });
  }, []);

  if (user.role === "parent") {
    if(statusCode)
    {
      return (
        <>
          <Container>
        <Row className="g-1 py-3">
          <Col md="5" lg="4">
            <KitCard
              className="mxh-500 col-lg-11 col-12 mx-lg-3 mt-lg-0 mt-md-3 mb-3 starter-kit"
              style={{ borderRadius: "10px" }}
            >
              <h2 className="info mb-5">Getting started kit</h2>
              <p className="mb-3 mb-md-5 p-17">
                Thank you for choosing unTaboo.
                <br />
                We have hand crafted a quick starter kit for you
              </p>
              <a href="https://api.untaboo.online/uploads/pdf/getting_started_kit.pdf" download>
                <StarterKitButton
                  variant="info"
                  className="mt-3 mt-md-5 align-self-start"
                >
                  Download
                </StarterKitButton>
              </a>
            </KitCard>
          </Col>
          <Col md="5" lg="8">
          <InvoicePDF data={invoiceData}></InvoicePDF>
          </Col>
          </Row>
          </Container>
        </>
      );
    }
    else{
      return (
        <>
          <Container>
          <Row className="g-1 py-3">
          <NoData
              className="mxh-500 col-lg-11 col-12 mx-lg-3 mt-lg-0 mt-md-3 mb-3"
              style={{ borderRadius: "10px" }}
            >
              <h2 className="info mb-5 pt-5 ps-5">No Data Found</h2>
          </NoData>
          </Row>
          </Container>
        </>
      );
    }
  } else {
    return <Navigate to="/logout" />;
  }
};

InvoiceView.propTypes = {};
