import React, { useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import bhaval from "../../assets/images/bhaval.png";
import manoj from "../../assets/images/manoj.png";
import manita from "../../assets/images/manita.jpg";
import rehaan from "../../assets/images/rehaan.jpg";
import stuti from "../../assets/images/stuti.jpg";

export const PfbCarousel = () => {
  const [index, setIndex] = useState(0);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  return (
    <div>
      <div className="d-none d-lg-flex">
        <Carousel
          className="text-center"
          activeIndex={index}
          onSelect={handleSelect}
          interval={50000}
        >
          <Carousel.Item
            className="px-4"
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
            <div className="col-10 mb-4 mx-auto d-lg-flex justify-content-around  align-items-center ">
              <div className="w-45 " style={{ color: "#282828" }}>
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                  style={{
                    backgroundColor: "#fff",
                    height: "900px",
                    color: "282828",
                  }}
                >
                  <img
                    className="w-30 w-sm-45 rounded-3 mb-5"
                    src={bhaval}
                    alt={"bhaval"}
                  />
                  <p
                    className="text-start secondary-b mb-5 p-17"
                    style={{ lineHeight: "1.7" }}
                  >
                    I feel extremely proud for being a part of the UnTaboo
                    family. My elder son who attended the workshop reacted by
                    telling me, "Mom this is the best thing you have gifted me -
                    The UnTaboo workshop!". The way knowledge &amp; learning about
                    one's body changes is imparted here, makes the journey of
                    all growing children a smooth one. Puberty is a very
                    important phase of transformation and I cannot think of any
                    other way of giving this knowledge to my kids than the way
                    UnTaboo does, in such a beautiful, innovative &amp;
                    child-friendly way. Thank you Anju ma'am and heartfelt
                    Gratitude to the UnTaboo Team for their commendable work.
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">
                    Bhaval Thakkar
                  </h4>
                  <h6 className="text-start secondary-b"> Parent, Mumbai</h6>
                </div>
              </div>

              <div className="w-45 w-sm-100">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3  p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "900px" }}
                >
                  <img
                    className="w-30 w-sm-45 rounded-3 mb-5"
                    src={manoj}
                    alt={"manoj"}
                  />
                  <p
                    className="text-start secondary-b mb-5 p-17"
                    style={{ lineHeight: "1.7" }}
                  >
                    My son participated in the Puberty workshop conducted by
                    UnTaboo when he was a tween. We were very happy with the
                    approach and the manner of communicating sensitive topics.
                    The information was very well structured for kids of that
                    age. The content was just right to satisfy the curious minds
                    and prepare the kids for the upcoming changes in their body
                    as they grow up and pass through this critical phase in
                    their life. The best part was the joint interactive session
                    along with parents, to make sure that the participation of
                    the kid was active & also ensure that their understanding is
                    assessed to clear the often occurring misconceptions on the
                    topic.
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">
                    Manoj Champanerkar
                  </h4>
                  <h6 className="text-start secondary-b"> Parent, Mumbai</h6>
                </div>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item
            className="px-4"
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
            <div className="col-10 mb-4 mx-auto d-lg-flex justify-content-around align-items-center">
              <div className="w-45">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "900px" }}
                >
                  <img
                    className="w-30 w-sm-45 rounded-3 mb-5"
                    src={manita}
                    alt={"manita"}
                  />
                  <p
                    className="text-start secondary-b mb-5 p-17"
                    style={{ lineHeight: "1.7" }}
                  >
                    Thank you UnTaboo, for the offline workshop with my elder
                    son and the online session with my younger one. Both
                    sessions were equally good learning, understanding and so
                    interactive. The much-needed knowledge and awareness for my
                    boy at the right age in the right manner. This was my gift
                    to them and that could be possible because of UnTaboo. Best
                    wishes for your future endeavours. Keep doing the wonderful
                    job of spreading knowledge to the young, creative minds.
                    Thank you!
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">Manita Malik</h4>
                  <h6 className="text-start secondary-b">Parent, Mumbai</h6>
                </div>
              </div>
              <div className="w-45">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "900px" }}
                >
                  <img
                    className="w-30 w-sm-45 rounded-3 mb-5"
                    src={rehaan}
                    alt={"rehaan"}
                  />
                  <p
                    className="text-start secondary-b mb-5 p-17"
                    style={{ lineHeight: "1.7" }}
                  >
                    When my mom enrolled me, I didn’t know what to expect and
                    what it would be all about. I remember asking her, "do I
                    have to do it?" I am so glad I did it! The seesion was VERY
                    informative. We also saw many videos that helped me
                    understand many things and I really felt good learning about
                    so many topics. The quiz was also very good and I could
                    answer most of the questions. I really enjoyed the quiz
                    competition between us and our parents. I loved the prizes
                    given! At the end of the program, I realised how important
                    it was for me to learn about my body, emotions and feelings
                    and I am more prepared to enter my teens!
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">Rehaan</h4>
                  <h6 className="text-start secondary-b">
                    {" "}
                    Age 10, Grade 5, Mumbai
                  </h6>
                </div>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item
            className="px-4"
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
            <div className="col-10 mb-4 mx-auto d-lg-flex justify-content-around align-items-center">
              <div className="w-45">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "900px" }}
                >
                  <img
                    className="w-45 w-sm-45 rounded-3 mb-5"
                    src={stuti}
                    alt={"stuti"}
                  />
                  <p
                    className="text-start secondary-b mb-5 p-17"
                    style={{ lineHeight: "1.7" }}
                  >
                    We were very impressed with UnTaboo’s engaging pedagogy
                    coupled with the matter-of-fact manner in which they
                    addressed these topics and answered queries. The
                    facilitators invested time in breaking the ice with the
                    children and created a space that felt truly safe for them.
                    This meant that even some of our otherwise reticent students
                    were very participative. We received overwhelmingly positive
                    feedback from our students and we look forward to a
                    long-term association with UnTaboo.
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">
                    Stuti Kuthiala{" "}
                  </h4>
                  <h6 className="text-start secondary-b">
                    {" "}
                    Deputy Head Pastoral, Doon School
                  </h6>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>

      <div className="d-flex d-lg-none">
        <Carousel
          className="text-center"
          activeIndex={index}
          onSelect={handleSelect}
          interval={50000}
        >
          <Carousel.Item
            className="px-4"
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
            <div className="w-45 " style={{ color: "#282828" }}>
              <div
                className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                style={{
                  backgroundColor: "#fff",
                  height: "900px",
                  color: "282828",
                }}
              >
                <img
                  className="w-30 w-sm-45 rounded-3 mb-5"
                  src={bhaval}
                  alt={"bhaval"}
                />
                <p
                  className="text-start secondary-b mb-5 p-17"
                  style={{ lineHeight: "1.7" }}
                >
                  I feel extremely proud for being a part of the UnTaboo family.
                  My elder son who attended the workshop reacted by telling me,
                  "Mom this is the best thing you have gifted me - The UnTaboo
                  workshop!". The way knowledge & learning about one's body
                  changes is imparted here, makes the journey of all growing
                  children a smooth one. Puberty is a very important phase of
                  transformation and I cannot think of any other way of giving
                  this knowledge to my kids than the way UnTaboo does, in such a
                  beautiful, innovative & child-friendly way. Thank you Anju
                  ma'am and heartfelt Gratitude to the UnTaboo Team for their
                  commendable work.
                </p>
                <h4 className="secondary-blue fs-5 fw-bold">Bhaval Thakkar</h4>
                <h6 className="text-start secondary-b"> Parent, Mumbai</h6>
              </div>
            </div>
          </Carousel.Item>
          <Carousel.Item
            className="px-4"
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
            <div className="w-45 w-sm-100">
              <div
                className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3  p-lg-5 px-3 py-5"
                style={{ backgroundColor: "#fff", height: "900px" }}
              >
                <img
                  className="w-30 w-sm-45 rounded-3 mb-5"
                  src={manoj}
                  alt={"manoj"}
                />
                <p
                  className="text-start secondary-b mb-5 p-17"
                  style={{ lineHeight: "1.7" }}
                >
                  My son participated in the Puberty workshop conducted by
                  UnTaboo when he was a tween. We were very happy with the
                  approach and the manner of communicating sensitive topics. The
                  information was very well structured for kids of that age. The
                  content was just right to satisfy the curious minds and
                  prepare the kids for the upcoming changes in their body as
                  they grow up and pass through this critical phase in their
                  life. The best part was the joint interactive session along
                  with parents, to make sure that the participation of the kid
                  was active & also ensure that their understanding is assessed
                  to clear the often occurring misconceptions on the topic.
                </p>
                <h4 className="secondary-blue fs-5 fw-bold">
                  Manoj Champanerkar
                </h4>
                <h6 className="text-start secondary-b"> Parent, Mumbai</h6>
              </div>
            </div>
          </Carousel.Item>

          <Carousel.Item
            className="px-4"
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
            
              <div className="w-45">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "900px" }}
                >
                  <img
                    className="w-30 w-sm-45 rounded-3 mb-5"
                    src={manita}
                    alt={"manita"}
                  />
                  <p
                    className="text-start secondary-b mb-5 p-17"
                    style={{ lineHeight: "1.7" }}
                  >
                    Thank you UnTaboo, for the offline workshop with my elder
                    son and the online session with my younger one. Both
                    sessions were equally good learning, understanding and so
                    interactive. The much-needed knowledge and awareness for my
                    boy at the right age in the right manner. This was my gift
                    to them and that could be possible because of UnTaboo. Best
                    wishes for your future endeavours. Keep doing the wonderful
                    job of spreading knowledge to the young, creative minds.
                    Thank you!
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">Manita Malik</h4>
                  <h6 className="text-start secondary-b">Parent, Mumbai</h6>
                </div>
              </div>
              </Carousel.Item>
              <Carousel.Item
            className="px-4"
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
              <div className="w-45">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "900px" }}
                >
                  <img
                    className="w-30 w-sm-45 rounded-3 mb-5"
                    src={rehaan}
                    alt={"rehaan"}
                  />
                  <p
                    className="text-start secondary-b mb-5 p-17"
                    style={{ lineHeight: "1.7" }}
                  >
                    When my mom enrolled me, I didn’t know what to expect and
                    what it would be all about. I remember asking her, "do I
                    have to do it?" I am so glad I did it! The seesion was VERY
                    informative. We also saw many videos that helped me
                    understand many things and I really felt good learning about
                    so many topics. The quiz was also very good and I could
                    answer most of the questions. I really enjoyed the quiz
                    competition between us and our parents. I loved the prizes
                    given! At the end of the program, I realised how important
                    it was for me to learn about my body, emotions and feelings
                    and I am more prepared to enter my teens!
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">Rehaan</h4>
                  <h6 className="text-start secondary-b">
                    {" "}
                    Age 10, Grade 5, Mumbai
                  </h6>
                </div>
              </div>
          
          </Carousel.Item>

          <Carousel.Item
            className="px-4"
            style={{
              backgroundColor: "#26547C",
              objectFit: "contain",
            }}
          >
            <div className="col-12 mb-4 mx-auto d-lg-flex justify-content-around align-items-center">
              <div className="w-45">
                <div
                  className="bg_quote  d-flex flex-column justify-content-center text-start my-5 rounded-3 p-lg-5 px-3 py-5"
                  style={{ backgroundColor: "#fff", height: "900px" }}
                >
                  <img
                    className="w-45 w-sm-45 rounded-3 mb-5"
                    src={stuti}
                    alt={"stuti"}
                  />
                  <p
                    className="text-start secondary-b mb-5 p-17"
                    style={{ lineHeight: "1.7" }}
                  >
                    We were very impressed with UnTaboo’s engaging pedagogy
                    coupled with the matter-of-fact manner in which they
                    addressed these topics and answered queries. The
                    facilitators invested time in breaking the ice with the
                    children and created a space that felt truly safe for them.
                    This meant that even some of our otherwise reticent students
                    were very participative. We received overwhelmingly positive
                    feedback from our students and we look forward to a
                    long-term association with UnTaboo.
                  </p>
                  <h4 className="secondary-blue fs-5 fw-bold">
                    Stuti Kuthiala{" "}
                  </h4>
                  <h6 className="text-start secondary-b">
                    {" "}
                    Deputy Head Pastoral, Doon School
                  </h6>
                </div>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </div>
    </div>
  );
};
