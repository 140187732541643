import React from "react";
import CardBootstrap from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import boy_img from "../../assets/images/boy.png";
import girl_img from "../../assets/images/girl.png";
import boy_girl from "../../assets/images/boy_and_girl.png";
import styled from "styled-components";
import ButtonBootstrap from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const StartLearningButton = styled(ButtonBootstrap)`
  transition: color 0.15s ease, background-color 0.15s ease,
    border-color 0.15s ease, box-shadow 0.15s ease,
    -webkit-box-shadow 0.15s ease;
  border-radius: 0.85rem;
  color: #0f3d67;
  background-color: #eee5ff;
  :hover {
    color: #ffffff;
    background-color: #0f3d67;
  }
`;

const ExpiringSoonWarning = styled.span`
  top: 1%;
  right: 3%;
`;

export const PurchasedProgram = (props) => {
  let progress = parseInt((props.currentLevel / props.level_length) * 100);

  return (
    <Col md="6" lg={props.parent && "4"} className="mb-4">
      <CardBootstrap
        className="mxh-500 col-lg-11 col-12 pt-lg-4 pt-5 pb-4 px-4 d-flex flex-column justify-content-center mx-lg-3 my-lg-0 my-3 h-100 dashboard_bg_img position-relative"
        style={{ borderRadius: "10px" }}
      >
        {props.expiringSoon && (
          <ExpiringSoonWarning className="position-absolute text-danger">
            <FontAwesomeIcon icon={faExclamationTriangle} /> Expiring soon
          </ExpiringSoonWarning>
        )}
        <img
          alt="child-img"
          className={` ${
            props.directed_at === "girls" ? "w-30" : "w-50"
          } position-absolute`}
          style={{
            top: `${props.directed_at === "girls" ? "20%" : "30%"}`,
            right: "3px",
            zIndex: "1",
          }}
          src={
            props.directed_at === "girls"
              ? girl_img
              : props.directed_at === "boy"
              ? boy_img
              : boy_girl
          }
        />
        <h2 className="info mb-1">{props.programName}</h2>
        <p>{props.currentLevelName}</p>
        <div className="col-8 ms-4 progress my-3" data-percentage={progress}>
          <span className="progress-left">
            <span className="progress-bar"></span>
          </span>
          <span className="progress-right">
            <span className="progress-bar"></span>
          </span>
          <div className="progress-value">
            <div>
              <h3 className="fw-bold fs-5 mb-1" style={{ color: "#464E5F" }}>
                {progress}%
              </h3>
              <p className="fw-bold mb-1 fs-6" style={{ color: "#464E5F" }}>
                Progress
              </p>
            </div>
          </div>
        </div>
        <p className="mt-2 " style={{ color: "#464E5F" }}>
          {props.firstName}
        </p>
        <StartLearningButton
          variant="primary"
          className="align-self-start fw-bold"
          style={{ zIndex: "2" }}
          onClick={() => props.goToLearner(props.id)}
        >
          {props.firstName
            ? progress === 100
              ? props.hasSurveyed
                ? "Revisit again →"
                : "Submit your feedback →"
              : progress > 0
              ? "Continue learning →"
              : "Start Learning Session →"
            : "Assign a learner"}
        </StartLearningButton>

        {props.parentReport && (
          <StartLearningButton
            variant="primary"
            className="align-self-start mt-2 fw-bold"
            style={{ zIndex: "2" }}
            href={process.env.REACT_APP_BACKEND_URL + props.parentReport}
            target="_blank"
          >
            Comparison Report
          </StartLearningButton>
        )}
      </CardBootstrap>
    </Col>
  );
};
