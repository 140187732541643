import axios from "axios";
import Cookie from "js-cookie";

export const app_api = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}api/v1/`,
});

app_api.interceptors.request.use((config) => {
  if (!!Cookie.get("ut_user")) {
    let ut_user = JSON.parse(Cookie.get("ut_user"));
    let token = ut_user["access_token"];
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

app_api.interceptors.response.use(
  (response) => response,
  (err) => {
    if (
      !err.request?.responseURL?.match(/login/) &&
      err?.response?.status === 401
    ) {
      window.location = "/logout";
    } else return Promise.reject(err);
  }
);


export const school_api = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}api/v1/`,
});

school_api.interceptors.request.use((config) => {
  if (!!Cookie.get("ut_school")) {
    let ut_school = JSON.parse(Cookie.get("ut_school"));
    let token = ut_school["access_token"];
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

school_api.interceptors.response.use(
  (response) => response,
  (err) => {
    if (
      !err.request?.responseURL?.match(/login/) &&
      err?.response?.status === 401
    ) {
      window.location = "/logout";
    } else return Promise.reject(err);
  }
);

export const razor_api = axios.create({
  baseURL: `${process.env.REACT_APP_BACKEND_URL}api/v1/transaction/`,
});
