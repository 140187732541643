import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Cookie from "js-cookie";
import pt from "prop-types";
import { schoolSignIn, signInUser } from "../../atoms/schema";
import {
  ChangeScreenMessage,
  ContainerDiv,
  SubmitButtonFormGroup,
  InputBox,
  PrimaryButton,
  SecondaryButton,
} from "../../pages/login";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormControl from "react-bootstrap/FormControl";
import Alert from "react-bootstrap/Alert";
import { app_api } from "../../atoms/api";
import { useNavigate } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import { Formik } from "formik";
import {
  useGAEventSchoolLogin,
  useGAEventSignUp,
} from "../../hoc/hooks/useGAEventTracker";

export const Links = styled.a`
  color: #0f3d67;
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;

  :hover {
    color: #6bcacf;
    text-decoration: none;
  }
`;

export const SchoolSignIn = (props) => {
  const GAEventSignUp = useGAEventSignUp();

  let navigate = useNavigate();
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState(null);
  const forgotPasswordLink = "#";

  const SignUp = () => {
    props.switchSection("signin");
    GAEventSignUp();
  };

  return (
    <ContainerDiv
      className="flex-column justify-content-start position-absolute col-12 col-lg-11 col-xl-10"
      {...props}
    >
      <ChangeScreenMessage>School login</ChangeScreenMessage>
      <Formik
        initialValues={{ username: "", password: "" }}
        validationSchema={schoolSignIn}
        onSubmit={(values, actions) => {
          setLoginLoading(true);
          app_api
            .post("school/login", values)
            .then((res) => {
              setLoginLoading(false);
              Cookie.remove("ut_user");
              Cookie.set("ut_school", JSON.stringify(res["data"]));
              navigate(props.go_back ? -1 : "/school");
            })
            .catch((err) => {
              setLoginLoading(false);
              setLoginError(err?.response?.data?.message);
            });
        }}
      >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          touched,
          values,
          errors,
          isValid,
          resetForm,
        }) => (
          <Form
            noValidate={true}
            onSubmit={handleSubmit}
            className="mt-5 pb-5 pb-lg-0 mb-4 mb-lg-0"
          >
            {loginError !== null && (
              <Alert
                variant="danger"
                dismissible={true}
                onClose={() => setLoginError(null)}
              >
                {loginError}
              </Alert>
            )}
            <FloatingLabel
              controlId="school-signin.username"
              label="Username"
              className="mb-3"
            >
              <InputBox
                type="text"
                placeholder="Username"
                autoComplete="username"
                name="username"
                value={values.username}
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                isValid={touched.username && !errors.username}
                isInvalid={touched.username && !!errors.username}
              />
              {touched.username && (
                <FormControl.Feedback type="invalid">
                  {errors.username}
                </FormControl.Feedback>
              )}
            </FloatingLabel>
            <FloatingLabel
              controlId="school-signin.password"
              label="Password"
              className="mb-3"
            >
              <InputBox
                type="password"
                placeholder="Password"
                autoComplete="new-password"
                name="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                required={true}
                isValid={touched.password && !errors.password}
                isInvalid={touched.password && !!errors.password}
              />
              {touched.password && (
                <FormControl.Feedback type="invalid">
                  {errors.password}
                </FormControl.Feedback>
              )}
            </FloatingLabel>
            <ChangeScreenMessage
              style={{ marginBottom: "3rem", display: "block" }}
            >
              <span>
                <a href="/forgotpassword?type=school">Forgot Password</a>
              </span>
            </ChangeScreenMessage>
            <SubmitButtonFormGroup className="d-flex flex-column align-items-lg-start flex-sm-row text-center text-lg-start">
              <PrimaryButton
                variant="primary"
                type="submit"
                disabled={loginLoading || !isValid}
              >
                {loginLoading
                  ? [
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />,
                      " Logging in...",
                    ]
                  : "Login"}
              </PrimaryButton>
              {/* <SecondaryButton variant="secondary">
                Sign in with Google
              </SecondaryButton> */}
              <SecondaryButton
                variant="secondary"
                onClick={() => {
                  SignUp();
                  resetForm();
                }}
              >
                Cancel
              </SecondaryButton>
            </SubmitButtonFormGroup>
          </Form>
        )}
      </Formik>
    </ContainerDiv>
  );
};

SchoolSignIn.propTypes = {
  switchSection: pt.func.isRequired,
  show: pt.bool.isRequired,
  go_back: pt.bool,
};
