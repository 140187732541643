import React, { useEffect, useLayoutEffect } from "react";
import styled from "styled-components";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import NavCollapse from "react-bootstrap/NavbarCollapse";
import NavToggle from "react-bootstrap/NavbarToggle";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { Watch } from "../../templates/watch";
import { Learn } from "../../templates/learn";
import { Discuss } from "../../templates/discuss";
import { Play } from "../../templates/play";
import Cookies from "js-cookie";

const SectionButton = styled(Button)`
  font-weight: 600;
  border: none;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  background-color: transparent;
  font-size: 22px;
  padding: 10px 30px;

  :hover {
    background-color: transparent;
    color: var(--bs-danger);
  }

  :focus {
    border-color: none;
    box-shadow: none;
  }

  &.selected {
    background-color: var(--bs-danger);
    color: white;
  }

  @media screen (max-width: "540px") {
    font-size: 16px;
  }
`;

export const Sections = (props) => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (props.isPost) {
      props.setCurrentSection("play");
    }
  }, [props.isPost]);

  let user = {};
  if (!!Cookies.get("ut_user")) {
    user = JSON.parse(Cookies.get("ut_user"));
  }
  // console.log(
  //   props.enrollment?.current_level + 1 ===
  //     props.program?.program?.level_sequence?.length
  // );
  return (
    <Card>
      <Navbar sticky="top" expand="md" variant="light" bg="white">
        <Container fluid={true}>
          <NavToggle
            aria-controls="programs-navbar-options"
            className="ms-auto"
          />
          <NavCollapse id="programs-navbar-options">
            <Navbar className="flex-wrap">
              {!props.isPost &&
                props.program?.watch_sequence &&
                Array.isArray(props.program?.watch_sequence) &&
                props.program?.watch_sequence.length > 0 && (
                  <SectionButton
                    variant="danger"
                    className={`rounded-pill ${
                      props.currentSection === "watch" && "selected"
                    }`}
                    onClick={() => props.setCurrentSection("watch")}
                  >
                    Watch
                  </SectionButton>
                )}
              {!props.isPost &&
                props.program?.learn_sequence &&
                Array.isArray(props.program?.learn_sequence) &&
                props.program?.learn_sequence.length > 0 && (
                  <SectionButton
                    variant="danger"
                    className={`rounded-pill ${
                      props.currentSection === "learn" && "selected"
                    }`}
                    onClick={() => props.setCurrentSection("learn")}
                  >
                    Learn
                  </SectionButton>
                )}
              {!props.isPost &&
                props.program?.discuss_sequence &&
                Array.isArray(props.program?.discuss_sequence) &&
                props.program?.discuss_sequence.length > 0 && (
                  <SectionButton
                    variant="danger"
                    className={`rounded-pill ${
                      props.currentSection === "discuss" && "selected"
                    }`}
                    onClick={() => props.setCurrentSection("discuss")}
                  >
                    Discuss
                  </SectionButton>
                )}
              {user?.role !== "teacher" &&
                props.program?.question_sequence &&
                Array.isArray(props.program?.question_sequence) &&
                props.program?.question_sequence.length > 0 && (
                  <SectionButton
                    variant="danger"
                    className={`rounded-pill ${
                      props.currentSection === "play" && "selected"
                    }`}
                    onClick={() => props.setCurrentSection("play")}
                  >
                    Play
                  </SectionButton>
                )}
            </Navbar>
          </NavCollapse>
        </Container>
      </Navbar>
      <Container className="mt-3">
        <Watch
          isFirstLevel={props.enrollment?.current_level === 0}
          show={props.currentSection === "watch"}
          title={props.program?.title}
          sequence={props.program?.watch_sequence}
          content={props.program?.watch}
          banner={props.program?.banner}
        />
        <Learn
          show={props.currentSection === "learn"}
          title={props.program?.title}
          heading={props.program?.learnHeading}
          sequence={props.program?.learn_sequence}
          content={props.program?.learn}
          footer={props.program?.learnFooter}
          banner={props.program?.banner}
        />
        <Discuss
          isDemo={props.isDemo}
          learner_id={props.enrollment?.learner_id}
          level_id={props.program?.id}
          show={props.currentSection === "discuss"}
          directed_at={props?.enrollment?.program?.directed_at || "girls"}
          title={props.program?.title}
          heading={props.program?.discussHeading}
          sequence={props.program?.discuss_sequence}
          content={props.program?.discuss}
          footer={props.program?.discussFooter}
        />
        <Play
          isPost={props.isPost}
          isDemo={props.isDemo}
          buyToProceed={props.buyToProceed}
          loading={props.questionLoading}
          error={props.questionError}
          enrollmentId={props.enrollmentId}
          show={props.currentSection === "play"}
          directed_at={props?.enrollment?.program?.directed_at || "girls"}
          type={props.questions?.type}
          isFaceoff={
            props.enrollment?.current_level + 1 ===
            props.program?.program?.level_sequence?.length
          }
          bannerImg={props?.program?.banner?.filePath}
          level_id={props.program?.id}
          title={props.questions?.title}
          heading={props.questions?.questionHeading}
          footer={props.questions?.questionFooter}
          sequence={props.questions?.question_sequence}
          content={props.questions?.question}
          completion_image={props.program?.completion_image}
        />
      </Container>
      {props.currentSection !== "play" && (
        <Button
          className="mx-auto my-5 text-white"
          onClick={props.processClick}
        >
          Proceed to next section
        </Button>
      )}
    </Card>
  );
};
