import React from "react";
import { Accordion } from "react-bootstrap";

export const Faq = () => {
  return (
    <Accordion className="mb-3 rounded-3 pb-5">
      <Accordion.Item eventKey="0" className="accordion-item mt-4">
        <Accordion.Header style={{ color: "#1e4363" }}>
          My kid has never been spoken to about these topics, will they still be
          able to do it?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          We do not expect your child to have any previous knowledge of the
          topic. We start with the basics and explain things to them in a
          child-friendly language, using fun videos, explanations, and
          activities. We aim not just to educate but also to create a positive &
          responsible attitude. Read the testimonials on this page to see what
          parents have to say about our programs.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Which age group is this Puberty Adventure program suitable for?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          This program is recommended for 9 to 12-year olds. However, it can be
          shown to a younger or older child, as information about one's body and
          its changes is normal and natural. There is NO SEXUAL CONTENT here and
          all information is very child friendly. There is no reason an
          8-year-old or a 13-year-old cannot watch and learn from this program.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header style={{ color: "#1e4363" }}>
          How long is the Puberty Adventure program?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          During our research, it took children anything from 4 to 6 hours to go
          through the entire Puberty Adventure. Some kids are fast learners, and
          some take a little while. Which is why you have access to the program
          for 6 whole weeks!
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Is there a recommended time frame to complete the program - does the
          impact differ?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Our experts at UnTaboo feel it depends on the child's learning style
          and needs. It is as impactful whether the child completes it in 1 day,
          3 days or over a few weeks. You and your child can pace it out as per
          your convenience.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Who has designed the awareness program?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          UnTaboo has over 10 years of experience in the field. Our in-house
          experts have designed the program to be age-appropriate, culturally
          relevant and engaging. The content has been tested multiple times over
          the years and perfected to create maximum impact.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Would this Puberty Education program require parental supervision?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Our Puberty Education program has a child-friendly and easy to follow
          interface. Children as young as 8 years are able to do the program
          independently. However, it is at the discretion of the parent whether
          they want to supervise the learning or let the child do it by
          themselves. UnTaboo recommends letting the child do this
          independently.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Can I see a sample of it before buying the entire program?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Yes, certainly. A demo is available which will give you a sense of how
          we structure the learning. You will be able to experience our unique
          teaching pedagogy using the WLDPB method - <b>W</b>atch learning
          video, <b>L</b>earn via text, <b>D</b>iscuss using FAQ, <b>P</b>lay to
          check child's learning and <b>B</b>ond with a parent-child activity.
          This is the format we follow for the entire program, ensuring that it
          is a truly child-friendly experience.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="7">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Will I be able to see the program before giving it to my child?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          You get to see all content topics and a demo level before purchase.
          Once you buy it, you will be able to view the content at each level as
          your child advances and levels unlock. You will also be able to track
          your child’s progress through the Parent Dashboard
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="8">
        <Accordion.Header style={{ color: "#1e4363" }}>
          How do I convince my child to attend this program?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          When children think of a learning program, they often think of a
          boring study class. And our Online programs are anything but boring!
          Get your child to see the demo level - watch the fun video, text and
          solve the fun quiz to experience the Puberty Adventure.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="9">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Does my child need to know about the changes specific to the other gender?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Children need to know not only about their own gender specific changes 
          but also about the changes specific to the other gender, which sensitises
          them about the other gender and teaches them  to respect and empathise with them.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="10">
        <Accordion.Header style={{ color: "#1e4363" }}>
          How do I prepare myself for questions my child may ask post taking the
          program?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Our Puberty Adventure program will make conversations between you and
          your child easy, comfortable and matter of fact. You should just be
          open to answering any questions that come your way and UnTaboo will
          support you by providing downloadable toolkits for the same. You also
          become a lifetime member of our Parent Support Community, which will
          also help you reach out to other parents &amp; UnTaboo for any
          situations you may need support on.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="11">
        <Accordion.Header style={{ color: "#1e4363" }}>
          What equipment does my child need for the program?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          You just need a device with an internet connection - A laptop, Tablet
          or Mobile Phone. However, we do recommend a laptop or tablet, so it is
          easier for the child to read but even a mobile phone with a large
          enough screen works well.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="12">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Will I be able to download the program?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          The program is not downloadable. The child has to go online to access
          it. However, we do give the child and parent some downloadable
          resources as part of the learning.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="13">
        <Accordion.Header style={{ color: "#1e4363" }}>
          I have 2 children of the same age - can they do the program together?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          You need to buy 2 separate units of the Puberty Adventure program,
          even if they are of the same age. The activities are designed to check
          the learning of the child and can be done only once. The impact will
          be reduced if 2 children share the program.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="14">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Can I share my program with family &amp; friends?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          This program generates reports about your child’s awareness level,
          progress level etc. Sharing it with others will reveal your child’s
          report to them. Moreover, you will not be able to track your child’s
          progress anymore. Feel free to recommend it to your family &amp;
          friends though…Talk about us…Be our brand ambassador!
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="15">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Once I purchase it, is it mine to keep forever?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          This program takes on an average 4- 6 hours to complete. Even if a
          child spares just one hour a week to do the program, it will take 6
          weeks to finish it. But you will have access to the program for 8
          weeks i.e. 2 months and during this time period, you can access the
          content as often as you like.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="16">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Can I cancel my purchase?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          We are very sure you will see the immense value of this program and
          will not want to cancel your purchase. However, if you still want to,
          please refer to our Cancellation &amp; Refunds policy
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="17">
        <Accordion.Header style={{ color: "#1e4363" }}>
          Are there any discounts available for multiple purchases?
        </Accordion.Header>
        <Accordion.Body style={{ textAlign: "left" }}>
          Our programs are already at a discounted rate as we want the maximum
          number of children to benefit from them. This is a one-time charge for
          a lifetime change in your child’s attitude. Moreover, when you buy
          this, you are not just purchasing a program for your child - you are
          contributing towards raising a responsible generation, leading to a
          safer society!
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
