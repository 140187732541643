import React from "react";
import ReactDOM from "react-dom";
// import Blog_Img from "../../assets/images/blog_Image.png";
import Anjukish from "../../assets/images/Anjukish.png";
import Slider from "react-slick";
import pubertyboy2 from "../../assets/blogs/puberty boys/pubertyboys2.png"
import pubertyboy1 from "../../assets/blogs/puberty boys/pubertyboys1.png"


import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// blog_Image
// import { Container } from "react-bootstrap";

// Define a functional component
export const FourthBlog = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 200,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="poppins">
      <div
        style={{ marginLeft: "10%", marginRight: "10%" }}
        className="my-4 font-weight-bold poppins"
      >

        
<img
            src={pubertyboy1}
            alt="pubertyboy1"
            class="img-fluid responsive-img"
            // height={{ height: "20rem" }}
            style={{height: "100vh"}}
          />
        <h6 className="text-primary poppins" style={{ fontSize: "15px" }}>
          Published 13 Jan 2022
        </h6>
        <h1
        className="title-header poppins"
        >
          FIRST PUBERTY TALK WITH YOUR SON
        </h1>
        <p className="text-black-50 poppins">
        <b className="text-light-blue">Parents</b> don’t have a choice but to talk to girls about puberty, because they need to tell them about periods. However, initiating the puberty talk with their little boy seems unnecessary to some of them. <b className="text-light-blue">Though not often spoken about, young boys go through equally grueling time when faced with the onset of puberty.</b> So, let’s start mini conversations with our little sons on what to expect when the Puberty fairy comes visiting!
        </p>

        <p className="text-black-50">
        <b className="text-blue">Puberty </b><b className="text-light-blue">approaches different bodies at different ages and brings with it emotional and social changes besides physical changes.</b>It’s the time when the rebellion starts, the physical aggression at one hand and the sensitive outbursts at another. We have been through this and that’s the experience we need to bring into our relationship with our children. Patience, information and confidence, these are what you need to inject your child with.
        </p>

      {/* </div> */}

      {/* <div
            className="carousel"

            data-aos="fade-up"
          >
            <Slider {...settings}>
              <div className="">
                <div className="">
                  <div className="w-full flex justify-center md:items-center p-2">
                    <img src={Anjukish} alt="" className="rounded-full "  width={280}/>
                  </div>
                </div>
              </div>
              <div className="p-2 bg-[#E4A795] w-full text-black rounded-xl gap-4">
                <div className="flex flex-col xl:flex-row  text-center">
                  <div className="w-full h-screen flex justify-center md:items-center p-2">
                    <img src={Anjukish} alt="" className="rounded"  width={280}/>
                  </div>
                </div>
              </div>
              <div className="p-2 bg-[#E4A795] w-full text-black rounded-xl">
                <div className="flex flex-col xl:flex-row text-center">
                  <div className="w-full flex justify-center md:items-center p-2">
                    <img src={Anjukish} alt="" className="rounded" width={280}/>
                  </div>
                </div>
              </div>
              <div className="p-2 bg-[#E4A795] w-full text-black rounded-xl">
                <div className="flex flex-col xl:flex-row text-center">
                  <div className="w-full flex justify-center md:items-center p-2">
                    <img src={Anjukish} alt="" className="rounded" width={280} />
                  </div>
                </div>
              </div>
              <div className="p-2 bg-[#E4A795] w-full text-black rounded-xl">
                <div className="flex flex-col xl:flex-row text-center">
                  <div className="w-full flex justify-center md:items-center p-2">
                    <img src={Anjukish} alt="" className="rounded" width={280} />
                  </div>
                </div>
              </div>
            </Slider>
          </div> */}




      {/* <div style={{ marginLeft: "20%", marginRight: "20%" }}> */}
        <p
          className="text-light-blue poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          Right Age
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
        The onset of puberty varies from child to child. However, typically boys start puberty anytime between the ages of 10 and 15. Hence, the age 9 is the right time to begin a conversation. A series of slow and steady talks which will help you break ice and build a new bond. It’s right to be prepared beforehand, so be ready for all kinds of questions from him. Also, do not wait until he approaches you, take the first step. You are the adult, after all.
          </p>

          <p
          className="text-light-blue poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          Starting Trouble
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
        Your son will turn into a young man in the coming years. Your conversations today will instil in him the right attitudes to become a more positive and empathetic person. It might be awkward in the beginning and he may walk out or not take the initiative in conversations. Yet, you need to carry on, without pressurizing him. Slow and steady. The conversation is a process and not a one-time chat, it will take time and build its own course.
          </p>

          <p
          className="text-light-blue poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          THINGS TO TALK ABOUT
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
       Your son will go through numerous physical changes as puberty sets in. Add to it emotional and social developments too. Make sure to touch upon all the below cited changes as you carry forward the talk.
          </p>

          <p
          className="text-light-blue poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          Growth Spurt
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
       He will grow taller at great speed and the size of his limbs will change too. He may feel he is too thin or lanky but assure him that it’s just the way puberty works. His friends may attain puberty at the same time or before/after, tell him that it varies from person to person and is perfectly alright.
          </p>

          <p
            className="text-light-blue poppins"
            style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
         Voice breaking
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
            His voice will deepen, for some it cracks and changes over a span of few years. Tell him that this is normal and irrespective of the way it sounds, it’s normal. Adams apple should also be included in this conversation.
          </p>

          <p
            className="text-light-blue poppins"
            style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
         Hair Growth
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
             Armpit, facial and genital hair will find its spot in the conversation. It’s the right time to tell him about hormones and the other physical changes his body will go through in the days to come.
          </p>


          <p
            className="text-light-blue poppins"
            style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
        Body Odour
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
             His sweat will smell. Hence, care for personal hygiene should be touched on at this stage. Pimples & acne, the need to eat right and exercise should be part of this talk too.
          </p>

          <p
            className="text-light-blue poppins"
            style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
        Genitals
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
            It’s the right time to explain to him about his genitals. He may also show concern over the size of his penis, tell him that all sizes are to be respected and are normal. Also explain to him how for some the penis may be circumcised and for some, not. The right way to clean the genitals is also an important topic to touch upon.
          </p>

          <p
            className="text-light-blue poppins"
            style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
        Wet Dreams and Ejaculation
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
           This is the also the time when he may experience wet dreams and his first ejaculation. This may be extremely embarrassing for him and he approaching you for answers will depend on how you talk to him about the puberty changes. Let this be a topic you touch on beforehand, hence he will know that it’s normal when he goes through the same.
          </p>
        
          <p
            className="text-light-blue poppins"
            style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
        Mood Swings
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
           New hormones trying to settle in might make him feel very unsettled. Mood swings, confusion, tears, aggression and rude behaviour are common. Make sure he knows that you are always there for him and he can come to you to share, discuss and question, anything.
          </p>

          <p
            className="text-light-blue poppins"
            style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
        Periods
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
           Its important to tell him about changes he will go through at puberty, but equally important is to tell him about changes girls will go through. Periods is one such important change he should know about and develop a positive and emphatic attitude towards.
          </p>
          
          <img
            src={pubertyboy2}
            alt="pubertyboy2"
            class="img-fluid responsive-img"
            // height={{ height: "20rem" }}
            style={{height: "100vh"}}
          />

          <p
            className="text-light-blue poppins"
            style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
        Respect
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
           Puberty also means relationships and attraction towards the opposite/same sex. This is when he should learn that he needs to respect himself and the other in equal terms. Lessons of love, respect, compassion and equality should be cultivated in him.
          </p>
          <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
           It may sound like a heavy bunch of conversations, but it doesn’t have to be. Your son, the little boy turning into a young man will learn to be a good human being and love and respect himself and others through you. Hold his hand as he enters adulthood and let him be independent and confident of the same 🙂
          </p>
          <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
           <b>In my upcoming articles, I shall touch open each of these topics in detail and also cover the ‘how to’ of the conversations to help you ease into the talk.</b>
          </p>

          

      </div>
      <div
              style={{
                // paddingLeft: "16%",
                // paddingRight: "16%",
                padding: "6% 16% 6% 16%",
                marginTop: "4%",
                fontFamily: "Inter",
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "37px",
                Paragraph: "24px",
                backgroundColor: "#B7C4D1",
              }}
            >
              <p className="text-uppercase poppins" style={{
                fontFamily: "Inter",
                fontSize: "27px",
                fontWeight: "600",
                lineHeight: "37px",
                textAlign: "center",
              }}>Come join our movement to UnTaboo safety & sex education and create a safer society.</p>
              
              <p className="poppins" style={{
                // paddingTop: "3%",
                // paddingLeft:"10%",
                padding:"3% 13% 3% 13%",
                fontFamily: "Inter",
                fontSize: "15px",
                fontWeight: "600",
                lineHeight: "30px",
                textAlign: "center",
              }}>Volunteer with us I Sponsor our program I Be an affiliate partner I Be our brand ambassador I Talk about us I Share your feedback & suggestions.</p>
              <div className="d-flex align-items-center justify-content-center ">
              <p
                className="p-0 px-4 m-0 bg-light rounded-more poppins"
                style={{
                  backgroundColor: "#FFFFFF",
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "26px",
                  Paragraph: "18px",
                }}
              >
                We would love to hear from you!
              </p>
              </div>
            </div>
    </div>
  );
};
