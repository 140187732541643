import { useFormik } from "formik";
import React, { useState, useEffect } from "react";
import { app_api } from "../../atoms/api";
import { useNavigate, useParams } from "react-router-dom";
import { InputBox, PrimaryButton } from "../../pages/login";
import { FloatingLabel } from "react-bootstrap";
import FormControl from "react-bootstrap/FormControl";
import { resetPasswordSchema } from "../../atoms/schema";

function ResetPassword() {
  const params = useParams();
  const navigate = useNavigate();
  const [forgot, setForgot] = useState({});
  const { handleChange, handleSubmit, values, errors, handleBlur, touched } =
    useFormik({
      validationSchema: resetPasswordSchema,
      initialValues: { password: "", confirmPassword: "" },
      onSubmit: (values, { setErrors }) => {
        if (values.password !== values.confirmPassword) {
          setErrors({ confirmPassword: "Password mismatch" });
          return;
        }
        setErrors({ confirmPassword: "" });
        app_api
          .patch("forgot-password/" + forgot.id, {
            newPassword: values.password,
          })
          .then(() => navigate("/login"))
          .catch(() => {});
      },
    });

  useEffect(() => {
    app_api
      .get("forgot-password/" + params.token)
      .then((res) => res.data)
      .then((res) => {
        setForgot(res);
      })
      .catch(() => {});
  }, []);

  return (
    <div className="mx-auto w-25 text-center mt-10">
      <h1 className="secondary-blue mb-5">Set New Password </h1>
      <h5>Hi, {forgot?.userName}</h5>
      <form onSubmit={handleSubmit} noValidate>
        <FloatingLabel controlId="register.passord" label="Enter New Password">
          <InputBox
            type="password"
            name="password"
            placeholder="Enter New Password"
            className="form-control mt-2  mb-4"
            value={values.password}
            onBlur={handleBlur}
            onChange={handleChange}
            isValid={touched.password && !errors.password}
            isInvalid={touched.password && !!errors.password}
          />
          {touched.password && (
            <FormControl.Feedback type="invalid">
              {errors.password}
            </FormControl.Feedback>
          )}
        </FloatingLabel>
        <br />
        <FloatingLabel
          controlId="register.cpassord"
          label="Enter Confirm Password"
        >
          <InputBox
            type="password"
            name="confirmPassword"
            placeholder="Enter Confirm Password"
            className="form-control mt-2 mb-4"
            value={values.confirmPassword}
            onChange={handleChange}
            onBlur={handleBlur}
            isValid={touched.confirmPassword && !errors.confirmPassword}
            isInvalid={touched.confirmPassword && !!errors.confirmPassword}
          />
          {touched.confirmPassword && (
            <FormControl.Feedback type="invalid">
              {errors.confirmPassword}
            </FormControl.Feedback>
          )}
        </FloatingLabel>
        {/* <span className="text-danger fs-6">{errors.confirmPassword}</span> */}
        <br />
        <PrimaryButton
          type="submit"
          class="btn btn-primary fw-semibold fs-5 px-4 py-2"
        >
          Submit
        </PrimaryButton>
      </form>
    </div>
  );
}

export default ResetPassword;
