import React from "react";
import ReactDOM from "react-dom";
import letterforreddays2 from "../../assets/blogs/letter for red days/letterforreddays2.png";
import letterforreddays1 from "../../assets/blogs/letter for red days/letterforreddays1.png";
import Anjukish from "../../assets/images/Anjukish.png";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// blog_Image
// import { Container } from "react-bootstrap";

// Define a functional component
export const FifthBlog = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 200,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="poppins">
      <div
        style={{ marginLeft: "10%", marginRight: "10%" }}
        className="my-4 font-weight-bold poppins"
      >
        <img src={letterforreddays1} alt="blog_img" class="img-fluid responsive-img" />
        <h6 className="text-primary poppins" style={{ fontSize: "15px" }}>
          Published 13 Jan 2022
        </h6>
        <h1
        className="title-header poppins"
        >
          A LETTER FOR RED DAYS
        </h1>
        <p className="text-black-50 poppins">
        Dear Ma Pa,
        </p>
        <p className="text-black-50 poppins">
            It has been a couple of months since this new entity crept into my life. It wakes me up at night, gets me writhing during the day and makes me feel vulnerable. I am icky, uncomfortable and in one word- confused. I feel so unprepared to meet it every month.<b className="text-light-blue">Periods.</b> What is it doing to my body Ma Pa? What is it doing to my mind? Are periods something to be ashamed of?
        </p>  
        <p className="text-black-50 poppins">
        I don’t understand. I don’t understand why the <b className="text-light-blue"> sanitary pad .</b>advertisements show blue blood instead of red. I hear things. I hear that when I get periods I turn ‘impure’. Is it true? Why does a biological process make me impure? Why can’t I go to temples or sit in places of worship when I have them?
        </p>   

      {/* </div> */}

      {/* <div
            className="carousel"

            data-aos="fade-up"
          >
            <Slider {...settings}>
              <div className="">
                <div className="">
                  <div className="w-full flex justify-center md:items-center p-2">
                    <img src={Anjukish} alt="" className="rounded-full "  width={280}/>
                  </div>
                </div>
              </div>
              <div className="p-2 bg-[#E4A795] w-full text-black rounded-xl gap-4">
                <div className="flex flex-col xl:flex-row  text-center">
                  <div className="w-full h-screen flex justify-center md:items-center p-2">
                    <img src={Anjukish} alt="" className="rounded"  width={280}/>
                  </div>
                </div>
              </div>
              <div className="p-2 bg-[#E4A795] w-full text-black rounded-xl">
                <div className="flex flex-col xl:flex-row text-center">
                  <div className="w-full flex justify-center md:items-center p-2">
                    <img src={Anjukish} alt="" className="rounded" width={280}/>
                  </div>
                </div>
              </div>
              <div className="p-2 bg-[#E4A795] w-full text-black rounded-xl">
                <div className="flex flex-col xl:flex-row text-center">
                  <div className="w-full flex justify-center md:items-center p-2">
                    <img src={Anjukish} alt="" className="rounded" width={280} />
                  </div>
                </div>
              </div>
              <div className="p-2 bg-[#E4A795] w-full text-black rounded-xl">
                <div className="flex flex-col xl:flex-row text-center">
                  <div className="w-full flex justify-center md:items-center p-2">
                    <img src={Anjukish} alt="" className="rounded" width={280} />
                  </div>
                </div>
              </div>
            </Slider>
          </div> */}




      {/* <div className="poppins" style={{ marginLeft: "20%", marginRight: "20%" }}> */}
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
          Dear Ma Pa, I want to talk about periods out loud. I want to discuss it with you instead of listening to hushed tones which pull me deeper into the abyss of uncertainty. I want to be able to talk about the cramps and pain I go through every month. I want to be able to know what’s clean and hygienic for me. I want to know if it’s normal for my periods to last 2 days at times and 5 on others. I want to know if it’s normal to have them irregular.
        </p>
        <p
          className="text-gray-500 poppins"
          style={{
            marginTop: "4%",
            fontFamily: "Inter",
            fontSize: "20px",
            fontWeight: "400",
            lineHeight: "37px",
            Paragraph: "24px",
          }}
        >
         What about the changes my body is going through? Should I be embarrassed about my breasts or feel proud of them? There seems to be hair everywhere, armpits, legs, pubic region. This is so new to me, I have to choose my clothes carefully now. Is body hair something to be shy of?
        </p>
        {/* <img src={letterforreddays2} alt="blog_img" class="img-fluid responsive-img" /> */}
        <p
          className="text-gray-500 poppins"
          style={{
            fontFamily: "Inter",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "26px",
            Paragraph: "24px",
          }}
        >
          Image courtesy of Mathilde Langevin via Unsplash
        </p>

        <div className="text-gray-500 poppins">
          <p
            className="text-gray-500 poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            My face is oily and greasy, and I can feel the sprouting of those menacing pimples? I feel the urge to pop them now and then, do they make me look ugly? Even my sweat smells weird. Is that normal? You know I love playing sports. Can I play during these red days? What about swimming?
          </p>
          <p
            className="text-gray-500 poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
           What about the emotional turmoil that my mind goes through? Few days before the onset of my periods I feel extremely short tempered. I also feel puffy, bloated and tired. Every moment of the day feels like an intense roller coaster.
          </p>
          <p
            className="text-gray-500 poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            My friends, especially the boys look at me differently now. I am confused if I enjoy it or detest the same. I don’t want to change Ma pa. Has periods changed me as a person?
          </p>

          <p
            className="text-gray-500 poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            What about the sanitary pads? Can I use tampons or are those only for older girls? How do I stop the leakage? Is it such a sin to stain my shorts or skirts? Why should I feel so awkward to spot some blood? Isn’t periods a normal and routine biological process? Then Ma Pa, why don’t you talk to me more about it?
          </p>

          <p
            className="text-gray-500 poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            I want to be happy and confident about my body and mind. I want to embrace it all and be at peace with this uninvited guest every month. I don’t want to feel <b className="text-light-blue">isolated, different and awkward</b>. Will you talk to me about periods, Ma Pa? I want to be friends with this monthly visitor. I don’t want to be the one to hate periods.
          </p>

          <p
            className="text-gray-500 poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            With Love,
          </p>

          <p
            className="text-gray-500 poppins"
            style={{
              marginTop: "4%",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: "400",
              lineHeight: "37px",
              Paragraph: "24px",
            }}
          >
            Your 11 year old Daughter
          </p>
          <hr />
          <div className="d-flex flex-column flex-md-row justify-content-between align-items-start poppins">
            {/* <img src={Anjukish} alt="Anjukish" height={"75px"} style={{borderRadius:"100%"}}/> */}
            <div className="d-flex flex-row poppins">
              <div class="image-container">
                <img
                  src={Anjukish}
                  class="circle-image"
                  alt="Description of the image"
                />
              </div>
              <div className="poppins" style={{ marginLeft: "20px" }}>
                <p
                  className="m-0 d-flex justify-center poppins ml-5"
                  style={{
                    marginTop: "4%",
                    fontFamily: "Inter",
                    fontSize: "20px",
                    fontWeight: "600",
                    lineHeight: "37px",
                    Paragraph: "24px",
                  }}
                >
                  Anju Kish
                </p>
                <p className="m-0 text-gray-500 poppins" style={{
                  fontFamily: "Inter",
                  fontSize: "20px",
                  fontWeight: "400",
                  lineHeight: "32px",
                  Paragraph: "24px",
                }}>Content Writer</p>
              </div>
            </div>
            <div className="d-flex flex-column poppins flex-md-row  gap-2 align-items-center justify-content-top text-center ">
              <p
                className="p-0 px-4 m-0 bg-light poppins rounded-more"
                style={{
                  backgroundColor: "#F2F4F7",
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "26px",
                  Paragraph: "18px",
                }}
              >
                Sexual Understanding
              </p>
              <p
                className="p-0 poppins px-4 m-0 bg-light rounded-more"
                style={{
                  backgroundColor: "#F2F4F7",
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "26px",
                  Paragraph: "18px",
                }}
              >
                Education
              </p>
            </div>
          </div>
          
        </div>
      </div>
      <div
              style={{
                // paddingLeft: "16%",
                // paddingRight: "16%",
                padding: "6% 16% 6% 16%",
                marginTop: "4%",
                fontFamily: "Inter",
                fontSize: "20px",
                fontWeight: "400",
                lineHeight: "37px",
                Paragraph: "24px",
                backgroundColor: "#B7C4D1",
              }}
            >
              <p className="text-uppercase poppins" style={{
                fontFamily: "Inter",
                fontSize: "27px",
                fontWeight: "600",
                lineHeight: "37px",
                textAlign: "center",
              }}>Come join our movement to UnTaboo safety & sex education and create a safer society.</p>
              
              <p className="poppins" style={{
                // paddingTop: "3%",
                // paddingLeft:"10%",
                padding:"3% 13% 3% 13%",
                fontFamily: "Inter",
                fontSize: "15px",
                fontWeight: "600",
                lineHeight: "30px",
                textAlign: "center",
              }}>Volunteer with us I Sponsor our program I Be an affiliate partner I Be our brand ambassador I Talk about us I Share your feedback & suggestions.</p>
              <div className="d-flex align-items-center justify-content-center poppins">
              <p
                className="p-0 px-4 m-0 bg-light poppins rounded-more"
                style={{
                  backgroundColor: "#FFFFFF",
                  fontFamily: "Inter",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "26px",
                  Paragraph: "18px",
                }}
              >
                We would love to hear from you!
              </p>
              </div>
            </div>
    </div>
  );
};
