import React from "react";
import ReactGA from "react-ga";

export const useGAEventPurchase = (category = "Purchase") => {
  const trackEvent = (action = "click", label = "Buy Now") => {
    ReactGA.event({ category, action, label });
  };
  return trackEvent;
};

export const useGAEventMedia = (category = "Media") => {
  const trackMediaEvent = (action = "play", label = "Video") => {
    ReactGA.event({ action, category, label });
  };
  return trackMediaEvent;
};

export const useGAEventBoysLP = () => {
  const trackLinkEvent = (to, label = "BoysLP") => {
    ReactGA.outboundLink({ label }, () => {
      window.location = to;
    });
  };
  return trackLinkEvent;
};

export const useGAEventGirlsLP = () => {
  const trackLinkEvent = (to, label = "GirlsLP") => {
    ReactGA.outboundLink({ label }, () => {
      window.location = to;
    });
  };
  return trackLinkEvent;
};

export const useGAEventBoysGirlsLP = () => {
  const trackLinkEvent = (to, label = "BoysGirlsLP") => {
    ReactGA.outboundLink({ label }, () => {
      window.location = to;
    });
  };
  return trackLinkEvent;
};

/////////////////////////Login GAEvents////////////////////

export const useGAEventRegister = (category = "login") => {
  const trackRegisterEvent = (action = "click", label = "register") => {
    ReactGA.event({ action, category, label });
  };
  return trackRegisterEvent;
};

export const useGAEventSignUp = (category = "login") => {
  const trackSignUpEvent = (action = "click", label = "signup") => {
    ReactGA.event({ action, category, label });
  };
  return trackSignUpEvent;
};

export const useGAEventLearnerLogin = (category = "login") => {
  const trackLearnerLoginEvent = (action = "click", label = "learnerlogin") => {
    console.log("ga learner login");
    ReactGA.event({ action, category, label });
  };
  return trackLearnerLoginEvent;
};

export const useGAEventSchoolLogin = (category = "login") => {
  const trackSchoolLoginEvent = (action = "click", label = "schoollogin") => {
    ReactGA.event({ action, category, label });
  };
  return trackSchoolLoginEvent;
};

export const useGAEventTeacherLogin = (category = "login") => {
  const trackTeacherLoginEvent = (action = "click", label = "teacherlogin") => {
    ReactGA.event({ action, category, label });
  };
  return trackTeacherLoginEvent;
};  

//To-Do Add Show me how GAEvent
export const useGAEventShowMeHow = (category = "scroll") => {
  const trackShowMeHow = (action = "click", label = "showmehow") => {
    ReactGA.event({ action, category, label });
  };
  return trackShowMeHow;
};

//To-Do Add Purchase GAEvent cat:-purchase ; action:click ; label:proceedtopay
export const useGAEventProceedToPay = (category = "purchase") => {
  const trackProccedToPay = (action = "click", label = "proceedtopay") => {
    ReactGA.event({ action, category, label });
  };
  return trackProccedToPay;
};

// export const useGAEventAlreadyAMemberLogin = (category = "login") => {
//   const trackAlreadyAMemberLogin = (action = "click", label = "alreadyamemberlogin") => {
//     ReactGA.event({ action, category, label });
//   };
//   return trackAlreadyAMemberLogin;
//   //To-Do Make it an outbound link
// };

export const useGAEventAlreadyAMemberLogin = () => {
  const trackLinkEvent = (to, label = "alreadyamemberlogin") => {
    ReactGA.outboundLink({ label }, () => {
      window.location = to;
    });
  };
  return trackLinkEvent;
};

/////////////////////////Progrm Cards////////////////////

// export const useGAEventUpcomingRegisterYourInterest = (category = "contactus") => {
//   const trackUpcomingRegisterYourInterest = (action = "click", label = "upcomingregisteryourinterest") => {
//     ReactGA.event({ action, category, label });
//   };
//   return trackUpcomingRegisterYourInterest;
// };
//To-Do Make it an outbound link
export const useGAEventUpcomingRegisterYourInterest = () => {
  const trackLinkEvent = (to, label = "upcomingregisteryourinterest") => {
    ReactGA.outboundLink({ label }, () => {
      window.location = to;
    });
  };
  return trackLinkEvent;
};
// export const useGAEventPubertyAdventure9to12yrBoys = (category = "programenquiry") => {
//   const trackPubertyAdventure9to12yrBoys = (action = "click", label = "pubertyadventure9to12yrboys") => {
//     ReactGA.event({ action, category, label });
//   };
//   return trackPubertyAdventure9to12yrBoys;
// };

//To-Do Make it an outbound link
export const useGAEventPubertyAdventure9to12yrBoys = () => {
  const trackLinkEvent = (to, label = "pubertyadventure9to12yrboys") => {
    ReactGA.outboundLink({ label }, () => {
      window.location = to;
    });
  };
  return trackLinkEvent;
};
// export const useGAEventPubertyAdventure9to12yrGirls = (category = "programenquiry") => {
//   const trackPubertyAdventure9to12yrGirls = (action = "click", label = "pubertyadventure9to12yrgirls") => {
//     ReactGA.event({ action, category, label });
//   };
//   return trackPubertyAdventure9to12yrGirls;
// };
//To-Do Make it an outbound link
export const useGAEventPubertyAdventure9to12yrGirls = () => {
  const trackLinkEvent = (to, label = "pubertyadventure9to12yrgirls") => {
    ReactGA.outboundLink({ label }, () => {
      window.location = to;
    });
  };
  return trackLinkEvent;
};
// export const useGAEventSexualliteracy13to15yr = (category = "programenquiry") => {
//   const trackSexualliteracy13to15yr = (action = "click", label = "sexualliteracy13to15yr") => {
//     ReactGA.event({ action, category, label });
//   };
//   return trackSexualliteracy13to15yr;
// };
//To-Do Make it an outbound link
export const useGAEventSexualliteracy13to15yr = () => {
  const trackLinkEvent = (to, label = "sexualliteracy13to15yr") => {
    ReactGA.outboundLink({ label }, () => {
      window.location = to;
    });
  };
  return trackLinkEvent;
};
// export const useGAEventSafety13to15yr = (category = "programenquiry") => {
//   const trackSafety13to15yr = (action = "click", label = "safety13to15yr") => {
//     ReactGA.event({ action, category, label });
//   };
//   return trackSafety13to15yr;
// };
//To-Do Make it an outbound link
export const useGAEventSafety13to15yr = () => {
  const trackLinkEvent = (to, label = "safety13to15yr") => {
    ReactGA.outboundLink({ label }, () => {
      window.location = to;
    });
  };
  return trackLinkEvent;
};
// export const useGAEventSmokingAlcoholDrugs13to18yr = (category = "programenquiry") => {
//   const trackSmokingAlcoholDrugs13to18yr = (action = "click", label = "smokingalcoholdrugs13to18yr") => {
//     ReactGA.event({ action, category, label });
//   };
//   return trackSmokingAlcoholDrugs13to18yr;
// };

//To-Do Make it an outbound link
export const useGAEventSmokingAlcoholDrugs13to18yr = () => {
  const trackLinkEvent = (to, label = "smokingalcoholdrugs13to18yr") => {
    ReactGA.outboundLink({ label }, () => {
      window.location = to;
    });
  };
  return trackLinkEvent;
};
// export const useGAEventSexsexualitySafety16to18yr = (category = "programenquiry") => {
//   const trackSexsexualitySafety16to18yr = (action = "click", label = "sexsexualitysafety16to18yr") => {
//     ReactGA.event({ action, category, label });
//   };
//   return trackSexsexualitySafety16to18yr;
// };
//To-Do Make it an outbound link
export const useGAEventSexsexualitySafety16to18yr = () => {
  const trackLinkEvent = (to, label = "sexsexualitysafety16to18yr") => {
    ReactGA.outboundLink({ label }, () => {
      window.location = to;
    });
  };
  return trackLinkEvent;
};
/////////////////////////Landing Page////////////////////

export const useGAEventPubertyForBoysHomeRedirect = (
  category = "landingpage"
) => {
  const trackPubertyForBoysHomeRedirect = (
    action = "click",
    label = "pubertyforboyshomeredirect"
  ) => {
    ReactGA.event({ action, category, label });
  };
  return trackPubertyForBoysHomeRedirect;
};

export const useGAEventPubertyForGirlsHomeRedirect = (
  category = "landingpage"
) => {
  const trackPubertyForGirlsHomeRedirect = (
    action = "click",
    label = "pubertyforgirlshomeredirect"
  ) => {
    ReactGA.event({ action, category, label });
  };
  return trackPubertyForGirlsHomeRedirect;
};
