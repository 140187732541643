import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Container } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Button from "../../atoms/button";
import confuse from "../../assets/images/confuse.png";
import pfg_img from "../../assets/images/1.png";
import pfb_img from "../../assets/images/2.png";
import ModalVideo from "react-modal-video";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import free_gift from "../../assets/pdf/HowToHaveThePubertyTalkWithYourChild.pdf";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormControl from "react-bootstrap/FormControl";
import { Formik } from "formik";
import { app_api } from "../../atoms/api";
import Cookies from "js-cookie";
import video_bg from "../../assets/images/Rectangle.png";
import peak from "../../assets/images/pubertypeak.png";
import icon_6 from "../../assets/images/programs_icon6.png";
import icon_7 from "../../assets/images/programs_icon7.png";
import icon_8 from "../../assets/images/programs_icon8.png";
import icon_9 from "../../assets/images/programs_icon9.png";
import pubertyready from "../../assets/images/Puberty_ready.png";
import pfbg_img from "../../assets/images/pfbg_image.png";
import asset1 from "../../assets/images/paimage.png";
import { InputBox } from "../../pages/login";
import * as yup from "yup";
import {
  useGAEventBoysLP,
  useGAEventGirlsLP,
  useGAEventBoysGirlsLP,
  useGAEventMedia,
  useGAEventPurchase,
} from "../../hoc/hooks/useGAEventTracker";
import Mvideo from "../../atoms/ModalVideo";
const InfoSpan = styled.span`
  font-size: 12px;
  color: gray;
`;
const PubertyAdventure = (props) => {
  const GAEventPurchase = useGAEventPurchase();
  const GAEventMedia = useGAEventMedia();
  const GAEventBoysLP = useGAEventBoysLP();
  const GAEventGirlsLP = useGAEventGirlsLP();
  const GAEventBoysGirlsLP = useGAEventBoysGirlsLP();
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    document.title = `UnTaboo | Puberty Adventure`;
    app_api
      .get("programs/public/puberty-for-boys")
      .then((res) => {
        setLoading(false);
        setPfb(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Error");
      });
    app_api
      .get("programs/public/puberty-adventure-for-boys-and-girls")
      .then((res) => {
        setLoading(false);
        setPfbg(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Error");
      });
    app_api
      .get("programs/public/puberty-for-girls")
      .then((res) => {
        setLoading(false);
        setPfg(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Error");
      });
    if (isLoggedIn) {
      app_api
        .get(`learner/parent/${user?.id}`)
        .then((res) => {
          setlLoading(false);
          setLearners(res.data);
        })
        .catch((err) => {
          setlLoading(false);
          setlError(err?.response?.data?.message || "Error");
        });
    }
    if (source !== "landingPage") {
      setShow(true);
    }
  }, []);

  const enrollClickPfb = () => {
    if (isLoggedIn) {
      GAEventPurchase();
      // setEnrollPfb(true);
      createCheckout(pfb?.id);
    } else {
      navigate(`/login?next_to=${pfb?.id}`);
    }
  };
  const enrollClickPfg = () => {
    if (isLoggedIn) {
      GAEventPurchase();
      // setEnrollPfg(true);
      createCheckout(pfg?.id);
    } else {
      navigate(`/login?next_to=${pfg?.id}`);
    }
  };

  const enrollClickPfbg = () => {
    if (isLoggedIn) {
      GAEventPurchase();
      // setEnrollPfg(true);
      createCheckout(pfbg?.id);
    } else {
      navigate(`/login?next_to=${pfbg?.id}`);
    }
  };

  const BoysLP = () => {
    GAEventBoysLP("/program/puberty-for-boys");
  };

  const GirlsLP = () => {
    GAEventGirlsLP("/program/puberty-for-girls");
  };

  const BoysGirlsLP = () => {
    GAEventBoysGirlsLP("/program/puberty-adventure-for-boys-and-girls");
  };

  let user;
  const isLoggedIn = !!Cookies.get("ut_user");
  if (isLoggedIn) {
    user = JSON.parse(Cookies.get("ut_user"));
  }
  const [program, setProgram] = useState({});
  const [pfb, setPfb] = useState({});
  const [pfg, setPfg] = useState({});
  const [pfbg, setPfbg] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [enrollpfb, setEnrollPfb] = useState(false);
  const [enrollpfg, setEnrollPfg] = useState(false);
  const [enrolling, setEnrolling] = useState(false);
  const [eError, seteError] = useState(null);
  const [learners, setLearners] = useState([]);
  const [lLoading, setlLoading] = useState(true);
  const [lError, setlError] = useState(null);
  const [transLoading, setTransLoading] = useState(false);
  const [transError, setTransError] = useState(null);
  const enrollClosepfb = () => setEnrollPfb(false);
  const enrollClosepfg = () => setEnrollPfg(false);
  const dismissEnrollError = () => seteError(null);
  const dismissLearnerError = () => setlError(null);
  const dismissTransError = () => setTransError(null);
  const [show, setShow] = useState(false);

  const [eventLoading, setEventLoading] = useState(false);
  const [eventSucces, setEventSucces] = useState(false);
  const [eventError, setEventError] = useState(null);

  let url = new URL(document.URL);

  let source = url.searchParams.get("source") || "landingPage";

  const createCheckout = (program_id) => {
    setEnrolling(true);
    app_api
      .post("enrollment-temp/user", {
        program_id,
        user_id: user?.id,
      })
      .then((res) => {
        setEnrolling(false);
        setTransLoading(true);
        let temp_enrollment = res.data;

        app_api
          .post("transaction", {
            user_id: user.id,
            temp_enrollment_id: temp_enrollment.id,
            amount: temp_enrollment?.program?.cost,
          })
          .then((res) => {
            setTransLoading(false);
            navigate(`/checkout/${res.data.id}`);
          })
          .catch((err) => {
            setTransLoading(false);
            setTransError(
              `Transaction error: ${err?.response?.data?.message}` ||
              "Transaction error: Error"
            );
          });
      })
      .catch((err) => {
        setEnrolling(false);
        seteError(
          `Enrollment error: ${err?.response?.data?.message}` ||
          "Enrollment error: Error"
        );
      });
  };

  useEffect(() => {
    try {
      let program_id = parseInt(url.searchParams.get("program_id"));
      if (!!program_id) {
        if (isLoggedIn) {
          createCheckout(program_id);
        } else {
          navigate("/login?go_back=true");
        }
      }
    } catch (err) { }
  }, []);

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };
  const RegisterEvent = yup.object().shape({
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().optional(),
    email: yup
      .string()
      .email("Should be a valid email ID")
      .required("Email is required"),
  });

  return (
    <div className="poppins  px-lg-0">
      {/* <ControlledCarousel></ControlledCarousel> */}
      {/* <div className="bg-img-about">
        <Container>
          <div className="pt-lg-10 pt-5  px-4 px-lg-0 d-lg-flex ">
            <div className="col-lg-6 col-12 mt-5">
              <h1
                className="f-55 f-sm-38"
                style={{
                  fontWeight: "700",
                  color: "#1e4363",
                }}
              >
                Puberty Can Be Confusing <br></br>
                For Your Child
              </h1>
              <p
                className="mt-lg-5 mb-10 pt-md-4 mt-4 secondary-b  text-lg-start"
                style={{ lineHeight: "1.8" }}
              >
                Research suggests that Puberty is one of the most stressful
                times in a person's life. This stress can be reduced
                considerably by preparing a child for this phase of life before
                it happens.
              </p>
              <div className="mt-5 text-center text-lg-start">
                <a href="#programDetails">
                  <Button className="me-lg-3 mb-3">Show Me How</Button>
                </a>
                <Link to="/login">
                  <Button secondary>Already A Member? Login</Button>
                </Link>
              </div>
              <div className="mt-5 text-start col-12">
                <h3 className="secondary-blue fs-5">
                  Tips on how to talk to your child about Puberty
                </h3>
                <a href="#downloadgift">
                  <Button
                    type="button"
                    className="btn btn-light poppins mb-lg-0 my-3"
                    onClick={handleShow}
                  >
                    Download free gift
                  </Button>
                </a>
              </div>
            </div>

            <div className="col-lg-6 col-12">
              <img className="w-100" src={confuse} alt={"untaboo characters"} />
            </div>
          </div>
        </Container>
      </div> */}

      <div className="mt-10 pt-md-5 text-center">
        <h3
          className="secondary-blue f-40 px-4 px-lg-0"
          style={{ fontWeight: "600" }}
        >
          UnTaboo’s Great Puberty Adventure
        </h3>
        <p className="mt-3 pb-lg-4 mb-5">
          <b>
            Animated & Gamified Program that turns Puberty into an exciting
            adventure!
          </b>
        </p>

        <div className="md:col-md-6 col-md-8 col-12 mx-auto programsfilter px-2 px-lg-0 d-lg-flex flex-wrap justify-content-around mt-5 pt-3 programCards">
          <div
            className="boysgirls   mb-5 card filtercard program_cards w-sm-100 rounded-3"
            style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
          >
            <img
              className="w-100 rounded-3-top"
              src={pfb_img}
              alt={"course_img"}
              style={{ borderRadius: "10px 10px 0 0 " }}
            />

            <div className="boysgirls  mx-auto col-10 py-4">
              <div className="d-flex">
                <div className="w-50 text-start">
                  <h3
                    className="secondary-blue fw-bold"
                    style={{ fontSize: "20px", fontWeight: "700" }}
                  >
                    PUBERTY ADVENTURE<br></br>For Boys
                  </h3>
                </div>
                <div className="w-50">
                  <h3 className="primary-pink fw-bold">
                    <span
                      style={{
                        color: "grey",
                        fontSize: "20px",
                        textDecoration: "line-through",
                      }}
                    >
                      ₹ 2200.00
                    </span>
                    <br></br>₹ 1499.00
                  </h3>
                </div>
              </div>
              <p
                className="pb-4 text-start secondary-b p-17"
                style={{ lineHeight: "1.7", height: "340px" }}
              >
                Puberty can be a confusing & stressful time for boys, as most of
                them grow up with silence around the subject. We open
                conversations on their changing bodies, emotions and also the
                changes that girls go through, thus sowing the seeds for a
                lifetime of body positivity & gender sensitivity.
              </p>
              <div className="d-flex justify-content-between position-absolute bottom-0 col-10 mx-auto  pb-4">
                {/* <Link to="/program/puberty-for-boys"> */}
                <Button
                  className="text-black"
                  style={{ borderRadius: "25px" }}
                  onClick={enrollClickPfb}
                >
                  Buy Now
                </Button>

                <Button className="text-black" style={{ borderRadius: "25px" }} onClick={BoysLP}>
                  Know More
                </Button>

                {/* </Link> */}
              </div>
            </div>
          </div>

          <div
            className="boysgirls mb-5 card filtercard  program_cards w-sm-100 rounded-3"
            style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
          >
            <img
              className="w-100 rounded-3-top"
              src={pfg_img}
              alt={"course_img"}
              style={{ borderRadius: "10px 10px 0 0 " }}
            />

            <div className="mx-auto col-10 py-4">
              <div className="d-flex">
                <div className="w-50 text-start">
                  <h3
                    className="secondary-blue fw-bold"
                    style={{ fontSize: "20px", fontWeight: "700" }}
                  >
                    PUBERTY ADVENTURE<br></br>For Girls
                  </h3>
                </div>
                <div className="w-50">
                  <h3 className="primary-pink fw-bold">
                    <span
                      style={{
                        color: "grey",
                        fontSize: "20px",
                        textDecoration: "line-through",
                      }}
                    >
                      ₹ 2200.00
                    </span>
                    <br></br>₹ 1499.00
                  </h3>
                </div>
              </div>
              <p
                className="pb-4 text-start secondary-b p-17"
                style={{ lineHeight: "1.7", height: "340px" }}
              >
                Puberty is happening very early on for girls today. It can be a
                confusing & difficult phase, but learning about it before it
                happens can make puberty a smooth ride for them. We prepare
                girls, so they feel comfortable with their changing bodies &
                emotions, empowering them with skills to cope with it positively
                & happily.
              </p>
              <div className="d-flex justify-content-between position-absolute bottom-0 col-10 mx-auto  pb-4">
                {/* <Link to="/program/puberty-for-boys"> */}
                <Button
                  style={{ borderRadius: "25px" }}
                  onClick={enrollClickPfg}
                  className="text-black"
                >
                  Buy Now
                </Button>

                <Button className="text-black" style={{ borderRadius: "25px" }} onClick={GirlsLP}>
                  Know More
                </Button>

                {/* </Link> */}
              </div>
            </div>
          </div>

          <div
            className="boysgirls mb-5 card filtercard  program_cards w-sm-100 rounded-3"
            style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 20%)" }}
          >
            <img
              className="w-100 rounded-3-top"
              src={pfbg_img}
              alt={"course_img"}
              style={{ borderRadius: "10px 10px 0 0 " }}
            />

            <div className="mx-auto col-10 py-4">
              <div className="d-flex">
                <div className="w-60 text-start">
                  <h3
                    className="secondary-blue fw-bold"
                    style={{ fontSize: "20px", fontWeight: "700" }}
                  >
                    PUBERTY ADVENTURE<br></br>For Boys And Girls
                  </h3>
                </div>
                <div className="w-40">
                  <h3 className="primary-pink fw-bold">
                    <span
                      style={{
                        color: "grey",
                        fontSize: "20px",
                        textDecoration: "line-through",
                      }}
                    >
                      ₹ 2500.00
                    </span>
                    <br></br>₹ 1899.00
                  </h3>
                </div>
              </div>
              <p
                className="pb-4 text-start secondary-b p-17"
                style={{ lineHeight: "1.7", height: "340px" }}
              >
                Puberty can be a confusing and stressful period for your child,
                but it needn’t be. Our program turns puberty into an exciting
                adventure, where every change of both boys & girls is taught in
                a fun manner. The end result - a positively informed child,
                ready to face puberty head on and be a supportive and empathetic
                ally!
              </p>
              <div className="d-flex justify-content-between position-absolute bottom-0 col-10 mx-auto  pb-4">
                {/* <Link to="/program/puberty-for-boys"> */}
                <Button
                  style={{ borderRadius: "25px" }}
                  onClick={enrollClickPfbg}
                  className="text-black"
                >
                  Buy Now
                </Button>

                <Button className="text-black" style={{ borderRadius: "25px" }} onClick={BoysGirlsLP}>
                  Know More
                </Button>

                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg_img_b mt-10 pt-5 pt-lg-0">
        <Container className="  d-lg-flex justify-content-between ">
          <div className="col-12 col-lg-6 mb-4 d-flex flex-column justify-content-start text-center">
            <div>
              {" "}
              <h3
                className="secondary-blue my-5  f-40 px-4 px-lg-0"
                style={{ fontWeight: "600" }}
              >
                WATCH A DEMO VIDEO
              </h3>
            </div>{" "}
            <div className="position-relative">
              <img
                className="w-90 "
                src={video_bg}
                alt={"video_img"}
                style={{
                  borderRadius: "20px",
                  boxShadow: " rgb(0 0 0 / 20%) 0px 3px 10px",
                }}
              />
              <Mvideo
                channel="custom"
                // autoplay
                isOpen={isOpen}
                // videoId="L61p2uyiMSo"
                url="https://api.untaboo.online/uploads/FILM_18_Parent.m3u8"
                onClose={() => setOpen(false)}
              />
              {/* <div style={{position: 'absolute' ,top:'0',left:'30%'}}>
              <video autoplay isOpen={isOpen}  onClose={() => setOpen(false)} src={video} width="750" height="450" controls></video>
              </div> */}
              <button
                onClick={() => {
                  setOpen(true);
                  GAEventMedia();
                }}
                className="video-play blue_shadow rounded-circle position-absolute  d-flex justify-content-center align-items-center"
                style={{
                  backgroundColor: "#6BCACF",
                  width: "80px",
                  height: "80px",
                  left: "43%",
                  top: "40%",
                }}
              >
                <div
                  className=""
                  style={{
                    width: "0",
                    height: " 0",
                    borderTop: "12px solid transparent",
                    borderBottom: "12px solid transparent",
                    borderLeft: "12px solid white",
                  }}
                ></div>
              </button>
            </div>
          </div>

          <div className="col-lg-5 text-center text-lg-start">
            <div
              className="py-4 px-5 mb-5"
              style={{ backgroundColor: "#6bcad0" }}
            >
              <h3 className="f-25 ">Advantage Online Programs</h3>
              <br></br>
              <ul>
                <li>
                  Highly engaging pre-recorded programs that are animated and
                  gamified
                </li>
                <br></br>
                <li>
                  Simple & engaging format to enable independent learning by a
                  child
                </li>
                <br></br>
                <li>
                  Takeaways that are easy to absorb & practical to follow for a
                  child
                </li>
                <br></br>
                <li>Child can learn from safety & comfort of home</li>
                <br></br>
                <li>Child has access to program for 8 weeks</li>
                <br></br>
                <li>
                  Access to a live Q&A webinar with UnTaboo experts for any
                  queries
                </li>
              </ul>
            </div>
          </div>
        </Container>
        <Container>
          <div className="text-center px-4 px-lg-0 mt-2 mb-10">
            <h3 className="secondary-blue f-40" style={{ fontWeight: "600" }}>
              Buy a program for your child - it will be the gift of a lifetime!
            </h3>

            {/* <video alt="VIDEO EXPLAINING PAIN POINT & PEDAGOGY (Online trailer video- new UVA)"/> */}
            <div className="mt-5 mb-5">
              <Button className="text-black me-lg-5 mb-5" onClick={enrollClickPfb}>
                Puberty for Boys: Buy Now
              </Button>

              <Button className="text-black me-lg-5 mb-5" onClick={enrollClickPfbg}>
                Puberty for Boys and Girls: Buy Now
              </Button>

              <Button className="text-black" onClick={enrollClickPfg}>
                Puberty for Girls: Buy Now
              </Button>
            </div>
          </div>
        </Container>
      </div>

      <div className="mt-10">
        <Container className="px-2 px-lg-0">
          <h3
            className="secondary-blue my-5 text-center  f-40 px-4 px-lg-0"
            style={{ fontWeight: "600" }}
          >
            One time buy - a lifetime of value
          </h3>
          <div className="px-2 px-lg-0 d-lg-flex flex-wrap">
            <div className="col-lg-3">
              <div
                className="text-center p-4 rounded-2 col-md-11 col-12 mx-auto mb-4"
                style={{ backgroundColor: "#6BCACF" }}
              >
                <h3 className="f-25">Gamified Program</h3>
                <p className="fs-6">
                  Easy to understand content with animated videos
                </p>
              </div>
              <div
                className="text-center p-4 rounded-2 col-md-11 col-12 mx-auto mb-4"
                style={{ backgroundColor: "#6BCACF" }}
              >
                <h3 className="f-25">Learn & Win</h3>
                <p className="fs-6">
                  Quizzes to solve and expert badges & gift coupons to be won
                </p>
              </div>
              <div
                className="text-center p-4 rounded-2 col-md-11 col-12 mx-auto mb-4"
                style={{ backgroundColor: "#6BCACF" }}
              >
                <h3 className="f-25">Parent-Child Bonding</h3>
                <p className="fs-6">
                  Fun ice breaking activity with parents to make conversations
                  easy
                </p>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="text-center">
                <img
                  src={pubertyready}
                  className="mb-3 text-center w-90 w-sm-100 mx-auto"
                />
              </div>
            </div>
            <div className="col-lg-3">
              <div
                className="text-center p-4 rounded-2 col-md-11 col-12 mx-auto mb-4"
                style={{ backgroundColor: "#6BCACF" }}
              >
                <h3 className="f-25">Downloadable Gifts</h3>
                <p className="fs-6">
                  Mood board & Conversation jar to open conversations at home
                </p>
              </div>
              <div
                className="text-center p-4 rounded-2 col-md-11 col-12 mx-auto mb-4"
                style={{ backgroundColor: "#6BCACF" }}
              >
                <h3 className="f-25">Parent Dashboard</h3>
                <p className="fs-6">
                  To monitor the child's progress & access helpful articles by
                  experts
                </p>
              </div>
              <div
                className="text-center p-4 rounded-2 col-md-11 col-12 mx-auto mb-4"
                style={{ backgroundColor: "#6BCACF" }}
              >
                <h3 className="f-25">Certificates To Flaunt</h3>
                <p className="fs-6">
                  A Puberty Graduate certificate for the child & one for the
                  Parent
                </p>
              </div>
            </div>
            <div className="col-12 p-0 p-lg-4  d-lg-flex ">
              <div
                className="text-center p-4 p-md-0 rounded-2 w-48 col-12 me-auto mb-4"
                style={{ backgroundColor: "#6BCACF" }}
              >
                <h3 className="f-25">Live Meet With Expert</h3>
                <p className="fs-6">
                  Interactive Q&A session with UnTaboo Experts to tackle
                  concerns
                </p>
              </div>
              <div
                className="text-center p-4 rounded-2 w-48 ms-auto mb-4"
                style={{ backgroundColor: "#6BCACF" }}
              >
                <h3 className="f-25">Support Community</h3>
                <p className="fs-6">
                  Entry to an exclusive ongoing WhatsApp support group for
                  parents
                </p>
              </div>
            </div>
            <div className="col-12 text-center mt-5 mb-5">
              <Button className="text-black me-lg-5 mb-5" onClick={BoysLP}>
                Get it now: Puberty for Boys
              </Button>

              <Button className="me-lg-5 mb-5 text-black" onClick={BoysGirlsLP}>
                Get it now: Puberty for Boys and Girls
              </Button>

              <Button className="text-black" onClick={GirlsLP}>Get it now: Puberty for Girls</Button>
            </div>
          </div>
        </Container>
      </div>

      <div className=" mt-5 pt-5 pt-lg-0">
        <Container className="  d-lg-flex justify-content-between align-items-center">
          <div className="col-12 col-lg-6 mb-4  position-relative text-start">
            <h3
              className="secondary-blue my-5  f-40 px-4 px-lg-0"
              style={{ fontWeight: "600" }}
            >
              Let the Adventure begin!
            </h3>
            <p className="mt-5 col-lg-10 fs-5 lh-md pt-lg-5 pt-3 px-4 px-lg-0">
              Experts have created this Puberty Education Program in an
              adventure format to ensure learning is enjoyable and effective.{" "}
              Every change experienced during puberty has been created like a
              ‘level’ in this adventure. At every level, a child follows a
              unique ‘Watch ~ Learn~ Discuss ~ Play’ method of learning. The
              next level unlocks on completing the current level, just like in a
              game.
            </p>
            <img src={peak} className="w-100" />
          </div>
          <div className="col-lg-6 text-start process_button px-4 px-lg-0">
            <div className="watch mt-5 col-lg-10 p-3 p-cursor  align-center ms-md-auto d-flex flex-row rounded-3 ">
              <div className="col-lg-3 my-auto">
                <img className="col-8" src={icon_6} />
              </div>

              <div className=" col-9">
                <h3 className="fs-5 fw-bold">
                  Watch &amp; Learn - anytime, anywhere
                </h3>
                <p className="fs-6" style={{ color: "#6c757d" }}>
                  Lessons are taught through videos to engage &amp; then
                  reiterated through text to maximise retention.
                </p>
              </div>
            </div>

            <div className="discuss mt-3 col-lg-10 p-3 p-cursor align-center ms-md-auto rounded-3 d-flex flex-row">
              <div className="col-lg-3 my-auto">
                <img className="col-8 " src={icon_7} />
              </div>
              <div className=" col-9">
                <h3 className="fs-4 fw-bold ">Discuss - anytime, anywhere </h3>
                <p className="fs-6 " style={{ color: "#6c757d" }}>
                  Any doubts arising post the lesson are clarified through a
                  FAQ, normalising changes &amp; differences.
                </p>
              </div>
            </div>

            <div className="play p-cursor  mt-3 col-lg-10 p-3 align-center ms-md-auto rounded-3 d-flex flex-row">
              <div className="col-lg-3 my-auto">
                <img className="col-8" src={icon_8} />
              </div>
              <div className=" col-9">
                <h3 className="fs-4 fw-bold">Play - anytime, anywhere </h3>
                <p className="fs-6" style={{ color: "#6c757d" }}>
                  Gamified evaluation to check learnings &amp; reward the child
                  while generating a progress report for the parent.
                </p>
              </div>
            </div>

            <div className="play p-cursor  mt-3 col-lg-10 p-3 align-center ms-md-auto rounded-3 d-flex flex-row">
              <div className="col-3 my-auto">
                <img className="col-8" src={icon_9} />
              </div>

              <div className=" col-9">
                <h3 className="fs-4 fw-bold">Bond - anytime, anywhere</h3>
                <p className="fs-6" style={{ color: "#6c757d" }}>
                  A fun Parent-child activity at the end of program to break the
                  ice &amp; help parents to take this conversation forward.
                </p>
              </div>
            </div>
            <div className="my-5 col-lg-10 d-flex-wrap justify-content-evenly ms-auto gap">
              <div>
                <Button onClick={enrollClickPfb} className="mb-5 hover:text-black" secondary >
                  Puberty for Boys
                </Button>
              </div>
              <div>
                <Button onClick={enrollClickPfg} secondary className="mb-5 hover:text-black">
                  Puberty for Girls
                </Button>
              </div>
              {/* </div> */}
              {/* <div className="my-5 col-lg-10 d-flex justify-content-evenly ms-auto"> */}
              <div>
                <Button onClick={enrollClickPfbg} secondary>
                  Puberty for Boys and Girls
                </Button>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Container>
        <div className="text-center px-4 px-lg-0 mt-10">
          <h3 className="secondary-blue f-40" style={{ fontWeight: "600" }}>
            Partner with us to make Puberty a wonderful experience for your
            child & help them grow up healthy, happy, and safe with responsible
            attitudes
          </h3>

          {/* <video alt="VIDEO EXPLAINING PAIN POINT & PEDAGOGY (Online trailer video- new UVA)"/> */}
          <div className="mt-5 mb-5 gap">
            <Button className="me-lg-5 mb-5" onClick={BoysLP}>
              Buy Puberty for Boys
            </Button>

            <Button className="me-lg-5 mb-5" onClick={BoysGirlsLP}>
              Buy Puberty for Boys and Girls
            </Button>

            <Button onClick={GirlsLP} className="me-lg-5 mb-5">Buy Puberty for Girls</Button>
          </div>
        </div>
      </Container>

      <Container>
        <div
          className="pt-lg-10 pt-5 mb-10 px-4 px-lg-0 d-lg-flex align-items-center"
          id="downloadgift"
        >
          <div className="col-lg-6 col-12">
            <img className="w-100" src={asset1} alt={"untaboo characters"} />
            <div className=" mt-5 text-start col-12">
              <h3 className="secondary-blue fs-5">
                Tips on how to talk to your child about Puberty
              </h3>

              <Button
                type="button"
                className="btn btn-light poppins mb-lg-0 my-3"
                onClick={handleShow}
              >
                Download free gift
              </Button>
            </div>
          </div>
          <div className="col-lg-6 col-12 mt-5 mb-10 ">
            <h3
              className="f-40 f-sm-38"
              style={{
                fontWeight: "700",
                color: "#1e4363",
              }}
            >
              Raising Positive &<br></br> Responsible Kids
            </h3>
            <p
              className="mt-lg-4 mb-10 pt-md-4 mt-4 secondary-b  text-lg-start"
              style={{ lineHeight: "1.8" }}
            >
              Kids need to be spoken to about Puberty, but it can get tricky! We
              understand, and our Founder Anju Kish, Certified Sexuality
              Educator shares here expert tips to help you & make this
              conversation easy. Go on, download them, open conversations and
              raise healthier, happier & responsible kids, ready to face puberty
              head on!
            </p>
            {/* <div className="mt-5 text-center text-lg-start">
                <a href="#programDetails">
                  <Button className="me-lg-3 mb-3">Show Me How</Button>
                </a>
                <Link to="/login">
                  <Button secondary>Already A Member? Login</Button>
                </Link>
              </div> */}
          </div>
        </div>
      </Container>

      <Container></Container>

      <Container>
        {/* <!-- Modal Event register--> */}
        <Modal show={show} onHide={handleClose} animation={true}>
          <ModalHeader closeButton>
            <ModalTitle className="fs-5 secondary-blue poppins fw-bold">
              {source !== "landingPage" && `${source} | `}Download Free Gift
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Formik
              initialValues={{
                first_name: "",
                last_name: "",
                email: "",
                source: source,
              }}
              enableReinitialize
              validationSchema={RegisterEvent}
              onSubmit={(values) => {
                setEventLoading(true);
                setEventError(null);
                app_api
                  .post("email-subscribers", values)
                  .then((res) => {
                    setEventLoading(false);
                    setEventSucces(true);
                  })
                  .catch((err) => {
                    setEventLoading(false);
                    setEventError(
                      err?.response?.data?.message ||
                      "Error registering for free gift"
                    );
                  });
              }}
            >
              {({
                handleBlur,
                handleChange,
                handleSubmit,
                errors,
                touched,
                values,
                isValid,
              }) => (
                <Form noValidate={true} onSubmit={handleSubmit}>
                  <Col md className="mb-3">
                    <FloatingLabel label="FirstName">
                      <InputBox
                        className="g-2"
                        name="first_name"
                        type="text"
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        isValid={touched.first_name && !errors.first_name}
                        isInvalid={touched.first_name && !!errors.first_name}
                      />
                      {touched.first_name && (
                        <FormControl.Feedback type="invalid">
                          {errors.first_name}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                  <Col md className="mb-3">
                    <FloatingLabel label="LastName">
                      <InputBox
                        className="g-2"
                        name="last_name"
                        type="text"
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        isValid={touched.last_name && !errors.last_name}
                        isInvalid={touched.last_name && !!errors.last_name}
                      />
                      {touched.last_name && (
                        <FormControl.Feedback type="invalid">
                          {errors.last_name}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                  <Col md className="mb-3">
                    <FloatingLabel label="Email">
                      <InputBox
                        name="email"
                        type="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required={true}
                        isValid={touched.email && !errors.email}
                        isInvalid={touched.email && !!errors.email}
                      />
                      {touched.email && (
                        <FormControl.Feedback type="invalid">
                          {errors.email}
                        </FormControl.Feedback>
                      )}
                    </FloatingLabel>
                  </Col>
                  <ModalFooter>
                    {eventError !== null && (
                      <span className="text-danger fs-6 col-12">
                        {eventError}
                      </span>
                    )}
                    {eventSucces && (
                      <span className="text-success fs-6 col-12">
                        Thank you for registering. Click here to download your
                        gift&nbsp;
                        <a href={free_gift} download onClick={handleClose}>
                          Free Gift
                        </a>
                      </span>
                    )}
                    <Button
                      variant="secondary"
                      type="button"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={!isValid || eventLoading}
                    >
                      {eventLoading ? "Submitting..." : "Submit"}
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      </Container>
    </div>
  );
};
export default PubertyAdventure;
