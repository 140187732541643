import { Modal } from 'react-bootstrap';
import React from "react";
import { UtVideo } from "../../atoms/video";
function Mvideo({ isOpen, url, onClose }) {


    return (
        <Modal
            size='xl'
            show={isOpen} onHide={onClose}>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <UtVideo
                    controls
                    autoPlay={true}
                    width="100%"
                    height="auto"
                    src={url}
                    controlsList="nodownload"
                    style={{ boxShadow: "rgb(0 0 0 / 20%) 0px 3px 10px", borderRadius: "10px" }}
                />
            </Modal.Body>
        </Modal>
    );
}

export default Mvideo;