import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import { app_api } from "../../atoms/api";
import Cookies from "js-cookie";
import Spinner from "react-bootstrap/Spinner";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalFooter from "react-bootstrap/ModalFooter";
import Alert from "react-bootstrap/Alert";
import { Formik } from "formik";
import { LearnerSchema, NewLearnerSchema } from "../../atoms/schema";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import FormSelect from "react-bootstrap/FormSelect";
import FormControl from "react-bootstrap/FormControl";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import FormCheck from "react-bootstrap/FormCheck";
import FormGroup from "react-bootstrap/FormGroup";
import { InfoSpan } from "./redeem";

export const RedeemGiftCode = (props) => {
  const params = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [gift, setGift] = useState({});

  const [user, setUser] = useState({});

  const [redeem, setRedeem] = useState(false);

  const [lLoading, setlLoading] = useState(false);
  const [lError, setlError] = useState(null);
  const [learners, setLearners] = useState([]);

  const [aLoading, setaLoading] = useState(false);
  const [aError, setaError] = useState(null);

  const [eLoading, seteLoading] = useState(false);
  const [eError, seteError] = useState(null);
  const [eSuccess, seteSuccess] = useState(false);

  useEffect(() => {
    app_api
      .get(`gift/code/${params.code}`)
      .then((res) => {
        setLoading(false);
        setGift(res.data);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Error");
      });
  }, [params.code]);

  useEffect(() => {
    try {
      let user = JSON.parse(Cookies.get("ut_user"));
      setUser(user);
      setlLoading(true);
      app_api
        .get(`learner/parent/${user?.id}`)
        .then((res) => {
          setlLoading(false);
          setLearners(res.data);
        })
        .catch((err) => {
          setlLoading(false);
          setlError(err?.response?.data?.message || "Error");
        });
    } catch {
      setUser({});
      setlLoading(false);
      setlError(null);
      setLearners([]);
    }
  }, [user?.id]);

  const loginClick = () => {
    navigate("/login?go_back=true");
  };

  const redeemClick = () => {
    if (user?.id) {
      setRedeem(true);
    } else {
      navigate("/login?go_back=true");
    }
  };

  const dashboardClick = () => {
    navigate("/dashboard/parent");
  };

  const redeemClose = () => setRedeem(false);
  const dismissRedeemError = () => seteError(null);
  const dimissAddLearnerError = () => setaError(null);
  const dismissLearnerError = () => setlError(null);

  return (
    <Container>
      <div className="my-10">
        <h3 className="f-40 secondary-blue">
          <b>Nice!</b>
        </h3>
        <h5 className="f-35 secondary-blue">
          Someone gifted you an UnTaboo program
        </h5>
        {loading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : error !== null ? (
          <span className="text-danger">{error}</span>
        ) : user?.id ? (
          !eSuccess ? (
            <Button
              variant="primary"
              className="text-white px-4 py-2"
              onClick={redeemClick}
              disabled={
                user?.email !== gift?.email_id ||
                user?.role !== "parent" ||
                !gift?.is_active
              }
            >
              Redeem Now
            </Button>
          ) : (
            <>
              <p className="text-success m-0">
                Success! You've successfully redeemed your gift. <br />
                You'll see your redeemed program on your dashboard
              </p>
              <Button
                variant="primary"
                className="text-white"
                onClick={dashboardClick}
              >
                Go to dashboard
              </Button>
            </>
          )
        ) : (
          <Button variant="primary" className="text-white" onClick={loginClick}>
            Login
          </Button>
        )}
        <br />
        {!!user?.id && 'email_id' in gift && user?.email !== gift?.email_id && (
          <p className="text-danger m-0">
            Sorry, this gift is not meant for you.
          </p>
        )}
        {!!user?.id && user?.role !== "parent" && (
          <p className="text-danger m-0">
            You need to be a parent to redeem gifts
          </p>
        )}
        {'is_active' in gift && !gift?.is_active && (
          <p className="text-info m-0">This gift is already redeemed</p>
        )}
        <Modal show={redeem} onHide={redeemClose}>
          <ModalHeader closeButton={true}>
            <ModalTitle>Choose a learner</ModalTitle>
          </ModalHeader>
          <ModalBody>
            {eError !== null && (
              <Alert
                variant="danger"
                dismissible={true}
                onClose={dismissRedeemError}
              >
                {eError}
              </Alert>
            )}
            {lError !== null && (
              <Alert
                variant="danger"
                dismissible={true}
                onClose={dismissLearnerError}
              >
                {lError}
              </Alert>
            )}
            <Formik
              initialValues={{
                learner_id: "",
              }}
              onSubmit={(values) => {
                seteLoading(true);
                app_api
                  .patch(`gift/${gift.id}/redeem`, {
                    ...values,
                    user_id: user?.id.toString(),
                  })
                  .then((res) => {
                    seteLoading(false);
                    seteSuccess(true);
                    setRedeem(false);
                  })
                  .catch((err) => {
                    seteLoading(false);
                    seteError(
                      err?.response?.data?.message || "Error redeeming"
                    );
                  });
              }}
              validationSchema={LearnerSchema}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                touched,
                errors,
                values,
                isValid,
              }) => (
                <Form
                  noValidate={true}
                  onSubmit={handleSubmit}
                  className="mb-4"
                >
                  <InputGroup className="mb-2">
                    <InputGroup.Text>Learner</InputGroup.Text>
                    <FormSelect
                      name="learner_id"
                      value={values.learner_id}
                      required={true}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isValid={touched.learner_id && !errors.learner_id}
                      isInvalid={touched.learner_id && !!errors.learner_id}
                    >
                      <option value="">Select a learner</option>
                      {learners.map((l) => (
                        <option value={l?.id}>
                          {l?.first_name} {l?.last_name}
                        </option>
                      ))}
                    </FormSelect>
                    {touched.learner_id && (
                      <FormControl.Feedback type="invalid">
                        {errors.learner_id}
                      </FormControl.Feedback>
                    )}
                  </InputGroup>
                  <Button
                    type="submit"
                    variant="primary"
                    className="text-white"
                    disabled={!isValid || eLoading}
                  >
                    {eLoading
                      ? [
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />,
                          " Enrolling...",
                        ]
                      : "Select learner"}
                  </Button>
                </Form>
              )}
            </Formik>
            {aError !== null && (
              <Alert
                variant="danger"
                dismissible={true}
                onClose={dimissAddLearnerError}
              >
                {aError}
              </Alert>
            )}
            <span>Or, create a new learner</span>
            <Formik
              initialValues={{
                first_name: "",
                last_name: user?.last_name || "",
                gender: "",
                date_of_birth: "",
                parent_id: user?.id,
                program_id: gift?.program_id,
                username: "",
                password: "",
              }}
              validationSchema={NewLearnerSchema}
              onSubmit={(values) => {
                setlLoading(true);
                app_api
                  .post("learner", values)
                  .then((res) => {
                    setlLoading(false);
                    let learner = learners;
                    learner.push(res.data);
                    setLearners(learner);
                    seteLoading(true);
                    app_api
                      .patch(`gift/${gift.id}/redeem`, {
                        learner_id: res.data.id.toString(),
                        user_id: user?.id.toString(),
                      })
                      .then((res) => {
                        seteLoading(false);
                        seteSuccess(true);
                        setRedeem(false);
                      })
                      .catch((err) => {
                        seteLoading(false);
                        seteError(
                          err?.response?.data?.message ||
                            "Learner added but error redeeming"
                        );
                      });
                  })
                  .catch((err) => {
                    setlLoading(false);
                    setlError(
                      `Adding learner error: ${err?.response?.data?.message}` ||
                        "Adding learner error: Error"
                    );
                  });
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                setValues,
                touched,
                values,
                errors,
                isValid,
              }) => (
                <Form
                  noValidate={true}
                  onSubmit={handleSubmit}
                  className="mt-2"
                >
                  <Row className="mb-2 g-2">
                    <Col md>
                      <FloatingLabel
                        controlId="add-learner.first_name"
                        label="First name"
                      >
                        <FormControl
                          type="text"
                          name="first_name"
                          placeholder="First name"
                          required={true}
                          value={values.first_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.first_name && !errors.first_name}
                          isInvalid={touched.first_name && !!errors.first_name}
                        />
                        {touched.first_name && (
                          <FormControl.Feedback type="invalid">
                            {errors.first_name}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel
                        controlId="add-learner.last_name"
                        label="Last name"
                      >
                        <FormControl
                          type="text"
                          name="last_name"
                          placeholder="Last name"
                          value={values.last_name}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.last_name && !errors.last_name}
                        />
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <Row className="mb-2 g-2">
                    <Col md>
                      <FloatingLabel
                        controlId="add-learner.date_of_birth"
                        label="Date of birth"
                      >
                        <FormControl
                          type="date"
                          name="date_of_birth"
                          placeholder="Date of birth"
                          required={true}
                          value={values.date_of_birth}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={
                            touched.date_of_birth && !errors.date_of_birth
                          }
                          isInvalid={
                            touched.date_of_birth && !!errors.date_of_birth
                          }
                        />
                        {touched.date_of_birth && (
                          <FormControl.Feedback type="invalid">
                            {errors.date_of_birth}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FormGroup>
                        <FormCheck
                          type="radio"
                          name="gender"
                          label="Female"
                          value="F"
                          inline={true}
                          checked={values.gender === "F"}
                          required={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.gender && !errors.gender}
                          isInvalid={touched.gender && !!errors.gender}
                        />
                        <FormCheck
                          type="radio"
                          name="gender"
                          label="Male"
                          value="M"
                          inline={true}
                          checked={values.gender === "M"}
                          required={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.gender && !errors.gender}
                          isInvalid={touched.gender && !!errors.gender}
                        />
                        <FormCheck
                          type="radio"
                          name="gender"
                          label="Other"
                          value="O"
                          inline={true}
                          checked={values.gender === "O"}
                          required={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.gender && !errors.gender}
                          isInvalid={touched.gender && !!errors.gender}
                        />
                        {touched.gender && (
                          <FormControl.Feedback type="invalid">
                            {errors.gender}
                          </FormControl.Feedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mb-2 g-2">
                    <Col md>
                      <FloatingLabel
                        controlId="add-learner.username"
                        label="Set username"
                      >
                        <FormControl
                          type="text"
                          name="username"
                          placeholder="Set username"
                          required={true}
                          value={values.username}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.username && !errors.username}
                          isInvalid={touched.username && !!errors.username}
                        />
                        {touched.username && (
                          <FormControl.Feedback type="invalid">
                            {errors.username}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                    <Col md>
                      <FloatingLabel
                        controlId="add-learner.password"
                        label="Set password"
                      >
                        <FormControl
                          type="password"
                          name="password"
                          placeholder="Set password"
                          autoComplete="new-password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isValid={touched.password && !errors.password}
                          isInvalid={touched.password && !!errors.password}
                        />
                        {touched.password && (
                          <FormControl.Feedback type="invalid">
                            {errors.password}
                          </FormControl.Feedback>
                        )}
                      </FloatingLabel>
                    </Col>
                  </Row>
                  <ModalFooter className="mt-2">
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={redeemClose}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      variant="primary"
                      className="text-white"
                      disabled={!isValid || aLoading || eLoading}
                    >
                      {aLoading || eLoading
                        ? [
                            <Spinner
                              as="span"
                              animation="border"
                              size="sm"
                              role="status"
                              aria-hidden="true"
                            />,
                            aLoading ? " Adding..." : " Enrolling...",
                          ]
                        : "Add learner"}
                    </Button>
                  </ModalFooter>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </Modal>
      </div>
    </Container>
  );
};
