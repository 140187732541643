import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalBody from "react-bootstrap/ModalBody";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import CardImg from "react-bootstrap/CardImg";
import Button from "react-bootstrap/Button";
import pdf_img from "../../assets/images/pdf1.png";
import pdf_img3 from "../../assets/images/thumbnail.png";
import { AiOutlineDownload } from "react-icons/ai";
import { saveAs } from "file-saver";
export async function downloadPDF(name, link) {
  try {
    const blob = await fetch(link).then((r) => r.blob());
    saveAs(blob, `${name}.pdf`);
  } catch (error) {
    console.error("Error downloading PDF:", error);
  }
}
export const Notes = (props) => {
  const [pdfLink, setPdfLink] = useState("");

  const handleClose = () => setPdfLink("");

  document.oncontextmenu = function () {
    return false;
  };
  return (
    <Container className="py-3">
      <Modal
        show={!!pdfLink}
        animation={true}
        onHide={handleClose}
        size="lg"
        fullscreen={true}
        centered={true}
      >
        <ModalHeader closeButton={true}></ModalHeader>
        <ModalBody>
          <iframe
            title="free-resource-pdf"
            src={pdfLink + "#toolbar=0"}
            width="100%"
            height="100%"
          />
        </ModalBody>
      </Modal>
      <Row className="g-3">
        <Col md="4">
          <Card>
            <CardImg variant="top p-1" src={"/peerpressure.png"} />
            <hr className="my-1"></hr>
            <Card.Body className="align-self-center gap-4">
              <Button
                className="rounded-3 text-white"
                onClick={() =>
                  setPdfLink(
                    process.env.REACT_APP_BACKEND_URL +
                    "uploads/pdf/ppts_pdf/Teacher's%20Notes%20Peer%20Pressure.pdf"
                  )
                }
                variant="primary"
              >
                Peer pressure
              </Button>
              <Button
                style={{
                  marginLeft:5
                }}
                className="rounded-3 text-white"
                onClick={() =>
                  downloadPDF("peer_pressure",
                    process.env.REACT_APP_BACKEND_URL +
                    "uploads/pdf/ppts_pdf/Teacher's%20Notes%20Peer%20Pressure.pdf"
                  )
                }
                variant="primary"
              >
                <AiOutlineDownload />
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <CardImg variant="top p-1" src={"/Bodypositivity.png"} />
            <hr className="my-1"></hr>
            <Card.Body className="align-self-center">
              <Button
                className="rounded-3 text-white"
                onClick={() =>
                  setPdfLink(
                    process.env.REACT_APP_BACKEND_URL +
                    "uploads/pdf/ppts_pdf/Teacher's%20Notes%20Body%20Positivity.pdf"
                  )
                }
                variant="primary"
              >
                Body Positivity
              </Button>
              <Button
                style={{
                  marginLeft:5
                }}
                className="rounded-3 text-white"
                onClick={() =>
                  downloadPDF("body_positivity",
                    process.env.REACT_APP_BACKEND_URL +
                    "uploads/pdf/ppts_pdf/Teacher's%20Notes%20Body%20Positivity.pdf"
                  )
                }
                variant="primary"
              >
                <AiOutlineDownload />
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <CardImg variant="top p-1" src={"/Communication.png"} />
            <hr className="my-1"></hr>
            <Card.Body className="align-self-center">
              <Button
                className="rounded-3 text-white"
                onClick={() =>
                  setPdfLink(
                    process.env.REACT_APP_BACKEND_URL +
                    "uploads/pdf/ppts_pdf/Teacher's%20Notes%20Communication%20Skills.pdf"
                  )
                }
                variant="primary"
              >
                Communications skill
              </Button>
              <Button
                style={{
                  marginLeft:5
                }}
                className="rounded-3 text-white"
                onClick={() =>
                  downloadPDF("communications_skills",
                    process.env.REACT_APP_BACKEND_URL +
                    "uploads/pdf/ppts_pdf/Teacher's%20Notes%20Communication%20Skills.pdf"
                  )
                }
                variant="primary"
              >
                <AiOutlineDownload />
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <CardImg variant="top p-1" src={"/Objectification.png"} />
            <hr className="my-1"></hr>
            <Card.Body className="align-self-center">
              <Button
                className="rounded-3 text-white"
                onClick={() =>
                  setPdfLink(
                    process.env.REACT_APP_BACKEND_URL +
                    "uploads/pdf/ppts_pdf/Teacher's%20PPT%20Notes%20Objectification%20&%20Foul%20Language).pdf"
                  )
                }
                variant="primary"
              >
                Objectification and foul language
              </Button>
              <Button
                style={{
                  marginLeft:5
                }}
                className="rounded-3 text-white"
                onClick={() =>
                  downloadPDF("Objectification and foul language",
                    process.env.REACT_APP_BACKEND_URL +
                    "uploads/pdf/ppts_pdf/Teacher's%20PPT%20Notes%20Objectification%20&%20Foul%20Language).pdf"
                  )
                }
                variant="primary"
              >
                <AiOutlineDownload />
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col md="4">
          <Card>
            <CardImg variant="top p-1" src={"/20230827_001843_0000.png"} />
            <hr className="my-1"></hr>
            <Card.Body className="align-self-center">
              <Button
                className="rounded-3 text-white"
                onClick={() =>
                  setPdfLink(
                    process.env.REACT_APP_BACKEND_URL +
                    "uploads/pdf/ppts_pdf/Teacher's%20Notes%20Puberty%20Allies.pdf"
                  )
                }
                variant="primary"
              >
                Puberty Ally
              </Button>
              <Button
                style={{
                  marginLeft:5
                }}
                className="rounded-3 text-white"
                onClick={() =>
                  downloadPDF("Puberty Alies",
                    process.env.REACT_APP_BACKEND_URL +
                    "uploads/pdf/ppts_pdf/Teacher's%20Notes%20Puberty%20Allies.pdf"
                  )
                }
                variant="primary"
              >
                <AiOutlineDownload />
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
