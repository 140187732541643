import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Spinner from "react-bootstrap/Spinner";
import { useParams, useNavigate } from "react-router-dom";
import { app_api } from "../../atoms/api";
import { UtImg } from "../../atoms/image";
import { Sections } from "./sections";
import { ProgramSideNav } from "../../templates/programSideNav";

export const Banner = styled(UtImg)`
  width: 80%;
`;

export const Program = (props) => {
  const [currentSection, setCurrentSection] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [qLoading, setqLoading] = useState(false);
  const [qError, setqError] = useState(null);
  const [enrollment, setEnrollment] = useState({});
  const [program, setProgram] = useState({});
  const [question, setQuestion] = useState({});

  const [hasLearn, setHasLearn] = useState(false);
  const [hasDiscuss, setHasDiscuss] = useState(false);
  const [nextSection, setNextSection] = useState("learn");

  const [isPost, setIsPost] = useState(false);

  const params = useParams();

  useEffect(() => {
    app_api
      .get(`enrollment/${params.enrollmentId}`)
      .then((res) => {
        setEnrollment(res.data);
        if (!!res.data) {
          try {
            let level_sequence = res.data?.program?.level_sequence;
            let levelId = level_sequence[res.data?.current_level];
            if (res.data?.is_school && res.data?.school_type === "hybrid") {
              levelId =
                level_sequence[
                  res.data?.current_level > res.data?.lockedAt
                    ? res.data?.lockedAt
                    : res.data?.current_level
                ];
            }
            if (levelId) {
              app_api
                .get(`levels/${levelId}/previousLevel`)
                .then((res) => {
                  setLoading(false);

                  if (
                    !!enrollment.current_level_name &&
                    enrollment.current_level_name === "Post assessment"
                  ) {
                    let id = enrollment.current_level + 1;
                  } else {
                    setProgram(res.data);
                  }

                  // console.log(res.data);
                  if (res.data?.watch?.length > 0) {
                    // console.log(res.data);
                    setCurrentSection("watch");
                  } else {
                    if (res.data?.learn?.length > 0) setCurrentSection("learn");
                    else {
                      if (res.data?.discuss?.length > 0)
                        setCurrentSection("discuss");
                      else setCurrentSection("play");
                    }
                  }

                  if (res.data?.learn?.length > 0) setHasLearn(true);
                  if (res.data?.discuss?.length > 0) setHasDiscuss(true);
                })
                .catch((err) => {
                  setLoading(false);
                  setError(err?.response?.data?.message || "Error");
                });
            } else {
              setLoading(false);
              if (
                res.data?.current_level_name === "Completed" &&
                !!res.data?.surveyed_on
              ) {
                navigate(`previous/${level_sequence[0]}`);
              } else {
                navigate(`success/survey`);
              }
            }
            if (res.data?.current_level_name !== "Post assessment") {
              setIsPost(false);
              app_api
                .get(`levels/${levelId}/question`)
                .then((res) => {
                  setqLoading(false);
                  setQuestion(res.data);
                })
                .catch((err) => {
                  setLoading(false);
                  setqError(
                    err?.response?.data?.message || "Error getting questions"
                  );
                });
            } else {
              setIsPost(true);
              setCurrentSection("play");
              app_api
                .get(`levels/program/${res.data?.program_id}/post-assessment`)
                .then((res) => {
                  setqLoading(false);
                  setQuestion(res.data);
                })
                .catch((err) => {
                  setLoading(false);
                  setqError(
                    err?.response?.data?.message || "Error getting questions"
                  );
                });
            }
          } catch {
            setError("Error getting level ID");
          }
        } else {
          setLoading(false);
          setError("No enrollment found");
        }
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.message || "Error getting enrollment ID");
      });
  }, [params.enrollmentId]);

  const navigate = useNavigate();

  const onNextSectionClick = () => {
    if (currentSection === "watch") {
      if (hasLearn) {
        setCurrentSection("learn");
        setNextSection("discuss");
      } else if (hasDiscuss) {
        setCurrentSection("discuss");
        setNextSection("play");
      } else {
        setCurrentSection("play");
      }
    } else if (currentSection === "learn") {
      if (hasDiscuss) {
        setCurrentSection("discuss");
        setNextSection("play");
      } else {
        setCurrentSection("play");
      }
    } else if (currentSection === "discuss") {
      setCurrentSection("play");
    }
  };
  useEffect(() => {
    if (isPost) {
      setCurrentSection("play");
    }
  }, [isPost]);

  useEffect(() => {
    if (
      enrollment &&
      enrollment?.is_school &&
      enrollment?.current_level > enrollment?.lockedAt
    ) {
      const redirect_level = enrollment?.program?.levels[enrollment?.lockedAt];
      navigate(`previous/${redirect_level?.id}`);
    }
  }, [enrollment, navigate]);

  return (
    <div>
      <ProgramSideNav
        navbar
        program={program}
        loading={loading}
        error={error}
        enrollmentId={params.enrollmentId}
        enrollment={enrollment}
      />
      <Row>
        <ProgramSideNav
          program={program}
          loading={loading}
          error={error}
          enrollmentId={params.enrollmentId}
          enrollment={enrollment}
        />
        <Col xs="12" className="p-0 mx-auto w-80 w-sm-100 px-2 px-lg-0">
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : !!error ? (
            <label className="text-danger text-capitalize">{error}</label>
          ) : (
            <>
              <Sections
                enrollmentId={params.enrollmentId}
                program={program}
                questions={question}
                enrollment={enrollment}
                currentSection={isPost ? "play" : currentSection}
                setCurrentSection={setCurrentSection}
                questionLoading={qLoading}
                questionError={qError}
                processClick={onNextSectionClick}
                nextSection={nextSection}
                isPost={isPost}
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};
