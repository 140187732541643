import { useEffect } from "react";
import ReactGA from "react-ga";

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_CODE);

export const Ga = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return null;
};


